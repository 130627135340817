import cloneDeep from 'clone-deep'
import { PlainInput } from 'components/PlainInput'
import type { ILtvValues } from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import { useEffect, useState } from 'react'
import { Button, Input2 } from 'stories/components'

import { convertNullValue } from './logic'

interface IProps {
  isTemplate: boolean
  loading: LoadingStatus
  ltvAdjustment: ILtvValues
  isCommercial: boolean
  isMsa?: boolean
  onChange?: (ltvAdj: ILtvValues) => void
  onSave: (ltvAdj: ILtvValues) => void
}

export const TierLtvAdjustment = (props: IProps) => {
  const { isTemplate, ltvAdjustment, isCommercial, loading, isMsa = false, onSave, onChange = () => {} } = props

  const [ltvAdj, setLtvAdj] = useState<any>()
  const [isChangedLtv, setIsChangedLtv] = useState(false)
  const [dscrLtv, setDscrLtv] = useState('N/A')

  useEffect(() => {
    if (!ltvAdjustment) return

    setLtvAdj(ltvAdjustment)

    if (ltvAdjustment?.aivLtv) setDscrLtv(convertNullValue(ltvAdjustment?.aivLtv))
    else setDscrLtv('N/A')

    setIsChangedLtv(false)
  }, [ltvAdjustment])

  const onChangeLtvAdj = (key: string, value: string) => {
    let newValue

    if (value === '') newValue = null
    else newValue = isNaN(Number(value)) ? null : Number(value)

    const newLtvAdjustment = cloneDeep(ltvAdj)

    if (newLtvAdjustment[key] == newValue) return

    newLtvAdjustment[key] = newValue

    setIsChangedLtv(true)
    setLtvAdj(newLtvAdjustment)
    onChange(newLtvAdjustment)
  }

  const onChangeDscrLtvAdj = () => {
    let newValue

    if (dscrLtv === '') newValue = null
    else newValue = isNaN(Number(dscrLtv)) ? null : Number(dscrLtv)

    setDscrLtv(convertNullValue(newValue))

    const newLtvAdjustment = cloneDeep(ltvAdj)

    if (newLtvAdjustment['aivLtv'] == newValue) return

    newLtvAdjustment['aivLtv'] = newValue

    setIsChangedLtv(true)
    setLtvAdj(newLtvAdjustment)
    onChange(newLtvAdjustment)
  }

  return (
    <div>
      {isCommercial ? (
        <>
          <div className="flex justify-between items-end mb-2">
            <span className="font-bold text-base">LTV Adjustment</span>

            {isChangedLtv && !isMsa && (
              <Button
                loading={loading === LoadingStatus.TIER_VALUES}
                className="text-[14px] pt-1.5 pb-1.5 mb-[-1px]"
                onClick={() => {
                  onSave(ltvAdj)
                  setIsChangedLtv(false)
                }}
              >
                Save
              </Button>
            )}
          </div>

          {ltvAdj && (
            <table className="table text-sm w-full">
              <thead className="font-bold bg-gray-100 text-center">
                <tr>
                  <td className="px-3 py-2 border">AIV-LTV</td>
                  <td className="px-3 py-2 border">ARV-LTV</td>
                  <td className="px-3 py-2 border">LTC</td>
                  <td className="px-3 py-2 border">LTP</td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td className="px-3 py-1 w-1/4 border">
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullValue(ltvAdj.aivLtv)}
                        origin={convertNullValue(ltvAdj.aivLtv)}
                        content={convertNullValue(ltvAdj.aivLtv)}
                        disabled={!isTemplate}
                        className="w-full text-center"
                        onChange={(value: string) => onChangeLtvAdj('aivLtv', value)}
                      />
                    </div>
                  </td>
                  <td className="px-3 py-1 w-1/4 border">
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullValue(ltvAdj.arvLtv)}
                        origin={convertNullValue(ltvAdj.arvLtv)}
                        disabled={!isCommercial || !isTemplate}
                        content={convertNullValue(ltvAdj.arvLtv)}
                        className="w-full text-center"
                        onChange={(value: string) => onChangeLtvAdj('arvLtv', value)}
                      />
                    </div>
                  </td>
                  <td className="px-3 py-1 w-1/4 border">
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullValue(ltvAdj.ltc)}
                        origin={convertNullValue(ltvAdj.ltc)}
                        disabled={!isCommercial || !isTemplate}
                        content={convertNullValue(ltvAdj.ltc)}
                        className="w-full text-center"
                        onChange={(value: string) => onChangeLtvAdj('ltc', value)}
                      />
                    </div>
                  </td>
                  <td className="px-3 py-1 w-1/4 border">
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullValue(ltvAdj.ltp)}
                        origin={convertNullValue(ltvAdj.ltp)}
                        disabled={!isCommercial || !isTemplate}
                        content={convertNullValue(ltvAdj.ltp)}
                        className="w-full text-center"
                        onChange={(value: string) => onChangeLtvAdj('ltp', value)}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </>
      ) : (
        <>
          {ltvAdj && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-3">
              <Input2
                title="LTV Adjustment"
                value={dscrLtv}
                disabled={!isTemplate}
                onChange={(value: string) => setDscrLtv(value)}
                onBlur={() => onChangeDscrLtvAdj()}
              />

              <div className="flex items-center">
                {isChangedLtv && !isMsa && (
                  <Button
                    size="sm"
                    loading={loading === LoadingStatus.TIER_VALUES}
                    className="text-[14px] mb-[-1px] w-[100px]"
                    onClick={() => {
                      onSave(ltvAdj)
                      setIsChangedLtv(false)
                    }}
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}
