import { useState } from 'react'
import { Input2, Modal, Select2 } from 'stories/components'

import { LoadingStatus } from '..'

const productTypes: Record<string, string> = {
  Bridge: 'Bridge',
  FixAndFlip: 'Fix & Flip / Fix to Rent',
  GroundUpConstruction: 'Ground Up Construction',
  DSCR: 'DSCR (Long Term Rental)',
}

export const NewProgramModal = (props: any) => {
  const [name, setName] = useState('')
  const [type, setType] = useState('')

  return (
    <div>
      <Modal
        title="Add New Program"
        titleOkay="Add"
        isOpen={props.isOpen}
        loading={props.loading === LoadingStatus.ADD_PROGRAM}
        onClose={props.onClose}
        onOk={() => props.onSubmit(name, type)}
      >
        <div className="w-[400px]">
          <Input2 title="Program Name" onChange={(value) => setName(value)} value={name} className="mb-4" />
          <Select2
            title="Product Type"
            id="type"
            onChange={(value) => setType(value)}
            value={type}
            options={productTypes}
            hasDefaultOption={true}
          />
        </div>
      </Modal>
    </div>
  )
}
