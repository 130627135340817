import { BarsArrowDownIcon, BarsArrowUpIcon } from '@heroicons/react/24/outline'

export const renderHeader = ({
  title,
  sortable = false,
  key,
  sortOrder = 1,
  index,
  onSort,
  border = false,
}: {
  title: string
  sortable: boolean
  key: string
  sortOrder: number
  index: number
  onSort: Function
  border?: boolean
}) => {
  if (!sortable)
    return (
      <th scope="col" className="py-3 px-2" key={`${title}-${index}`}>
        {title}
      </th>
    )

  const _onSort = () => {
    if (sortOrder == 0) sortOrder = -1
    onSort(key, 0 - sortOrder)
  }

  return (
    <th scope="col" className={`py-3 px-2 ${border ? 'border' : ''}`} key={`${title}-${index}`}>
      <button className="flex uppercase bg-transparent font-variation-settings-600 text-left" onClick={() => _onSort()}>
        {title}
        {sortOrder !== 0 ? (
          sortOrder == 1 ? (
            <BarsArrowUpIcon className="w-3 h-3 ml-2" />
          ) : (
            <BarsArrowDownIcon className="w-3 h-3 ml-2" />
          )
        ) : (
          <div className="w-3 h-3 ml-2" />
        )}
      </button>
    </th>
  )
}
