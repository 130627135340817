import { API_MASTER_PROGRAM, API_MASTER_PROGRAM_ID } from 'config'
import Api from 'services/api'

export const updateMasterProgram = (loanId: number, data: Record<string, any>) => {
  return Api.put(API_MASTER_PROGRAM, data, { loanId })
}

export const removeMasterProgram = (loanId: number, programID: number) => {
  return Api.delete(API_MASTER_PROGRAM_ID, {}, { loanId, programID })
}
