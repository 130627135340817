import { API_REHAB_BUDGET_TYPES_DEF, API_REHAB_BUDGET_TYPES_GENERAL_DEF, IRehabBudgetType } from 'config'
import Api from 'services/api'

export const getRehabBudgetTypeDefinition = (companyID: number) => {
  return Api.get(API_REHAB_BUDGET_TYPES_DEF, {}, { companyID })
}

export const updateRehabBudgetTypeDefinition = (companyID: number, data: IRehabBudgetType[]) => {
  return Api.post(API_REHAB_BUDGET_TYPES_DEF, data, { companyID })
}

export const updateRehabBudgetTypeGeneralDefinition = (data: Record<string, any>) => {
  return Api.put(API_REHAB_BUDGET_TYPES_GENERAL_DEF, data)
}
