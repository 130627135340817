import {
  bankruptcyOptions,
  citizenshipOptions,
  commercialOccupancyTypeOptions,
  CommercialProdutTypes,
  condoTypeOptions,
  escrowTypesOptions,
  foreclosuresOptions,
  InputType,
  loanTermOptions,
  mortgageLatesOptions,
  prePaymentPenaltyTermInMonthsOptions,
  transactionTypeOptions,
} from 'config'
import { forbearanceOptions, originalPropertyTypeLabels } from 'config/loanItems.constants'
import { statesConstant } from 'config/states.constants'

import { fromToNumberOptions } from './logic'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    loanName: {
      title: 'Loan Name',
      inputType: 'text',
      error: '',
      required: true,
      length: 1,
    },
    productType: {
      title: 'Product Type',
      inputType: 'select',
      hasDefaultOption: true,
      options: CommercialProdutTypes,
      error: '',
      required: true,
    },
    transactionType: {
      title: 'Transaction Type',
      inputType: 'select',
      hasDefaultOption: true,
      options: transactionTypeOptions,
      error: '',
      required: true,
    },
    propertyUnderConstruction: {
      title: 'Property Under Construction?',
      inputType: 'check',
      error: '',
      required: true,
    },
    isPropertyInLeasableState: {
      title: 'Can property be rented as is?',
      inputType: 'check',
      error: '',
      required: true,
    },
    isShortTermRental: {
      title: 'Is the property a short term rental?',
      inputType: 'check',
      error: '',
      required: true,
    },
    decliningMarketProperty: {
      title: 'Is the property located in a declining market?',
      inputType: 'check',
      error: '',
      required: true,
    },
    isNewConstructionProperty: {
      title: 'Is the property a new construction?',
      inputType: 'check',
      error: '',
      required: true,
    },
    proposedOccupancy: {
      title: 'Occupancy',
      inputType: 'select',
      hasDefaultOption: true,
      options: commercialOccupancyTypeOptions,
      error: '',
      required: true,
    },
    loanTerm: {
      title: 'Loan Term',
      inputType: 'select',
      hasDefaultOption: true,
      options: loanTermOptions,
      error: '',
      required: true,
    },
    proposedLoanAmount: {
      title: 'Proposed Loan Amount',
      inputType: 'text',
      key: 'proposedLoanAmount',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    propertyPurchasePrice: {
      title: 'Property Purchase Price',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    asIsValue: {
      title: 'As Is Appraised Value',
      key: 'asIsValue',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    afterRepairValue: {
      title: 'After Repair Value',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    rehabBudget: {
      title: 'Construction Budget',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    constructionReserve: {
      title: 'Construction Amount Financed',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    interestReserve: {
      title: 'Interest Reserve',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
      tooltip: 'The dollar amount of interest payment(s) being financed into the loan amount.',
    },
    experience: {
      title: 'Experience',
      inputType: 'select',
      hasDefaultOption: true,
      options: [
        '0 Transaction',
        '1 Transaction',
        '2 Transactions',
        '3 Transactions',
        '4 Transactions',
        '5+ Transactions',
      ],
      error: '',
      required: true,
    },
    howManyExperiences: {
      title: 'How Many Experiencies?',
      inputType: 'select',
      hasDefaultOption: true,
      options: {
        ...fromToNumberOptions(5, 24),
        25: '25+',
      },
      error: '',
      required: true,
    },
    floodInsurance: {
      title: 'Flood Insurance',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    schoolTax: {
      title: 'School Tax',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    otherTax: {
      title: 'Other Tax',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    proposedMonthlyTaxes: {
      title: 'Proposed Monthly Taxes',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    proposedMonthlyInsurance: {
      title: 'Proposed Monthly Insurance',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    proposedMonthlyRent: {
      title: 'Proposed Monthly Rent',
      key: 'proposedMonthlyRent',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    proposedMonthlyHoaDues: {
      title: 'Proposed Monthly HOA Dues',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    propertyType: {
      title: 'Property Type',
      inputType: 'select',
      hasDefaultOption: true,
      options: originalPropertyTypeLabels,
      error: '',
      required: true,
    },
    condoType: {
      title: 'Condo Type',
      inputType: 'select',
      hasDefaultOption: true,
      options: condoTypeOptions,
      error: '',
      required: true,
    },
    prepaymentPenalty: {
      title: 'Prepayment Penalty',
      inputType: 'select',
      hasDefaultOption: true,
      options: prePaymentPenaltyTermInMonthsOptions,
      error: '',
      required: true,
    },
    propertyHasBeenListed: {
      title: 'Has property been listed in the last 6 months?',
      inputType: 'check',
      error: '',
      length: 1,
      required: true,
    },
    residency: {
      title: 'Citizenship',
      inputType: 'select',
      hasDefaultOption: true,
      options: citizenshipOptions,
      error: '',
      required: true,
    },
    amortizationType: {
      title: 'Amortization Type',
      inputType: 'select',
      hasDefaultOption: true,
      options: ['ARM', 'Fixed', 'IO - ARM', 'IO - Fixed'],
      error: '',
      required: true,
    },
    escrowType: {
      title: 'Escrow Type',
      inputType: 'select',
      hasDefaultOption: true,
      options: escrowTypesOptions,
      error: '',
      required: true,
    },
    firstTimeHomeBuyer: {
      title: 'First Time Home Buyer?',
      inputType: 'check',
      error: '',
      required: true,
    },
    firstTimeHomeInvestor: {
      title: 'First Time Home Investor?',
      inputType: 'check',
      error: '',
      required: true,
    },
    ruralProperty: {
      title: 'Rural Property?',
      inputType: 'check',
      error: '',
      required: true,
    },
    bankruptcy: {
      title: 'Bankruptcy',
      inputType: 'select',
      hasDefaultOption: true,
      options: bankruptcyOptions,
      error: '',
      required: true,
    },
    foreclosure: {
      title: 'Foreclosure',
      inputType: 'select',
      hasDefaultOption: true,
      options: foreclosuresOptions,
      error: '',
      required: true,
    },
    deedInLieu: {
      title: 'Deed-In-Lieu',
      inputType: 'select',
      hasDefaultOption: true,
      options: foreclosuresOptions,
      error: '',
      required: true,
    },
    shortSale: {
      title: 'Short Sale',
      inputType: 'select',
      hasDefaultOption: true,
      options: foreclosuresOptions,
      error: '',
      required: true,
    },
    mortgageLates: {
      title: 'Mortgage Lates',
      inputType: 'select',
      hasDefaultOption: true,
      options: mortgageLatesOptions,
      error: '',
      required: true,
    },
    forbearanceLoanModification: {
      title: 'Forbearance / Loan Modification',
      inputType: 'select',
      hasDefaultOption: true,
      options: forbearanceOptions,
      error: '',
      required: true,
    },
    secondLien: {
      title: 'Second Lien',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    secondLienPayment: {
      title: 'Second Lien Payment',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    lienPayoff: {
      title: 'Lien Payoff',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    closingCostEstimate: {
      title: 'Closing Cost Estimate',
      inputType: 'text',
      type: 'thousandSep',
      error: '',
      prefix: '$',
      required: true,
    },
    monthsReserve: {
      title: 'Months Reserve',
      inputType: 'text',
      type: 'number',
      error: '',
      required: true,
    },
    estimatedDscr: {
      title: 'DSCR',
      inputType: 'select',
      hasDefaultOption: true,
      options: {},
      error: '',
      required: true,
      visible: false,
    },
    numberOfUnits: {
      title: 'Number of Units',
      inputType: 'text',
      type: 'number',
      error: '',
      required: true,
    },
    isNoScore: {
      title: 'No Score',
      inputType: 'checkbox',
      color: 'gray',
      className: 'mt-6',
      value: false,
      error: '',
    },
    estimatedCreditScore: {
      title: 'Credit Score',
      key: 'estimatedCreditScore',
      inputType: 'text',
      type: 'creditScore',
      error: '',
      required: true,
    },
    propertyState: {
      title: 'State',
      inputType: 'select',
      hasDefaultOption: true,
      options: statesConstant,
      error: '',
      required: true,
    },
    isCEMATransaction: {
      title: 'Is this transaction a CEMA?',
      inputType: 'check',
      error: '',
      length: 1,
      required: true,
    },
    subjectPropertyAddress: {
      title: 'Subject Property Address',
      inputType: 'Map',
      error: '',
      required: true,
      length: 1,
    },
    additionalPropertyAddress: {
      title: 'Additional Subject Property Address',
      inputType: 'AutoComplete',
      autoCompleteType: 'map',
      error: '',
      length: 1,
    },
    ltvAdjustment: {
      title: 'LTV Adj',
      inputType: 'text',
      type: 'number',
      error: '',
    },
    cltvAdjustment: {
      title: 'CLTV Adj',
      inputType: 'text',
      type: 'number',
      error: '',
    },
    aivLtvAdjustment: {
      title: 'AIV-LTV Adj',
      inputType: 'text',
      type: 'number',
      error: '',
    },
    arvLtvAdjustment: {
      title: 'ARV-LTV Adj',
      inputType: 'text',
      type: 'number',
      error: '',
    },
    ltcAdjustment: {
      title: 'LTC Adj',
      inputType: 'text',
      type: 'number',
      error: '',
    },
    ltpAdjustment: {
      title: 'LTP Adj',
      inputType: 'text',
      type: 'number',
      error: '',
    },
  }
}

export const fieldsByTransactionTypeAndProductTypeConstant: { [key: string]: { [key1: string]: string[] } } = {
  Bridge: {
    Purchase: [
      'proposedOccupancy',
      'loanTerm',
      'proposedLoanAmount',
      'propertyPurchasePrice',
      'asIsValue',
      'interestReserve',
      'closingCostEstimate',
      'experience',
      'propertyType',
      'numberOfUnits',
      'bankruptcy',
      'foreclosure',
      'deedInLieu',
      'shortSale',
      'mortgageLates',
      'forbearanceLoanModification',
      'secondLien',
      'firstTimeHomeBuyer',
      'firstTimeHomeInvestor',
      'propertyUnderConstruction',
      'isPropertyInLeasableState',
      'decliningMarketProperty',
      'isNewConstructionProperty',
      'ruralProperty',
      'isNoScore',
      'estimatedCreditScore',
      'propertyState',
      'subjectPropertyAddress',
      'additionalPropertyAddress',
      'residency',
      'amortizationType',
      'monthsReserve',
      'aivLtvAdjustment',
      'arvLtvAdjustment',
      'ltcAdjustment',
      'ltpAdjustment',
    ],
    Refinance: [
      'proposedOccupancy',
      'loanTerm',
      'proposedLoanAmount',
      'asIsValue',
      'interestReserve',
      'lienPayoff',
      'closingCostEstimate',
      'experience',
      'propertyType',
      'numberOfUnits',
      'bankruptcy',
      'foreclosure',
      'deedInLieu',
      'shortSale',
      'mortgageLates',
      'forbearanceLoanModification',
      'secondLien',
      'firstTimeHomeBuyer',
      'firstTimeHomeInvestor',
      'propertyUnderConstruction',
      'isPropertyInLeasableState',
      'decliningMarketProperty',
      'isNewConstructionProperty',
      'ruralProperty',
      'isNoScore',
      'estimatedCreditScore',
      'propertyState',
      'subjectPropertyAddress',
      'additionalPropertyAddress',
      'residency',
      'amortizationType',
      'monthsReserve',
      'aivLtvAdjustment',
      'arvLtvAdjustment',
      'ltcAdjustment',
      'ltpAdjustment',
    ],
  },
  FixAndFlip: {
    Purchase: [
      'proposedOccupancy',
      'loanTerm',
      'proposedLoanAmount',
      'propertyPurchasePrice',
      'asIsValue',
      'interestReserve',
      'closingCostEstimate',
      'experience',
      'afterRepairValue',
      'rehabBudget',
      'constructionReserve',
      'propertyType',
      'numberOfUnits',
      'bankruptcy',
      'foreclosure',
      'deedInLieu',
      'shortSale',
      'mortgageLates',
      'forbearanceLoanModification',
      'secondLien',
      'firstTimeHomeBuyer',
      'firstTimeHomeInvestor',
      'ruralProperty',
      'decliningMarketProperty',
      'isNoScore',
      'estimatedCreditScore',
      'propertyState',
      'subjectPropertyAddress',
      'additionalPropertyAddress',
      'residency',
      'amortizationType',
      'monthsReserve',
      'aivLtvAdjustment',
      'arvLtvAdjustment',
      'ltcAdjustment',
      'ltpAdjustment',
    ],
    Refinance: [
      'proposedOccupancy',
      'loanTerm',
      'proposedLoanAmount',
      'asIsValue',
      'interestReserve',
      'lienPayoff',
      'closingCostEstimate',
      'experience',
      'afterRepairValue',
      'rehabBudget',
      'constructionReserve',
      'propertyType',
      'numberOfUnits',
      'bankruptcy',
      'foreclosure',
      'deedInLieu',
      'shortSale',
      'mortgageLates',
      'forbearanceLoanModification',
      'secondLien',
      'firstTimeHomeBuyer',
      'firstTimeHomeInvestor',
      'ruralProperty',
      'decliningMarketProperty',
      'isNoScore',
      'estimatedCreditScore',
      'propertyState',
      'subjectPropertyAddress',
      'additionalPropertyAddress',
      'residency',
      'amortizationType',
      'monthsReserve',
      'aivLtvAdjustment',
      'arvLtvAdjustment',
      'ltcAdjustment',
      'ltpAdjustment',
    ],
  },
  GroundUpConstruction: {
    Purchase: [
      'proposedOccupancy',
      'loanTerm',
      'proposedLoanAmount',
      'propertyPurchasePrice',
      'asIsValue',
      'interestReserve',
      'closingCostEstimate',
      'experience',
      'afterRepairValue',
      'rehabBudget',
      'constructionReserve',
      'propertyType',
      'numberOfUnits',
      'bankruptcy',
      'foreclosure',
      'deedInLieu',
      'shortSale',
      'mortgageLates',
      'forbearanceLoanModification',
      'secondLien',
      'firstTimeHomeBuyer',
      'firstTimeHomeInvestor',
      'ruralProperty',
      'decliningMarketProperty',
      'isNoScore',
      'estimatedCreditScore',
      'propertyState',
      'subjectPropertyAddress',
      'additionalPropertyAddress',
      'residency',
      'amortizationType',
      'monthsReserve',
      'aivLtvAdjustment',
      'arvLtvAdjustment',
      'ltcAdjustment',
      'ltpAdjustment',
    ],
    Refinance: [
      'proposedOccupancy',
      'loanTerm',
      'proposedLoanAmount',
      'asIsValue',
      'interestReserve',
      'lienPayoff',
      'closingCostEstimate',
      'experience',
      'afterRepairValue',
      'rehabBudget',
      'constructionReserve',
      'propertyType',
      'numberOfUnits',
      'bankruptcy',
      'foreclosure',
      'deedInLieu',
      'shortSale',
      'mortgageLates',
      'forbearanceLoanModification',
      'secondLien',
      'firstTimeHomeBuyer',
      'firstTimeHomeInvestor',
      'ruralProperty',
      'decliningMarketProperty',
      'isNoScore',
      'estimatedCreditScore',
      'propertyState',
      'subjectPropertyAddress',
      'additionalPropertyAddress',
      'residency',
      'amortizationType',
      'monthsReserve',
      'aivLtvAdjustment',
      'arvLtvAdjustment',
      'ltcAdjustment',
      'ltpAdjustment',
    ],
  },
  DSCR: {
    Purchase: [
      'proposedOccupancy',
      'proposedLoanAmount',
      'propertyPurchasePrice',
      'asIsValue',
      'closingCostEstimate',
      'propertyType',
      'numberOfUnits',
      'prepaymentPenalty',
      'residency',
      'amortizationType',
      'escrowType',
      'firstTimeHomeBuyer',
      'firstTimeHomeInvestor',
      'ruralProperty',
      'decliningMarketProperty',
      'isNewConstructionProperty',
      'isPropertyInLeasableState',
      'isShortTermRental',
      'bankruptcy',
      'foreclosure',
      'deedInLieu',
      'shortSale',
      'mortgageLates',
      'forbearanceLoanModification',
      'secondLien',
      'monthsReserve',
      'estimatedDscr',
      'proposedMonthlyRent',
      'proposedMonthlyTaxes',
      'proposedMonthlyInsurance',
      'proposedMonthlyHoaDues',
      'floodInsurance',
      'schoolTax',
      'otherTax',
      'isNoScore',
      'estimatedCreditScore',
      'propertyState',
      'subjectPropertyAddress',
      'additionalPropertyAddress',
      'ltvAdjustment',
      'cltvAdjustment',
    ],
    Refinance: [
      'proposedOccupancy',
      'proposedLoanAmount',
      'asIsValue',
      'lienPayoff',
      'closingCostEstimate',
      'propertyType',
      'numberOfUnits',
      'prepaymentPenalty',
      'propertyHasBeenListed',
      'residency',
      'amortizationType',
      'escrowType',
      'firstTimeHomeBuyer',
      'firstTimeHomeInvestor',
      'ruralProperty',
      'decliningMarketProperty',
      'isNewConstructionProperty',
      'isPropertyInLeasableState',
      'isShortTermRental',
      'bankruptcy',
      'foreclosure',
      'deedInLieu',
      'shortSale',
      'mortgageLates',
      'forbearanceLoanModification',
      'secondLien',
      'monthsReserve',
      'estimatedDscr',
      'proposedMonthlyRent',
      'proposedMonthlyTaxes',
      'proposedMonthlyInsurance',
      'proposedMonthlyHoaDues',
      'floodInsurance',
      'schoolTax',
      'otherTax',
      'isNoScore',
      'estimatedCreditScore',
      'propertyState',
      'subjectPropertyAddress',
      'additionalPropertyAddress',
      'ltvAdjustment',
      'cltvAdjustment',
    ],
  },
}

export const groupedFields = [
  ['loanName'],
  [
    'productType',
    'transactionType',
    'propertyUnderConstruction',
    'isPropertyInLeasableState',
    'isShortTermRental',
    'decliningMarketProperty',
    'isNewConstructionProperty',
  ],
  ['proposedOccupancy', 'loanTerm', 'prepaymentPenalty', 'propertyHasBeenListed'],
  [
    'propertyPurchasePrice',
    'asIsValue',
    'afterRepairValue',
    'interestReserve',
    'secondLien',
    'secondLienPayment',
    'lienPayoff',
    'rehabBudget',
    'constructionReserve',
    'closingCostEstimate',
    'proposedLoanAmount',
  ],
  ['experience', 'howManyExperiences'],
  ['amortizationType', 'escrowType'],
  ['isNoScore', 'propertyState', 'estimatedCreditScore', 'isCEMATransaction'],
  ['propertyType', 'condoType', 'numberOfUnits'],
  ['firstTimeHomeBuyer', 'firstTimeHomeInvestor', 'ruralProperty'],
  [
    'bankruptcy',
    'foreclosure',
    'deedInLieu',
    'shortSale',
    'mortgageLates',
    'forbearanceLoanModification',
    'residency',
    'monthsReserve',
  ],
  [
    'proposedMonthlyRent',
    'proposedMonthlyTaxes',
    'proposedMonthlyInsurance',
    'proposedMonthlyHoaDues',
    'floodInsurance',
    'schoolTax',
    'otherTax',
    'estimatedDscr',
  ],
  ['subjectPropertyAddress', 'additionalPropertyAddress'],
  ['ltvAdjustment', 'cltvAdjustment', 'aivLtvAdjustment', 'arvLtvAdjustment', 'ltcAdjustment', 'ltpAdjustment'],
]

export const priceLoanRelatedFields = [
  'transactionType',
  'productType',
  'loanTerm',
  'proposedOccupancy',
  'proposedLoanAmount',
  'propertyPurchasePrice',
  'asIsValue',
  'afterRepairValue',
  'rehabBudget',
  'interestReserve',
  'constructionReserve',
  'escrowType',
  'propertyType',
  'numberOfUnits',
  'propertyHasBeenListed',
  'firstTimeHomeBuyer',
  'firstTimeHomeInvestor',
  'propertyUnderConstruction',
  'isPropertyInLeasableState',
  'isShortTermRental',
  'ruralProperty',
  'decliningMarketProperty',
  'isCEMATransaction',
  'isNewConstructionProperty',
  'bankruptcy',
  'foreclosure',
  'deedInLieu',
  'shortSale',
  'mortgageLates',
  'forbearanceLoanModification',
  'secondLien',
  'secondLienPayment',
  'monthsReserve',
  'estimatedDscr',
  'numberOfUnits',
  'condoType',
  'prepaymentPenalty',
  'residency',
  'amortizationType',
  'subjectPropertyAddress',
  'additionalPropertyAddress',
  'isNoScore',
  'estimatedCreditScore',
  'propertyState',
  'experience',
  'howManyExperiences',
  'proposedMonthlyRent',
  'proposedMonthlyTaxes',
  'proposedMonthlyInsurance',
  'proposedMonthlyHoaDues',
  'floodInsurance',
  'schoolTax',
  'otherTax',
  'ltvAdjustment',
  'cltvAdjustment',
  'aivLtvAdjustment',
  'arvLtvAdjustment',
  'ltcAdjustment',
  'ltpAdjustment',
]

export const ltvCalcRelatedFields = [
  'propertyPurchasePrice',
  'asIsValue',
  'constructionReserve',
  'proposedLoanAmount',
  'afterRepairValue',
  'rehabBudget',
  'secondLien',
]

export const arvRelatedFields = [
  'propertyPurchasePrice',
  'asIsValue',
  'constructionReserve',
  'proposedLoanAmount',
  'afterRepairValue',
  'rehabBudget',
  'lienPayoff',
  'closingCostEstimate',
  'interestReserve',
  'secondLien',
]

export const visibleChangeFields = [
  'transactionType',
  'productType',
  'propertyType',
  'secondLien',
  'experience',
  'propertyState',
]
