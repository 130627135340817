import { ClockIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { useEffect, useRef, useState } from 'react'
import Autocomplete from 'react-google-autocomplete'
import { Tooltip } from 'stories/components/Tooltip/Tooltip'

import { EditAddressDialog } from './EditAddressDialog'

interface GoogleAutoCompleteProps {
  /**
   * Is disabled
   */
  disabled?: boolean
  /**
   * Is readOnly
   */
  readOnly?: boolean
  /**
   * Title of Input
   */
  title?: string
  /**
   * Placeholder of Input
   */
  placeholder?: string
  /**
   * Tooltip of Input
   */
  tooltip?: string
  /**
   * Value of Input
   */
  value?: string
  /**
   * Input key of Input
   */
  inputKey?: string
  /**
   * Error of Input
   */
  error?: string
  /**
   * Custom class name
   */
  className?: string
  /**
   * Show History
   */
  history?: boolean
  /**
   * Required
   */
  required?: boolean
  /**
   * Editable
   */
  editable?: boolean
  onChange: (e: any) => void // string | React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: () => void
  showHistory?: () => void
}

/**
 * Primary UI component for user interaction
 */
export const GoogleAutoComplete = ({
  disabled = false,
  readOnly = false,
  title = '',
  placeholder = ' ',
  tooltip = '',
  value: _value = '',
  inputKey = 'google-map-auto',
  error = '',
  required = false,
  editable = true,
  className = '',
  history = false,
  onChange = () => {},
  onBlur = () => {},
  showHistory = () => {},
}: GoogleAutoCompleteProps) => {
  const classNames = [
    'block',
    'rounded-t',
    'px-2.5',
    'pb-[2px]',
    'pt-[27px]',
    'w-full',
    'text-[15px]',
    'text-gray-900',
    disabled ? 'bg-gray-100' : 'bg-white',
    readOnly ? 'cursor-not-allowed' : '',
    'border',
    'border-gray-300',
    'focus:outline-none',
    'focus:ring-0',
    `focus:border-sky-600`,
    'peer',
    error && 'border-rose-700',
    className,
  ]
  const inputRef = useRef<HTMLInputElement>(null)
  const [value, setValue] = useState<string | null>(null)
  const [isShowEdit, setShowEdit] = useState(false)
  useEffect(() => {
    setValue(_value)
  }, [_value])

  useEffect(() => {
    if (value !== null && value != _value) onChange(value)
  }, [value])

  useEffect(() => {
    if (inputRef.current) (inputRef.current as any).value = value
  }, [value])

  const change = (e: any) => {
    let value: string = e.target.value
    if (value.endsWith('USA')) {
      const index = value.lastIndexOf(', USA')
      value = value.substring(0, index)
    }
    setValue(value)
    inputRef?.current?.focus()
    setTimeout(() => {
      if (document.activeElement === inputRef.current) {
        inputRef?.current?.blur()
      }
    }, 250)
  }

  const editAddress = () => {
    setShowEdit(true)
  }

  const onChangeText = async (address: string) => {
    if (address) {
      const e = {
        target: {
          value: address,
        },
      }
      change(e)
    }
    setShowEdit(false)
  }

  if (value === null) return <div />

  return (
    <div className="input-container w-full">
      <div className={`group relative z-0 w-full`}>
        {readOnly ? (
          <input className={classNames.join(' ')} disabled={true} value={value} />
        ) : (
          <Autocomplete
            ref={inputRef}
            apiKey={'AIzaSyA2TV23UkRc7jDyLklenO902VTvnECVBOU'}
            onPlaceSelected={(place) => {
              if (place.address_components) {
                let county = ''
                let locality = ''
                for (let i = 0; i < place.address_components.length; i += 1) {
                  const item = place.address_components[i]
                  if (item.types.indexOf('administrative_area_level_2') !== -1) county = item.long_name
                  if (item.types.indexOf('locality') !== -1) locality = item.long_name
                }
                if (county.length === 0 && locality.length) {
                  county = locality + ' city'
                }
                const e = {
                  target: {
                    value: place.formatted_address,
                    county: county,
                    state: place.address_components[5]?.short_name || '',
                  },
                }
                change(e)
              }
            }}
            onBlur={onBlur}
            className={classNames.join(' ')}
            inputAutocompleteValue={value}
            defaultValue={value}
            placeholder={placeholder}
            options={{ types: ['address'], componentRestrictions: { country: 'us' } }}
            key={`google-map-auto-${inputKey}`}
          />
        )}

        <label className="absolute text-[12px] text-gray-700 top-1.5 border-b z-10 origin-[0] left-2.5 flex gap-2 items-center">
          {title}
          {required && '*'}
          {tooltip.length > 0 ? <Tooltip message={tooltip}></Tooltip> : null}
          {history && (
            <span className="ml-1 hidden group-hover:inline" onClick={() => showHistory()}>
              <ClockIcon className="h-[14px] w-[14px] text-gray-500 cursor-pointer" aria-hidden="true" />
            </span>
          )}
          {editable && (
            <span className="ml-1 hidden group-hover:inline" onClick={() => editAddress()}>
              <PencilSquareIcon className="h-[14px] w-[14px] text-gray-500 cursor-pointer" aria-hidden="true" />
            </span>
          )}
        </label>
      </div>
      {error && <p className="peer-invalid:visible text-rose-700 text-[13px] pt-[1px] pl-1">{error}</p>}

      {editable && isShowEdit && <EditAddressDialog address={value} onSubmit={onChangeText} />}
    </div>
  )
}
