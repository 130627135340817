import cloneDeep from 'clone-deep'
import { CommercialProdutTypes, IProgram } from 'config'
import { useState } from 'react'
import { Modal, Select } from 'stories/components'

import { LoadingStatus } from '..'

interface IProps {
  isTemplate: boolean
  programs: IProgram[]
  data: Record<number, string>
  isOpen: boolean
  loading: string
  onClose: () => void
  onSubmit: (data: Record<number, string>) => void
}

export const EditProductTypeModal = (props: IProps) => {
  const { programs, data, isOpen, loading, isTemplate, onClose, onSubmit } = props

  const [productTypes, setProductTypes] = useState<Record<number, string>>(data)
  const [edit, setEdit] = useState<Record<number, string>>({})

  const onChangeProductTypes = (ID: number, value: string) => {
    const newProductTypes = cloneDeep(productTypes)
    const newEdit = cloneDeep(edit)

    newProductTypes[ID] = value
    newEdit[ID] = value
    setProductTypes(newProductTypes)
    setEdit(newEdit)
  }

  return (
    <div>
      <Modal
        title={`${isTemplate ? 'Edit Product Type' : 'Product Type'}`}
        titleOkay={`${isTemplate ? 'Save' : ''}`}
        isOpen={isOpen}
        loading={loading === LoadingStatus.CHANGE_PROGRAMS_ORDER}
        onClose={onClose}
        onOk={() => onSubmit(edit)}
      >
        <div className="text-gray-700">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-3 py-3">
                  No
                </th>
                <th scope="col" className="px-3 py-3">
                  Name
                </th>
                <th scope="col" className="px-3 py-3">
                  Program Type
                </th>
                <th scope="col" className="px-3 py-3">
                  Product Type
                </th>
              </tr>
            </thead>
            <tbody>
              {programs.map((item, index: number) => {
                return (
                  <tr className="bg-white border-b" key={index}>
                    <td scope="row" className="px-3 py-1 text-center text-gray-900 whitespace-nowrap">
                      {index + 1}
                    </td>
                    <td scope="row" className="px-3 py-1 text-gray-900 whitespace-nowrap">
                      {item.Name}
                    </td>
                    <td scope="row" className="px-3 py-1 text-gray-900 whitespace-nowrap">
                      {item.Type}
                    </td>
                    <td scope="row" className={`${isTemplate ? 'py-1' : 'py-2'} px-3 text-gray-900 whitespace-nowrap`}>
                      {isTemplate ? (
                        <Select
                          id="productType"
                          value={productTypes[item.ID]}
                          options={CommercialProdutTypes}
                          hasDefaultOption={true}
                          className="mb-[-1rem] w-[220px]"
                          onChange={(value) => onChangeProductTypes(item.ID, value)}
                        />
                      ) : (
                        CommercialProdutTypes[productTypes[item.ID]]
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  )
}
