import cloneDeep from 'clone-deep'
import type { IExperienceMsaTier, IMsaTier, IStateMSA } from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import { useEffect, useState } from 'react'
import { getMsa } from 'services/apis/admin'
import { svgSearch } from 'stories/assets'
import { Checkbox, Input2, Modal } from 'stories/components'
import { renderHeader } from 'utils/table'

import {
  checkMsaTierDisableValue,
  checkMsaTierValue,
  getMsaAllAvailableItems,
  parseMsaValues,
  sortAndSearchMsa,
} from '../logic'

interface IProps {
  allTiers: IMsaTier[] | IExperienceMsaTier[]
  tierData: IMsaTier | IExperienceMsaTier
  isOpen: boolean
  isTemplate: boolean
  loading: string
  onClose: () => void
  onSubmit: (tierName: string, key: string, values: IStateMSA[]) => void
}

const headerList = [
  // {
  //   title: 'Rank',
  //   key: 'sortRank',
  // },
  {
    title: 'MSA Name',
    key: 'sortMsa',
  },
]

const defaultSortOrder = {
  // sortRank: 1,
  sortMsa: 1,
}

export const MsaTierValueModal = (props: IProps) => {
  const { allTiers, tierData, loading, isTemplate, isOpen, onClose, onSubmit } = props

  const [isLoading, setIsLoading] = useState(false)
  const [msaData, setMsaData] = useState<IStateMSA[]>([])
  const [originMsaData, setOriginMsaData] = useState<IStateMSA[]>([])
  const [values, setValues] = useState<IStateMSA[]>(tierData.values)
  const [total, setTotal] = useState<number>(0)
  const [sortOrder, setSortOrder] = useState<Record<string, number>>(defaultSortOrder)
  const [orderKey, setOrderKey] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [isAll, setIsAll] = useState(false)

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)

      try {
        const res = await getMsa({})

        setMsaData(res.msa)
        setOriginMsaData(res.msa)
        setTotal(res.total)
      } catch (error) {
        console.log(error)
      }

      setIsLoading(false)
    })()
  }, [])

  useEffect(() => {
    const selectedAll = parseMsaValues(allTiers, values, tierData.title, originMsaData.length)

    setIsAll(selectedAll)
  }, [values, originMsaData])

  const onSort = (key: string, orderValue: number) => {
    setSortOrder({ ...defaultSortOrder, [key]: orderValue })
    setOrderKey(key)

    setIsLoading(true)

    let res

    if (key === 'sortMsa') res = sortAndSearchMsa(originMsaData, searchValue, orderValue, NaN)
    // if (key === 'sortRank') res = sortAndSearchMsa(originMsaData, searchValue, NaN, orderValue)

    if (res) {
      setMsaData(res.msa)
      setTotal(res.total)
    }

    setIsLoading(false)
  }

  const onSearchByName = async (str: string) => {
    setSearchValue(str)

    setIsLoading(true)

    setIsLoading(true)

    let res

    if (orderKey === 'sortMsa') res = sortAndSearchMsa(originMsaData, str, sortOrder[orderKey], NaN)
    // else if (orderKey === 'sortRank') res = sortAndSearchMsa(originMsaData, str, NaN, sortOrder[orderKey])
    else res = sortAndSearchMsa(originMsaData, str, NaN, NaN)

    if (res) {
      setMsaData(res.msa)
      setTotal(res.total)
    }

    setIsLoading(false)
  }

  const onSelectValue = (rank: number) => {
    const newValues = cloneDeep(values)

    const removeIndex = values.findIndex((item) => item.msa.ranking === rank)

    if (removeIndex !== -1) {
      newValues.splice(removeIndex, 1)
    } else {
      const selectedValue = originMsaData.find((item) => item.msa.ranking === rank)

      if (selectedValue) newValues.push(selectedValue)
    }

    setValues(newValues)
  }

  const onSelectAll = () => {
    if (isAll) {
      setValues([])
    } else {
      const allAvailableValues = getMsaAllAvailableItems(allTiers, originMsaData, tierData.title)

      setValues(allAvailableValues)
    }
  }

  return (
    <Modal
      title={`Edit ${tierData.title} (${values.length}/${total})`}
      titleOkay="Save"
      isOpen={isOpen}
      loading={loading === LoadingStatus.TIER_VALUES || isLoading}
      onClose={onClose}
      onOk={() => onSubmit(tierData.title, 'msa', values)}
    >
      <div className="max-w-xl">
        <div className="flex justify-between items-center gap-4">
          <Input2
            type="search"
            title="Search By MSA Name"
            hasIcon
            icon={svgSearch}
            value={searchValue}
            className="mb-2 max-w-[300px]"
            onChange={(value) => onSearchByName(value)}
          />

          {isTemplate && (
            <Checkbox
              id="select-all"
              title="Select All"
              value={isAll}
              fontClass="font-variation-settings-600 text-base"
              className="w-[120px]"
              color="gray"
              onClick={onSelectAll}
            />
          )}
        </div>

        <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-3 py-3">
                No
              </th>
              {headerList.map((item: Record<string, string>, index: number) => {
                return renderHeader({
                  title: item.title,
                  key: item.key,
                  index: index,
                  onSort: (key: string, order: number) => onSort(key, order),
                  sortable: true,
                  sortOrder: sortOrder[item.key],
                })
              })}
              <th scope="col" className="px-3 py-3">
                States MSA
              </th>
              <th scope="col" className="px-3 py-3">
                Status
              </th>
            </tr>
          </thead>

          <tbody>
            {msaData &&
              msaData.map((item, index) => {
                const statesMsa = item.states.join('-') + ' MSA'
                return (
                  <tr className={`border-b ${index % 2 ? 'bg-slate-50' : 'bg-white'}`} key={index}>
                    <td scope="row" className="px-3 py-3">
                      {index + 1}
                    </td>
                    {/* <td scope="row" className="px-3 py-3">
                      {item.msa.ranking}
                    </td> */}
                    <td scope="row" className="px-3 py-3">
                      {item.msa.name}
                    </td>
                    <td scope="row" className="px-3 py-3">
                      {statesMsa}
                    </td>
                    <td scope="row" className="px-3 py-3 text-center">
                      <Checkbox
                        id={index.toString()}
                        value={checkMsaTierValue(allTiers, values, tierData.title, item.msa.ranking)}
                        disabled={checkMsaTierDisableValue(allTiers, tierData.title, item.msa.ranking)}
                        fontClass="font-variation-settings-600 text-base"
                        color="gray"
                        onClick={() => onSelectValue(item.msa.ranking)}
                      />
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    </Modal>
  )
}
