import { ArchiveBoxXMarkIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { PlainInput } from 'components/PlainInput'
import type { IRatePriceLimitWithExitFee } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from 'stories/components'
import { confirm, removeComma } from 'utils'

import { LoadingStatus } from '..'

interface IProps {
  data: IRatePriceLimitWithExitFee[]
  loading: string
  onSave: (data: IRatePriceLimitWithExitFee[]) => void
}

export const RatePriceLimitWithExitFee = (props: IProps) => {
  const { data, loading, onSave } = props
  const [limit, setLimit] = useState<IRatePriceLimitWithExitFee[]>([])
  const [edit, setEdit] = useState(false)

  const step: number = 0.01

  useEffect(() => {
    setLimit(data)
    setEdit(false)
  }, [data])

  const onAddRange = () => {
    const newData = cloneDeep(limit)

    newData.push({
      exitFee: {
        from: limit.length === 0 ? 0 : Number((limit[limit.length - 1].exitFee.to + step).toFixed(2)),
        to: limit.length === 0 ? 0 : Number((limit[limit.length - 1].exitFee.to + step).toFixed(2)),
      },
      rate: {
        min: 0,
        max: 999,
      },
      price: {
        min: 0,
        max: 999,
      },
    })

    setLimit(newData)
    setEdit(true)
  }

  const removeLimit = async (index: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this limit?
        <br />
        <span className="text-gray-600 text-base">
          Exit Fee: {limit[index].exitFee.from} - {limit[index].exitFee.to}
        </span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const newData = cloneDeep(limit)

    newData.splice(index, 1)

    for (let i = 1; i < newData.length; i++) {
      if (newData[i].exitFee.from - newData[i - 1].exitFee.to !== step)
        newData[i].exitFee.from = Number((newData[i - 1].exitFee.to + step).toFixed(2))

      if (newData[i].exitFee.from > newData[i].exitFee.to) newData[i].exitFee.to = newData[i].exitFee.from
    }

    setLimit(newData)
    setEdit(true)
  }

  const onChangeExitFeeRange = (index: number, value: any, startValue = false) => {
    const newData = cloneDeep(limit)

    value = removeComma(value)

    if (startValue) {
      newData[index].exitFee.from = value

      if (newData[index].exitFee.from > newData[index].exitFee.to)
        newData[index].exitFee.to = newData[index].exitFee.from
    } else {
      if (newData[index].exitFee.from > value) return

      newData[index].exitFee.to = value
    }

    if (index < newData.length - 1)
      for (let i = index + 1; i < newData.length; i++) {
        if (newData[i].exitFee.from - newData[i - 1].exitFee.to !== step)
          newData[i].exitFee.from = Number((newData[i - 1].exitFee.to + step).toFixed(2))

        if (newData[i].exitFee.from > newData[i].exitFee.to) newData[i].exitFee.to = newData[i].exitFee.from
      }

    setLimit(newData)
    setEdit(true)
  }

  const onChangeRatePriceLimit = (index: number, type: 'rate' | 'price', key: 'min' | 'max', value: any) => {
    const newLimit = cloneDeep(limit)
    value = removeComma(value)

    if (newLimit[index][type][key] == value) return

    newLimit[index][type][key] = value

    if (newLimit[index][type].min > newLimit[index][type].max) {
      toast(`Min should be lower than Max`, { type: 'error' })
      return
    }

    setLimit(newLimit)
    setEdit(true)
  }

  const renderLimit = useMemo(() => {
    if (limit.length === 0)
      return (
        <div className="w-full flex justify-center items-center mt-10">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limit</span>
          </div>
        </div>
      )

    return (
      <table className="table w-full text-center text-sm">
        <thead className="bg-gray-100">
          <tr>
            <th colSpan={2} className="border px-2 py-1">
              Exit Fee
            </th>
            <th colSpan={2} className="border px-2 py-1">
              Rate
            </th>
            <th colSpan={2} className="border px-2 py-1">
              Price
            </th>
            <th rowSpan={2} className="border px-3 py-1">
              Action
            </th>
          </tr>
          <tr>
            <th className="border px-2 py-1 w-1/6">From</th>
            <th className="border px-2 py-1 w-1/6">To</th>
            <th className="border px-2 py-1 w-1/6">Min</th>
            <th className="border px-2 py-1 w-1/6">Max</th>
            <th className="border px-2 py-1 w-1/6">Min</th>
            <th className="border px-2 py-1 w-1/6">Max</th>
          </tr>
        </thead>
        <tbody>
          {limit.map((item, index) => (
            <tr key={index}>
              <td className="border p-1">
                <div className="flex justify-center">
                  <PlainInput
                    value={item.exitFee.from}
                    origin={item.exitFee.from}
                    content={item.exitFee.from}
                    onChange={(value: any) => onChangeExitFeeRange(index, value, index === 0)}
                    disabled={index !== 0}
                    className="w-full"
                  />
                </div>
              </td>
              <td className="border p-1">
                <div className="flex justify-center">
                  <PlainInput
                    value={item.exitFee.to}
                    origin={item.exitFee.to}
                    content={item.exitFee.to}
                    onChange={(value: any) => onChangeExitFeeRange(index, value)}
                    className="w-full"
                  />
                </div>
              </td>
              <td className="border p-1">
                <div className="flex justify-center">
                  <PlainInput
                    value={item.rate.min}
                    origin={item.rate.min}
                    content={item.rate.min}
                    onChange={(value: any) => onChangeRatePriceLimit(index, 'rate', 'min', value)}
                    className="w-full"
                  />
                </div>
              </td>
              <td className="border p-1">
                <div className="flex justify-center">
                  <PlainInput
                    value={item.rate.max}
                    origin={item.rate.max}
                    content={item.rate.max}
                    onChange={(value: any) => onChangeRatePriceLimit(index, 'rate', 'max', value)}
                    className="w-full"
                  />
                </div>
              </td>
              <td className="border p-1">
                <div className="flex justify-center">
                  <PlainInput
                    value={item.price.min}
                    origin={item.price.min}
                    content={item.price.min}
                    onChange={(value: any) => onChangeRatePriceLimit(index, 'price', 'min', value)}
                    className="w-full"
                  />
                </div>
              </td>
              <td className="border p-1">
                <div className="flex justify-center">
                  <PlainInput
                    value={item.price.max}
                    origin={item.price.max}
                    content={item.price.max}
                    onChange={(value: any) => onChangeRatePriceLimit(index, 'price', 'max', value)}
                    className="w-full"
                  />
                </div>
              </td>
              <td className="px-3 py-1 border">
                {index !== 0 && (
                  <div className="flex justify-center">
                    <div
                      className="w-fit p-1 transition-all duration-200 hover:cursor-pointer hover-shadow1 rounded"
                      onClick={() => removeLimit(index)}
                    >
                      <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                    </div>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }, [limit])

  return (
    <div>
      <p className="text-lg font-variation-settings-600 mt-4 mb-1">Rate & Price Limit</p>

      <div className="px-2 mt-2">
        <div className={`flex items-center ${edit ? 'justify-between' : 'justify-end'} mb-2`}>
          {edit && (
            <Button
              loading={LoadingStatus.UPDATE_PROGRAM_INFO === loading}
              onClick={() => onSave(limit)}
              className="pt-1.5 pb-1.5"
            >
              Save
            </Button>
          )}

          <div
            className="flex items-center gap-2 cursor-pointer w-fit h-6 text-shade-blue transition-all duration-200 hover:border-b hover:border-b-shade-blue pr-1"
            onClick={onAddRange}
          >
            <PlusIcon className="w-4 h-4" />
            <span>Add Limit</span>
          </div>
        </div>

        {renderLimit}
      </div>
    </div>
  )
}
