import type { InputType } from 'config'
import {
  AutoComplete,
  Checkbox,
  Input2,
  InputFile,
  MultiSelect,
  MultiSelectPanel,
  RadioGroup,
  Select2,
  TextArea,
  Toggle,
  ToggleButton,
} from 'stories/components'
import { InputFormTable } from 'stories/components/FormTable/InputFormTable'
import { GoogleAutoComplete } from 'stories/components/GoogleAutoComplete/GoogleAutoComplete'

interface Prop {
  input: InputType
  Key: string
  onChange: Function
  showHistory?: Function
  onBlur?: Function
}

export const RenderInput = (props: Prop) => {
  const { input, Key, onChange, showHistory = () => {}, onBlur = () => {} } = props
  let {
    fontSize,
    history,
    prefix,
    title,
    rows,
    error = '',
    disabled,
    value = '',
    readOnly = false,
    sort = false,
    tooltip = '',
    acceptFileTypes,
    required,
    additionalElements,
  } = input
  required = required && !disabled
  if (!(input.inputType as any)) input.inputType = 'Text' as any
  if (disabled && ['Map', 'map'].indexOf(input.inputType) !== -1) input.inputType = 'Text'

  switch (input.inputType) {
    case 'Text':
    case 'text':
      return (
        <Input2
          title={title}
          prefix={prefix}
          key={Key}
          type={input.type}
          error={error}
          value={value as string}
          fontSize={fontSize}
          disabled={disabled}
          readOnly={readOnly}
          tooltip={tooltip}
          required={required}
          history={history}
          additionalElements={additionalElements}
          onChange={(value) => onChange(Key, value)}
          onBlur={() => onBlur(Key)}
          showHistory={() => showHistory(Key)}
        />
      )
    case 'Select':
    case 'select':
      return (
        <Select2
          id={Key}
          title={title}
          options={input.options}
          key={Key}
          error={error}
          disabled={disabled}
          readOnly={readOnly}
          sort={sort}
          tooltip={tooltip}
          value={input.value as string}
          hasDefaultOption={input.hasDefaultOption}
          defaultOptionText={input.defaultOptionText}
          required={required}
          history={history}
          onChange={(value) => onChange(Key, value)}
          onBlur={() => onBlur(Key)}
          showHistory={() => showHistory(Key)}
        />
      )
    case 'MultiSelect':
    case 'multiselect':
      return (
        <MultiSelect
          id={Key}
          title={title}
          options={input.options}
          key={Key}
          error={error}
          disabled={disabled}
          readOnly={readOnly}
          tooltip={tooltip}
          value={input.value as string[]}
          required={required}
          history={history}
          onChange={(value) => onChange(Key, value)}
          onBlur={() => onBlur(Key)}
          showHistory={() => showHistory(Key)}
        />
      )
    case 'MultiSelectPanel':
    case 'multiselectpanel':
      return (
        <MultiSelectPanel
          id={Key}
          title={title}
          options={input.options}
          key={Key}
          error={error}
          disabled={disabled}
          readOnly={readOnly}
          tooltip={tooltip}
          value={input.value as string[]}
          required={required}
          history={history}
          gridClassName={input.gridClassName}
          additionalElements={input.additionalElements}
          onChange={(value) => onChange(Key, value)}
          showHistory={() => showHistory(Key)}
        />
      )
    case 'Check':
    case 'check':
      return (
        <ToggleButton
          className="pt-1"
          id={Key}
          title={title}
          key={Key}
          disabled={disabled}
          error={error}
          value={input.value as boolean}
          history={history}
          onChange={(value) => onChange(Key, value)}
          onBlur={() => onBlur(Key)}
          showHistory={() => showHistory(Key)}
          label={['Yes', 'No']}
        />
      )
    case 'CheckBox':
    case 'checkbox':
      return (
        <Checkbox
          className={`pt-1 ${input.className}`}
          id={Key}
          title={title}
          key={Key}
          color={input.color}
          disabled={disabled}
          value={input.value as boolean}
          history={history}
          onChange={(value) => onChange(Key, value)}
          onBlur={() => onBlur(Key)}
          showHistory={() => showHistory(Key)}
        />
      )
    case 'Radio':
    case 'radio':
      return (
        <RadioGroup
          id={Key}
          title={title}
          options={input.options}
          key={Key}
          name={Key}
          error={error}
          disabled={disabled}
          value={input.value as string}
          required={required}
          history={history}
          onChange={(value) => onChange(Key, value)}
          showHistory={() => showHistory(Key)}
        />
      )
    case 'TextArea':
    case 'textarea':
      return (
        <TextArea
          className="pt-1"
          title={title}
          key={Key}
          rows={rows}
          error={error}
          disabled={disabled}
          value={input.value as string}
          history={history}
          required={required}
          onBlur={() => onBlur(Key)}
          onChange={(value) => onChange(Key, value)}
          showHistory={() => showHistory(Key)}
        />
      )
    case 'Map':
    case 'map':
      return (
        <GoogleAutoComplete
          title={title}
          key={Key}
          inputKey={Key}
          error={error}
          value={value as string}
          disabled={disabled}
          required={required}
          readOnly={readOnly}
          tooltip={tooltip}
          onChange={(value) => onChange(Key, value)}
          onBlur={() => onBlur(Key)}
          history={history}
          showHistory={() => showHistory(Key)}
        />
      )
    case 'Toggle':
    case 'toggle':
      return (
        <Toggle
          className="pt-1"
          id={Key}
          title={title}
          key={Key}
          disabled={disabled}
          error={error}
          value={input.value as boolean}
          history={history}
          onChange={(value) => onChange(Key, value)}
          onBlur={() => onBlur(Key)}
          showHistory={() => showHistory(Key)}
        />
      )
    case 'ToggleButton':
    case 'togglebutton':
      return (
        <ToggleButton
          className="pt-1"
          id={Key}
          title={title}
          key={Key}
          disabled={disabled}
          error={error}
          value={input.value as boolean}
          history={history}
          onChange={(value) => onChange(Key, value)}
          onBlur={() => onBlur(Key)}
          showHistory={() => showHistory(Key)}
          textLeft={input.textLeft}
        />
      )
    case 'File':
    case 'file':
      return (
        <InputFile
          className="pt-1"
          title={title}
          key={Key}
          acceptFileTypes={acceptFileTypes}
          disabled={disabled}
          error={error}
          history={history}
          onChange={(value) => onChange(Key, value)}
          showHistory={() => showHistory(Key)}
        />
      )
    case 'AutoComplete':
    case 'autocomplete':
      return (
        <AutoComplete
          className="pt-1"
          title={title}
          key={Key}
          value={value}
          disabled={disabled}
          error={error}
          type={input.type}
          autoCompleteType={input.autoCompleteType}
          required={input.required}
          onBlur={() => onBlur(Key)}
          history={history}
          onChange={(value) => onChange(Key, value)}
          showHistory={() => showHistory(Key)}
        />
      )
    case 'Table':
    case 'table':
      return (
        <InputFormTable
          inputs={input.inputs}
          data={input.value}
          onChange={(value: any) => onChange(Key, value)}
          error={error}
          permission={disabled ? 3 : 1}
        />
      )

    case 'Section':
    case 'section':
      return (
        <div className={`border-b-2 border-gray-300 w-full`}>
          <p>- {title}</p>
        </div>
      )

    case 'Custom':
    case 'custom':
      return input.render ? input.render(input.value, (value: any) => onChange(Key, value)) : null
  }
  return null
}
