import { ArchiveBoxXMarkIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { PlainInput } from 'components/PlainInput'
import type { IMaxCashToBorrowerLeverageLimit } from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import { useEffect, useMemo, useState } from 'react'
import { Button } from 'stories/components'
import { confirm, removeComma } from 'utils'

import { convertNullToBlank, convertNullToBlankValueToDecimal, convertNullToNoMax } from '../logic'

interface IProps {
  isTemplate: boolean
  isDSCR?: boolean
  leverageData: IMaxCashToBorrowerLeverageLimit[]
  isLoading: boolean
  loading: string
  onSave: (data: IMaxCashToBorrowerLeverageLimit[], limitKey: string) => void
}

export const MaxCashToBorrowerMaxLimits = (props: IProps) => {
  const { leverageData, isLoading, isTemplate, loading, isDSCR = true, onSave } = props

  const [data, setData] = useState<IMaxCashToBorrowerLeverageLimit[]>([])
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    if (!leverageData) return

    setData(leverageData)
    setEdit(false)
  }, [leverageData])

  useEffect(() => {
    if (!loading) setEdit(false)
  }, [loading])

  const addLimit = () => {
    const newData = cloneDeep(data)

    newData.push({
      ltv: {
        from: data.length === 0 ? 0 : data[data.length - 1].ltv.from,
        to: data.length === 0 ? 0 : data[data.length - 1].ltv.to,
      },
      maxValue: NaN,
      loanAmount: {
        min: NaN,
        max: NaN,
      },
      minDSCR: 0,
      minFICO: 0,
    })

    setData(newData)
    setEdit(true)
  }

  const removeLimit = async (index: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this row?
        <br />
        <span className="text-gray-600 text-base">
          LTV: {data[index].ltv.from} - {data[index].ltv.to}
        </span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const newData = cloneDeep(data)

    newData.splice(index, 1)

    setData(newData)
    setEdit(true)
  }

  const onChangeLTV = (index: number, key: 'from' | 'to', value: any) => {
    const newData = cloneDeep(data)

    value = removeComma(value)

    if (newData[index].ltv[key] === value) return

    newData[index].ltv[key] = value

    setData(newData)
    setEdit(true)
  }

  const onChangeMaxValue = (index: number, value: any) => {
    const newData = cloneDeep(data)

    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (newData[index].maxValue === value) return

    newData[index].maxValue = value

    setData(newData)
    setEdit(true)
  }

  const onChangeLoanAmount = (index: number, key: 'min' | 'max', value: any) => {
    const newData = cloneDeep(data)

    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (newData[index].loanAmount[key] === value) return

    newData[index].loanAmount[key] = value

    setData(newData)
    setEdit(true)
  }

  const onChangeMinDscrFico = (index: number, key: 'minDSCR' | 'minFICO', value: any) => {
    const newData = cloneDeep(data)

    value = removeComma(value)

    if (newData[index][key] === value) return

    newData[index][key] = value

    setData(newData)
    setEdit(true)
  }

  const renderLtvMaxLimits = useMemo(() => {
    if (data.length === 0)
      return (
        <div className="w-full flex justify-center items-center mt-10">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limits</span>
          </div>
        </div>
      )

    return (
      <table className="table w-full text-center text-sm">
        <thead className="bg-gray-100">
          <tr>
            <th colSpan={2} className="p-1 border">
              {isDSCR ? 'LTV' : 'AIV-LTV'}
            </th>
            <th colSpan={2} className="p-1 border">
              Loan Amount
            </th>
            {isDSCR && (
              <th rowSpan={2} className="p-1 border whitespace-nowrap w-[100px]">
                Min DSCR
              </th>
            )}
            <th rowSpan={2} className="p-1 border whitespace-nowrap w-[100px]">
              Min FICO
            </th>
            <th rowSpan={2} className="p-1 border whitespace-nowrap">
              Max Estimated Closing Amount to Borrower
            </th>
            {isTemplate && (
              <th rowSpan={2} className="p-1 border whitespace-nowrap">
                Action
              </th>
            )}
          </tr>
          <tr>
            <th className="border p-1 w-[100px]">From</th>
            <th className="border p-1 w-[100px]">To</th>
            <th className="border p-1 w-[140px]">From</th>
            <th className="border p-1 w-[140px]">To</th>
          </tr>
        </thead>

        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td className="border p-1">
                <div className="flex justify-center">
                  <PlainInput
                    value={item.ltv.from}
                    origin={item.ltv.from}
                    content={item.ltv.from}
                    onChange={(value: any) => onChangeLTV(index, 'from', value)}
                    disabled={!isTemplate}
                    className="w-full"
                  />
                </div>
              </td>
              <td className="border p-1">
                <div className="flex justify-center">
                  <PlainInput
                    value={item.ltv.to}
                    origin={item.ltv.to}
                    content={item.ltv.to}
                    disabled={!isTemplate}
                    onChange={(value: any) => onChangeLTV(index, 'to', value)}
                    className="w-full"
                  />
                </div>
              </td>
              <td className="border p-1">
                <div className="flex justify-center">
                  <PlainInput
                    value={item.loanAmount.min}
                    origin={item.loanAmount.min}
                    content={convertNullToBlankValueToDecimal(item.loanAmount.min)}
                    disabled={!isTemplate}
                    onChange={(value: any) => onChangeLoanAmount(index, 'min', value)}
                    className="w-full"
                  />
                </div>
              </td>
              <td className="border p-1">
                <div className="flex justify-center">
                  <PlainInput
                    value={item.loanAmount.max}
                    origin={item.loanAmount.max}
                    content={convertNullToBlankValueToDecimal(item.loanAmount.max)}
                    disabled={!isTemplate}
                    onChange={(value: any) => onChangeLoanAmount(index, 'max', value)}
                    className="w-full"
                  />
                </div>
              </td>
              {isDSCR && (
                <td className="border p-1">
                  <div className="flex justify-center">
                    <PlainInput
                      value={item.minDSCR}
                      origin={item.minDSCR}
                      content={item.minDSCR}
                      disabled={!isTemplate}
                      onChange={(value: any) => onChangeMinDscrFico(index, 'minDSCR', value)}
                      className="w-full"
                    />
                  </div>
                </td>
              )}
              <td className="border p-1">
                <div className="flex justify-center">
                  <PlainInput
                    value={item.minFICO}
                    origin={item.minFICO}
                    content={item.minFICO}
                    disabled={!isTemplate}
                    onChange={(value: any) => onChangeMinDscrFico(index, 'minFICO', value)}
                    className="w-full"
                  />
                </div>
              </td>
              <td className="border p-1">
                <div className="flex justify-center">
                  <PlainInput
                    value={convertNullToBlank(item.maxValue)}
                    origin={convertNullToBlank(item.maxValue)}
                    content={convertNullToNoMax(item.maxValue)}
                    disabled={!isTemplate}
                    onChange={(value: any) => onChangeMaxValue(index, value)}
                    className="w-full"
                  />
                </div>
              </td>
              {isTemplate && (
                <td className="p-1 border">
                  <div className="flex justify-center">
                    <div
                      className="w-fit p-1 transition-all duration-200 hover:cursor-pointer hover-shadow1 rounded"
                      onClick={() => removeLimit(index)}
                    >
                      <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                    </div>
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    )
  }, [data])

  return (
    <div className="relative">
      <LayoutLoading show={LoadingStatus.LEVERAGE_LIMIT === loading || isLoading} />

      <div className="px-2 mt-2">
        <div className="flex justify-between items-center mb-2">
          <p>
            <span>Only when the</span> "
            <i>
              <b>Transaction Type</b>
            </i>
            " <span>is a</span> "
            <i>
              <b>Refinance</b>
            </i>
            "
          </p>
        </div>

        {isTemplate && (
          <div className={`flex items-center mb-2 ${edit ? 'justify-between' : 'justify-end'}`}>
            {edit && (
              <Button
                loading={LoadingStatus.LEVERAGE_LIMIT === loading}
                onClick={() => onSave(data, 'maxCashToBorrowerLeverageMaxLimit')}
                className="pt-1.5 pb-1.5"
              >
                Save
              </Button>
            )}

            <div
              className="flex items-center gap-2 cursor-pointer w-fit text-shade-blue transition-all duration-200 hover:border-b hover:border-b-shade-blue pr-1 h-6"
              onClick={addLimit}
            >
              <PlusIcon className="w-4 h-4" />
              <span>Add Limit</span>
            </div>
          </div>
        )}

        {renderLtvMaxLimits}
      </div>
    </div>
  )
}
