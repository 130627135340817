import { ArchiveBoxXMarkIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { PlainInput } from 'components/PlainInput'
import { IDscrFicoLeverageLimit } from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import {
  convertNullToBlank,
  convertNullToBlankValueToDecimal,
} from 'pages/RateSheetOverview/ExcelSheets/CommercialOnlyProgrammed/logic'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from 'stories/components'
import { confirm, removeComma, solveDecimalJavascriptSum } from 'utils'

interface IProps {
  leverageData: IDscrFicoLeverageLimit[]
  isLoading: boolean
  isTemplate: boolean
  loading: string
  onSave: (data: IDscrFicoLeverageLimit[], key: string) => void
}

export const DscrFicoLeverageLimit = (props: IProps) => {
  const { loading, isTemplate, leverageData, isLoading, onSave } = props

  const [data, setData] = useState<IDscrFicoLeverageLimit[]>([])
  const [edit, setEdit] = useState(false)

  const step = 1

  useEffect(() => {
    if (!leverageData) return

    setData(leverageData)

    setEdit(false)
  }, [leverageData])

  const addLimit = () => {
    const newData = cloneDeep(data)

    newData.push({
      ficoRange: {
        from: !data.length ? 0 : data[data.length - 1].ficoRange.to + step,
        to: !data.length ? 0 : data[data.length - 1].ficoRange.to + step,
      },
      loanAmount: {
        purchase: {
          min: NaN,
          max: NaN,
        },
        nocashout: {
          min: NaN,
          max: NaN,
        },
        cashout: {
          min: NaN,
          max: NaN,
        },
      },
      minDSCR: {
        purchase: 0,
        nocashout: 0,
        cashout: 0,
      },
      minMonthsReserve: {
        purchase: 0,
        nocashout: 0,
        cashout: 0,
      },
      LTV: {
        purchase: { ltv: { min: NaN, max: NaN }, cltv: NaN },
        nocashout: { ltv: { min: NaN, max: NaN }, cltv: NaN },
        cashout: { ltv: { min: NaN, max: NaN }, cltv: NaN },
      },
    })

    setData(newData)
    setEdit(true)
  }

  const onChangeLtvLimit = (
    index: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: 'min' | 'max',
    value: any,
  ) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if (newData[index].LTV[loanPurpose].ltv[key] == value) return

    newData[index].LTV[loanPurpose].ltv[key] = value

    setData(newData)
    setEdit(true)
  }

  const onChangeCLtvLimit = (index: number, loanPurpose: 'purchase' | 'nocashout' | 'cashout', value: any) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if (newData[index].LTV[loanPurpose].cltv == value) return

    newData[index].LTV[loanPurpose].cltv = value

    setData(newData)
    setEdit(true)
  }

  const onChangeMinDscrMonthsReserve = (
    index: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: 'minDSCR' | 'minMonthsReserve',
    value: any,
  ) => {
    value = removeComma(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if (newData[index][key][loanPurpose] == value) return
    newData[index][key][loanPurpose] = value

    setData(newData)
    setEdit(true)
  }

  const removeLtvLimit = async (index: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this row?
        <br />
        <span className="text-gray-600 text-base">
          FICO: {data[index].ficoRange.from} - {data[index].ficoRange.to}
        </span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const newData = cloneDeep(data)

    newData.splice(index, 1)

    for (let i = 1; i < newData.length; i++) {
      if (newData[i].ficoRange.from - newData[i - 1].ficoRange.to !== step)
        newData[i].ficoRange.from = newData[i - 1].ficoRange.to + step

      if (newData[i].ficoRange.from > newData[i].ficoRange.to) newData[i].ficoRange.to = newData[i].ficoRange.from
    }

    setData(newData)
    setEdit(true)
  }

  const onChangeLoanAmountOverly = (
    index: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: string,
    value: any,
  ) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if ((newData[index].loanAmount[loanPurpose] as any)[key] == value) return
    ;(newData[index].loanAmount[loanPurpose] as any)[key] = value

    setData(newData)
    setEdit(true)
  }

  const onChangeFICO = (index: number, value: any, startValue = false) => {
    const newData = cloneDeep(data)

    value = removeComma(value)

    if (startValue) {
      newData[0].ficoRange.from = value

      if (newData[0].ficoRange.from > newData[0].ficoRange.to) newData[0].ficoRange.to = newData[0].ficoRange.from
    } else {
      if (newData[index].ficoRange.from > value) return

      newData[index].ficoRange.to = value
    }

    if (index < newData.length - 1)
      for (let i = index + 1; i < newData.length; i++) {
        if (newData[i].ficoRange.from - newData[i - 1].ficoRange.to !== step)
          newData[i].ficoRange.from = solveDecimalJavascriptSum([newData[i - 1].ficoRange.to, step])

        if (newData[i].ficoRange.from > newData[i].ficoRange.to) newData[i].ficoRange.to = newData[i].ficoRange.from
      }

    setData(newData)
    setEdit(true)
  }

  const renderLtvLimits = useMemo(() => {
    if (data.length === 0)
      return (
        <div className="w-full flex justify-center items-center mt-10">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limits</span>
          </div>
        </div>
      )

    return (
      <table className="table w-full text-center text-sm">
        <thead className="bg-gray-100">
          <tr>
            <th colSpan={2} className="border p-1 whitespace-nowrap">
              FICO
            </th>
            <th rowSpan={2} className="border p-1">
              Loan Purpose
            </th>
            <th colSpan={2} className="border p-1">
              Loan Amount
            </th>
            <th rowSpan={2} className="border p-1 w-[90px]">
              Min DSCR
            </th>
            <th rowSpan={2} className="border p-1 w-[100px]">
              Min Months Reserve
            </th>
            <th colSpan={2} className="border p-1">
              LTV
            </th>
            <th rowSpan={2} className="border p-1 w-[100px]">
              Max CLTV
            </th>
            {isTemplate && (
              <th rowSpan={2} className="border p-1 w-[100px]">
                Action
              </th>
            )}
          </tr>
          <tr>
            <th className="border p-1 w-[80px]">From</th>
            <th className="border p-1 w-[80px]">To</th>
            <th className="border p-1 w-[120px]">From</th>
            <th className="border p-1 w-[120px]">To</th>
            <th className="border p-1 w-[90px]">Min</th>
            <th className="border p-1 w-[90px]">Max</th>
          </tr>
        </thead>

        <tbody>
          {data.map((item, index) => {
            return (
              <Fragment key={index}>
                <tr>
                  <td rowSpan={3} className="border p-1">
                    <div className="flex justify-center">
                      <PlainInput
                        value={item.ficoRange.from}
                        origin={item.ficoRange.from}
                        content={item.ficoRange.from}
                        onChange={(value: any) => onChangeFICO(index, value, index === 0)}
                        disabled={!isTemplate || index !== 0}
                        className="w-full"
                      />
                    </div>
                  </td>
                  <td rowSpan={3} className="border p-1">
                    <div className="flex justify-center">
                      <PlainInput
                        value={item.ficoRange.to}
                        origin={item.ficoRange.to}
                        content={item.ficoRange.to}
                        onChange={(value: any) => onChangeFICO(index, value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                  <td className={`border whitespace-nowrap p-1`}>Purchase</td>

                  {['min', 'max'].map((key, index1) => (
                    <td key={`${index}-${index1}`} className={`border whitespace-nowrap p-1`}>
                      <div className="flex justify-center">
                        <PlainInput
                          value={convertNullToBlank((item.loanAmount.purchase as any)[key])}
                          origin={convertNullToBlank((item.loanAmount.purchase as any)[key])}
                          content={convertNullToBlankValueToDecimal((item.loanAmount.purchase as any)[key])}
                          onChange={(value: any) => onChangeLoanAmountOverly(index, 'purchase', key, value)}
                          disabled={!isTemplate}
                          className="w-full"
                        />
                      </div>
                    </td>
                  ))}

                  <td className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.minDSCR.purchase)}
                        origin={convertNullToBlank(item.minDSCR.purchase)}
                        content={convertNullToBlank(item.minDSCR.purchase)}
                        onChange={(value: any) => onChangeMinDscrMonthsReserve(index, 'purchase', 'minDSCR', value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>

                  <td className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.minMonthsReserve.purchase)}
                        origin={convertNullToBlank(item.minMonthsReserve.purchase)}
                        content={convertNullToBlank(item.minMonthsReserve.purchase)}
                        onChange={(value: any) =>
                          onChangeMinDscrMonthsReserve(index, 'purchase', 'minMonthsReserve', value)
                        }
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>

                  {['min', 'max'].map((key, index1) => (
                    <td key={`${index}-${index1}`} className={`border whitespace-nowrap p-1`}>
                      <div className="flex justify-center">
                        <PlainInput
                          value={convertNullToBlank(item.LTV.purchase.ltv[key as 'min' | 'max'])}
                          origin={convertNullToBlank(item.LTV.purchase.ltv[key as 'min' | 'max'])}
                          content={convertNullToBlank(item.LTV.purchase.ltv[key as 'min' | 'max'])}
                          onChange={(value: any) => onChangeLtvLimit(index, 'purchase', key as 'min' | 'max', value)}
                          disabled={!isTemplate}
                          className="w-full"
                        />
                      </div>
                    </td>
                  ))}

                  <td className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.LTV.purchase.cltv)}
                        origin={convertNullToBlank(item.LTV.purchase.cltv)}
                        content={convertNullToBlank(item.LTV.purchase.cltv)}
                        onChange={(value: any) => onChangeCLtvLimit(index, 'purchase', value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>

                  {isTemplate && (
                    <td rowSpan={3} className="border p-1">
                      <div className="flex justify-center">
                        <div
                          className="w-fit p-1 transition-all duration-200 hover:cursor-pointer hover-shadow1 rounded"
                          onClick={() => removeLtvLimit(index)}
                        >
                          <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                        </div>
                      </div>
                    </td>
                  )}
                </tr>

                <tr>
                  <td className={`border whitespace-nowrap p-1`}>No-Cashout</td>

                  {['min', 'max'].map((key, index1) => (
                    <td key={`${index}-${index1}`} className={`border whitespace-nowrap p-1`}>
                      <div className="flex justify-center">
                        <PlainInput
                          value={convertNullToBlank((item.loanAmount.nocashout as any)[key])}
                          origin={convertNullToBlank((item.loanAmount.nocashout as any)[key])}
                          content={convertNullToBlankValueToDecimal((item.loanAmount.nocashout as any)[key])}
                          onChange={(value: any) => onChangeLoanAmountOverly(index, 'nocashout', key, value)}
                          disabled={!isTemplate}
                          className="w-full"
                        />
                      </div>
                    </td>
                  ))}

                  <td className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.minDSCR.nocashout)}
                        origin={convertNullToBlank(item.minDSCR.nocashout)}
                        content={convertNullToBlank(item.minDSCR.nocashout)}
                        onChange={(value: any) => onChangeMinDscrMonthsReserve(index, 'nocashout', 'minDSCR', value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>

                  <td className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.minMonthsReserve.nocashout)}
                        origin={convertNullToBlank(item.minMonthsReserve.nocashout)}
                        content={convertNullToBlank(item.minMonthsReserve.nocashout)}
                        onChange={(value: any) =>
                          onChangeMinDscrMonthsReserve(index, 'nocashout', 'minMonthsReserve', value)
                        }
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>

                  {['min', 'max'].map((key, index1) => (
                    <td key={`${index}-${index1}`} className={`border whitespace-nowrap p-1`}>
                      <div className="flex justify-center">
                        <PlainInput
                          value={convertNullToBlank(item.LTV.nocashout.ltv[key as 'min' | 'max'])}
                          origin={convertNullToBlank(item.LTV.nocashout.ltv[key as 'min' | 'max'])}
                          content={convertNullToBlank(item.LTV.nocashout.ltv[key as 'min' | 'max'])}
                          onChange={(value: any) => onChangeLtvLimit(index, 'nocashout', key as 'min' | 'max', value)}
                          disabled={!isTemplate}
                          className="w-full"
                        />
                      </div>
                    </td>
                  ))}

                  <td className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.LTV.nocashout.cltv)}
                        origin={convertNullToBlank(item.LTV.nocashout.cltv)}
                        content={convertNullToBlank(item.LTV.nocashout.cltv)}
                        onChange={(value: any) => onChangeCLtvLimit(index, 'nocashout', value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className={`border whitespace-nowrap p-1`}>Cashout</td>

                  {['min', 'max'].map((key, index1) => (
                    <td key={`${index}-${index1}`} className={`border whitespace-nowrap p-1`}>
                      <div className="flex justify-center">
                        <PlainInput
                          value={convertNullToBlank((item.loanAmount.cashout as any)[key])}
                          origin={convertNullToBlank((item.loanAmount.cashout as any)[key])}
                          content={convertNullToBlankValueToDecimal((item.loanAmount.cashout as any)[key])}
                          onChange={(value: any) => onChangeLoanAmountOverly(index, 'cashout', key, value)}
                          disabled={!isTemplate}
                          className="w-full"
                        />
                      </div>
                    </td>
                  ))}

                  <td className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.minDSCR.cashout)}
                        origin={convertNullToBlank(item.minDSCR.cashout)}
                        content={convertNullToBlank(item.minDSCR.cashout)}
                        onChange={(value: any) => onChangeMinDscrMonthsReserve(index, 'cashout', 'minDSCR', value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>

                  <td className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.minMonthsReserve.cashout)}
                        origin={convertNullToBlank(item.minMonthsReserve.cashout)}
                        content={convertNullToBlank(item.minMonthsReserve.cashout)}
                        onChange={(value: any) =>
                          onChangeMinDscrMonthsReserve(index, 'cashout', 'minMonthsReserve', value)
                        }
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>

                  {['min', 'max'].map((key, index1) => (
                    <td key={`${index}-${index1}`} className={`border whitespace-nowrap p-1`}>
                      <div className="flex justify-center">
                        <PlainInput
                          value={convertNullToBlank(item.LTV.cashout.ltv[key as 'min' | 'max'])}
                          origin={convertNullToBlank(item.LTV.cashout.ltv[key as 'min' | 'max'])}
                          content={convertNullToBlank(item.LTV.cashout.ltv[key as 'min' | 'max'])}
                          onChange={(value: any) => onChangeLtvLimit(index, 'cashout', key as 'min' | 'max', value)}
                          disabled={!isTemplate}
                          className="w-full"
                        />
                      </div>
                    </td>
                  ))}

                  <td className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.LTV.cashout.cltv)}
                        origin={convertNullToBlank(item.LTV.cashout.cltv)}
                        content={convertNullToBlank(item.LTV.cashout.cltv)}
                        onChange={(value: any) => onChangeCLtvLimit(index, 'cashout', value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                </tr>
              </Fragment>
            )
          })}
        </tbody>
      </table>
    )
  }, [data])

  return (
    <div className=" relative">
      <LayoutLoading show={LoadingStatus.LEVERAGE_LIMIT === loading || isLoading} />

      <div className="px-2 mt-2">
        {isTemplate && (
          <div className={`flex items-center mb-2 ${edit ? 'justify-between' : 'justify-end'}`}>
            {edit && (
              <Button
                loading={LoadingStatus.LEVERAGE_LIMIT === loading}
                onClick={() => onSave(data, 'FICOLeverageLimit')}
                className="pt-1.5 pb-1.5"
              >
                Save
              </Button>
            )}

            <div
              className="flex items-center gap-2 cursor-pointer w-fit text-shade-blue transition-all duration-200 hover:border-b hover:border-b-shade-blue pr-1 h-6"
              onClick={addLimit}
            >
              <PlusIcon className="w-4 h-4" />
              <span>Add Limit</span>
            </div>
          </div>
        )}

        {renderLtvLimits}
      </div>
    </div>
  )
}
