import {
  API_ADD_PROGRAM,
  API_ADD_RATESHEET,
  API_ADD_STATE_TIER,
  API_CLONE_PROGRAM,
  API_CONVERT_DB_FROM_MONGO,
  API_CONVERT_DB_FROM_PG,
  API_DELETE_PROGRAM,
  API_DELETE_RATESHEET,
  API_DSCR_LEVERAGE_DATA,
  API_EXPERIENCE_TIER_LIMIT,
  API_FAST_MONEY_PRICE_LOAN,
  API_GET_DSCR_LEVERAGE_DATA,
  API_GET_LOANIDS,
  API_GET_NON_DSCR_LEVERAGE_DATA,
  API_GET_PREPAY_PENALTY,
  API_GET_RATESHEETS,
  API_GET_TEMPLATES,
  API_NON_DSCR_LEVERAGE_DATA,
  API_PRICE_LOAN,
  API_PROGRAM_BONUS_MARGIN,
  API_RATESHEET_HISTORY,
  API_REMOVE_MSA_TIER_LTV_ADJ_CONDITION,
  API_REMOVE_OLD_RATESHEET,
  API_REMOVE_STATE_TIER,
  API_SAVE_COMMERCIAL_PRODUCT_TYPES,
  API_SAVE_MSA_TIER_LTV_ADJ,
  API_SAVE_STATE_TIER_VALUES,
  API_SAVE_TIER_LTV_ADJ,
  API_STATE_TIER,
  API_SYNC_PROGRAM,
  API_SYNC_PROGRAM_TIERS,
  API_SYNC_PROGRAM_TO_GENERAL,
  API_UPDATE_DSCR_OCCUPANCY_LTV_REDUCTION,
  API_UPDATE_LTV_RATE_RANGE_FOR_NON_DSCR,
  API_UPDATE_PRODUCT_POWER,
  API_UPDATE_PROGRAM,
  API_UPDATE_PROGRAM_INFO,
  API_UPDATE_PROGRAM_RESTRICTION,
  API_UPDATE_PROGRAM_RESTRICTION_LTVLIMITS,
  API_UPDATE_PROGRAMS_ORDER,
  API_UPDATE_RANGE_FOR_DSCR,
  API_UPDATE_RATESHEET,
  API_UPDATE_STATE_TIER,
  API_UPLOAD_EXCEL_TO_S3,
} from 'config'
import Api from 'services/api'

export const addRatesheet = (id: number) => {
  return Api.post(API_ADD_RATESHEET, {}, { id })
}

export const getRatesheets = (id: number, data: any) => {
  return Api.post(API_GET_RATESHEETS, data, { id })
}

export const getRatesheet = (id: number) => {
  return Api.get(API_GET_RATESHEETS, {}, { id })
}

export const updateRatesheet = (id: number, data: any) => {
  return Api.put(API_UPDATE_RATESHEET, data, { id })
}

export const deleteRatesheet = (loanId: number) => {
  return Api.delete(API_DELETE_RATESHEET, {}, { loanId })
}

export const removeOldRatesheets = (data: Record<string, any>) => {
  return Api.put(API_REMOVE_OLD_RATESHEET, data, {})
}

export const getLoanIDs = (id: number) => {
  return Api.get(API_GET_LOANIDS, {}, { id })
}

export const addNewProgram = (id: number, data: any) => {
  return Api.post(API_ADD_PROGRAM, data, { id })
}

export const cloneProgram = (id: number, data: Record<string, string>) => {
  return Api.post(API_CLONE_PROGRAM, data, { id })
}

export const updateProgramRestrictionLTVLimits = (id: number, data: any) => {
  return Api.put(API_UPDATE_PROGRAM_RESTRICTION_LTVLIMITS, data, { id })
}

export const updateProgramRestriction = (id: number, data: any) => {
  return Api.put(API_UPDATE_PROGRAM_RESTRICTION, data, { id })
}

export const updateProgramData = (id: number, data: any) => {
  return Api.put(API_UPDATE_PROGRAM, data, { id })
}

export const updateDscrOccupancyLtvReduction = (loanId: number, data: Record<string, any>) => {
  return Api.put(API_UPDATE_DSCR_OCCUPANCY_LTV_REDUCTION, data, { loanId })
}

export const addRatesheetHistory = (loanId: number, data: Record<string, string>) => {
  return Api.post(API_RATESHEET_HISTORY, data, { loanId })
}

export const clearRatesheetHistory = (loanId: number) => {
  return Api.delete(API_RATESHEET_HISTORY, {}, { loanId })
}

export const updateProgramsOrder = (id: number, newOrder: number[]) => {
  return Api.put(API_UPDATE_PROGRAMS_ORDER, newOrder, { id })
}

export const updateProductPower = (id: number, prodId: number) => {
  return Api.put(API_UPDATE_PRODUCT_POWER, {}, { id, prodId })
}

export const updateProgramInfo = (id: number, data: Record<string, any>) => {
  return Api.put(API_UPDATE_PROGRAM_INFO, data, { id })
}

export const removeProgram = (id: number, proId: number) => {
  return Api.delete(API_DELETE_PROGRAM, {}, { id, proId })
}

export const getProgram = (id: number, proId: number) => {
  return Api.get(API_DELETE_PROGRAM, {}, { id, proId })
}

export const getPrepayPenalty = () => {
  return Api.get(API_GET_PREPAY_PENALTY, {}, {})
}

export const getTemplates = () => {
  return Api.get(API_GET_TEMPLATES, {}, {})
}

export const syncProgram = (data: Record<string, any>) => {
  return Api.put(API_SYNC_PROGRAM, data, {})
}

export const syncProgramToGeneral = (data: Record<string, any>) => {
  return Api.put(API_SYNC_PROGRAM_TO_GENERAL, data, {})
}

export const syncProgramTiers = (data: Record<string, any>) => {
  return Api.put(API_SYNC_PROGRAM_TIERS, data, {})
}

export const priceLoan = (data: Record<string, any>) => {
  return Api.post(API_PRICE_LOAN, data, {})
}

export const fastMoneyPriceLoan = (data: Record<string, any>) => {
  return Api.post(API_FAST_MONEY_PRICE_LOAN, data, {})
}

export const updateLtvRatePriceRangeForNonDscr = (data: Record<string, any>, loanId: number) => {
  return Api.put(API_UPDATE_LTV_RATE_RANGE_FOR_NON_DSCR, data, { loanId })
}

export const updateRangeForDscr = (data: Record<string, any>, loanId: number) => {
  return Api.put(API_UPDATE_RANGE_FOR_DSCR, data, { loanId })
}

export const convertDBFromMongo = () => {
  return Api.get(API_CONVERT_DB_FROM_MONGO, {}, {}, { timeout: 3000000 })
}

export const convertDBFromPG = () => {
  return Api.get(API_CONVERT_DB_FROM_PG, {}, {}, { timeout: 3000000 })
}

export const uploadExcelToS3Bucket = (loanId: number) => {
  return Api.get(API_UPLOAD_EXCEL_TO_S3, {}, { loanId }, { timeout: 3000000 })
}

export const getStateTier = (loanId: number, programID: number) => {
  return Api.get(API_STATE_TIER, {}, { loanId, programID })
}

export const addStateTier = (data: any, loanId: number) => {
  return Api.post(API_ADD_STATE_TIER, data, { loanId })
}

export const removeStateTier = (data: any, loanId: number) => {
  return Api.post(API_REMOVE_STATE_TIER, data, { loanId })
}

export const updateStateTier = (data: any, loanId: number) => {
  return Api.put(API_UPDATE_STATE_TIER, data, { loanId })
}

export const saveStateTierValues = (data: any, loanId: number) => {
  return Api.put(API_SAVE_STATE_TIER_VALUES, data, { loanId })
}

export const saveTierLtvAdjustment = (data: any, loanId: number) => {
  return Api.put(API_SAVE_TIER_LTV_ADJ, data, { loanId })
}

export const saveMsaTierLtvAdjustment = (data: any, loanId: number) => {
  return Api.put(API_SAVE_MSA_TIER_LTV_ADJ, data, { loanId })
}

export const removeMsaTierLtvAdjCondition = (data: any, loanId: number) => {
  return Api.post(API_REMOVE_MSA_TIER_LTV_ADJ_CONDITION, data, { loanId })
}

export const saveExperienceTierLimit = (data: any, loanId: number) => {
  return Api.put(API_EXPERIENCE_TIER_LIMIT, data, { loanId })
}

export const saveCommercialProductTypes = (data: Record<number, string>, loanId: number) => {
  return Api.put(API_SAVE_COMMERCIAL_PRODUCT_TYPES, data, { loanId })
}

export const saveNonDscrLeverageData = (loanId: number, data: Record<string, any>) => {
  return Api.post(API_NON_DSCR_LEVERAGE_DATA, data, { loanId })
}

export const getNonDscrLeverageData = (loanId: number, programID: number, key: string) => {
  return Api.get(API_GET_NON_DSCR_LEVERAGE_DATA, {}, { loanId, programID, key })
}

export const saveDscrLeverageData = (loanId: number, data: Record<string, any>) => {
  return Api.post(API_DSCR_LEVERAGE_DATA, data, { loanId })
}

export const getDscrLeverageData = (loanId: number, programID: number, key: string) => {
  return Api.get(API_GET_DSCR_LEVERAGE_DATA, {}, { loanId, programID, key })
}

export const saveBonusMargin = (loanId: number, data: Record<string, any>) => {
  return Api.post(API_PROGRAM_BONUS_MARGIN, data, { loanId })
}
