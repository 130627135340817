import cloneDeep from 'clone-deep'
import { PlainInput } from 'components/PlainInput'
import { type IPrepaymentPenaltyOverlay, type IProgram, prePaymentPenaltyTermInMonthsOptions } from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Select } from 'stories/components'

interface Props {
  title: string
  program: IProgram
  loading: string
  onSave: (restriction: Record<string, any>) => Promise<boolean>
  onSavePPPOverlay: (pppOverlay: IPrepaymentPenaltyOverlay[], key: string) => void
}

export function PrepaymentPenalty({ title, program, loading, onSave, onSavePPPOverlay }: Props) {
  const [prepaymentRestriction, setPrepaymentRestriction] = useState<Record<string, any>>()
  const [enableSaveBtn, setEnableSaveBtn] = useState(false)
  const [pppOverlay, setPPPOverlay] = useState<IPrepaymentPenaltyOverlay[]>([])
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    setPrepaymentRestriction(program?.Restriction?.PrepaymentPenalty)

    if (program?.Restriction?.PPPOverlay) setPPPOverlay(program?.Restriction?.PPPOverlay)
    else setPPPOverlay([])
  }, [program])

  useEffect(() => {
    if (!loading) {
      setEnableSaveBtn(false)
      setEdit(false)
    }
  }, [loading])

  const onChange = (key: string, name: string, value: string) => {
    let nPrepaymentRestriction = cloneDeep(prepaymentRestriction)

    if (!nPrepaymentRestriction) return

    if (nPrepaymentRestriction.limits[key][name] != Number(value)) {
      nPrepaymentRestriction.limits[key][name] = Number(value)

      setPrepaymentRestriction(nPrepaymentRestriction)
      setEnableSaveBtn(true)
    }
  }

  const onSubmit = () => {
    if (!prepaymentRestriction) return

    const hasError = Object.keys(prepaymentRestriction.limits).find(
      (key) => Number(prepaymentRestriction.limits[key].max) < Number(prepaymentRestriction.limits[key].min),
    )

    if (hasError) {
      toast(
        `${prepaymentRestriction.limits[hasError].min} is greater than ${prepaymentRestriction.limits[hasError].max}`,
        { type: 'error' },
      )
      return
    }

    onSave(prepaymentRestriction)
  }

  const onChangeMinPPP = (index: number, value: any) => {
    const newPPPOverlay = cloneDeep(pppOverlay)

    if (pppOverlay[index].minPPP == value) return

    newPPPOverlay[index].minPPP = Number(value)

    setPPPOverlay(newPPPOverlay)
    setEdit(true)
  }

  return (
    <div>
      <p className="text-lg font-variation-settings-600 mb-3">{title}</p>

      {enableSaveBtn && (
        <div className="flex justify-end mt-3">
          <Button
            size="sm"
            className="pt-1.5 pb-1.5"
            loading={loading === LoadingStatus.SAVE_RESTRICTION}
            onClick={onSubmit}
          >
            Save
          </Button>
        </div>
      )}

      <table className="table w-full text-center">
        <thead className="bg-gray-100">
          <tr>
            <th className="border p-2">Type</th>
            <th className="border p-2">Min Price</th>
            <th className="border p-2">Max Price</th>
          </tr>
        </thead>

        <tbody>
          {prepaymentRestriction?.limits &&
            Object.keys(prepaymentRestriction.limits).map((key, index) => {
              const item = prepaymentRestriction.limits[key]

              return (
                <tr key={index}>
                  <td className="border py-1 w-1/3 min-w-[80px]">{prePaymentPenaltyTermInMonthsOptions[key]}</td>
                  <td className="border py-1 w-1/3">
                    <div className="flex justify-center">
                      <PlainInput
                        type="number"
                        value={item.min}
                        content={item.min}
                        className="w-full"
                        onChange={(value: string) => onChange(key, 'min', value)}
                      />
                    </div>
                  </td>
                  <td className="border py-1 w-1/3">
                    <div className="flex justify-center">
                      <PlainInput
                        type="number"
                        value={item.max}
                        content={item.max}
                        className="w-full"
                        onChange={(value: string) => onChange(key, 'max', value)}
                      />
                    </div>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>

      <p className="text-lg font-variation-settings-600 mt-4 mb-2">Prepayment Penalty Overlay</p>

      <div className="w-fit">
        {edit && (
          <div className="flex justify-end mt-3">
            <Button
              size="sm"
              className="pt-1.5 pb-1.5"
              loading={loading === LoadingStatus.SAVE_RESTRICTION}
              onClick={() => onSavePPPOverlay(pppOverlay, 'PPPOverlay')}
            >
              Save
            </Button>
          </div>
        )}

        <table className="table text-center">
          <thead className="bg-gray-100">
            <tr>
              <th className="border py-2 px-6 w-fit whitespace-nowrap">
                Has property been listed in the last 6 months?
              </th>
              <th className="border py-2 px-6 w-fit whitespace-nowrap">Min Prepayment Penalty</th>
            </tr>
          </thead>

          <tbody>
            {pppOverlay.map((item, index) => {
              return (
                <tr key={index}>
                  <td className="border p-1">{item.label}</td>
                  <td className="border p-1">
                    <Select
                      id="pppOverlay"
                      options={prePaymentPenaltyTermInMonthsOptions}
                      hasDefaultOption={true}
                      value={String(item.minPPP)}
                      className="w-full mb-[-16px]"
                      onChange={(v) => onChangeMinPPP(index, v)}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
