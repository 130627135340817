import type { ICommercialLtvData } from 'config'
import { Tooltip } from 'stories/components'
import { getPrice3decimal, isEmpty } from 'utils'

export const LtvItem = ({ title, maxValue, value, message }: ICommercialLtvData) => {
  const isMaxValue = !isNaN(maxValue) && !isEmpty(maxValue)

  return (
    <div className="flex justify-center">
      <div className="w-[100px]">
        <div className="flex justify-center items-center font-variation-settings-600 mb-1 p-1">
          <span className="mr-2">{title}</span>
          <Tooltip message={message} />
        </div>

        <div className="mb-2 text-center">{value ? `${getPrice3decimal(value.toFixed(2))}%` : '-'}</div>

        {isMaxValue && (
          <div className={`w-full px-2 text-white text-center ${maxValue < value ? 'bg-red-500' : 'bg-lime-600'}`}>
            {maxValue + '%'}
          </div>
        )}
      </div>
    </div>
  )
}
