import cloneDeep from 'clone-deep'
import { CommercialProdutTypes, IMasterProgram, InputType, IProgram } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { Checkbox, Modal } from 'stories/components'
import { InputValidate, isEmpty } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { LoadingStatus } from '..'
import { masterProgramDefaultInputs } from '../constants'

interface IProps {
  masterProgram?: IMasterProgram
  programs: IProgram[]
  productTypes: Record<number, string>
  isOpen: boolean
  loading: string
  onClose: () => void
  onSubmit: (data: IMasterProgram) => void
}

export const MasterProgramModal = (props: IProps) => {
  const { programs, masterProgram, productTypes, isOpen, loading, onClose, onSubmit } = props

  const [dscrPrograms, setDscrPrograms] = useState<IProgram[]>([])
  const [ids, setIDs] = useState<number[]>([])
  const [inputs, setInputs] = useState<Record<string, InputType>>(masterProgramDefaultInputs())

  useEffect(() => {
    const nPrograms = programs.filter((item) => productTypes[item.ID] === 'DSCR' && !!item.Power)
    setDscrPrograms(nPrograms)
  }, [programs])

  useEffect(() => {
    if (!masterProgram) return
    const newInputs = cloneDeep(inputs)
    Object.keys(newInputs).forEach((key) => {
      newInputs[key].value = (masterProgram as any)[key]
    })
    newInputs.Level.options = getPriceOptions(masterProgram.ProgramIDs.length)

    setIDs(masterProgram.ProgramIDs)
    setTimeout(() => {
      setInputs(newInputs)
    }, 200)
  }, [masterProgram])

  const isRequired = useMemo(() => {
    let isError = false
    Object.keys(inputs).forEach((key) => {
      const error = InputValidate(inputs[key])
      if (!isEmpty(error)) isError = true
    })
    return isError
  }, [inputs])

  useEffect(() => {
    const nInputs = cloneDeep(inputs)
    nInputs.Level.options = getPriceOptions(ids.length)
    setInputs(nInputs)
  }, [ids])

  const getPriceOptions = (value: number) => {
    const numbers = Array.from({ length: value }, (_, i) => i + 1)
    const priceOptions: Record<number, string> = {}
    numbers.map((num) => {
      const suffixes = ['th', 'st', 'nd', 'rd']
      const value = num % 100
      const sequentString = num + (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0])
      priceOptions[num] = 'Best ' + sequentString + ' Ex'
    })
    return priceOptions
  }

  const onChange = (key: string, value: any) => {
    let newInputs = cloneDeep(inputs)

    newInputs[key].value = value

    setInputs(newInputs)
  }

  const onCheckProgram = (programID: number) => {
    const newIDs = cloneDeep(ids)

    if (newIDs.includes(programID)) {
      const targetIndex = newIDs.findIndex((item) => item === programID)
      newIDs.splice(targetIndex, 1)
    } else newIDs.push(programID)

    setIDs(newIDs)
  }

  const onSelectAll = () => {
    let newIDs = cloneDeep(ids)

    if (!!newIDs.length) {
      if (newIDs.length === dscrPrograms.length) newIDs = []
      else newIDs = dscrPrograms.map((item) => item.ID)
    } else newIDs = dscrPrograms.map((item) => item.ID)

    setIDs(newIDs)
  }

  const onOk = () => {
    const data: IMasterProgram = {
      ID: masterProgram?.ID || NaN,
      Name: inputs.Name.value,
      Level: inputs.Level.value,
      ProgramIDs: ids,
    }
    onSubmit(data)
  }

  return (
    <div>
      <Modal
        title={`${!!masterProgram ? 'Edit Master Program' : 'Update Master Program'}`}
        titleOkay={`${!!masterProgram ? 'Update' : 'Add'}`}
        isOpen={isOpen}
        loading={loading === LoadingStatus.MODAL_LOADING}
        onClose={onClose}
        onOk={onOk}
        disabled={!ids.length || isRequired}
      >
        <div className="text-gray-700">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            {Object.keys(inputs).map((key, index) => {
              let input = inputs[key]

              return (
                <div className={`input md:col-span-${input.span || 1} `} key={index}>
                  <RenderInput input={input} Key={key} onChange={onChange} />
                </div>
              )
            })}
          </div>

          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-gray-700 bg-gray-50">
              <tr>
                <th colSpan={5} className="px-3 py-3 border text-center">
                  Sub Programs
                </th>
              </tr>
              <tr>
                <th className="px-3 py-3 border">No</th>
                <th className="px-3 py-3 border">Program Name</th>
                <th className="px-3 py-3 border">Program Type</th>
                <th className="px-3 py-3 border">Product Type</th>
                <th className="px-3 py-3 border">
                  <Checkbox
                    title="All"
                    id="all"
                    checked={ids.length === dscrPrograms.length}
                    onChange={onSelectAll}
                    className="mb-[-6px]"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {dscrPrograms.map((item, index) => {
                return (
                  <tr className="bg-white" key={index}>
                    <td className="px-3 py-2 border text-center text-gray-900 whitespace-nowrap">{index + 1}</td>
                    <td className="px-3 py-2 border text-gray-900 whitespace-nowrap">{item.Name}</td>
                    <td className="px-3 py-2 border text-gray-900 whitespace-nowrap">{item.Type}</td>
                    <td className={`px-3 py-2 border text-gray-900 whitespace-nowrap`}>
                      {CommercialProdutTypes[productTypes[item.ID]]}
                    </td>
                    <td className="px-3 py-2 border text-gray-900 whitespace-nowrap">
                      <Checkbox
                        title=""
                        id={`${index}`}
                        checked={ids.includes(item.ID)}
                        onChange={() => onCheckProgram(item.ID)}
                        className="w-5 mb-[-6px]"
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  )
}
