import { LoadingStatus } from 'pages/RateSheetOverview'
import { useState } from 'react'
import { Input2, Modal } from 'stories/components'

export function AddNewFico(props: any) {
  const [fico, setFico] = useState<number>()

  return (
    <div>
      <Modal
        title="Add New FICO"
        titleOkay="Add"
        isOpen={props.isOpen}
        loading={props.loading === LoadingStatus.ADD_NEW_FICO}
        onClose={props.onClose}
        onOk={() => props.onSubmit(Number(fico), props.index, props.dscrKey)}
      >
        <div className="w-[400px]">
          <Input2
            type="number"
            title="FICO number"
            onChange={(value) => setFico(Number(value))}
            value={fico?.toString()}
          />
        </div>
      </Modal>
    </div>
  )
}
