// import React from 'react'

import { BuildingLibraryIcon, BuildingOfficeIcon, HomeIcon } from '@heroicons/react/24/outline'

interface IProps {
  title: string
}

export const LoanPurposeDefine = (props: IProps) => {
  const { title } = props

  return (
    <div>
      <div className="text-2xl font-variation-settings-600 mb-3">
        <span>{title}</span>
      </div>

      <div className="p-4 border rounded-md mt-3">
        <p className="text-lg font-semibold mb-3">DSCR</p>

        <div className="flex items-center font-semibold gap-2 mb-2">
          <HomeIcon className="w-5 h-5" />
          <span>Purchase</span>
        </div>

        <p className="pl-6">
          <code className="text-shade-blue">Transaction Type</code>
          <code>{` = 'Purchase'`}</code>
        </p>

        <div className="flex items-center font-semibold gap-2 mt-3 mb-2">
          <BuildingLibraryIcon className="w-5 h-5" />
          <span>Cashout</span>
        </div>

        <p className="pl-6">
          <code className="text-shade-blue">Transaction Type</code>
          <code>{` = 'Refinance'`}</code>
          <i className="font-bold">{` And `}</i>
          <code className="text-shade-blue">Estimated Closing Amount to Borrower</code>
          <code>{` > Min(`}</code>
          <span className="font-semibold">{`$ 5000`}</span>
          <code>{`, `}</code>
          <span className="font-semibold">{`2 %`}</span>
          <code>{` of `}</code>
          <code className="text-shade-blue">Proposed Loan Amount</code>
          <code>{`)`}</code>
        </p>

        <div className="flex items-center font-semibold gap-2 mt-3 mb-2">
          <BuildingOfficeIcon className="w-5 h-5" />
          <span>No-Cashout</span>
        </div>

        <p className="pl-6">
          <code className="text-shade-blue">Transaction Type</code>
          <code>{` = 'Refinance'`}</code>
          <i className="font-bold">{` And `}</i>
          <code className="text-shade-blue">Estimated Closing Amount to Borrower</code>
          <code>{` <= Min(`}</code>
          <span className="font-semibold">{`$ 5000`}</span>
          <code>{`, `}</code>
          <span className="font-semibold">{`2 %`}</span>
          <code>{` of `}</code>
          <code className="text-shade-blue">Proposed Loan Amount</code>
          <code>{`)`}</code>
        </p>
      </div>

      <div className="p-4 border rounded-md mt-3">
        <p className="text-lg font-semibold mb-3">Non-DSCR</p>

        <div className="flex items-center font-semibold gap-2 mb-2">
          <HomeIcon className="w-5 h-5" />
          <span>Purchase</span>
        </div>

        <p className="pl-6">
          <code className="text-shade-blue">Transaction Type</code>
          <code>{` = 'Purchase'`}</code>
        </p>

        <div className="flex items-center font-semibold gap-2 mt-3 mb-2">
          <BuildingLibraryIcon className="w-5 h-5" />
          <span>Cashout</span>
        </div>

        <p className="pl-6">
          <code className="text-shade-blue">Transaction Type</code>
          <code>{` = 'Refinance'`}</code>
          <i className="font-bold">{` And `}</i>
          <code className="text-shade-blue">Estimated Closing Amount to Borrower</code>
          <code>{` > `}</code>
          <span className="font-semibold">{`2 %`}</span>
          <code>{` of `}</code>
          <code className="text-shade-blue">Proposed Loan Amount</code>
        </p>

        <div className="flex items-center font-semibold gap-2 mt-3 mb-2">
          <BuildingOfficeIcon className="w-5 h-5" />
          <span>No-Cashout</span>
        </div>

        <p className="pl-6">
          <code className="text-shade-blue">Transaction Type</code>
          <code>{` = 'Refinance'`}</code>
          <i className="font-bold">{` And `}</i>
          <code className="text-shade-blue">Estimated Closing Amount to Borrower</code>
          <code>{` <= `}</code>
          <span className="font-semibold">{`2 %`}</span>
          <code>{` of `}</code>
          <code className="text-shade-blue">Proposed Loan Amount</code>
        </p>
      </div>
    </div>
  )
}
