import { ArchiveBoxXMarkIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { PlainInput } from 'components/PlainInput'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from 'stories/components'
import { confirm, getPrice3decimal, removeComma } from 'utils'

import { LoadingStatus } from '..'
import type { ILoanAmountDscrOverlay } from './interface'

interface IProps {
  dscrOverlay: ILoanAmountDscrOverlay[]
  loading: string
  onSave: (data: ILoanAmountDscrOverlay[], key: string) => void
}

export const LoanAmountDscrOverlay = (props: IProps) => {
  const { dscrOverlay, loading, onSave } = props

  const [data, setData] = useState<ILoanAmountDscrOverlay[]>([])
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    if (!loading) setEdit(false)
  }, [loading])

  useEffect(() => {
    if (dscrOverlay) setData(dscrOverlay)
    else setData([])

    setEdit(false)
  }, [dscrOverlay])

  const addOverlay = () => {
    const newData = cloneDeep(data)

    newData.push({
      loanAmount: {
        from: data.length === 0 ? 0 : data[data.length - 1].loanAmount.to + 1,
        to: data.length === 0 ? 0 : data[data.length - 1].loanAmount.to + 1,
      },
      minDscr: 0,
    })

    setData(newData)
    setEdit(true)
  }

  const onChangeLoanAmount = (index: number, value: any, startValue = false) => {
    const newData = cloneDeep(data)

    value = removeComma(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    if (startValue) {
      newData[0].loanAmount.from = value

      if (newData[0].loanAmount.from > newData[0].loanAmount.to) newData[0].loanAmount.to = newData[0].loanAmount.from
    } else {
      if (newData[index].loanAmount.from > value) return

      newData[index].loanAmount.to = value
    }

    if (index < newData.length - 1)
      for (let i = index + 1; i < newData.length; i++) {
        if (newData[i].loanAmount.from - newData[i - 1].loanAmount.to !== 1)
          newData[i].loanAmount.from = newData[i - 1].loanAmount.to + 1

        if (newData[i].loanAmount.from > newData[i].loanAmount.to) newData[i].loanAmount.to = newData[i].loanAmount.from
      }

    setData(newData)
    setEdit(true)
  }

  const onChangeMinDscr = (index: number, value: any) => {
    const newData = cloneDeep(data)

    value = removeComma(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    newData[index].minDscr = value

    setData(newData)
    setEdit(true)
  }

  const onRemove = async (index: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this row?
        <br />
        <span className="text-gray-600 text-base">
          Loan Amount: {getPrice3decimal(data[index].loanAmount.from)} ― {getPrice3decimal(data[index].loanAmount.to)}
        </span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const newData = cloneDeep(data)

    newData.splice(index, 1)

    for (let i = 1; i < newData.length; i++) {
      if (newData[i].loanAmount.from - newData[i - 1].loanAmount.to !== 1)
        newData[i].loanAmount.from = newData[i - 1].loanAmount.to + 1

      if (newData[i].loanAmount.from > newData[i].loanAmount.to) newData[i].loanAmount.to = newData[i].loanAmount.from
    }

    setData(newData)
    setEdit(true)
  }

  const renderDscrOverlay = useMemo(() => {
    if (!data.length)
      return (
        <div className="w-full flex justify-center items-center mt-10">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Data</span>
          </div>
        </div>
      )

    return (
      <table className="table w-full text-center text-sm">
        <thead className="bg-gray-100">
          <tr>
            <th colSpan={2} className="border px-3 py-2">
              Loan Amount
            </th>
            <th rowSpan={2} className="border px-3 py-2 w-1/4">
              Min DSCR
            </th>
            <th rowSpan={2} className="border px-3 py-2 w-1/4">
              Action
            </th>
          </tr>

          <tr>
            <th className="border px-3 py-2 w-1/4">From</th>
            <th className="border px-3 py-2 w-1/4">To</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td className="border p-1">
                <div className="flex justify-center">
                  <PlainInput
                    value={item.loanAmount.from}
                    origin={item.loanAmount.from}
                    disabled={index !== 0}
                    content={getPrice3decimal(item.loanAmount.from)}
                    onChange={(value: any) => onChangeLoanAmount(index, value, index === 0)}
                    className="w-full"
                  />
                </div>
              </td>
              <td className="border p-1">
                <div className="flex justify-center">
                  <PlainInput
                    value={item.loanAmount.to}
                    origin={item.loanAmount.to}
                    content={getPrice3decimal(item.loanAmount.to)}
                    onChange={(value: any) => onChangeLoanAmount(index, value)}
                    className="w-full"
                  />
                </div>
              </td>
              <td className="border p-1">
                <div className="flex justify-center">
                  <PlainInput
                    value={item.minDscr}
                    origin={item.minDscr}
                    content={getPrice3decimal(item.minDscr)}
                    onChange={(value: any) => onChangeMinDscr(index, value)}
                    className="w-full"
                  />
                </div>
              </td>
              <td className="border p-1">
                <div className="flex justify-center">
                  <div
                    className="w-fit p-1 transition-all duration-200 hover:cursor-pointer hover-shadow1 rounded"
                    onClick={() => onRemove(index)}
                  >
                    <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }, [data])

  return (
    <div className="relative">
      <LayoutLoading show={LoadingStatus.LEVERAGE_LIMIT === loading} />

      <div className="px-2 mt-2">
        <div className={`flex items-center mb-2 ${edit ? 'justify-between' : 'justify-end'}`}>
          {edit && (
            <Button
              loading={LoadingStatus.LEVERAGE_LIMIT === loading}
              onClick={() => onSave(data, 'loanAmountDscrOverlay')}
              className="pt-1.5 pb-1.5"
            >
              Save
            </Button>
          )}

          <div
            className="flex items-center gap-2 cursor-pointer w-fit text-shade-blue transition-all duration-200 hover:border-b hover:border-b-shade-blue pr-1 h-6"
            onClick={addOverlay}
          >
            <PlusIcon className="w-4 h-4" />
            <span>Add Overlay</span>
          </div>
        </div>

        {renderDscrOverlay}
      </div>
    </div>
  )
}
