import { BuildingLibraryIcon, TrashIcon } from '@heroicons/react/24/outline'
import { PlainInput } from 'components/PlainInput'
import { IRehabBudgetType, RehabBudgetTypeOption } from 'config'
import { useMemo, useState } from 'react'
import { Button, Select } from 'stories/components'
import { getPrice3decimal, removeComma } from 'utils'

interface IProps {
  programID?: number
  programNames?: Record<number, string>
  error?: string
  data: IRehabBudgetType
  loading?: boolean
  isGeneral: boolean
  onChangeProgram?: (newProgramID: number) => void
  onChangeValues?: (
    key:
      | 'lightRate'
      | 'lightTo'
      | 'standardRate'
      | 'standardFrom'
      | 'standardTo'
      | 'heavyRate'
      | 'heavyFrom'
      | 'heavyRateFrom'
      | 'heavyRateTo'
      | 'heavyArvFrom',
    option: RehabBudgetTypeOption,
    value: string,
  ) => void
  onChangeGeneralValues?: (
    key:
      | 'lightRate'
      | 'lightTo'
      | 'standardRate'
      | 'standardFrom'
      | 'standardTo'
      | 'heavyRate'
      | 'heavyFrom'
      | 'heavyRateFrom'
      | 'heavyRateTo'
      | 'heavyArvFrom',
    option: RehabBudgetTypeOption,
    value: string,
  ) => void
  onChangeOption?: (newOption: RehabBudgetTypeOption) => void
  onChangeGeneralOption?: (newOption: RehabBudgetTypeOption) => void
  onRemove?: () => void
  onSubmitGeneral?: () => void
}

const rehabBudgetTypeOption: Record<RehabBudgetTypeOption, string> = {
  opt1: 'Option 1',
  opt2: 'Option 2',
  opt3: 'Option 3',
}

export const RehabBudgetTypeDefinition = (props: IProps) => {
  const {
    data,
    isGeneral,
    loading = false,
    programID = NaN,
    programNames = [],
    error = '',
    onChangeProgram = () => {},
    onChangeValues = () => {},
    onChangeGeneralValues = () => {},
    onChangeOption = () => {},
    onChangeGeneralOption = () => {},
    onRemove = () => {},
    onSubmitGeneral = () => {},
  } = props

  const [edit, setEdit] = useState(false)

  const renderOption1 = () => {
    const { definition } = data
    return (
      <>
        <div className="flex items-center font-semibold gap-2 mb-2">
          <BuildingLibraryIcon className="w-4 h-4" />
          <span>Light Rehab</span>
        </div>
        <p className="pl-6">
          <code className="text-shade-blue">Rehab Budget</code>
          <code>{` <= `}</code>
          <span className="w-fit max-w-[70px] relative inline-block text-center">
            <PlainInput
              value={definition.opt1.lightRate}
              origin={definition.opt1.lightRate}
              content={definition.opt1.lightRate}
              suffix="%"
              onChange={(value: string) => {
                if (isGeneral && definition.opt1.lightRate !== removeComma(value)) {
                  setEdit(true)
                  onChangeGeneralValues('lightRate', data.option, value)
                } else onChangeValues('lightRate', data.option, value)
              }}
            />
          </span>
          <code>{` of Min(`}</code>
          <code className="text-shade-blue">As Is Value</code>
          <code>, </code>
          <code className="text-shade-blue">Purchase Price</code>
          <code>{`) `}</code>
          <i className="font-bold">And</i>
          <code className="text-shade-blue"> Rehab Budget</code>
          <code>{` <= `}</code>
          <span className="w-fit max-w-[130px] relative inline-block text-center">
            <PlainInput
              value={definition.opt1.lightTo}
              origin={definition.opt1.lightTo}
              content={getPrice3decimal(definition.opt1.lightTo)}
              prefix="$"
              onChange={(value: string) => {
                if (isGeneral && definition.opt1.lightTo !== removeComma(value)) {
                  setEdit(true)
                  onChangeGeneralValues('lightTo', data.option, value)
                } else onChangeValues('lightTo', data.option, value)
              }}
            />
          </span>
        </p>

        <div className="flex items-center font-semibold gap-2 mb-2 mt-4">
          <BuildingLibraryIcon className="w-4 h-4" />
          <span>Standard Rehab</span>
        </div>
        <p className="pl-6">
          <code className="text-shade-blue">Rehab Budget</code>
          <code>{` > `}</code>
          <span className="w-fit max-w-[70px] relative inline-block text-center">
            <PlainInput
              value={definition.opt1.standardRate}
              origin={definition.opt1.standardRate}
              content={definition.opt1.standardRate}
              suffix="%"
              onChange={(value: string) => {
                if (isGeneral && definition.opt1.standardRate !== removeComma(value)) {
                  setEdit(true)
                  onChangeGeneralValues('standardRate', data.option, value)
                } else onChangeValues('standardRate', data.option, value)
              }}
            />
          </span>
          <code>{` of Min(`}</code>
          <code className="text-shade-blue">As Is Value</code>
          <code>, </code>
          <code className="text-shade-blue">Purchase Price</code>
          <code>{`) `}</code>
          <i className="font-bold">{`Or `}</i>
          <span className="w-fit max-w-[130px] relative inline-block text-center">
            <PlainInput
              value={definition.opt1.standardFrom}
              origin={definition.opt1.standardFrom}
              content={getPrice3decimal(definition.opt1.standardFrom)}
              prefix="$"
              onChange={(value: string) => {
                if (isGeneral && definition.opt1.standardFrom !== removeComma(value)) {
                  setEdit(true)
                  onChangeGeneralValues('standardFrom', data.option, value)
                } else onChangeValues('standardFrom', data.option, value)
              }}
            />
          </span>
          <code>{` < `}</code>
          <code className="text-shade-blue">Rehab Budget</code>
          <code>{` <= `}</code>
          <span className="w-fit max-w-[130px] relative inline-block text-center">
            <PlainInput
              value={definition.opt1.standardTo}
              origin={definition.opt1.standardTo}
              content={getPrice3decimal(definition.opt1.standardTo)}
              prefix="$"
              onChange={(value: string) => {
                if (isGeneral && definition.opt1.standardTo !== removeComma(value)) {
                  setEdit(true)
                  onChangeGeneralValues('standardTo', data.option, value)
                } else onChangeValues('standardTo', data.option, value)
              }}
            />
          </span>
        </p>

        <div className="flex items-center font-semibold gap-2 mt-4 mb-2">
          <BuildingLibraryIcon className="w-4 h-4" />
          <span>Heavy Rehab</span>
        </div>
        <p className="pl-6">
          <code className="text-shade-blue">Rehab Budget</code>
          <code>{` > `}</code>{' '}
          <span className="w-fit max-w-[70px] relative inline-block text-center">
            <PlainInput
              value={definition.opt1.heavyRate}
              origin={definition.opt1.heavyRate}
              content={definition.opt1.heavyRate}
              suffix="%"
              onChange={(value: string) => {
                if (isGeneral && definition.opt1.heavyRate !== removeComma(value)) {
                  setEdit(true)
                  onChangeGeneralValues('heavyRate', data.option, value)
                } else onChangeValues('heavyRate', data.option, value)
              }}
            />
          </span>
          <code>{` of Min(`}</code>
          <code className="text-shade-blue">As Is Value</code>
          <code>, </code>
          <code className="text-shade-blue">Purchase Price</code>
          <code>{`) `}</code>
          <i className="font-bold">{`Or `}</i>
          <span className="w-fit max-w-[130px] relative inline-block text-center">
            <PlainInput
              value={definition.opt1.heavyFrom}
              origin={definition.opt1.heavyFrom}
              content={getPrice3decimal(definition.opt1.heavyFrom)}
              prefix="$"
              onChange={(value: string) => {
                if (isGeneral && definition.opt1.heavyFrom !== removeComma(value)) {
                  setEdit(true)
                  onChangeGeneralValues('heavyFrom', data.option, value)
                } else onChangeValues('heavyFrom', data.option, value)
              }}
            />
          </span>
          <code>{`  <`}</code>
          <code className="text-shade-blue"> Rehab Budget</code>
        </p>
      </>
    )
  }

  const renderOption2 = () => {
    const { definition } = data
    return (
      <>
        <div className="flex items-center font-semibold gap-2 mb-2">
          <BuildingLibraryIcon className="w-4 h-4" />
          <span>Light Rehab</span>
        </div>
        <p className="pl-6">
          <code className="text-shade-blue">Rehab Budget</code>
          <code>{` <= `}</code>
          <span className="w-fit max-w-[70px] relative inline-block text-center">
            <PlainInput
              value={definition.opt2.lightRate}
              origin={definition.opt2.lightRate}
              content={definition.opt2.lightRate}
              suffix="%"
              onChange={(value: string) => {
                if (isGeneral && definition.opt2.lightRate !== removeComma(value)) {
                  setEdit(true)
                  onChangeGeneralValues('lightRate', data.option, value)
                } else onChangeValues('lightRate', data.option, value)
              }}
            />
          </span>
          <code>{` of Min(`}</code>
          <code className="text-shade-blue">As Is Value</code>
          <code>, </code>
          <code className="text-shade-blue">Purchase Price</code>
          <code>{`) `}</code>
          <i className="font-bold">Or</i>
          <code className="text-shade-blue"> Rehab Budget</code>
          <code>{` <= `}</code>
          <span className="w-fit max-w-[130px] relative inline-block text-center">
            <PlainInput
              value={definition.opt2.lightTo}
              origin={definition.opt2.lightTo}
              content={getPrice3decimal(definition.opt2.lightTo)}
              prefix="$"
              onChange={(value: string) => {
                if (isGeneral && definition.opt2.lightTo !== removeComma(value)) {
                  setEdit(true)
                  onChangeGeneralValues('lightTo', data.option, value)
                } else onChangeValues('lightTo', data.option, value)
              }}
            />
          </span>
        </p>

        <div className="flex items-center font-semibold gap-2 mt-4 mb-2">
          <BuildingLibraryIcon className="w-4 h-4" />
          <span>Heavy Rehab</span>
        </div>
        <p className="pl-6">
          <code className="text-shade-blue">Rehab Budget</code>
          <code>{` > `}</code>{' '}
          <span className="w-fit max-w-[70px] relative inline-block text-center">
            <PlainInput
              value={definition.opt2.heavyRateFrom}
              origin={definition.opt2.heavyRateFrom}
              content={definition.opt2.heavyRateFrom}
              suffix="%"
              onChange={(value: string) => {
                if (isGeneral && definition.opt2.heavyRateFrom !== removeComma(value)) {
                  setEdit(true)
                  onChangeGeneralValues('heavyRateFrom', data.option, value)
                } else onChangeValues('heavyRateFrom', data.option, value)
              }}
            />
          </span>
          <code>{` but less than `}</code>
          <span className="w-fit max-w-[70px] relative inline-block text-center">
            <PlainInput
              value={definition.opt2.heavyRateTo}
              origin={definition.opt2.heavyRateTo}
              content={definition.opt2.heavyRateTo}
              suffix="%"
              onChange={(value: string) => {
                if (isGeneral && definition.opt2.heavyRateTo !== removeComma(value)) {
                  setEdit(true)
                  onChangeGeneralValues('heavyRateTo', data.option, value)
                } else onChangeValues('heavyRateTo', data.option, value)
              }}
            />
          </span>
          <code>{` of Min(`}</code>
          <code className="text-shade-blue">As Is Value</code>
          <code>, </code>
          <code className="text-shade-blue">Purchase Price</code>
          <code>{`) `}</code>
          <i className="font-bold">{`And `}</i>
          <code className="text-shade-blue">Rehab Budget</code>
          <code>{` > `}</code>
          <span className="w-fit max-w-[130px] relative inline-block text-center">
            <PlainInput
              value={definition.opt2.heavyFrom}
              origin={definition.opt2.heavyFrom}
              content={getPrice3decimal(definition.opt2.heavyFrom)}
              prefix="$"
              onChange={(value: string) => {
                if (isGeneral && definition.opt2.heavyFrom !== removeComma(value)) {
                  setEdit(true)
                  onChangeGeneralValues('heavyFrom', data.option, value)
                } else onChangeValues('heavyFrom', data.option, value)
              }}
            />
          </span>
          <i className="font-bold">{` Or `}</i>
          <code className="text-shade-blue">After Repair Value</code>
          <code>{` >= `}</code>
          <span className="w-fit max-w-[50px] relative inline-block text-center">
            <PlainInput
              value={definition.opt2.heavyArvFrom}
              origin={definition.opt2.heavyArvFrom}
              content={definition.opt2.heavyArvFrom}
              onChange={(value: string) => {
                if (isGeneral && definition.opt2.heavyArvFrom !== removeComma(value)) {
                  setEdit(true)
                  onChangeGeneralValues('heavyArvFrom', data.option, value)
                } else onChangeValues('heavyArvFrom', data.option, value)
              }}
            />
          </span>
          <code>{` * Min(`}</code>
          <code className="text-shade-blue">As Is Value</code>
          <code>, </code>
          <code className="text-shade-blue">Purchase Price</code>
          <code>{`)`}</code>
        </p>
      </>
    )
  }

  const renderOption3 = () => {
    const { definition } = data
    return (
      <>
        <div className="flex items-center font-semibold gap-2 mb-2">
          <BuildingLibraryIcon className="w-4 h-4" />
          <span>Light Rehab</span>
        </div>
        <p className="pl-6">
          <code className="text-shade-blue">Rehab Budget</code>
          <code>{` <= `}</code>
          <span className="w-fit max-w-[70px] relative inline-block text-center">
            <PlainInput
              value={definition.opt3.lightRate}
              origin={definition.opt3.lightRate}
              content={definition.opt3.lightRate}
              suffix="%"
              onChange={(value: string) => {
                if (isGeneral && definition.opt3.lightRate !== removeComma(value)) {
                  setEdit(true)
                  onChangeGeneralValues('lightRate', data.option, value)
                } else onChangeValues('lightRate', data.option, value)
              }}
            />
          </span>
          <code>{` of Min(`}</code>
          <code className="text-shade-blue">As Is Value</code>
          <code>, </code>
          <code className="text-shade-blue">Purchase Price</code>
          <code>{`)`}</code>
        </p>

        <div className="flex items-center font-semibold gap-2 mt-4 mb-2">
          <BuildingLibraryIcon className="w-4 h-4" />
          <span>Heavy Rehab</span>
        </div>
        <p className="pl-6">
          <code className="text-shade-blue">Rehab Budget</code>
          <code>{` > `}</code>{' '}
          <span className="w-fit max-w-[70px] relative inline-block text-center">
            <PlainInput
              value={definition.opt3.heavyRateFrom}
              origin={definition.opt3.heavyRateFrom}
              content={definition.opt3.heavyRateFrom}
              suffix="%"
              onChange={(value: string) => {
                if (isGeneral && definition.opt3.heavyRateFrom !== removeComma(value)) {
                  setEdit(true)
                  onChangeGeneralValues('heavyRateFrom', data.option, value)
                } else onChangeValues('heavyRateFrom', data.option, value)
              }}
            />
          </span>
          <code>{` but less than `}</code>
          <span className="w-fit max-w-[70px] relative inline-block text-center">
            <PlainInput
              value={definition.opt3.heavyRateTo}
              origin={definition.opt3.heavyRateTo}
              content={definition.opt3.heavyRateTo}
              suffix="%"
              onChange={(value: string) => {
                if (isGeneral && definition.opt3.heavyRateTo !== removeComma(value)) {
                  setEdit(true)
                  onChangeGeneralValues('heavyRateTo', data.option, value)
                } else onChangeValues('heavyRateTo', data.option, value)
              }}
            />
          </span>
          <code>{` of Min(`}</code>
          <code className="text-shade-blue">As Is Value</code>
          <code>, </code>
          <code className="text-shade-blue">Purchase Price</code>
          <code>{`)`}</code>
          <i className="font-bold">{` Or `}</i>
          <code className="text-shade-blue">After Repair Value</code>
          <code>{` >= `}</code>
          <span className="w-fit max-w-[50px] relative inline-block text-center">
            <PlainInput
              value={definition.opt3.heavyArvFrom}
              origin={definition.opt3.heavyArvFrom}
              content={definition.opt3.heavyArvFrom}
              onChange={(value: string) => {
                if (isGeneral && definition.opt3.heavyArvFrom !== removeComma(value)) {
                  setEdit(true)
                  onChangeGeneralValues('heavyArvFrom', data.option, value)
                } else onChangeValues('heavyArvFrom', data.option, value)
              }}
            />
          </span>
          <code>{` * Min(`}</code>
          <code className="text-shade-blue">As Is Value</code>
          <code>, </code>
          <code className="text-shade-blue">Purchase Price</code>
          <code>{`)`}</code>
        </p>
      </>
    )
  }

  const renderRehabBudgetTypeDef = useMemo(() => {
    if (!data) return <></>

    switch (data.option) {
      case RehabBudgetTypeOption.OPTION1:
        return renderOption1()
      case RehabBudgetTypeOption.OPTION2:
        return renderOption2()
      case RehabBudgetTypeOption.OPTION3:
        return renderOption3()
    }
  }, [data])

  return (
    <div className="p-4 border rounded-md my-3">
      {isGeneral ? (
        <div className="flex justify-between items-center gap-4">
          <div className="flex items-center gap-4">
            <p className="text-lg font-semibold mb-3">General Definition</p>

            <Select
              id="options"
              options={rehabBudgetTypeOption}
              value={data.option}
              onChange={(value) => {
                setEdit(true)
                onChangeGeneralOption(value)
              }}
              className="min-w-[100px] w-fit"
            />
          </div>

          {edit && (
            <Button
              onClick={() => {
                onSubmitGeneral()
                setEdit(false)
              }}
              loading={loading}
              className="pt-1 pb-1 mb-[-2px] transition-all duration-200"
            >
              Save
            </Button>
          )}
        </div>
      ) : (
        <div className="flex justify-between items-start gap-4">
          <div className="flex gap-4">
            <Select
              id="program"
              title="Program Name"
              hasDefaultOption
              options={programNames}
              value={String(programID)}
              error={error}
              onChange={(value) => onChangeProgram(Number(value))}
              className="min-w-[200px] w-fit"
            />

            <Select
              id="options"
              options={rehabBudgetTypeOption}
              value={data.option}
              onChange={(value) => onChangeOption(value)}
              className="min-w-[100px] w-fit"
            />
          </div>

          <div
            className="w-fit p-1 hover:cursor-pointer transition-all duration-200 hover-shadow1 rounded"
            onClick={onRemove}
          >
            <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
          </div>
        </div>
      )}

      {renderRehabBudgetTypeDef}
    </div>
  )
}
