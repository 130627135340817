import { XMarkIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { useState } from 'react'

import { ItemsGroup } from './ItemsGroup'

interface IProps {
  title: string
  data: string[]
  onAdd: (title: string) => void
  onRemove: (title: string) => void
  onUpdate: (originValue: string, newValue: string) => void
  onClose?: () => void
}

export const AddItems = (props: IProps) => {
  const { title, data, onAdd, onRemove, onUpdate, onClose = () => {} } = props

  const [items, setItems] = useState<string[]>(data)

  const saveItem = async (values: string[]) => {
    const newItem: string[] = values.filter((v) => !items.includes(v))

    if (newItem.length === 0) {
      const removedItem = items.filter((item) => !values.includes(item))

      await onRemove(removedItem[0])

      setItems(values)
    } else {
      await onAdd(newItem[0])

      values.sort((x, y) => {
        const a = x.toLowerCase()
        const b = y.toLowerCase()

        if (a < b) return -1
        if (a > b) return 1
        return 0
      })

      setItems(values)
    }
  }

  const onUpdateItem = async (originValue: string, newValue: string) => {
    try {
      await onUpdate(originValue, newValue)

      const updatedItems = cloneDeep(items)
      const updatedIndex = items.findIndex((item) => item === originValue)

      updatedItems[updatedIndex] = newValue
      updatedItems.sort((x, y) => {
        const a = x.toLowerCase()
        const b = y.toLowerCase()

        if (a < b) return -1
        if (a > b) return 1
        return 0
      })

      setItems(updatedItems)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="px-4 pt-2">
      <div className="flex justify-end px-2 mb-2 h-6">
        <span
          className="flex items-center text-red-600 hover:cursor-pointer hover:border-b hover:border-b-red-600"
          onClick={onClose}
        >
          Close <XMarkIcon className="w-4 h-4" />
        </span>
      </div>

      <ItemsGroup
        className="pt-1"
        title={title}
        value={data}
        autoCompleteType="text"
        onChange={(values) => saveItem(values)}
        onUpdate={onUpdateItem}
      />
    </div>
  )
}
