import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { PlainInput } from 'components/PlainInput'
import { LoanPurposeMapping } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from 'stories/components'
import { getPrice3decimal, removeComma } from 'utils'

import { LoadingStatus } from '..'
import type { ILoanPurposeDscrOverlay } from './interface'

interface IProps {
  dscrOverlay: ILoanPurposeDscrOverlay[]
  loading: string
  onSave: (data: ILoanPurposeDscrOverlay[], key: string) => void
}

export const LoanPurposeDscrOverlay = (props: IProps) => {
  const { dscrOverlay, loading, onSave } = props

  const [data, setData] = useState<ILoanPurposeDscrOverlay[]>([])
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    if (!loading) setEdit(false)
  }, [loading])

  useEffect(() => {
    if (dscrOverlay) setData(dscrOverlay)
    else setData([])

    setEdit(false)
  }, [dscrOverlay])

  const onChangeMinDscr = (index: number, value: any) => {
    const newData = cloneDeep(data)

    value = removeComma(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    if (newData[index].minDscr === value) return
    newData[index].minDscr = value

    setData(newData)
    setEdit(true)
  }

  const renderDscrOverlay = useMemo(() => {
    if (!data.length)
      return (
        <div className="w-full flex justify-center items-center mt-10">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Data</span>
          </div>
        </div>
      )

    return (
      <table className="table w-1/2 min-w-max text-center text-sm">
        <thead className="bg-gray-100">
          <tr>
            <th className="border px-3 py-2">Loan Purpose</th>
            <th className="border px-3 py-2 w-5/12">Min DSCR</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td className="border p-1">
                <div className="flex justify-center">{LoanPurposeMapping[item.loanPurpose]}</div>
              </td>
              <td className="border p-1">
                <div className="flex justify-center">
                  <PlainInput
                    value={item.minDscr}
                    origin={item.minDscr}
                    content={getPrice3decimal(item.minDscr)}
                    onChange={(value: any) => onChangeMinDscr(index, value)}
                    className="w-full"
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }, [data])

  return (
    <div className="relative">
      <LayoutLoading show={LoadingStatus.LEVERAGE_LIMIT === loading} />

      <div className="px-2 mt-2">
        {edit && (
          <Button
            loading={LoadingStatus.LEVERAGE_LIMIT === loading}
            onClick={() => onSave(data, 'loanPurposeDscrOverlay')}
            className="pt-1.5 pb-1.5"
          >
            Save
          </Button>
        )}

        {renderDscrOverlay}
      </div>
    </div>
  )
}
