import { useSelector } from 'react-redux'
import { AccountType } from 'stories/layouts'

interface IProps {
  children: any
  permissions: AccountType[]
}

export const PermissionGate = (props: IProps) => {
  const { children, permissions } = props

  const auth = useSelector((state: any) => state.auth)

  const permissionGranted = permissions.includes(auth.profile.userType)

  if (!permissionGranted) {
    return (
      <div
        className="p-4 mb-4 text-center text-[16px] text-red-800 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
        role="alert"
      >
        <span className="">Access Forbidden!</span>
      </div>
    )
  }

  return <>{children}</>
}
