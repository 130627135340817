import { LayoutLoading } from 'components/LayoutLoading'
import { ICompany } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getCompanies } from 'services'
import { ButtonGroup } from 'stories/components'
import { AccountType } from 'stories/layouts'

import { LoanProgramTable } from './LoanProgramTable'

export const LoanPrograms = ({ title }: { title: string }) => {
  const [companies, setCompanies] = useState<ICompany[]>([])
  const [selectedCompany, setSelectedCompany] = useState<ICompany>()
  const [isLoading, setLoading] = useState(false)

  const auth = useSelector((state: any) => state.auth)
  const isInvestor = [AccountType.INVESTOR, AccountType.ASSOCIATE].includes(auth.profile.userType)

  useEffect(() => {
    ;(async function getData() {
      setLoading(true)
      const res = await getCompanies()
      if (!!res.length) setSelectedCompany(res[0])
      setCompanies(res)
      setLoading(false)
    })()
  }, [])

  const renderTabs = useMemo(() => {
    if (isInvestor || !companies.length) return <></>
    else
      return (
        <ButtonGroup
          title={companies.map((item) => item.name)}
          value={selectedCompany?.name || ''}
          onChange={(value: string) => {
            const selectedItem = companies.find((item) => item.name === value)
            setSelectedCompany(selectedItem)
          }}
        />
      )
  }, [isInvestor, companies, selectedCompany])

  const renderLoanPrograms = useMemo(() => {
    if (!selectedCompany) return <></>

    return <LoanProgramTable company={selectedCompany} />
  }, [selectedCompany])

  return (
    <div className="relative">
      <LayoutLoading show={isLoading} />
      <div className="text-2xl font-variation-settings-600 mb-3">
        <span>{title}</span>
      </div>

      {renderTabs}

      {renderLoanPrograms}
    </div>
  )
}
