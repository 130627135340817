import { ArchiveBoxXMarkIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { PlainInput } from 'components/PlainInput'
import {
  type ICrossCollateralBlanket,
  commercialOccupancyTypeOptions,
  originalPropertyTypeLabels,
  propertyTypeLabels,
} from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Checkbox, Input3, Select } from 'stories/components'
import { confirm, isEmpty, removeComma } from 'utils'

interface IProps {
  isTemplate: boolean
  leverageData: ICrossCollateralBlanket | undefined
  isLoading: boolean
  loading: string
  onSave: (data: ICrossCollateralBlanket, limitKey: string) => void
}

export const CrossCollateralBlanketLimit = (props: IProps) => {
  const { isLoading, isTemplate, leverageData, loading, onSave } = props

  const [data, setData] = useState<ICrossCollateralBlanket>()
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    if (!loading) setEdit(false)
  }, [loading])

  useEffect(() => {
    if (!leverageData) return

    setData(leverageData)
    setEdit(false)
  }, [leverageData])

  const onChangeNumberOfProperties = (key: string, value: number) => {
    if (!data) return

    const newData = cloneDeep(data)
    const origialData = cloneDeep(data)

    value = removeComma(value)

    if (value < 0) {
      setData(origialData)
      return
    }

    if ((newData.numberOfPropertiesLimit as any)[key] == value) return
    ;(newData.numberOfPropertiesLimit as any)[key] = value

    if (newData.numberOfPropertiesLimit.max < newData.numberOfPropertiesLimit.min) {
      setData(origialData)
      return
    }

    setData(newData)
    setEdit(true)
  }

  const onChangeAllocatedBalance = (key: string, value: number) => {
    if (!data) return

    const newData = cloneDeep(data)
    const origialData = cloneDeep(data)

    value = removeComma(value)

    if (value < 0) {
      setData(origialData)
      return
    }

    if ((newData.propertyLevelAllocatedBalanceLimit as any)[key] == value) return
    ;(newData.propertyLevelAllocatedBalanceLimit as any)[key] = value

    if (newData.propertyLevelAllocatedBalanceLimit.max < newData.propertyLevelAllocatedBalanceLimit.min) {
      setData(origialData)
      return
    }

    setData(newData)
    setEdit(true)
  }

  const onChangeOccupancy = (index: number) => {
    if (!data) return

    if (!isTemplate) return

    const newData = cloneDeep(data)

    const targetIndex = newData.eligibleOccupancies.findIndex((item) => item === commercialOccupancyTypeOptions[index])

    if (targetIndex !== -1) newData.eligibleOccupancies.splice(targetIndex, 1)
    else newData.eligibleOccupancies.push(commercialOccupancyTypeOptions[index])

    setData(newData)
    setEdit(true)
  }

  const onChangePropertyType = (index: number) => {
    if (!data) return

    if (!isTemplate) return

    const newData = cloneDeep(data)

    const targetIndex = newData.eligiblePropertyTypes.findIndex((item) => item === originalPropertyTypeLabels[index])

    if (targetIndex !== -1) newData.eligiblePropertyTypes.splice(targetIndex, 1)
    else newData.eligiblePropertyTypes.push(originalPropertyTypeLabels[index])

    setData(newData)
    setEdit(true)
  }

  const onChangeAsIsValuePurchasePrice = (key: 'minAsIsValue' | 'minPurchasePrice', value: number) => {
    if (!data) return

    const newData = cloneDeep(data)
    const origialData = cloneDeep(data)

    value = removeComma(value)

    if (value < 0) {
      setData(origialData)
      return
    }

    if (newData[key] == value) return
    newData[key] = value

    setData(newData)
    setEdit(true)
  }

  const onAddDscrLimit = () => {
    const newData = cloneDeep(data)

    if (newData?.minDscr.length === propertyTypeLabels.length) return

    newData?.minDscr.push({
      propertyType: '',
      amortizingPaymentMinDscr: 0,
      interestOnlyMinDscr: 0,
    })

    setData(newData)
    setEdit(true)
  }

  const onChangePropertyTypeForDscr = (index: number, value: string) => {
    if (!data) return

    const newData = cloneDeep(data)

    const exist = newData.minDscr.find((item) => item.propertyType === value)

    if (exist) {
      toast(`Please select a different Property Type.`, {
        type: 'warning',
      })
      return
    }

    newData.minDscr[index].propertyType = value

    setData(newData)
    setEdit(true)
  }

  const onChangeMinDscr = (index: number, key: 'amortizingPaymentMinDscr' | 'interestOnlyMinDscr', value: any) => {
    if (!data) return
    value = removeComma(value)
    const newData = cloneDeep(data)

    newData.minDscr[index][key] = value

    setData(newData)
    setEdit(true)
  }

  const onRemoveDscrLimit = async (index: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this row?
        <br />
        <span className="text-gray-600 text-base">Property Type: {data?.minDscr[index].propertyType}</span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const newData = cloneDeep(data)

    newData?.minDscr.splice(index, 1)

    setData(newData)
    setEdit(true)
  }

  const onSubmit = () => {
    if (!data) return

    const requiredExist = data.minDscr.find((item) => isEmpty(item.propertyType))

    if (requiredExist) {
      toast(`Property Type is required on DSCR`, {
        type: 'warning',
      })
      return
    }

    onSave(data, 'crossCollateralBlanket')
  }

  const renderLimit = useMemo(() => {
    if (!data) return <></>

    return (
      <div>
        <div className="text-base font-semibold border-b border-b-gray-300 pb-1 mb-3 px-2">Min/Max # of Properties</div>
        <div className="flex items-center gap-4 p-2">
          {Object.keys(data.numberOfPropertiesLimit)
            .reverse()
            .map((key, index) => {
              const value = (data.numberOfPropertiesLimit as any)[key]

              return (
                <Input3
                  key={index}
                  title={`${key === 'min' ? 'Min' : 'Max'}`}
                  type="number"
                  readOnly={!isTemplate}
                  value={value}
                  className="mb-[-16px]"
                  onChange={(value) => onChangeNumberOfProperties(key, value)}
                />
              )
            })}
        </div>

        <div className="text-base font-semibold border-b border-b-gray-300 pb-1 mb-3 mt-6 px-2">
          Property Level Allocated Balance
        </div>
        <div className="flex items-center gap-4 p-2">
          {Object.keys(data.propertyLevelAllocatedBalanceLimit)
            .reverse()
            .map((key, index) => {
              const value = (data.propertyLevelAllocatedBalanceLimit as any)[key]

              return (
                <Input3
                  key={index}
                  title={`${key === 'min' ? 'Min' : 'Max'}`}
                  type="thousandSep"
                  readOnly={!isTemplate}
                  value={value}
                  className="mb-[-16px]"
                  onChange={(value) => onChangeAllocatedBalance(key, value)}
                />
              )
            })}
        </div>

        <div className="text-base font-semibold border-b border-b-gray-300 pb-1 mb-3 mt-6 px-2">Eligible Occupancy</div>
        <div className="flex justify-start flex-wrap gap-x-10 gap-y-2 px-2">
          {commercialOccupancyTypeOptions.map((item, index) => (
            <Checkbox
              key={index}
              id={item}
              title={item}
              size={3}
              value={data.eligibleOccupancies.includes(item)}
              color="gray"
              className="w-fit"
              onChange={() => onChangeOccupancy(index)}
            />
          ))}
        </div>

        <div className="text-base font-semibold border-b border-b-gray-300 pb-1 mb-3 mt-6 px-2">
          Eligible Property Type
        </div>
        <div className="flex justify-start flex-wrap gap-x-10 gap-y-2 px-2">
          {originalPropertyTypeLabels.map((item, index) => (
            <Checkbox
              key={index}
              id={item}
              title={item}
              size={3}
              value={data.eligiblePropertyTypes.includes(item)}
              color="gray"
              className="w-[175px]"
              onChange={() => onChangePropertyType(index)}
            />
          ))}
        </div>

        <div className="text-base font-semibold border-b border-b-gray-300 pb-1 mb-3 mt-6 px-2">As Is Value</div>
        <div className="flex items-center gap-4 p-2">
          <Input3
            title="Min As Is Value"
            type="thousandSep"
            readOnly={!isTemplate}
            value={data.minAsIsValue.toString()}
            className="mb-[-16px]"
            onChange={(value) => onChangeAsIsValuePurchasePrice('minAsIsValue', value)}
          />
        </div>

        <div className="text-base font-semibold border-b border-b-gray-300 pb-1 mb-3 mt-6 px-2">Purchase Price</div>
        <div className="flex items-center gap-4 p-2">
          <Input3
            title="Min Purchase Price"
            type="thousandSep"
            readOnly={!isTemplate}
            value={data.minPurchasePrice.toString()}
            className="mb-[-16px]"
            onChange={(value) => onChangeAsIsValuePurchasePrice('minPurchasePrice', value)}
          />
        </div>

        <div className="text-base font-semibold border-b border-b-gray-300 pb-1 mb-3 mt-6 px-2">DSCR</div>
        {isTemplate && (
          <div className="flex justify-end mb-2">
            <div
              className="flex items-center gap-2 cursor-pointer w-fit text-shade-blue transition-all duration-200 hover:border-b hover:border-b-shade-blue pr-1 h-6"
              onClick={onAddDscrLimit}
            >
              <PlusIcon className="w-4 h-4" />
              <span>Add DSCR Limit</span>
            </div>
          </div>
        )}

        {data.minDscr.length ? (
          <table className="table w-full text-center text-sm">
            <thead className="bg-gray-100">
              <tr>
                <th rowSpan={2} className="border px-3 py-2 whitespace-nowrap">
                  Property Type
                </th>
                <th colSpan={2} className="border px-3 py-2">
                  Min DSCR
                </th>
                {isTemplate && (
                  <th rowSpan={2} className="border px-3 py-2">
                    Action
                  </th>
                )}
              </tr>
              <tr>
                <th className="border px-3 py-2 w-1/4">Amortizing Payment</th>
                <th className="border px-3 py-2 w-1/4">Interest Only</th>
              </tr>
            </thead>

            <tbody>
              {data.minDscr.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className={`border whitespace-nowrap p-1`}>
                      {isTemplate ? (
                        <Select
                          id="propertyType"
                          options={propertyTypeLabels}
                          hasDefaultOption={true}
                          value={item.propertyType}
                          className="w-full mb-[-16px]"
                          onChange={(value) => onChangePropertyTypeForDscr(index, value)}
                        />
                      ) : (
                        item.propertyType
                      )}
                    </td>
                    <td className={`border whitespace-nowrap p-1`}>
                      <div className="flex justify-center">
                        <PlainInput
                          type="number"
                          value={item.amortizingPaymentMinDscr}
                          origin={item.amortizingPaymentMinDscr}
                          content={item.amortizingPaymentMinDscr}
                          onChange={(value: any) => onChangeMinDscr(index, 'amortizingPaymentMinDscr', value)}
                          disabled={!isTemplate}
                          className="w-full"
                        />
                      </div>
                    </td>
                    <td className={`border whitespace-nowrap p-1`}>
                      <div className="flex justify-center">
                        <PlainInput
                          type="number"
                          value={item.interestOnlyMinDscr}
                          origin={item.interestOnlyMinDscr}
                          content={item.interestOnlyMinDscr}
                          onChange={(value: any) => onChangeMinDscr(index, 'interestOnlyMinDscr', value)}
                          disabled={!isTemplate}
                          className="w-full"
                        />
                      </div>
                    </td>
                    {isTemplate && (
                      <td className="border p-1">
                        <div className="flex justify-center">
                          <div
                            className="w-fit p-1 transition-all duration-200 hover:cursor-pointer hover-shadow1 rounded"
                            onClick={() => onRemoveDscrLimit(index)}
                          >
                            <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                          </div>
                        </div>
                      </td>
                    )}
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          <div className="w-full flex justify-center items-center mt-10">
            <div className="flex flex-col items-center gap-1">
              <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
              <span className="text-gray-400">No Limits</span>
            </div>
          </div>
        )}
      </div>
    )
  }, [data])

  return (
    <div className="relative">
      <LayoutLoading show={LoadingStatus.LEVERAGE_LIMIT === loading || isLoading} />

      <div className="px-2 mt-4">
        {isTemplate && edit && (
          <div className="flex justify-end">
            <Button
              loading={LoadingStatus.LEVERAGE_LIMIT === loading}
              onClick={onSubmit}
              className="pt-1 pb-1 mb-[-1px]"
            >
              Save
            </Button>
          </div>
        )}

        {renderLimit}
      </div>
    </div>
  )
}
