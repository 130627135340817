import { useState } from 'react'
import { Input2, Modal, Select2 } from 'stories/components'

import { LoadingStatus } from '..'

export const AddRatesheetHistoryModal = (props: any) => {
  const [program, setProgram] = useState('')
  const [content, setContent] = useState('')

  return (
    <div>
      <Modal
        title="Add Rate Sheet History"
        titleOkay="Add"
        isOpen={props.isOpen}
        loading={props.loading === LoadingStatus.ADD_RATESHEET_HISTORY}
        onClose={props.onClose}
        onOk={() => props.onSubmit(program, content)}
      >
        <div className="w-[400px]">
          <Select2
            title="Program Name"
            id="name"
            onChange={(value) => setProgram(value)}
            value={program}
            options={props.programs}
            className="mb-4"
            hasDefaultOption={true}
            required={true}
          />
          <Input2 title="Content" required={true} onChange={(value) => setContent(value)} value={content} />
        </div>
      </Modal>
    </div>
  )
}
