import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import type { ISecondLienLimit } from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import { useEffect, useMemo, useState } from 'react'
import { Button, Input3 } from 'stories/components'
import { removeComma } from 'utils'

interface IProps {
  isTemplate: boolean
  leverageData: ISecondLienLimit | undefined
  isLoading: boolean
  loading: string
  onSave: (data: ISecondLienLimit) => void
}

export const SecondLienMaxLimits = (props: IProps) => {
  const { isLoading, isTemplate, leverageData, loading, onSave } = props

  const [data, setData] = useState<ISecondLienLimit>()
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    if (!leverageData) return

    setData(leverageData)
    setEdit(false)
  }, [leverageData])

  useEffect(() => {
    if (!loading) setEdit(false)
  }, [loading])

  const onChange = (key: 'maxSecondLien' | 'maxCLTV', value: any) => {
    if (!data) return

    const newData = cloneDeep(data)
    value = removeComma(value)

    if (newData[key] === value) return

    newData[key] = value

    setData(newData)
    setEdit(true)
  }

  const renderMaxLimit = useMemo(() => {
    if (!data) return <></>

    return (
      <div className="flex items-center gap-4">
        <Input3
          title="Max Amount"
          type="thousandSep"
          readOnly={!isTemplate}
          value={data.maxSecondLien.toString()}
          className="mb-[-16px]"
          onChange={(value) => onChange('maxSecondLien', value)}
        />
        <Input3
          title="Max CLTV"
          type="number"
          readOnly={!isTemplate}
          value={data.maxCLTV.toString()}
          className="mb-[-16px]"
          onChange={(value) => onChange('maxCLTV', value)}
        />

        {isTemplate && edit && (
          <Button
            loading={LoadingStatus.LEVERAGE_LIMIT === loading}
            onClick={() => onSave(data)}
            className="pt-1.5 pb-1.5 mb-[-2px]"
          >
            Save
          </Button>
        )}
      </div>
    )
  }, [data, edit, isTemplate, loading])

  return (
    <div className="relative">
      <LayoutLoading show={LoadingStatus.LEVERAGE_LIMIT === loading || isLoading} />

      <div className="px-2 py-4 mt-2">{renderMaxLimit}</div>
    </div>
  )
}
