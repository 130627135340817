import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import type { InputType, IProgram } from 'config'
import { useEffect, useState } from 'react'
import { submitLoanProgram } from 'services'
import { svgLoading } from 'stories/assets'
import { Button } from 'stories/components'
import { InputConvert, InputValidate, isEmpty } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { defaultInputs } from './constants'
import type { ILoanProgram } from './interface'
import { inputLogic, parseProductType } from './logic'

interface IProps {
  companyID: number
  program?: IProgram
  defaults: ILoanProgram | null
  lastUpdatedAt: number
  productOptions: string[]
  programOptions: Record<string, string>
  onComplete: () => void
  onBack: () => void
}

export default function LoanProgramForm(props: IProps) {
  const { program = undefined, defaults = null, lastUpdatedAt, companyID, productOptions, programOptions } = props

  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<Record<string, InputType>>(defaultInputs())

  useEffect(() => {
    setLoading(true)

    const newInputs = defaultInputs()

    if (!!program) {
      newInputs['programID'].disabled = true
      newInputs['programID'].value = program.ID
    }

    if (!defaults) {
      newInputs['productName'].options = productOptions
      newInputs['programID'].options = programOptions
      setInputs(newInputs)
      setLoading(false)
      return
    }

    newInputs['productName'].options = productOptions
    newInputs['programID'].options = programOptions

    for (const key in newInputs) {
      if (!isEmpty((defaults as any)[key])) {
        newInputs[key].value = (defaults as any)[key]
      }
    }

    setInputs(inputLogic(newInputs))
    setLoading(false)
  }, [defaults, lastUpdatedAt, productOptions, programOptions, program])

  const onBack = () => {
    props.onBack()
  }

  const onChange = (key: string, value: any) => {
    let newInputs = cloneDeep(inputs)

    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = InputValidate(newInputs[key])

    if (['amortizationType', 'ARMNoAdjCap', 'interestOnly'].indexOf(key) !== -1) {
      newInputs = inputLogic(newInputs)
    }

    if (['productName'].includes(key)) newInputs = parseProductType(newInputs)

    setInputs(newInputs)
  }

  const onSubmit = () => {
    let hasError = false
    let newInputs = cloneDeep(inputs)
    let data: Record<string, any> = {}
    Object.keys(newInputs).forEach((key) => {
      if (newInputs[key].visible === false) return null
      newInputs[key].error = InputValidate(newInputs[key])
      data[key] = newInputs[key].value
      if (newInputs[key].error) hasError = true
    })
    setInputs(newInputs)

    if (hasError) return

    setLoading(true)

    const id = defaults ? defaults.id : 0

    submitLoanProgram(companyID, id, data)
      .then((res) => {
        if (res.success) {
          props.onComplete()
          onBack()
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="ARMDetails-container p-2">
      <h2 className="text-lg font-variation-settings-600 mb-3">
        Loan Program {!defaults ? '- Add' : `#${defaults.no} - Update`}
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      <Button link onClick={onBack}>
        <div className="flex text-shade-blue items-center">
          <ArrowLeftIcon className="w-4 h-4 mr-2" /> <p>Return to Loan Programs Lists</p>
        </div>
      </Button>

      <div className="mb-4">
        <div className="grid gap-4 md:grid-cols-6 grid-cols-1 mb-3">
          {Object.keys(inputs).map((key: string) => {
            let input = inputs[key]
            if (input.visible === false) return null

            return (
              <div className={`input md:col-span-${input.span || 2}`} key={key}>
                <RenderInput input={input} Key={key} onChange={onChange} />
              </div>
            )
          })}
        </div>
      </div>

      <div className="block text-center">
        <Button onClick={onSubmit} className="px-10" loading={isLoading}>
          <>{defaults ? 'Update' : 'Add'} Loan Program</>
        </Button>
      </div>
    </div>
  )
}
