import cloneDeep from 'clone-deep'
import type { IStateMSA } from 'config'
import { statesConstant } from 'config/states.constants'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Checkbox, Input2, Modal } from 'stories/components'

interface IProps {
  loading: string
  data?: IStateMSA
  isEdit: boolean
  isOpen: boolean
  onClose: () => void
  onAdd: (newMsa: IStateMSA) => void
  onUpdate: (rank: number, newMsa: IStateMSA) => void
}

export const MsaEditModal = (props: IProps) => {
  const { data, isEdit, isOpen, loading, onClose, onAdd, onUpdate } = props

  const [name, setName] = useState('')
  const [states, setStates] = useState<string[]>([])

  useEffect(() => {
    if (!data) return

    setStates(data.states)
    setName(data.msa.name)
  }, [data])

  const onOk = () => {
    if (!name) {
      toast(`MSA Name is required`, { type: 'error' })
      return
    }
    if (states.length === 0) {
      toast(`Select States`, { type: 'error' })
      return
    }

    const newState: IStateMSA = {
      states: states.sort(),
      msa: {
        name: name.trim(),
        ranking: 0,
      },
    }

    if (isEdit) {
      if (!data) return

      newState.msa.ranking = data.msa.ranking

      onUpdate(data.msa.ranking, newState)
    } else onAdd(newState)
  }

  const onSelectState = (state: string) => {
    const newStates = cloneDeep(states)
    const removeIndex = newStates.findIndex((item) => item === state)

    if (removeIndex === -1) newStates.push(state)
    else newStates.splice(removeIndex, 1)

    setStates(newStates)
  }

  return (
    <div>
      <Modal
        title={`${isEdit ? 'Edit MSA' : 'Add New MSA'}`}
        titleOkay={`${isEdit ? 'Save' : 'Add'}`}
        isOpen={isOpen}
        loading={['updateMsa', 'addMsa', 'removeMsa'].includes(loading)}
        onClose={onClose}
        onOk={onOk}
      >
        <div className=" max-w-[750px]">
          <div className="flex gap-2 w-full mb-4">
            <Input2 value={name} title="MSA Name" type="text" className="w-[450px]" onChange={(v) => setName(v)} />
          </div>

          <div className="flex flex-wrap gap-2">
            {statesConstant.map((state, index) => (
              <Checkbox
                key={index}
                id={index.toString()}
                title={state}
                value={states.includes(state)}
                color="gray"
                className="flex items-center w-[55px]"
                onClick={() => onSelectState(state)}
              />
            ))}
          </div>
        </div>
      </Modal>
    </div>
  )
}
