import { ArchiveBoxXMarkIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { PlainInput } from 'components/PlainInput'
import { IDscrPropertyTypeLeverageData, propertyTypeLabels } from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import {
  convertNullToBlank,
  convertNullToBlankValueToDecimal,
} from 'pages/RateSheetOverview/ExcelSheets/CommercialOnlyProgrammed/logic'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Select } from 'stories/components'
import { confirm, getPrice3decimal, isEmpty, removeComma } from 'utils'

interface IProps {
  leverageData: IDscrPropertyTypeLeverageData[]
  isLoading: boolean
  isTemplate: boolean
  title: string
  loading: string
  onSave: (data: IDscrPropertyTypeLeverageData[]) => void
}

export const DscrPropertyTypeLtvMaxLimit = (props: IProps) => {
  const { loading, isTemplate, leverageData, isLoading, onSave } = props

  const [data, setData] = useState<IDscrPropertyTypeLeverageData[]>([])
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    if (!leverageData) return

    setData(leverageData)

    setEdit(false)
  }, [leverageData])

  const addLimit = () => {
    const newData = cloneDeep(data)

    if (newData.length === propertyTypeLabels.length) {
      toast(`All options are already included.`, { type: 'info' })
      return
    }

    newData.unshift({
      value: '',
      loanAmount: NaN,
      LTV: NaN,
      CLTV: NaN,
      minFICO: 0,
      limits: [
        {
          FICO: { from: 0, to: 1000 },
          loanAmount: {
            purchase: {
              min: NaN,
              max: NaN,
            },
            nocashout: {
              min: NaN,
              max: NaN,
            },
            cashout: {
              min: NaN,
              max: NaN,
            },
          },
          LTV: {
            purchase: { ltv: { min: NaN, max: NaN }, cltv: NaN },
            nocashout: { ltv: { min: NaN, max: NaN }, cltv: NaN },
            cashout: { ltv: { min: NaN, max: NaN }, cltv: NaN },
          },
          minDSCR: {
            purchase: 0,
            nocashout: 0,
            cashout: 0,
          },
          minMonthsReserve: {
            purchase: 0,
            nocashout: 0,
            cashout: 0,
          },
        },
      ],
    })

    setData(newData)
    setEdit(true)
  }

  const addSubLimit = (index: number) => {
    const newData = cloneDeep(data)

    newData[index].limits.push({
      FICO: {
        from: newData[index].limits[newData[index].limits.length - 1].FICO.from,
        to: newData[index].limits[newData[index].limits.length - 1].FICO.to,
      },
      loanAmount: {
        purchase: {
          min: NaN,
          max: NaN,
        },
        nocashout: {
          min: NaN,
          max: NaN,
        },
        cashout: {
          min: NaN,
          max: NaN,
        },
      },
      LTV: {
        purchase: { ltv: { min: NaN, max: NaN }, cltv: NaN },
        nocashout: { ltv: { min: NaN, max: NaN }, cltv: NaN },
        cashout: { ltv: { min: NaN, max: NaN }, cltv: NaN },
      },
      minDSCR: {
        purchase: 0,
        nocashout: 0,
        cashout: 0,
      },
      minMonthsReserve: {
        purchase: 0,
        nocashout: 0,
        cashout: 0,
      },
    })

    setData(newData)
    setEdit(true)
  }

  const onChangePropertyType = (index: number, value: string) => {
    const newData = cloneDeep(data)

    if (newData.find((item) => item.value === value)) {
      toast(`Please choose another option.`, {
        type: 'info',
      })
      return
    }

    newData[index].value = value

    setData(newData)
    setEdit(true)
  }

  const onChangeMaxLimits = (index: number, key: 'LTV' | 'CLTV' | 'loanAmount', value: any) => {
    const newData = cloneDeep(data)

    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (newData[index][key] == value) return

    newData[index][key] = value

    setData(newData)
    setEdit(true)
  }

  const onChangeMinFICO = (index: number, value: any) => {
    const newData = cloneDeep(data)

    value = removeComma(value)

    if (newData[index].minFICO == value) return

    newData[index].minFICO = value

    setData(newData)
    setEdit(true)
  }

  const onChangeFICO = (index: number, idx: number, key: string, value: any) => {
    value = removeComma(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if ((newData[index].limits[idx].FICO as any)[key] === value) return
    ;(newData[index].limits[idx].FICO as any)[key] = value

    setData(newData)
    setEdit(true)
  }

  const onChangeLtvLimit = (
    index: number,
    idx: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: 'min' | 'max',
    value: any,
  ) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if (newData[index].limits[idx].LTV[loanPurpose].ltv[key] == value) return

    newData[index].limits[idx].LTV[loanPurpose].ltv[key] = value

    setData(newData)
    setEdit(true)
  }

  const onChangeCLtvLimit = (
    index: number,
    idx: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    value: any,
  ) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if (newData[index].limits[idx].LTV[loanPurpose].cltv == value) return

    newData[index].limits[idx].LTV[loanPurpose].cltv = value

    setData(newData)
    setEdit(true)
  }

  const onChangeMinDscrMonthsReserve = (
    index: number,
    idx: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: string,
    value: any,
  ) => {
    value = removeComma(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if ((newData[index].limits[idx] as any)[key][loanPurpose] == value) return
    ;(newData[index].limits[idx] as any)[key][loanPurpose] = value

    setData(newData)
    setEdit(true)
  }

  const removeLtvLimit = async (index: number, idx: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this row?
        <br />
        <span className="text-gray-600 text-base">Property Type: {data[index].value || 'N/A'}</span>
        <br />
        <span className="text-gray-600 text-base">
          FICO: {data[index].limits[idx].FICO.from} - {data[index].limits[idx].FICO.to}
        </span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const newData = cloneDeep(data)

    newData[index].limits.splice(idx, 1)

    if (!newData[index].limits.length) newData.splice(index, 1)

    setData(newData)
    setEdit(true)
  }

  const submit = () => {
    let hasError = false

    data.forEach((item) => {
      if (isEmpty(item.value)) {
        hasError = true
      }
    })

    if (hasError) {
      toast(`Required fields exist!`, { type: 'error' })
      return
    }

    onSave(data)
  }

  const onChangeLoanAmountOverly = (
    index: number,
    idx: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: string,
    value: any,
  ) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if ((newData[index].limits[idx].loanAmount[loanPurpose] as any)[key] == value) return
    ;(newData[index].limits[idx].loanAmount[loanPurpose] as any)[key] = value

    setData(newData)
    setEdit(true)
  }

  const renderLtvLimits = useMemo(() => {
    if (data.length === 0)
      return (
        <div className="w-full flex justify-center items-center mt-10">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limits</span>
          </div>
        </div>
      )

    return (
      <div className="overflow-x-auto">
        <table className="table min-w-max w-full text-center text-sm border-collapse">
          <thead className="bg-gray-100">
            <tr>
              <th rowSpan={2} className="border p-1 whitespace-nowrap">
                Property Type
              </th>
              <th colSpan={3} className="border p-1 whitespace-nowrap">
                Max Limits
              </th>
              <th rowSpan={2} className="border p-1 whitespace-nowrap w-[80px]">
                Min FICO
              </th>
              <th colSpan={2} className="border p-1">
                FICO
              </th>
              <th rowSpan={2} className="border p-1 whitespace-nowrap">
                Loan Purpose
              </th>
              <th colSpan={2} className="border p-1">
                Loan Amount
              </th>
              <th rowSpan={2} className="border p-1 w-[85px]">
                Min DSCR
              </th>
              <th rowSpan={2} className="border p-1 w-[100px]">
                Min Months Reserve
              </th>
              <th colSpan={2} className="border p-1">
                LTV
              </th>
              <th rowSpan={2} className="border p-1 w-[85px] whitespace-nowrap">
                Max CLTV
              </th>
              {isTemplate && (
                <th rowSpan={2} className="border p-1">
                  Action
                </th>
              )}
            </tr>
            <tr>
              <th className="border p-1 w-[60px]">LTV</th>
              <th className="border p-1 w-[60px]">CLTV</th>
              <th className="border p-1 w-[100px] whitespace-nowrap">Loan Amount</th>
              <th className="border p-1 w-[60px]">From</th>
              <th className="border p-1 w-[60px]">To</th>
              <th className="border p-1 w-[100px]">From</th>
              <th className="border p-1 w-[100px]">To</th>
              <th className="border p-1 w-[60px]">Min</th>
              <th className="border p-1 w-[60px]">Max</th>
            </tr>
          </thead>

          <tbody>
            {data.map((item, index) => {
              return (
                <Fragment key={index}>
                  <tr>
                    <td rowSpan={3 * item.limits.length + 1} className={`border whitespace-nowrap p-1`}>
                      {isTemplate ? (
                        <Select
                          id="propertyType"
                          options={propertyTypeLabels}
                          hasDefaultOption={true}
                          value={item.value}
                          className="w-full mb-[-16px]"
                          onChange={(v) => onChangePropertyType(index, v)}
                        />
                      ) : (
                        item.value
                      )}

                      {isTemplate && (
                        <div className="mt-5 flex justify-center w-full">
                          <span
                            className="cursor-pointer text-shade-blue hover:scale-105 transform duration-100 hover:underline"
                            onClick={() => addSubLimit(index)}
                          >
                            Add Limit
                          </span>
                        </div>
                      )}
                    </td>
                    <td rowSpan={3 * item.limits.length + 1} className={`border whitespace-nowrap p-1`}>
                      <div className="flex justify-center">
                        <PlainInput
                          value={convertNullToBlank(item.LTV)}
                          origin={convertNullToBlank(item.LTV)}
                          content={convertNullToBlank(item.LTV)}
                          onChange={(value: any) => onChangeMaxLimits(index, 'LTV', value)}
                          disabled={!isTemplate}
                          className="w-full"
                        />
                      </div>
                    </td>
                    <td rowSpan={3 * item.limits.length + 1} className={`border whitespace-nowrap p-1`}>
                      <div className="flex justify-center">
                        <PlainInput
                          value={convertNullToBlank(item.CLTV)}
                          origin={convertNullToBlank(item.CLTV)}
                          content={convertNullToBlank(item.CLTV)}
                          onChange={(value: any) => onChangeMaxLimits(index, 'CLTV', value)}
                          disabled={!isTemplate}
                          className="w-full"
                        />
                      </div>
                    </td>
                    <td rowSpan={3 * item.limits.length + 1} className={`border whitespace-nowrap p-1`}>
                      <div className="flex justify-center">
                        <PlainInput
                          value={convertNullToBlank(item.loanAmount)}
                          origin={convertNullToBlank(item.loanAmount)}
                          content={getPrice3decimal(item.loanAmount)}
                          onChange={(value: any) => onChangeMaxLimits(index, 'loanAmount', value)}
                          disabled={!isTemplate}
                          className="w-full"
                        />
                      </div>
                    </td>
                    <td rowSpan={3 * item.limits.length + 1} className={`border whitespace-nowrap p-1`}>
                      <div className="flex justify-center">
                        <PlainInput
                          value={item.minFICO}
                          origin={item.minFICO}
                          content={item.minFICO}
                          onChange={(value: any) => onChangeMinFICO(index, value)}
                          disabled={!isTemplate}
                          className="w-full"
                        />
                      </div>
                    </td>
                  </tr>

                  {item.limits.map((limit, idx) => (
                    <Fragment key={`${index}-${idx}`}>
                      <tr>
                        {['from', 'to'].map((key, index1) => (
                          <td rowSpan={3} key={`${index}-${idx}-${index1}`} className={`border whitespace-nowrap p-1`}>
                            <div className="flex justify-center">
                              <PlainInput
                                value={(limit.FICO as any)[key]}
                                origin={(limit.FICO as any)[key]}
                                content={(limit.FICO as any)[key]}
                                onChange={(value: any) => onChangeFICO(index, idx, key, value)}
                                disabled={!isTemplate}
                                className="w-full"
                              />
                            </div>
                          </td>
                        ))}
                        <td className={`border whitespace-nowrap p-1`}>Purchase</td>

                        {['min', 'max'].map((key, index1) => (
                          <td key={`${index}-${idx}-${index1}`} className={`border whitespace-nowrap p-1`}>
                            <div className="flex justify-center">
                              <PlainInput
                                value={convertNullToBlank((limit.loanAmount.purchase as any)[key])}
                                origin={convertNullToBlank((limit.loanAmount.purchase as any)[key])}
                                content={convertNullToBlankValueToDecimal((limit.loanAmount.purchase as any)[key])}
                                onChange={(value: any) => onChangeLoanAmountOverly(index, idx, 'purchase', key, value)}
                                disabled={!isTemplate}
                                className="w-full"
                              />
                            </div>
                          </td>
                        ))}
                        {['minDSCR', 'minMonthsReserve'].map((key, index1) => (
                          <td key={`${index}-${idx}-${index1}`} className={`border whitespace-nowrap p-1`}>
                            <div className="flex justify-center">
                              <PlainInput
                                value={convertNullToBlank((limit as any)[key].purchase)}
                                origin={convertNullToBlank((limit as any)[key].purchase)}
                                content={convertNullToBlank((limit as any)[key].purchase)}
                                onChange={(value: any) =>
                                  onChangeMinDscrMonthsReserve(index, idx, 'purchase', key, value)
                                }
                                disabled={!isTemplate}
                                className="w-full"
                              />
                            </div>
                          </td>
                        ))}
                        {['min', 'max'].map((key, index1) => (
                          <td key={`${index}-${idx}-${index1}`} className={`border whitespace-nowrap p-1`}>
                            <div className="flex justify-center">
                              <PlainInput
                                value={convertNullToBlank(limit.LTV.purchase.ltv[key as 'min' | 'max'])}
                                origin={convertNullToBlank(limit.LTV.purchase.ltv[key as 'min' | 'max'])}
                                content={convertNullToBlank(limit.LTV.purchase.ltv[key as 'min' | 'max'])}
                                onChange={(value: any) =>
                                  onChangeLtvLimit(index, idx, 'purchase', key as 'min' | 'max', value)
                                }
                                disabled={!isTemplate}
                                className="w-full"
                              />
                            </div>
                          </td>
                        ))}

                        <td className={`border whitespace-nowrap p-1`}>
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank(limit.LTV.purchase.cltv)}
                              origin={convertNullToBlank(limit.LTV.purchase.cltv)}
                              content={convertNullToBlank(limit.LTV.purchase.cltv)}
                              onChange={(value: any) => onChangeCLtvLimit(index, idx, 'purchase', value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>

                        {isTemplate && (
                          <td rowSpan={3} className="border p-1">
                            <div className="flex justify-center">
                              <div
                                className="w-fit p-1 transition-all duration-200 hover:cursor-pointer hover-shadow1 rounded"
                                onClick={() => removeLtvLimit(index, idx)}
                              >
                                <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                              </div>
                            </div>
                          </td>
                        )}
                      </tr>

                      <tr>
                        <td className={`border whitespace-nowrap p-1`}>No-Cashout</td>

                        {['min', 'max'].map((key, index1) => (
                          <td key={`${index}-${idx}-${index1}`} className={`border whitespace-nowrap p-1`}>
                            <div className="flex justify-center">
                              <PlainInput
                                value={convertNullToBlank((limit.loanAmount.nocashout as any)[key])}
                                origin={convertNullToBlank((limit.loanAmount.nocashout as any)[key])}
                                content={convertNullToBlankValueToDecimal((limit.loanAmount.nocashout as any)[key])}
                                onChange={(value: any) => onChangeLoanAmountOverly(index, idx, 'nocashout', key, value)}
                                disabled={!isTemplate}
                                className="w-full"
                              />
                            </div>
                          </td>
                        ))}
                        {['minDSCR', 'minMonthsReserve'].map((key, index1) => (
                          <td key={`${index}-${idx}-${index1}`} className={`border whitespace-nowrap p-1`}>
                            <div className="flex justify-center">
                              <PlainInput
                                value={convertNullToBlank((limit as any)[key].nocashout)}
                                origin={convertNullToBlank((limit as any)[key].nocashout)}
                                content={convertNullToBlank((limit as any)[key].nocashout)}
                                onChange={(value: any) =>
                                  onChangeMinDscrMonthsReserve(index, idx, 'nocashout', key, value)
                                }
                                disabled={!isTemplate}
                                className="w-full"
                              />
                            </div>
                          </td>
                        ))}
                        {['min', 'max'].map((key, index1) => (
                          <td key={`${index}-${idx}-${index1}`} className={`border whitespace-nowrap p-1`}>
                            <div className="flex justify-center">
                              <PlainInput
                                value={convertNullToBlank(limit.LTV.nocashout.ltv[key as 'min' | 'max'])}
                                origin={convertNullToBlank(limit.LTV.nocashout.ltv[key as 'min' | 'max'])}
                                content={convertNullToBlank(limit.LTV.nocashout.ltv[key as 'min' | 'max'])}
                                onChange={(value: any) =>
                                  onChangeLtvLimit(index, idx, 'nocashout', key as 'min' | 'max', value)
                                }
                                disabled={!isTemplate}
                                className="w-full"
                              />
                            </div>
                          </td>
                        ))}

                        <td className={`border whitespace-nowrap p-1`}>
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank(limit.LTV.nocashout.cltv)}
                              origin={convertNullToBlank(limit.LTV.nocashout.cltv)}
                              content={convertNullToBlank(limit.LTV.nocashout.cltv)}
                              onChange={(value: any) => onChangeCLtvLimit(index, idx, 'nocashout', value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className={`border whitespace-nowrap p-1`}>Cashout</td>

                        {['min', 'max'].map((key, index1) => (
                          <td key={`${index}-${idx}-${index1}`} className={`border whitespace-nowrap p-1`}>
                            <div className="flex justify-center">
                              <PlainInput
                                value={convertNullToBlank((limit.loanAmount.cashout as any)[key])}
                                origin={convertNullToBlank((limit.loanAmount.cashout as any)[key])}
                                content={convertNullToBlankValueToDecimal((limit.loanAmount.cashout as any)[key])}
                                onChange={(value: any) => onChangeLoanAmountOverly(index, idx, 'cashout', key, value)}
                                disabled={!isTemplate}
                                className="w-full"
                              />
                            </div>
                          </td>
                        ))}
                        {['minDSCR', 'minMonthsReserve'].map((key, index1) => (
                          <td key={`${index}-${idx}-${index1}`} className={`border whitespace-nowrap p-1`}>
                            <div className="flex justify-center">
                              <PlainInput
                                value={convertNullToBlank((limit as any)[key].cashout)}
                                origin={convertNullToBlank((limit as any)[key].cashout)}
                                content={convertNullToBlank((limit as any)[key].cashout)}
                                onChange={(value: any) =>
                                  onChangeMinDscrMonthsReserve(index, idx, 'cashout', key, value)
                                }
                                disabled={!isTemplate}
                                className="w-full"
                              />
                            </div>
                          </td>
                        ))}
                        {['min', 'max'].map((key, index1) => (
                          <td key={`${index}-${idx}-${index1}`} className={`border whitespace-nowrap p-1`}>
                            <div className="flex justify-center">
                              <PlainInput
                                value={convertNullToBlank(limit.LTV.cashout.ltv[key as 'min' | 'max'])}
                                origin={convertNullToBlank(limit.LTV.cashout.ltv[key as 'min' | 'max'])}
                                content={convertNullToBlank(limit.LTV.cashout.ltv[key as 'min' | 'max'])}
                                onChange={(value: any) =>
                                  onChangeLtvLimit(index, idx, 'cashout', key as 'min' | 'max', value)
                                }
                                disabled={!isTemplate}
                                className="w-full"
                              />
                            </div>
                          </td>
                        ))}

                        <td className={`border whitespace-nowrap p-1`}>
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank(limit.LTV.cashout.cltv)}
                              origin={convertNullToBlank(limit.LTV.cashout.cltv)}
                              content={convertNullToBlank(limit.LTV.cashout.cltv)}
                              onChange={(value: any) => onChangeCLtvLimit(index, idx, 'cashout', value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                </Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }, [data])

  return (
    <div className=" relative">
      <LayoutLoading show={LoadingStatus.LEVERAGE_LIMIT === loading || isLoading} />

      <div className="px-2 mt-2">
        {isTemplate && (
          <div className={`flex items-center mb-2 ${edit ? 'justify-between' : 'justify-end'}`}>
            {edit && (
              <Button loading={LoadingStatus.LEVERAGE_LIMIT === loading} onClick={submit} className="pt-1.5 pb-1.5">
                Save
              </Button>
            )}

            <div
              className="flex items-center gap-2 cursor-pointer w-fit text-shade-blue transition-all duration-200 hover:border-b hover:border-b-shade-blue pr-1 h-6"
              onClick={addLimit}
            >
              <PlusIcon className="w-4 h-4" />
              <span>Add Limit</span>
            </div>
          </div>
        )}

        {renderLtvLimits}
      </div>
    </div>
  )
}
