import { ArchiveBoxXMarkIcon, PencilSquareIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import type { ICityCountyTier, ILtvValues } from 'config'
import { statesConstant } from 'config/states.constants'
import { LoadingStatus } from 'pages/RateSheetOverview'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Checkbox, Tooltip } from 'stories/components'
import { confirm, isEmpty } from 'utils'

import { checkState } from './logic'
import { AddTierModal } from './Modal/AddTierModal'
import { StateDataModal } from './Modal/StateDataModal'
import { TierLtvAdjustment } from './TierLtvAdjustment'

interface IProps {
  isTemplate: boolean
  title: string
  tiers: ICityCountyTier[]
  loading: LoadingStatus
  isCommercial: boolean
  onAdd: (tierName: string, key: string) => Promise<boolean>
  onRemove: (tierName: string, key: string) => Promise<boolean>
  onUpdate: (originalName: string, tierName: string, key: string) => Promise<boolean>
  onSaveTierValues: (tierName: string, key: string, state: string, values: string[]) => Promise<boolean>
  onSaveLtvAdj: (tierName: string, key: string, ltvAdj: ILtvValues) => Promise<boolean>
}

export const CityCountyTiers = (props: IProps) => {
  const { isTemplate, tiers, title, loading, isCommercial, onAdd, onRemove, onUpdate, onSaveTierValues, onSaveLtvAdj } =
    props

  const [modal, setModal] = useState('')
  const [selectedTier, setSelectedTier] = useState<ICityCountyTier>()
  const [selectedState, setSelectedState] = useState('')
  const [key, setKey] = useState('')

  useEffect(() => {
    if (!tiers) {
      setSelectedTier(undefined)
      return
    }

    if (tiers.length > 0) {
      if (selectedTier) {
        const newTierData = tiers.find((item) => item.title === selectedTier.title)

        if (!newTierData) setSelectedTier(tiers[0])
        else setSelectedTier(newTierData)
      } else setSelectedTier(tiers[0])
    } else setSelectedTier(undefined)
  }, [tiers])

  useEffect(() => {
    const keyValue = title.toLowerCase().includes('city') ? 'city' : 'county'

    setKey(keyValue)
  }, [title])

  const onAddTier = async (tierName: string) => {
    if (!tierName.trim()) {
      toast('Tier Name is required', {
        type: 'error',
      })

      return
    }
    if (!isEmpty(tiers)) {
      const isSameName = tiers.find((item) => item.title === tierName.trim())

      if (isSameName) {
        toast('This Tier Name already exists', {
          type: 'error',
        })

        return
      }
    }

    const res = await onAdd(tierName, key)

    if (res) setModal('')
  }

  const onUpdateTier = async (originName: string, tierName: string) => {
    if (originName === tierName.trim()) {
      setModal('')
      return true
    }
    if (!isEmpty(tiers)) {
      const isSameName = tiers.find((item) => item.title === tierName.trim())

      if (isSameName && originName !== tierName) {
        toast('This Tier Name already exists', {
          type: 'error',
        })

        return false
      }
    }

    const res = await onUpdate(originName, tierName, key)

    if (res) setModal('')

    return res
  }

  const onRemoveTier = async () => {
    if (!selectedTier) return

    if (!isEmpty(tiers)) {
      const isSameName = tiers.find((item) => item.title === selectedTier.title)

      if (!isSameName) {
        toast(`This Tier doesn't exist`, {
          type: 'error',
        })

        return
      }
    }

    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this Tier?
        <br />
        <span className="text-gray-600 text-base">Tier Name: {selectedTier.title}</span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const res = await onRemove(selectedTier.title, key)

    if (res) setModal('')
  }

  const onSaveValues = async (values: string[]) => {
    if (!selectedTier) return

    const res = await onSaveTierValues(selectedTier.title, key, selectedState, values)

    if (res) setModal('')
  }

  const renderTierContent = useMemo(() => {
    if (!selectedTier)
      return (
        <div className="w-full flex justify-center items-center">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Tiers</span>
          </div>
        </div>
      )

    return (
      <div>
        <div className="mb-4">
          {isTemplate && (
            <div className="flex justify-end px-2 mb-1">
              <Tooltip message="Edit Tier Name">
                <div
                  className="text-shade-blue p-1 transition-all duration-200 hover-shadow1 cursor-pointer rounded mr-2"
                  onClick={() => setModal('editTier')}
                >
                  <PencilSquareIcon className="w-5 h-5" />
                </div>
              </Tooltip>

              <Tooltip message="Remove Tier">
                <div
                  className="p-1 hover-shadow1 transition-all duration-200 cursor-pointer rounded"
                  onClick={() => onRemoveTier()}
                >
                  <TrashIcon className="w-5 h-5 text-red-600" />
                </div>
              </Tooltip>
            </div>
          )}

          <div className="flex flex-wrap gap-4 p-3 border rounded">
            {selectedTier &&
              statesConstant.map((state, index) => (
                <div key={index} className="flex items-center gap-2 w-[80px]">
                  <Checkbox
                    id={index.toString()}
                    title={state}
                    value={checkState(selectedTier.values, state)}
                    color="gray"
                    className="flex items-center w-[45px]"
                    onClick={() => {
                      setSelectedState(state)
                      setModal('showStateData')
                    }}
                  />

                  {selectedTier.values[state] && selectedTier.values[state].length > 0 && (
                    <div className=" text-sm">({selectedTier.values[state].length})</div>
                  )}
                </div>
              ))}
          </div>
        </div>

        <TierLtvAdjustment
          loading={loading}
          isTemplate={isTemplate}
          ltvAdjustment={selectedTier.ltvAdjustment}
          isCommercial={isCommercial}
          onSave={(ltvAdj) => onSaveLtvAdj(selectedTier.title, key, ltvAdj)}
        />
      </div>
    )
  }, [selectedTier, isTemplate])

  return (
    <div className="relative h-full">
      <LayoutLoading
        show={[LoadingStatus.ADD_TIER, LoadingStatus.REMOVE_TIER, LoadingStatus.TIER_VALUES].includes(loading)}
      />

      {isCommercial && (
        <div className="text-lg font-variation-settings-600 mb-3">
          <span>{title}</span>
          {tiers && tiers.length > 0 && <span className="ml-1 text-base">({tiers.length})</span>}
        </div>
      )}

      <div className="flex items-center flex-wrap mb-1">
        {tiers &&
          tiers.length > 0 &&
          tiers.map((tier, index: number) => (
            <Button
              color={`${selectedTier?.title === tier.title ? 'blue' : 'gray'}`}
              className="text-[14px] pt-[6px] pb-[6px] transition-all duration-200"
              key={index}
              onClick={() => setSelectedTier(tier)}
            >
              {tier.title}
            </Button>
          ))}

        {isTemplate && (
          <Button
            color="gray"
            className="pt-[7px] pb-[7px] transition-all duration-200"
            onClick={() => setModal('addTier')}
          >
            <PlusIcon className="w-5 h-5" />
          </Button>
        )}
      </div>

      {renderTierContent}

      {['addTier', 'editTier'].includes(modal) && (
        <AddTierModal
          name={modal === 'editTier' ? selectedTier?.title || '' : ''}
          isOpen={['addTier', 'editTier'].includes(modal)}
          loading={loading}
          onClose={() => setModal('')}
          onSave={onAddTier}
          onUpdate={onUpdateTier}
        />
      )}

      {modal === 'showStateData' && selectedTier && key && (
        <StateDataModal
          isTemplate={isTemplate}
          state={selectedState}
          title={key}
          tierData={selectedTier}
          allTiers={tiers}
          loading={loading}
          isOpen={modal === 'showStateData'}
          onClose={() => setModal('')}
          onSubmit={onSaveValues}
        />
      )}
    </div>
  )
}
