import { itemCountPerPage } from 'config'

export const checkLastOneItem = (ratesheets: Record<string, any>[], currentLoanId: number) => {
  const isLastItem = ratesheets[ratesheets.length - 1].loanId === currentLoanId

  if (isLastItem) {
    const itemsNumOfLastPage = ratesheets.length % itemCountPerPage

    if (itemsNumOfLastPage === 1) return true
    else return false
  } else return false
}
