import { PlusIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { DscrLeverageAdjustmentTable } from 'components/LeverageDataTable/DscrLeverageAdjustmentTable'
import type { IDscrValueLeverageData } from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from 'stories/components'
import { confirm, isEmpty } from 'utils'

interface IProps {
  title: string
  option: any
  leverageData: IDscrValueLeverageData[]
  isLoading: boolean
  isTemplate: boolean
  loading: string
  readOnlyValue?: boolean
  onSave: (data: IDscrValueLeverageData[]) => void
}

export const DscrLeverageAdjustment = (props: IProps) => {
  const { title, option, loading, isTemplate, leverageData, isLoading, readOnlyValue = false, onSave } = props

  const [data, setData] = useState<IDscrValueLeverageData[]>([])
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    if (!leverageData) return

    setData(leverageData)

    setEdit(false)
  }, [leverageData])

  const addLtvAdjustment = () => {
    const newData = cloneDeep(data)

    if (newData.length === Object.keys(option).length) {
      toast(`The addition of further LTV adjustment is impossible.`, { type: 'warning' })
      return
    }

    newData.unshift({
      value: '',
      limit: {
        purchase: 0,
        nocashout: 0,
        cashout: 0,
      },
    })

    setData(newData)
    setEdit(true)
  }

  const onChange = (index: number, value: string) => {
    const newData = cloneDeep(data)

    if (newData.find((item) => item.value === value)) {
      toast(`Please select different ${title}.`, {
        type: 'warning',
      })
      return
    }

    newData[index].value = value

    setData(newData)
    setEdit(true)
  }

  const onChangeLtvAdjustment = (index: number, loanPurpose: string, value: any) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    const newData = cloneDeep(data)

    if ((newData[index].limit as any)[loanPurpose] == value) return
    ;(newData[index].limit as any)[loanPurpose] = value

    setData(newData)
    setEdit(true)
  }

  const removeLtvAdjustment = async (index: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this row?
        <br />
        <span className="text-gray-600 text-base">
          {title}: {option[data[index].value] || data[index].value || 'N/A'}
        </span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const newData = cloneDeep(data)

    newData.splice(index, 1)

    setData(newData)
    setEdit(true)
  }

  const submit = () => {
    let hasError = false

    data.forEach((item) => {
      if (isEmpty(item.value)) {
        hasError = true
      }
    })

    if (hasError) {
      toast(`Required fields exist!`, { type: 'error' })
      return
    }

    onSave(data)
  }

  const renderAdjustments = useMemo(() => {
    return (
      <DscrLeverageAdjustmentTable
        title={title}
        data={data}
        isTemplate={isTemplate}
        options={option}
        onChange={onChange}
        readOnlyValue={readOnlyValue}
        removeAdjustment={removeLtvAdjustment}
        onChangeLtvAdjustment={onChangeLtvAdjustment}
      />
    )
  }, [data])

  return (
    <div className=" relative">
      <LayoutLoading show={LoadingStatus.LEVERAGE_LIMIT === loading || isLoading} />

      <div className="px-2 mt-2">
        {isTemplate && (
          <div className={`flex items-center mb-2 ${edit && !readOnlyValue ? 'justify-between' : 'justify-end'}`}>
            {edit && (
              <Button loading={LoadingStatus.LEVERAGE_LIMIT === loading} onClick={submit} className="pt-1.5 pb-1.5">
                Save
              </Button>
            )}

            {!readOnlyValue && (
              <div
                className="flex items-center gap-2 cursor-pointer w-fit text-shade-blue transition-all duration-200 hover:border-b hover:border-b-shade-blue pr-1 h-6"
                onClick={addLtvAdjustment}
              >
                <PlusIcon className="w-4 h-4" />
                <span>Add Adjustment</span>
              </div>
            )}
          </div>
        )}

        {renderAdjustments}
      </div>
    </div>
  )
}
