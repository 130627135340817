import { ArchiveBoxXMarkIcon, TrashIcon } from '@heroicons/react/24/outline'
import { PlainInput } from 'components/PlainInput'
import type { ICommercialOnlyProgrammed, IFirstTimeInvestorValueLeverageLimit } from 'config'
import {
  convertNullToBlank,
  convertNullToBlankValueToDecimal,
} from 'pages/RateSheetOverview/ExcelSheets/CommercialOnlyProgrammed/logic'
import { Fragment } from 'react'
import { Select } from 'stories/components'
import { isEmpty } from 'utils'

interface IProps {
  programData: ICommercialOnlyProgrammed | undefined
  data: IFirstTimeInvestorValueLeverageLimit[]
  title: string
  isTemplate: boolean
  readOnlyValue?: boolean
  options?: Record<string, string>
  onChangeValue?: (index: number, value: string) => void
  onChangeLoanAmountOverly: (
    index: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: string,
    value: any,
  ) => void
  onChangeLtvLimit: (index: number, loanPurpose: 'purchase' | 'nocashout' | 'cashout', key: string, value: any) => void
  onChangeMinFicoMonthsReserve?: (
    index: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: 'minFICO' | 'minMonthsReserve',
    value: any,
  ) => void
  removeLtvLimit?: (index: number) => void
}

export default function FirstTimeInvestorLeverageLimitTable(props: IProps) {
  const {
    programData,
    data,
    title,
    isTemplate,
    readOnlyValue = false,
    options = {},
    onChangeValue = () => {},
    onChangeLoanAmountOverly,
    onChangeLtvLimit,
    onChangeMinFicoMonthsReserve = () => {},
    removeLtvLimit = () => {},
  } = props

  if (!programData || data.length === 0)
    return (
      <div className="w-full flex justify-center items-center mt-10">
        <div className="flex flex-col items-center gap-1">
          <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
          <span className="text-gray-400">No Limits</span>
        </div>
      </div>
    )

  return (
    <table className="table w-full text-center text-sm">
      <thead className="bg-gray-100">
        <tr>
          <th rowSpan={2} className="border p-1 whitespace-nowrap">
            {title}
          </th>
          <th rowSpan={2} className="border p-1 whitespace-nowrap">
            Loan Purpose
          </th>
          <th colSpan={2} className="border p-1 whitespace-nowrap">
            Loan Amount
          </th>
          <th rowSpan={2} className="border p-1 w-[100px]">
            Min Months Reserve
          </th>
          <th colSpan={4} className="border p-1 whitespace-nowrap">
            Max LTVs
          </th>
          {isTemplate && !readOnlyValue && (
            <th rowSpan={2} className="border p-1">
              Action
            </th>
          )}
        </tr>
        <tr>
          <th className="border p-1 w-[120px]">From</th>
          <th className="border p-1 w-[120px]">To</th>
          <th className="border p-1 w-[80px]">AIV-LTV</th>
          <th className="border p-1 w-[80px]">ARV-LTV</th>
          <th className="border p-1 w-[80px]">LTC</th>
          <th className="border p-1 w-[80px]">LTP</th>
        </tr>
      </thead>

      <tbody>
        {data.map((item, index) => {
          return (
            <Fragment key={index}>
              <tr>
                <td rowSpan={3} className="border p-1">
                  {isTemplate && !readOnlyValue ? (
                    <Select
                      id="value"
                      options={options}
                      hasDefaultOption={true}
                      value={item.value}
                      className="mb-[-16px]"
                      onChange={(v) => onChangeValue(index, v)}
                    />
                  ) : (
                    options[item.value]
                  )}
                </td>

                <td className="border p-1">Purchase</td>

                {['min', 'max'].map((key, index1) => (
                  <td
                    key={`${index}-${index1}`}
                    className={`border whitespace-nowrap p-1 ${isTemplate ? 'bg-gray-50' : ''}`}
                  >
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank((item.loanAmount.purchase as any)[key])}
                        origin={convertNullToBlank((item.loanAmount.purchase as any)[key])}
                        content={convertNullToBlankValueToDecimal((item.loanAmount.purchase as any)[key])}
                        onChange={(value: any) => onChangeLoanAmountOverly(index, 'purchase', key, value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                ))}
                <td className={`p-1 border ${!isTemplate ? '' : 'bg-gray-50'}`}>
                  <div className="flex justify-center">
                    <PlainInput
                      value={convertNullToBlank(item.minMonthsReserve.purchase)}
                      origin={convertNullToBlank(item.minMonthsReserve.purchase)}
                      content={convertNullToBlank(item.minMonthsReserve.purchase)}
                      disabled={!isTemplate}
                      onChange={(value: any) =>
                        onChangeMinFicoMonthsReserve(index, 'purchase', 'minMonthsReserve', value)
                      }
                      className="w-full"
                    />
                  </div>
                </td>
                {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, idx) => (
                  <td
                    key={`${index}-${idx}`}
                    className={`p-1 border ${
                      isEmpty((programData.ltvLimits.purchase as any)[key]) || !isTemplate ? '' : 'bg-gray-50'
                    }`}
                  >
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank((item.limit.purchase as any)[key])}
                        origin={convertNullToBlank((item.limit.purchase as any)[key])}
                        content={convertNullToBlank((item.limit.purchase as any)[key])}
                        disabled={isEmpty((programData.ltvLimits.purchase as any)[key]) || !isTemplate}
                        onChange={(value: any) => onChangeLtvLimit(index, 'purchase', key, value)}
                        className="w-full"
                      />
                    </div>
                  </td>
                ))}

                {isTemplate && !readOnlyValue && (
                  <td rowSpan={3} className="border p-1">
                    <div className="flex justify-center">
                      <div
                        className="w-fit p-1 transition-all duration-200 hover:cursor-pointer hover-shadow1 rounded"
                        onClick={() => removeLtvLimit(index)}
                      >
                        <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                      </div>
                    </div>
                  </td>
                )}
              </tr>

              <tr>
                <td className="border p-1">No-Cashout</td>

                {['min', 'max'].map((key, index1) => (
                  <td
                    key={`${index}-${index1}`}
                    className={`border whitespace-nowrap p-1 ${isTemplate ? 'bg-gray-50' : ''}`}
                  >
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank((item.loanAmount.nocashout as any)[key])}
                        origin={convertNullToBlank((item.loanAmount.nocashout as any)[key])}
                        content={convertNullToBlankValueToDecimal((item.loanAmount.nocashout as any)[key])}
                        onChange={(value: any) => onChangeLoanAmountOverly(index, 'nocashout', key, value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                ))}
                <td className={`p-1 border ${!isTemplate ? '' : 'bg-gray-50'}`}>
                  <div className="flex justify-center">
                    <PlainInput
                      value={convertNullToBlank(item.minMonthsReserve.nocashout)}
                      origin={convertNullToBlank(item.minMonthsReserve.nocashout)}
                      content={convertNullToBlank(item.minMonthsReserve.nocashout)}
                      disabled={!isTemplate}
                      onChange={(value: any) =>
                        onChangeMinFicoMonthsReserve(index, 'nocashout', 'minMonthsReserve', value)
                      }
                      className="w-full"
                    />
                  </div>
                </td>
                {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, idx) => (
                  <td
                    key={`${index}-${idx}`}
                    className={`p-1 border ${
                      isEmpty((programData.ltvLimits.nocashout as any)[key]) || !isTemplate ? '' : 'bg-gray-50'
                    }`}
                  >
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank((item.limit.nocashout as any)[key])}
                        origin={convertNullToBlank((item.limit.nocashout as any)[key])}
                        content={convertNullToBlank((item.limit.nocashout as any)[key])}
                        disabled={isEmpty((programData.ltvLimits.nocashout as any)[key]) || !isTemplate}
                        onChange={(value: any) => onChangeLtvLimit(index, 'nocashout', key, value)}
                        className="w-full"
                      />
                    </div>
                  </td>
                ))}
              </tr>

              <tr>
                <td className="border p-1">Cashout</td>

                {['min', 'max'].map((key, index1) => (
                  <td
                    key={`${index}-${index1}`}
                    className={`border whitespace-nowrap p-1 ${isTemplate ? 'bg-gray-50' : ''}`}
                  >
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank((item.loanAmount.cashout as any)[key])}
                        origin={convertNullToBlank((item.loanAmount.cashout as any)[key])}
                        content={convertNullToBlankValueToDecimal((item.loanAmount.cashout as any)[key])}
                        onChange={(value: any) => onChangeLoanAmountOverly(index, 'cashout', key, value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                ))}
                <td className={`p-1 border ${!isTemplate ? '' : 'bg-gray-50'}`}>
                  <div className="flex justify-center">
                    <PlainInput
                      value={convertNullToBlank(item.minMonthsReserve.cashout)}
                      origin={convertNullToBlank(item.minMonthsReserve.cashout)}
                      content={convertNullToBlank(item.minMonthsReserve.cashout)}
                      disabled={!isTemplate}
                      onChange={(value: any) =>
                        onChangeMinFicoMonthsReserve(index, 'cashout', 'minMonthsReserve', value)
                      }
                      className="w-full"
                    />
                  </div>
                </td>
                {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, idx) => (
                  <td
                    key={`${index}-${idx}`}
                    className={`p-1 border ${
                      isEmpty((programData.ltvLimits.cashout as any)[key]) || !isTemplate ? '' : 'bg-gray-50'
                    }`}
                  >
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank((item.limit.cashout as any)[key])}
                        origin={convertNullToBlank((item.limit.cashout as any)[key])}
                        content={convertNullToBlank((item.limit.cashout as any)[key])}
                        disabled={isEmpty((programData.ltvLimits.cashout as any)[key]) || !isTemplate}
                        onChange={(value: any) => onChangeLtvLimit(index, 'cashout', key, value)}
                        className="w-full"
                      />
                    </div>
                  </td>
                ))}
              </tr>
            </Fragment>
          )
        })}
      </tbody>
    </table>
  )
}
