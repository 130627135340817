import type { ILTVs } from 'config'

import { PLOnlyConditions } from './components/Conditions/PLOnlyConditions'
import { RestrictionTable } from './components/RestrictionTable'

interface Props {
  title: string
  onSave: (restriction: Record<string, ILTVs[] | undefined>) => {}
  onCancel: () => {}
}

export function PLOnly({ title, onSave, onCancel }: Props) {
  return (
    <div>
      <p className="text-lg font-variation-settings-600 mb-3">{title}</p>
      <RestrictionTable onSave={onSave} onCancel={onCancel} restrictionKey="PLOnly" />
      <PLOnlyConditions />
    </div>
  )
}
