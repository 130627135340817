import { MinusCircleIcon, PlusCircleIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import type { IHistory, IRateSheet } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from 'stories/components'

import { LoadingStatus } from '..'
import { AddRatesheetHistoryModal } from './AddRatesheetHistoryModal'

interface Props {
  history: IHistory[]
  ratesheet: IRateSheet
  loading: string
  onAdd: (program: string, content: string) => Promise<boolean>
  onClear: () => void
}

export function RatesheetHistory({ history, ratesheet, loading, onAdd, onClear }: Props) {
  const [expandedHistory, setExpandedHistory] = useState<number[]>([])
  const [onAddModal, setAddModal] = useState(false)
  const [programs, setPrograms] = useState<string[]>([])

  useEffect(() => {
    const nPrograms: string[] = ratesheet.programs.map((item) => item.Name)

    setPrograms(nPrograms)
  }, [ratesheet])

  const onSubmit = async (program: string, content: string) => {
    if (!program) {
      toast('Program Name is required!', { type: 'error' })
      return
    }
    if (!content) {
      toast('History content is required!', { type: 'error' })
      return
    }

    const res = await onAdd(program, content)

    if (res) setAddModal(false)
  }

  const onExpand = (index: number) => {
    const nExpandHistory = cloneDeep(expandedHistory)
    const id = nExpandHistory.indexOf(index)

    if (id === -1) nExpandHistory.push(index)
    else nExpandHistory.splice(id, 1)

    setExpandedHistory(nExpandHistory)
  }

  const renderHistoryData = (data: string, index: number) => {
    if (data.length <= 300) return data

    if (expandedHistory.indexOf(index) !== -1) {
      return (
        <span className="flex items-end">
          {data}
          <i onClick={() => onExpand(index)}>
            <MinusCircleIcon className="w-5 h-5 hover:cursor-pointer" />
          </i>
        </span>
      )
    } else {
      return (
        <span className="flex items-end">
          {data.substring(0, 300)}...
          <i onClick={() => onExpand(index)}>
            <PlusCircleIcon className="w-5 h-5 hover:cursor-pointer" />
          </i>
        </span>
      )
    }
  }

  return (
    <div className="shadow1 py-4 px-5 rounded">
      <div className="flex justify-between items-center border-b mb-3">
        <span className="text-lg font-variation-settings-600">Rate Sheet History</span>

        <div>
          <Button className="text-sm" loading={loading === LoadingStatus.CLEAR_RATESHEET_HISTORY} onClick={onClear}>
            <span className="flex items-center">
              <TrashIcon className="w-4 h-4 mr-2" />
              Clear
            </span>
          </Button>
          <Button
            className="text-sm"
            loading={loading === LoadingStatus.CLEAR_RATESHEET_HISTORY}
            onClick={() => setAddModal(true)}
          >
            <span className="flex items-center">
              <PlusIcon className="w-4 h-4 mr-2" />
              Add
            </span>
          </Button>
        </div>
      </div>

      <div className="h-120 overflow-y-auto">
        <table className="table w-full">
          <thead className="bg-gray-100">
            <tr>
              <th className="border px-3 py-2">No</th>
              <th className="border px-3 py-2">Program Name</th>
              <th className="border px-3 py-2">Date</th>
              <th className="border px-3 py-2">Updated By</th>
              <th className="border px-3 py-2">Details</th>
            </tr>
          </thead>

          <tbody className="text-sm font-medium">
            {history.map((item, index: number) => (
              <tr key={index}>
                <td className="border p-2 text-center">{index + 1}</td>
                <td className="border p-2 whitespace-nowrap">{item.program}</td>
                <td className="border p-2 whitespace-nowrap">{item.time}</td>
                <td className="border p-2">{item.by}</td>
                <td className="border p-2">{renderHistoryData(item.data, index)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {onAddModal && (
        <AddRatesheetHistoryModal
          isOpen={onAddModal}
          programs={programs}
          loading={loading}
          onClose={() => setAddModal(false)}
          onSubmit={onSubmit}
        />
      )}
    </div>
  )
}
