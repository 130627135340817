import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { svgLoading } from 'stories/assets'
import { AccountType } from 'stories/layouts'
import { useTitle } from 'utils/pageTitle'

import { LoanPrograms } from './LoanPrograms'
import { LoanPurposeDefine } from './LoanPurposeDefine'
import { RehabBudgetType } from './RehabBudgetType'
import { StateCities } from './StateCities/StateCities'
import { StateCounties } from './StateCounties/StateCounties'
import { StateMsa } from './StateMsa/StateMsa'

interface menuType {
  label: String
  visible: Boolean | undefined
}

const menusLabels = {
  cities: 'State Cities',
  counties: 'State Counties',
  msa: 'State MSAs',
  rehabBudgetType: 'Rehab Budget Types',
  loanPurposeDefine: 'Loan Purpose',
  loanPrograms: 'Loan Programs',
}

export const AdminTools = () => {
  useTitle('Admin Tools - RTLTrader')

  const auth = useSelector((state: any) => state.auth)
  const isInvestor = [AccountType.INVESTOR, AccountType.ASSOCIATE].includes(auth.profile.userType)

  const [menus, setMenus] = useState<menuType[]>([])
  const [selectedMenu, setSelectedMenu] = useState<menuType>()

  useEffect(() => {
    let draftMenus: menuType[] = [
      {
        label: menusLabels.cities,
        visible: !isInvestor,
      },
      {
        label: menusLabels.counties,
        visible: !isInvestor,
      },
      {
        label: menusLabels.msa,
        visible: !isInvestor,
      },
      {
        label: menusLabels.rehabBudgetType,
        visible: true,
      },
      {
        label: menusLabels.loanPurposeDefine,
        visible: true,
      },
      {
        label: menusLabels.loanPrograms,
        visible: true,
      },
    ]
    setMenus(draftMenus)
    setSelectedMenu(draftMenus.filter((item) => item.visible)[0])
  }, [auth])

  const selectedPage = useMemo(() => {
    switch (selectedMenu?.label) {
      case menusLabels.cities:
        return <StateCities title={menusLabels.cities} />
      case menusLabels.counties:
        return <StateCounties title={menusLabels.counties} />
      case menusLabels.msa:
        return <StateMsa title={menusLabels.msa} />
      case menusLabels.rehabBudgetType:
        return <RehabBudgetType title={menusLabels.rehabBudgetType} />
      case menusLabels.loanPurposeDefine:
        return <LoanPurposeDefine title={menusLabels.loanPurposeDefine} />
      case menusLabels.loanPrograms:
        return <LoanPrograms title={menusLabels.loanPrograms} />
      default: {
        return (
          <span className="flex justify-center w-full my-4">
            <img src={svgLoading} className="inline w-10 h-10 text-white animate-spin" />
          </span>
        )
      }
    }
  }, [selectedMenu])

  return (
    <div className="py-6 px-2">
      <div className="max-w-screen-2xl m-auto grid grid-cols-12 gap-6 px-2">
        <div className="col-span-12 md:col-span-3 shrink-0 p-4 bg-white shadow1 rounded">
          <ul className="flex flex-col">
            {menus
              .filter((item) => item.visible)
              .map((item, index) => {
                return (
                  <li key={index} onClick={() => setSelectedMenu(item)} className="border-b py-2">
                    <p
                      className={`hover:underline cursor-pointer ${
                        selectedMenu?.label === item.label ? 'border px-4 py-1 bg-zinc-100' : 'py-1'
                      }`}
                    >
                      {index + 1 + '. ' + item.label}
                    </p>
                  </li>
                )
              })}
          </ul>
        </div>
        <div className="col-span-12 md:col-span-9 shrink-0 p-4 bg-white shadow1 rounded">{selectedPage}</div>
      </div>
    </div>
  )
}
