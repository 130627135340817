// import { useState } from 'react'
import { Modal } from 'stories/components'

import { LoadingStatus } from '..'

export const EditProgramInfoModal = (props: any) => {
  return (
    <div>
      <Modal
        title="Edit Program"
        titleOkay="Confirm"
        isOpen={props.isOpen}
        loading={props.loading === LoadingStatus.ADD_PROGRAM}
        onClose={props.onClose}
        onOk={() => props.onSubmit()}
      >
        <div className="w-[550px]">EditProgramInfoModal</div>
      </Modal>
    </div>
  )
}
