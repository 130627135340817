import type { ICommercialLtvData, ILtvValues } from 'config'
import { Fragment, useEffect, useState } from 'react'
import { isEmpty } from 'utils'

import { LtvItem } from './LtvItem'

interface Props {
  values: ILtvValues | undefined
  limits: ILtvValues | undefined
}

export const LtvValuesAndLimits = (props: Props) => {
  const { values, limits } = props

  if (!values || !limits) return <></>
  const { aivLtv, arvLtv, ltc, ltp } = values

  const [ltvNumbes, setLtvNumbers] = useState<number | undefined>(undefined)

  useEffect(() => {
    let count = 0

    Object.values(values).forEach((value) => {
      if (!isNaN(value) && value !== Number.POSITIVE_INFINITY && !isEmpty(value) && value !== 0) count++
    })

    setLtvNumbers(count)
  }, [values])

  const priceData: ICommercialLtvData[] = [
    {
      title: 'AIV-LTV',
      maxValue: limits.aivLtv,
      value: aivLtv,
      message: '(Loan Amount - Rehab Amount Financed) / Min(Appraised Value, Property Purchase Price)',
    },
    {
      title: 'ARV-LTV',
      maxValue: limits.arvLtv,
      value: arvLtv,
      message: 'Loan Amount / After Repair Value',
    },
    {
      title: 'LTC',
      maxValue: limits.ltc,
      value: ltc,
      message: 'Loan Amount / (Min(Appraised Value, Property Purchase Price) + Rehab Budget)',
    },
    {
      title: 'LTP',
      maxValue: limits.ltp,
      value: ltp,
      message: '(Loan Amount - Rehab Amount Financed) / Property Purchase Price',
    },
  ]

  return (
    <div>
      {ltvNumbes ? (
        <div className={`grid grid-cols-${ltvNumbes} gap-1 rounded shadow1 w-full p-3 my-3`}>
          {priceData.map((item, index) => {
            const isEmptyValue =
              isNaN(item.value) || item.value === Number.POSITIVE_INFINITY || isEmpty(item.value) || item.value === 0

            if (isEmptyValue) return <Fragment key={index}></Fragment>

            return (
              <LtvItem
                key={index}
                title={item.title}
                maxValue={item.maxValue}
                value={item.value}
                message={item.message}
              />
            )
          })}
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
