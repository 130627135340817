import { useState } from 'react'
import { Input2, Modal } from 'stories/components'

interface IProps {
  value: string
  title: string
  isOpen: boolean
  onClose: () => void
  onUpdate: (originName: string, newName: string) => void
}

export const UpdateItemModal = (props: IProps) => {
  const { value, title, isOpen, onClose, onUpdate } = props

  const [newName, setNewName] = useState(value)

  return (
    <div>
      <Modal
        title={`Update ${title} Name`}
        titleOkay="Update"
        isOpen={isOpen}
        onClose={onClose}
        onOk={() => onUpdate(value, newName)}
      >
        <div className="w-[300px]">
          <Input2 value={newName} title={`${title} Name`} type="text" onChange={(v) => setNewName(v)} />
        </div>
      </Modal>
    </div>
  )
}
