import { useState } from 'react'
import { Input2, Modal } from 'stories/components'

export const NewCompanyModal = (props: any) => {
  const [name, setName] = useState('')

  return (
    <div>
      <Modal
        title="Add New Company"
        titleOkay="Confirm"
        isOpen={props.isOpen}
        loading={props.loading === 'addCompany'}
        onClose={props.onClose}
        onOk={() => props.onSubmit(name)}
      >
        <div className="w-80">
          <Input2 title="Company Name" onChange={(value) => setName(value)} value={name} />
        </div>
      </Modal>
    </div>
  )
}
