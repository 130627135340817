import 'sheet-happens/dist/index.css'

import type { IProgram } from 'config'
import { useEffect, useState } from 'react'
import excelService from 'services/excelService'
import Sheet, { Style } from 'sheet-happens'
import { Button } from 'stories/components'
import { getPrice3decimal, isEmpty } from 'utils'

import { LoadingStatus } from '..'
import { hardMoneyInformations } from '../constants'

interface Props {
  program: IProgram
  loading: string
  onSave: (data: Record<string, number>) => {}
}

interface IData {
  sheet: any[]
}

export function HardMoneyExcelSheet({ program, loading, onSave }: Props) {
  const [edit, setEdit] = useState<Record<string, number>>({})
  const [data, setData] = useState<any[][]>([[], []])
  const [editable, setEditable] = useState<string[]>([])
  const [cellWidth, setCellWidth] = useState<number[]>([])
  const [cellHeight, setCellHeight] = useState<number[]>([])
  const [editObject, setEditObject] = useState<Record<string, any>>({})

  const columnHeaders = ['A', 'B', 'C']

  useEffect(() => {
    ;(async function initializeData() {
      let { sheet } = (await excelService.parseHardMoneyProgram(program.OtherTypeProgramData)) as IData

      setData(sheet)
      setEdit({})

      for (let i = 0; i < 100; i += 1) cellWidth.push(100)
      setCellWidth(cellWidth)

      let _editable = [
        '3-0',
        '3-1',
        '3-2',
        '3-3',
        '0-11',
        '0-12',
        '0-13',
        '13-0',
        '13-1',
        '13-2',
        '13-3',
        '10-11',
        '10-12',
        '10-13',
      ]
      const _rows = [3, 4, 5, 6, 7, 8, 13, 14, 15, 16, 17, 18]
      const _columns = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13]

      _rows.map((y) => {
        _columns.map((x) => {
          _editable.push(`${y}-${x}`)
        })
      })

      setEditable(_editable)
      setEditObject(program.OtherTypeProgramData)
    })()
  }, [program])

  const onSelectionChanged = (x1: number, y1: number, x2: number, y2: number) => {
    console.log('track change', x1, y1, x2, y2)
    return {}
  }

  const cellStyle = (x: number, y: number) => {
    let rlt = {
      textAlign: 'left',
      fillColor: '',
      weight: '',
    }

    if ([0, 1, 10, 11].indexOf(y) !== -1) {
      if ([0, 1, 2, 3, 4].indexOf(x) !== -1) {
        rlt.fillColor = '#6DA2FB22'
        rlt.weight = 'bold'
      }
    }
    if (editable.indexOf(`${y}-${x}`) !== -1) {
      rlt.fillColor = '#f7f9fa'
    }
    return rlt
  }

  const editData = (x: number, y: number) => {
    return data?.[y]?.[x]
  }

  const displayData = (x: number, y: number) => {
    let rlt = data?.[y]?.[x]
    if ([0, 10].indexOf(y) !== -1) {
      if ([11, 12, 13].indexOf(x) !== -1) rlt = !isEmpty(rlt) ? 'Price at ' + rlt : 'Price at N/A'
    }
    if (['3-0', '3-1', '13-0', '13-1'].indexOf(`${y}-${x}`) !== -1) {
      rlt = '$' + getPrice3decimal(rlt)
    }
    if (x >= 5 && x <= 13) {
      if ((y >= 3 && y <= 8) || (y >= 13 && y <= 18)) {
        if (rlt === '') rlt = 'N/A'
        else if (rlt !== 'N/A') rlt += '%'
      }
    }
    return rlt
  }

  const sourceData = (x: number, y: number) => {
    return data?.[y]?.[x]
  }

  const onChange = async (changes: any[]) => {
    let newData = [...data]
    let newEdit: Record<string, number> = { ...edit }

    for (const change of changes) {
      let { x, y, value } = change

      if (newData[y][x] === value) continue

      if (value === '') value = ''
      else value = isNaN(Number(value)) ? '' : Number(Number(value).toFixed(3))

      if (editable.indexOf(`${y}-${x}`) !== -1) {
        let yy = y
        let key: string = data[0][0]
        if (y >= 9) {
          yy = y - 10
          key = data[10][0]
        }

        if (yy === 0) {
          editObject[key].TierPricing[x - 10] = value
        } else if (x <= 3) {
          if (x === 0) editObject[key].loanAmount.from = value
          if (x === 1) editObject[key].loanAmount.to = value
          if (x === 2) editObject[key].monthsReserve = value
          if (x === 3) editObject[key].minDscr = value
        } else {
          const index = yy - 3
          if (x === 4) editObject[key].ficos[index] = value
          if (x === 5) editObject[key].purchaseLTVs[index] = value
          if (x === 6) editObject[key].purchaseLTCs[index] = value
          if (x === 7) editObject[key].cashOutLTVs.Tier1[index] = value
          if (x === 8) editObject[key].cashOutLTCs.Tier1[index] = value
          if (x === 9) editObject[key].cashOutLTVs.Tier2[index] = value
          if (x === 10) editObject[key].cashOutLTCs.Tier2[index] = value
          if (x === 11) editObject[key].TierRates.Tier1[index] = value
          if (x === 12) editObject[key].TierRates.Tier2[index] = value
          if (x === 13) editObject[key].TierRates.Tier3[index] = value
        }
        newData[y][x] = value
        newEdit[`${y}-${x}`] = value
      }
    }

    setEditObject(editObject)
    setEdit(newEdit)
    setData(newData)
  }

  const isReadOnly = (x: number, y: number) => {
    if (editable.indexOf(`${y}-${x}`) === -1) {
      return true
    }
    return false
  }

  const onCellWidthChange = (columnIdx: any, newWidth: number) => {
    const cw: number[] = [...cellWidth]
    if (columnIdx > cw.length) {
      for (let i = cw.length; i <= columnIdx; i++) {
        cw.push(100)
      }
    }
    cw[columnIdx] = newWidth
    setCellWidth(cw)
  }
  const onCellHeightChange = (rowIdx: any, newHeight: number) => {
    const ch: number[] = [...cellHeight]
    if (rowIdx > ch.length) {
      for (let i = ch.length; i <= rowIdx; i++) {
        ch.push(22)
      }
    }
    ch[rowIdx] = newHeight
    setCellHeight(ch)
  }

  return (
    <div className="py-6">
      <div className="relative max-w-screen-2xl m-auto w-full">
        <div className="flex flex-col justify-center items-center mb-5">
          <div className="w-full lg:w-2/3">
            <p className="font-variation-settings-600 mb-2">Commercial Bridge and Rehab General Information</p>

            <table className="w-full">
              <tbody>
                {Object.keys(hardMoneyInformations).map((key: string, index: number) => {
                  const logic = hardMoneyInformations[key]
                  const rlt = []
                  rlt.push(
                    <tr key={index}>
                      <th className="border bg-gray-100 py-2">{key}</th>
                    </tr>,
                  )

                  rlt.push(
                    <tr key={`${key}-${index}`}>
                      <td className="border py-2 px-3 text-sm">
                        <ul className="list-disc pl-5">
                          {logic.map((item: string, id: number) => (
                            <li key={`${index}-${id}`} className="my-2">
                              {item}
                            </li>
                          ))}
                        </ul>
                      </td>
                    </tr>,
                  )

                  return rlt
                })}
              </tbody>
            </table>
          </div>
        </div>

        {program && Object.keys(edit).length > 0 && (
          <div className="flex justify-end">
            <Button
              loading={loading === LoadingStatus.SAVE_PROGRAM_CHANGES}
              onClick={async () => {
                await onSave(editObject)
                setEdit({})
              }}
            >
              Save
            </Button>
          </div>
        )}
        <Sheet
          onSelectionChanged={onSelectionChanged}
          onRightClick={() => {}}
          columnHeaders={columnHeaders}
          cellStyle={cellStyle as Style}
          editData={editData}
          displayData={displayData}
          sourceData={sourceData}
          cellWidth={cellWidth}
          cellHeight={cellHeight}
          onChange={onChange}
          readOnly={isReadOnly}
          onCellWidthChange={onCellWidthChange}
          onCellHeightChange={onCellHeightChange}
        />
      </div>
    </div>
  )
}
