import { LoadingStatus } from 'pages/RateSheetOverview'
import { useState } from 'react'
import { Input2, Modal } from 'stories/components'

interface IProps {
  name: string
  isOpen: boolean
  loading: string
  onClose: () => void
  onSave: (tierName: string) => void
  onUpdate: (originName: string, tierName: string) => Promise<boolean>
}

export const AddTierModal = (props: IProps) => {
  const { name, isOpen, loading, onClose, onSave, onUpdate } = props

  const [tierName, setTierName] = useState<string>(name || '')

  const onOk = async () => {
    let res

    if (name) res = await onUpdate(name, tierName)
    else res = await onSave(tierName)

    if (res) setTierName('')
  }

  return (
    <div>
      <Modal
        title={`${name ? 'Edit Tier Name' : 'Add New Tier'}`}
        titleOkay={`${name ? 'Save' : 'Add'}`}
        isOpen={isOpen}
        loading={[LoadingStatus.ADD_TIER, 'editTier'].includes(loading)}
        onClose={onClose}
        onOk={onOk}
      >
        <div className="w-[300px]">
          <Input2 title="Tier Name" value={tierName} onChange={(value) => setTierName(value)} />
        </div>
      </Modal>
    </div>
  )
}
