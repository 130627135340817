import { ArrowLongRightIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import type { ICompany, IProgram } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { getTemplates } from 'services/apis/ratesheet'
import { Modal, Select2 } from 'stories/components'

import { LoadingStatus } from '..'

interface ITemplateData {
  company: ICompany
  programs: {
    ID: number
    Name: string
  }[]
}

interface IProps {
  program: IProgram
  isOpen: boolean
  loading: string
  onClose: () => void
  onSubmit: (data: Record<string, any>) => void
}

export const SyncTiersModal = (props: IProps) => {
  const { program, isOpen, loading, onClose, onSubmit } = props

  const [companies, setCompanies] = useState<ICompany[]>([])
  const [templates, setTemplates] = useState<ITemplateData[]>([])
  const [company, setCompany] = useState('')
  const [template, setTemplate] = useState('')
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      setLoading(true)

      const res = await getTemplates()

      setCompanies(res.companies)
      setTemplates(res.templates)
      setLoading(false)
    })()
  }, [])

  const programs = useMemo(() => {
    if (!company) return []

    const ratesheet = templates.find((item) => item.company.name === company)

    if (!ratesheet) return []

    return ratesheet.programs.map((item) => item.Name)
  }, [templates, company])

  const onOk = async () => {
    const selectedCompany = companies.find((item) => item.name === company)
    const selectedTemplate = templates.find((item) => item.company.name === company)

    if (!selectedCompany) {
      toast('Select Company', {
        type: 'error',
      })
      return
    }

    const targetProgram = selectedTemplate?.programs.find((item) => item.Name === template)

    const data = {
      generalProgamId: program.ID,
      companyID: selectedCompany.id,
      programID: targetProgram?.ID,
    }

    await onSubmit(data)
  }

  return (
    <div>
      <Modal
        title="Sync Tiers with General"
        titleOkay="Sync"
        isOpen={isOpen}
        loading={loading === LoadingStatus.SYNC_TIERS}
        onClose={onClose}
        disabled={template !== program.Name ? true : false}
        onOk={onOk}
      >
        <div className="relative min-w-[400px]">
          <LayoutLoading show={isLoading} />

          <div className="mb-3 font-variation-settings-600">
            Sync <span className="text-shade-blue">{program.Name}</span> to
          </div>

          <div className="flex mb-3 font-variation-settings-600">
            {company && (
              <span className="flex items-center">
                {company}
                <ArrowLongRightIcon className="w-5 mx-2" />
              </span>
            )}
            {template && <span className="text-shade-blue">{template}</span>}
          </div>

          <Select2
            title="Company"
            id="company"
            onChange={(value) => setCompany(value)}
            value={company}
            hasDefaultOption={true}
            options={companies.map((company) => company.name)}
            className="mb-4"
          />
          <Select2
            title="Program"
            id="program"
            onChange={(value) => setTemplate(value)}
            value={template}
            options={programs}
            hasDefaultOption={true}
            error={`${template && program.Name !== template ? 'Program Names are different' : ''}`}
          />
        </div>
      </Modal>
    </div>
  )
}
