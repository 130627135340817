import { LoadingStatus } from 'pages/RateSheetOverview'
import { useState } from 'react'
import { Input2, Modal } from 'stories/components'

import type { ICommercialDSCRRestriction } from './CommercialDSCRSheet'

export const UpdateRestrictionModal = (props: any) => {
  const [restriction, setRestriction] = useState<ICommercialDSCRRestriction>(props.restriction)

  return (
    <div>
      <Modal
        title="Update Restriction"
        titleOkay="Update"
        isOpen={props.isOpen}
        loading={props.loading === LoadingStatus.SAVE_RESTRICTION}
        onClose={props.onClose}
        onOk={() => props.onSubmit(restriction)}
      >
        <div>
          <p className="font-variation-settings-600">
            1-point fee = {restriction.RateReduction}% rate reduction. | Minimum Rate {restriction.MinRate}%.
          </p>
          <Input2
            title="Rate Reduction"
            type="number"
            required={true}
            onChange={(value) => setRestriction({ ...restriction, RateReduction: Number(value) })}
            value={restriction.RateReduction.toString()}
            className="mb-4"
          />
          <Input2
            title="Min Rate"
            type="number"
            required={true}
            onChange={(value) => setRestriction({ ...restriction, MinRate: Number(value) })}
            value={restriction.MinRate.toString()}
          />
        </div>
      </Modal>
    </div>
  )
}
