import { ArchiveBoxXMarkIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { PlainInput } from 'components/PlainInput'
import {
  type ICommercialOnlyProgrammed,
  type IProgram,
  type IRuralPropertyLeverageLimit,
  propertyTypeLabels,
  yesNoOptions,
} from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Checkbox, Select } from 'stories/components'
import { confirm, getPrice3decimal, isEmpty, removeComma } from 'utils'

import { convertNullToBlank, convertNullToBlankValueToDecimal } from '../logic'

interface IProps {
  isTemplate: boolean
  leverageData: IRuralPropertyLeverageLimit[]
  program: IProgram
  isLoading: boolean
  loading: string
  title: string
  onSave: (data: IRuralPropertyLeverageLimit[], dataKey: string) => void
}

export const RuralPropertyLeverageMaxLimit = (props: IProps) => {
  const { program, leverageData, isLoading, isTemplate, loading, onSave } = props

  const [data, setData] = useState<IRuralPropertyLeverageLimit[]>([])
  const [edit, setEdit] = useState(false)
  const [programData, setProgramData] = useState<ICommercialOnlyProgrammed>()

  useEffect(() => {
    if (!program) return
    if (!leverageData) return

    setData(leverageData)

    setProgramData(program.OtherTypeProgramData as ICommercialOnlyProgrammed)
    setEdit(false)
  }, [program, leverageData])

  useEffect(() => {
    if (!loading) setEdit(false)
  }, [loading])

  const addLimit = () => {
    const newData = cloneDeep(data)

    if (newData.length === 2) {
      toast(`All options are already included.`, { type: 'info' })
      return
    }

    newData.unshift({
      value: '',
      loanAmount: NaN,
      minFICO: 0,
      limits: [
        {
          FICO: { from: 0, to: 1000 },
          loanAmount: {
            purchase: { min: NaN, max: NaN },
            nocashout: { min: NaN, max: NaN },
            cashout: { min: NaN, max: NaN },
          },
          LTV: {
            purchase: {
              aivLtv: NaN,
              arvLtv: NaN,
              ltc: NaN,
              ltp: NaN,
            },
            nocashout: {
              aivLtv: NaN,
              arvLtv: NaN,
              ltc: NaN,
              ltp: NaN,
            },
            cashout: {
              aivLtv: NaN,
              arvLtv: NaN,
              ltc: NaN,
              ltp: NaN,
            },
          },
          minMonthsReserve: {
            purchase: 0,
            nocashout: 0,
            cashout: 0,
          },
          propertyTypes: propertyTypeLabels,
        },
      ],
    })

    setData(newData)
    setEdit(true)
  }

  const addSubLimit = (index: number) => {
    const newData = cloneDeep(data)

    newData[index].limits.push({
      FICO: {
        from: newData[index].limits[newData[index].limits.length - 1].FICO.from,
        to: newData[index].limits[newData[index].limits.length - 1].FICO.to,
      },
      LTV: {
        purchase: {
          aivLtv: NaN,
          arvLtv: NaN,
          ltc: NaN,
          ltp: NaN,
        },
        nocashout: {
          aivLtv: NaN,
          arvLtv: NaN,
          ltc: NaN,
          ltp: NaN,
        },
        cashout: {
          aivLtv: NaN,
          arvLtv: NaN,
          ltc: NaN,
          ltp: NaN,
        },
      },
      loanAmount: {
        purchase: { min: NaN, max: NaN },
        nocashout: { min: NaN, max: NaN },
        cashout: { min: NaN, max: NaN },
      },
      minMonthsReserve: {
        purchase: 0,
        nocashout: 0,
        cashout: 0,
      },
      propertyTypes: propertyTypeLabels,
    })

    setData(newData)
    setEdit(true)
  }

  const removeLimit = async (index: number, idx: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this row?
        <br />
        <span className="text-gray-600 text-base">Rural Property: {data[index].value || 'N/A'}</span>
        <br />
        <span className="text-gray-600 text-base">
          FICO: {data[index].limits[idx].FICO.from} - {data[index].limits[idx].FICO.to}
        </span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const newData = cloneDeep(data)

    newData[index].limits.splice(idx, 1)

    if (!newData[index].limits.length) newData.splice(index, 1)

    setData(newData)
    setEdit(true)
  }

  const onChangeValue = (index: number, value: string) => {
    const newData = cloneDeep(data)

    if (newData.find((item) => item.value === value)) {
      toast(`Please choose another option.`, {
        type: 'info',
      })
      return
    }

    newData[index].value = value

    setData(newData)
    setEdit(true)
  }

  const onChangeFICO = (index: number, idx: number, key: string, value: any) => {
    value = removeComma(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if ((newData[index].limits[idx].FICO as any)[key] === value) return
    ;(newData[index].limits[idx].FICO as any)[key] = value

    setData(newData)
    setEdit(true)
  }

  const onChangeLtvMaxLimit = (
    index: number,
    idx: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: string,
    value: any,
  ) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    const newData = cloneDeep(data)

    if ((newData[index].limits[idx].LTV[loanPurpose] as any)[key] === value) return
    ;(newData[index].limits[idx].LTV[loanPurpose] as any)[key] = value

    setData(newData)
    setEdit(true)
  }

  const onChangeMinMonthsReserve = (
    index: number,
    idx: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    value: any,
  ) => {
    value = removeComma(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if (newData[index].limits[idx].minMonthsReserve[loanPurpose] === value) return
    newData[index].limits[idx].minMonthsReserve[loanPurpose] = value

    setData(newData)
    setEdit(true)
  }

  const onChangePropertyTypes = (index: number, id: number, idx: number) => {
    if (!isTemplate) return

    const newData = cloneDeep(data)

    const targetIndex = newData[index].limits[id].propertyTypes.findIndex((item) => item === propertyTypeLabels[idx])

    if (targetIndex !== -1) newData[index].limits[id].propertyTypes.splice(targetIndex, 1)
    else newData[index].limits[id].propertyTypes.push(propertyTypeLabels[idx])

    setData(newData)
    setEdit(true)
  }

  const onChangeLoanAmountOverly = (
    index: number,
    idx: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: string,
    value: any,
  ) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if ((newData[index].limits[idx].loanAmount[loanPurpose] as any)[key] == value) return
    ;(newData[index].limits[idx].loanAmount[loanPurpose] as any)[key] = value

    setData(newData)
    setEdit(true)
  }

  const submit = () => {
    let hasError = false

    data.forEach((item) => {
      if (isEmpty(item.value)) {
        hasError = true
      }
    })

    if (hasError) {
      toast(`Required fields exist!`, { type: 'error' })
      return
    }

    onSave(data, 'ruralPropertyLeverageMaxLimit')
  }

  const onChangeMaxLoanAmount = (index: number, value: any) => {
    const newData = cloneDeep(data)

    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (newData[index].loanAmount === value) return

    newData[index].loanAmount = value

    setData(newData)
    setEdit(true)
  }

  const onChangeMinFICO = (index: number, value: any) => {
    const newData = cloneDeep(data)

    value = removeComma(value)

    if (newData[index].minFICO == value) return

    newData[index].minFICO = value

    setData(newData)
    setEdit(true)
  }

  const renderMaxLimit = useMemo(() => {
    if (!programData || data.length === 0)
      return (
        <div className="w-full flex justify-center items-center mt-10">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Data</span>
          </div>
        </div>
      )

    return (
      <div className="overflow-x-auto">
        <table className="table min-w-max w-full text-center text-sm">
          <thead className="bg-gray-100">
            <tr>
              <th rowSpan={2} className="border p-1 whitespace-nowrap">
                Rural Property
              </th>
              <th rowSpan={2} className="border p-1 whitespace-nowrap w-[150px]">
                Max Loan Amount
              </th>
              <th rowSpan={2} className="border p-1 whitespace-nowrap w-[90px]">
                Min FICO
              </th>
              <th colSpan={2} className="border p-1 whitespace-nowrap">
                FICO
              </th>
              <th rowSpan={2} className="border p-1 whitespace-nowrap w-[140px]">
                Loan Purpose
              </th>
              <th colSpan={2} className="border p-1 whitespace-nowrap">
                Loan Amount
              </th>
              <th rowSpan={2} className="border p-1 w-[100px]">
                Min Months Reserve
              </th>
              <th colSpan={4} className="border p-1 whitespace-nowrap">
                Max LTVs
              </th>
              {isTemplate && (
                <th rowSpan={2} className="border p-1">
                  Action
                </th>
              )}
            </tr>
            <tr>
              <th className="border p-1 w-[90px]">From</th>
              <th className="border p-1 w-[90px]">To</th>
              <th className="border p-1 w-[120px]">From</th>
              <th className="border p-1 w-[120px]">To</th>
              <th className="border p-1 w-[100px]">AIV-LTV</th>
              <th className="border p-1 w-[100px]">ARV-LTV</th>
              <th className="border p-1 w-[100px]">LTC</th>
              <th className="border p-1 w-[100px]">LTP</th>
            </tr>
          </thead>

          <tbody>
            {data.map((item, index) => (
              <Fragment key={index}>
                <tr>
                  <td rowSpan={4 * item.limits.length + 1} className="border p-1">
                    {isTemplate ? (
                      <Select
                        id="value"
                        options={yesNoOptions}
                        hasDefaultOption={true}
                        value={item.value}
                        className="mb-[-16px]"
                        onChange={(v) => onChangeValue(index, v)}
                      />
                    ) : (
                      item.value
                    )}

                    {isTemplate && (
                      <div className="mt-5 flex justify-center w-full">
                        <span
                          className="cursor-pointer text-shade-blue hover:scale-105 transform duration-100 hover:underline"
                          onClick={() => addSubLimit(index)}
                        >
                          Add Limit
                        </span>
                      </div>
                    )}
                  </td>
                  <td rowSpan={4 * item.limits.length + 1} className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.loanAmount)}
                        origin={convertNullToBlank(item.loanAmount)}
                        content={getPrice3decimal(item.loanAmount)}
                        onChange={(value: any) => onChangeMaxLoanAmount(index, value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                  <td rowSpan={4 * item.limits.length + 1} className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={item.minFICO}
                        origin={item.minFICO}
                        content={item.minFICO}
                        onChange={(value: any) => onChangeMinFICO(index, value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                </tr>

                {item.limits.map((limit, idx) => (
                  <Fragment key={`${index}-${idx}`}>
                    <tr>
                      {['from', 'to'].map((key, index1) => (
                        <td rowSpan={3} key={`${index}-${idx}-${index1}`} className={`border whitespace-nowrap p-1`}>
                          <div className="flex justify-center">
                            <PlainInput
                              value={(limit.FICO as any)[key]}
                              origin={(limit.FICO as any)[key]}
                              content={(limit.FICO as any)[key]}
                              onChange={(value: any) => onChangeFICO(index, idx, key, value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}

                      <td className="border p-1">Purchase</td>
                      {['min', 'max'].map((key, index1) => (
                        <td
                          key={`${index}-${idx}-${index1}`}
                          className={`border whitespace-nowrap p-1 ${isTemplate ? 'bg-gray-50' : ''}`}
                        >
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank((limit.loanAmount.purchase as any)[key])}
                              origin={convertNullToBlank((limit.loanAmount.purchase as any)[key])}
                              content={convertNullToBlankValueToDecimal((limit.loanAmount.purchase as any)[key])}
                              onChange={(value: any) => onChangeLoanAmountOverly(index, idx, 'purchase', key, value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}
                      <td className={`p-1 border ${!isTemplate ? '' : 'bg-gray-50'}`}>
                        <div className="flex justify-center">
                          <PlainInput
                            value={convertNullToBlank(limit.minMonthsReserve.purchase)}
                            origin={convertNullToBlank(limit.minMonthsReserve.purchase)}
                            content={convertNullToBlank(limit.minMonthsReserve.purchase)}
                            disabled={!isTemplate}
                            onChange={(value: any) => onChangeMinMonthsReserve(index, idx, 'purchase', value)}
                            className="w-full"
                          />
                        </div>
                      </td>
                      {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, index1) => (
                        <td
                          key={`${index}-${idx}-${index1}`}
                          className={`p-1 border ${
                            isEmpty(program.OtherTypeProgramData.ltvLimits.purchase[key]) || !isTemplate
                              ? ''
                              : 'bg-gray-50'
                          }`}
                        >
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank((limit.LTV.purchase as any)[key])}
                              origin={convertNullToBlank((limit.LTV.purchase as any)[key])}
                              content={convertNullToBlank((limit.LTV.purchase as any)[key])}
                              disabled={isEmpty(program.OtherTypeProgramData.ltvLimits.purchase[key]) || !isTemplate}
                              onChange={(value: any) => onChangeLtvMaxLimit(index, idx, 'purchase', key, value)}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}

                      {isTemplate && (
                        <td rowSpan={4} className="border p-1">
                          <div className="flex justify-center">
                            <div
                              className="w-fit p-1 transition-all duration-200 hover:cursor-pointer hover-shadow1 rounded"
                              onClick={() => removeLimit(index, idx)}
                            >
                              <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                            </div>
                          </div>
                        </td>
                      )}
                    </tr>

                    <tr>
                      <td className="border p-1">No-Cashout</td>
                      {['min', 'max'].map((key, index1) => (
                        <td
                          key={`${index}-${idx}-${index1}`}
                          className={`border whitespace-nowrap p-1 ${isTemplate ? 'bg-gray-50' : ''}`}
                        >
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank((limit.loanAmount.nocashout as any)[key])}
                              origin={convertNullToBlank((limit.loanAmount.nocashout as any)[key])}
                              content={convertNullToBlankValueToDecimal((limit.loanAmount.nocashout as any)[key])}
                              onChange={(value: any) => onChangeLoanAmountOverly(index, idx, 'nocashout', key, value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}
                      <td className={`p-1 border ${!isTemplate ? '' : 'bg-gray-50'}`}>
                        <div className="flex justify-center">
                          <PlainInput
                            value={convertNullToBlank(limit.minMonthsReserve.nocashout)}
                            origin={convertNullToBlank(limit.minMonthsReserve.nocashout)}
                            content={convertNullToBlank(limit.minMonthsReserve.nocashout)}
                            disabled={!isTemplate}
                            onChange={(value: any) => onChangeMinMonthsReserve(index, idx, 'nocashout', value)}
                            className="w-full"
                          />
                        </div>
                      </td>
                      {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, index1) => (
                        <td
                          key={`${index}-${idx}-${index1}`}
                          className={`p-1 border ${
                            isEmpty(program.OtherTypeProgramData.ltvLimits.nocashout[key]) || !isTemplate
                              ? ''
                              : 'bg-gray-50'
                          }`}
                        >
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank((limit.LTV.nocashout as any)[key])}
                              origin={convertNullToBlank((limit.LTV.nocashout as any)[key])}
                              content={convertNullToBlank((limit.LTV.nocashout as any)[key])}
                              disabled={isEmpty(program.OtherTypeProgramData.ltvLimits.nocashout[key]) || !isTemplate}
                              onChange={(value: any) => onChangeLtvMaxLimit(index, idx, 'nocashout', key, value)}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}
                    </tr>

                    <tr>
                      {' '}
                      <td className="border p-1">Cashout</td>
                      {['min', 'max'].map((key, index1) => (
                        <td
                          key={`${index}-${idx}-${index1}`}
                          className={`border whitespace-nowrap p-1 ${isTemplate ? 'bg-gray-50' : ''}`}
                        >
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank((limit.loanAmount.cashout as any)[key])}
                              origin={convertNullToBlank((limit.loanAmount.cashout as any)[key])}
                              content={convertNullToBlankValueToDecimal((limit.loanAmount.cashout as any)[key])}
                              onChange={(value: any) => onChangeLoanAmountOverly(index, idx, 'cashout', key, value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}
                      <td className={`p-1 border ${!isTemplate ? '' : 'bg-gray-50'}`}>
                        <div className="flex justify-center">
                          <PlainInput
                            value={convertNullToBlank(limit.minMonthsReserve.cashout)}
                            origin={convertNullToBlank(limit.minMonthsReserve.cashout)}
                            content={convertNullToBlank(limit.minMonthsReserve.cashout)}
                            disabled={!isTemplate}
                            onChange={(value: any) => onChangeMinMonthsReserve(index, idx, 'cashout', value)}
                            className="w-full"
                          />
                        </div>
                      </td>
                      {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, index1) => (
                        <td
                          key={`${index}-${idx}-${index1}`}
                          className={`p-1 border ${
                            isEmpty(program.OtherTypeProgramData.ltvLimits.cashout[key]) || !isTemplate
                              ? ''
                              : 'bg-gray-50'
                          }`}
                        >
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank((limit.LTV.cashout as any)[key])}
                              origin={convertNullToBlank((limit.LTV.cashout as any)[key])}
                              content={convertNullToBlank((limit.LTV.cashout as any)[key])}
                              disabled={isEmpty(program.OtherTypeProgramData.ltvLimits.cashout[key]) || !isTemplate}
                              onChange={(value: any) => onChangeLtvMaxLimit(index, idx, 'cashout', key, value)}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}
                    </tr>

                    <tr>
                      <td colSpan={10} className="p-3 text-left border">
                        <div className="border-b font-bold mb-1 pb-1">Property Type</div>
                        <div className="flex justify-start flex-wrap gap-x-4 gap-y-2">
                          {propertyTypeLabels.map((p, index1) => (
                            <Checkbox
                              key={`${index}-${idx}-${index1}`}
                              id={`${index}-${idx}-${index1}`}
                              title={p}
                              size={3}
                              value={limit.propertyTypes.includes(p)}
                              color="gray"
                              className="w-[175px]"
                              onChange={() => onChangePropertyTypes(index, idx, index1)}
                            />
                          ))}
                        </div>
                      </td>
                    </tr>
                  </Fragment>
                ))}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    )
  }, [data])

  return (
    <div className="relative">
      <LayoutLoading show={LoadingStatus.LEVERAGE_LIMIT === loading || isLoading} />

      <div className="px-2 mt-2">
        {isTemplate && (
          <div className={`flex items-center ${edit ? 'justify-between' : 'justify-end'} mb-2`}>
            {edit && (
              <Button loading={LoadingStatus.LEVERAGE_LIMIT === loading} onClick={submit} className="pt-1.5 pb-1.5">
                Save
              </Button>
            )}

            <div
              className="flex items-center gap-2 cursor-pointer w-fit text-shade-blue transition-all duration-200 hover:border-b hover:border-b-shade-blue pr-1 h-6"
              onClick={addLimit}
            >
              <PlusIcon className="w-4 h-4" />
              <span>Add Limit</span>
            </div>
          </div>
        )}

        {renderMaxLimit}
      </div>
    </div>
  )
}
