import { ClockIcon } from '@heroicons/react/24/outline'
import { useRef } from 'react'

interface CheckboxProps {
  /**
   * Is Full
   */
  full?: boolean
  /**
   * Is disabled
   */
  disabled?: boolean
  /**
   * Id of Input
   */
  id: string
  /**
   * Title of Input
   */
  title?: string
  /**
   * Name of Input
   */
  name?: string
  /**
   * Value of Input
   */
  value?: boolean
  /**
   * Custom class name
   */
  className?: string
  fontClass?: string
  /**
   * Custom color
   */
  color?: string
  /**
   * Custom size
   */
  size?: number

  checked?: boolean
  onClick?: () => void

  /**
   * Optional click handler
   */
  /**
   * Optional history handler
   */
  history?: boolean
  onChange?: (checked: boolean) => void
  onBlur?: () => void
  showHistory?: () => void
}

/**
 * Primary UI component for user interaction
 */
export const Checkbox = ({
  disabled = false,
  id = '',
  title = '',
  name = '',
  color = 'blue',
  size = 4,
  value = true,
  history = false,
  className = '',
  fontClass = '',
  onChange = () => {},
  onBlur = () => {},
  showHistory = () => {},
  ...props
}: CheckboxProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const _onChange = (checked: boolean) => {
    onChange(checked)
    setTimeout(() => {
      if (document.activeElement === inputRef.current) {
        inputRef?.current?.blur()
      }
    }, 250)
  }

  const now = Date.now()
  return (
    <div className={`group relative z-0 w-full group ${className}`}>
      <label htmlFor={`${id}-${now}`} className="inline-flex relative items-center cursor-pointer min-h-5">
        <input
          type="checkbox"
          id={`${id}-${now}`}
          name={name}
          className={`w-${size} h-${size} ${disabled ? `text-${color}-300` : `text-${color}-500`} ${
            size >= 4 ? 'rounded' : ''
          } ${
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          } border-gray-300 focus:ring-${color}-500 dark:focus:ring-${color}-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600`}
          // defaultChecked={value}
          checked={value}
          disabled={disabled}
          onChange={(event) => _onChange(event.target.checked)}
          ref={inputRef}
          onBlur={onBlur}
          {...props}
        />
        <span
          className={`ml-2 ${
            size >= 4 ? 'text-sm' : 'text-[13px]'
          } font-medium text-gray-900 dark:text-gray-300 ${fontClass}`}
        >
          {title}
        </span>
      </label>
      {history && (
        <span className="ml-3 hidden mt-[4px] group-hover:inline float-right" onClick={() => showHistory()}>
          <ClockIcon className="h-[14px] w-[14px] text-gray-500 cursor-pointer" aria-hidden="true" />
        </span>
      )}
    </div>
  )
}
