import cloneDeep from 'clone-deep'
import {
  type IDscrLeverageAdjustments,
  type IProgram,
  bankruptcyOptions,
  commercialOccupancyTypeOptions,
  forbearanceOptions,
  foreclosuresOptions,
  mortgageLatesOptions,
  propertyTypeLabels,
  yesNoOptions,
} from 'config'
import { useEffect, useMemo, useState } from 'react'
import { getDscrLeverageData } from 'services'
import { ButtonGroup } from 'stories/components'

import { DscrLeverageAdjustment } from './DscrLeverageAdjustment'
import { DscrRangeLeverageAdjustment } from './DscrRangeLeverageAdjustment'

const initialMenus = {
  occupancy: 'Occupancy',
  loanAmount: 'Loan Amount',
  propertyType: 'Property Type',
  bankruptcy: 'Bankruptcy',
  fcSsDil: 'FC/SS/DIL',
  mortgageLates: 'Mortgage Lates',
  forbearance: 'Forbearance / Loan Modification',
  isPropertyInLeasableState: 'Can property be rented as is? (2)',
  isShortTermRental: 'Is the property a short term rental? (2)',
  decliningMarketProperty: 'Property in a declining market? (2)',
  newConstructionProperty: 'Is the property a new construction? (2)',
}

interface IProps {
  program: IProgram
  loanId: number
  title: string
  isTemplate: boolean
  loading: string
  onSave: (data: any[], key: string, type: string) => void
}

export const DscrLeverageAdjustments = (props: IProps) => {
  const { isTemplate, loanId, program, title, loading, onSave } = props

  const [menus, setMenus] = useState(initialMenus)
  const [selectedMenu, setSelectedMenu] = useState(menus.occupancy)
  const [selectedMenuKey, setSelectedMenuKey] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [leverageData, setLeverageData] = useState<IDscrLeverageAdjustments>()

  useEffect(() => {
    if (isTemplate) setLeverageData(program.Restriction?.LeverageAdjustments)
    else {
      ;(async () => {
        setLoading(true)

        try {
          const res = await getDscrLeverageData(loanId, program.ID, 'LeverageAdjustments')

          setLeverageData(res)
        } catch (err) {
          console.log(err)
        }

        setLoading(false)
      })()
    }
  }, [program])

  useEffect(() => {
    if (!leverageData) {
      setMenus(initialMenus)
      return
    }

    const {
      OccupancyLeverageAdjustment,
      PropertyTypeLeverageAdjustment,
      MortgageLatesLeverageAdjustment,
      FcSsDilLeverageAdjustment,
      BankruptcyLeverageAdjustment,
      ForbearanceLeverageAdjustment,
      LoanAmountLeverageAdjustment,
    } = leverageData as IDscrLeverageAdjustments

    const newMenus = cloneDeep(initialMenus)

    Object.keys(initialMenus).forEach((key) => {
      switch (key) {
        case 'occupancy':
          if (!!OccupancyLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${OccupancyLeverageAdjustment.length})`
          }
          break
        case 'loanAmount':
          if (!!LoanAmountLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${LoanAmountLeverageAdjustment.length})`
          }
          break
        case 'propertyType':
          if (!!PropertyTypeLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${PropertyTypeLeverageAdjustment.length})`
          }
          break
        case 'mortgageLates':
          if (!!MortgageLatesLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${MortgageLatesLeverageAdjustment.length})`
          }
          break
        case 'fcSsDil':
          if (!!FcSsDilLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${FcSsDilLeverageAdjustment.length})`
          }
          break
        case 'bankruptcy':
          if (!!BankruptcyLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${BankruptcyLeverageAdjustment.length})`
          }
          break
        case 'forbearance':
          if (!!ForbearanceLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${ForbearanceLeverageAdjustment.length})`
          }
          break
      }
    })

    setMenus(newMenus)

    if (selectedMenuKey) setSelectedMenu((newMenus as any)[selectedMenuKey])
    else setSelectedMenu(newMenus.occupancy)
  }, [leverageData, program])

  const renderContent = useMemo(() => {
    switch (selectedMenu) {
      case menus.loanAmount:
        return (
          <DscrRangeLeverageAdjustment
            isTemplate={isTemplate}
            title="Loan Amount"
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.LoanAmountLeverageAdjustment || []}
            onSave={(data) => onSave(data, 'LoanAmountLeverageAdjustment', 'LeverageAdjustments')}
          />
        )
      case menus.occupancy:
        return (
          <DscrLeverageAdjustment
            isTemplate={isTemplate}
            title="Occupancy"
            option={commercialOccupancyTypeOptions}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.OccupancyLeverageAdjustment || []}
            onSave={(data) => onSave(data, 'OccupancyLeverageAdjustment', 'LeverageAdjustments')}
          />
        )
      case menus.propertyType:
        return (
          <DscrLeverageAdjustment
            isTemplate={isTemplate}
            title="Property Type"
            option={propertyTypeLabels}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.PropertyTypeLeverageAdjustment || []}
            onSave={(data) => onSave(data, 'PropertyTypeLeverageAdjustment', 'LeverageAdjustments')}
          />
        )
      case menus.mortgageLates:
        return (
          <DscrLeverageAdjustment
            isTemplate={isTemplate}
            title="Mortgage Lates"
            option={mortgageLatesOptions}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.MortgageLatesLeverageAdjustment || []}
            onSave={(data) => onSave(data, 'MortgageLatesLeverageAdjustment', 'LeverageAdjustments')}
          />
        )
      case menus.fcSsDil:
        return (
          <DscrLeverageAdjustment
            isTemplate={isTemplate}
            title="FC/SS/DIL"
            option={foreclosuresOptions}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.FcSsDilLeverageAdjustment || []}
            onSave={(data) => onSave(data, 'FcSsDilLeverageAdjustment', 'LeverageAdjustments')}
          />
        )
      case menus.bankruptcy:
        return (
          <DscrLeverageAdjustment
            isTemplate={isTemplate}
            title="Bankruptcy"
            option={bankruptcyOptions}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.BankruptcyLeverageAdjustment || []}
            onSave={(data) => onSave(data, 'BankruptcyLeverageAdjustment', 'LeverageAdjustments')}
          />
        )
      case menus.forbearance:
        return (
          <DscrLeverageAdjustment
            isTemplate={isTemplate}
            title="Forbearance / Loan Modification"
            option={forbearanceOptions}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.ForbearanceLeverageAdjustment || []}
            onSave={(data) => onSave(data, 'ForbearanceLeverageAdjustment', 'LeverageAdjustments')}
          />
        )
      case menus.isPropertyInLeasableState:
        return (
          <DscrLeverageAdjustment
            isTemplate={isTemplate}
            title="Can property be rented as is?"
            readOnlyValue={true}
            loading={loading}
            isLoading={isLoading}
            option={yesNoOptions}
            leverageData={leverageData?.IsPropertyInLeasableStateLeverageAdjustment || []}
            onSave={(data) => onSave(data, 'IsPropertyInLeasableStateLeverageAdjustment', 'LeverageAdjustments')}
          />
        )
      case menus.isShortTermRental:
        return (
          <DscrLeverageAdjustment
            isTemplate={isTemplate}
            title="Is the property a short term rental?"
            readOnlyValue={true}
            loading={loading}
            isLoading={isLoading}
            option={yesNoOptions}
            leverageData={leverageData?.IsShortTermRentalLeverageAdjustment || []}
            onSave={(data) => onSave(data, 'IsShortTermRentalLeverageAdjustment', 'LeverageAdjustments')}
          />
        )
      case menus.decliningMarketProperty:
        return (
          <DscrLeverageAdjustment
            isTemplate={isTemplate}
            title="Property in a declining market?"
            readOnlyValue={true}
            loading={loading}
            isLoading={isLoading}
            option={yesNoOptions}
            leverageData={leverageData?.DecliningMarketPropertyLeverageAdjustment || []}
            onSave={(data) => onSave(data, 'DecliningMarketPropertyLeverageAdjustment', 'LeverageAdjustments')}
          />
        )
      case menus.newConstructionProperty:
        return (
          <DscrLeverageAdjustment
            isTemplate={isTemplate}
            title="Is the property a new construction?"
            readOnlyValue={true}
            loading={loading}
            isLoading={isLoading}
            option={yesNoOptions}
            leverageData={leverageData?.NewConstructionPropertyLeverageAdjustment || []}
            onSave={(data) => onSave(data, 'NewConstructionPropertyLeverageAdjustment', 'LeverageAdjustments')}
          />
        )
      default:
        return <></>
    }
  }, [selectedMenu, loading, program, isLoading, leverageData])

  const onChangeMenuItem = (value: string) => {
    const selectedKey = Object.keys(menus).find((key) => (menus as any)[key] === value)

    if (selectedKey) setSelectedMenuKey(selectedKey)

    setSelectedMenu(value)
  }

  const renderMenus = useMemo(() => {
    return <ButtonGroup title={Object.values(menus)} value={selectedMenu} onChange={onChangeMenuItem} />
  }, [menus, selectedMenu])

  return (
    <div>
      <div className="text-lg font-variation-settings-600 mb-3">{title}</div>

      {renderMenus}

      <div className="py-2">{renderContent}</div>
    </div>
  )
}
