import type { ILLPAData, ILockTermPrice, IProgramResponse } from 'config'
import { useEffect, useState } from 'react'
import { Modal } from 'stories/components'
import { getPrice3decimal } from 'utils'

interface Props {
  program: IProgramResponse
  rate: number
  price: ILockTermPrice
  isOpen: boolean
  onClose: () => void
}

export const PriceCalculationModal = (props: Props) => {
  const { program, rate, price, isOpen, onClose } = props
  const { LTV } = program

  const [llpaSum, setLLPASum] = useState(0)
  const [baseRate, setBaseRate] = useState<number>()
  const [llpa, setLLPA] = useState<Record<string, ILLPAData>>()
  const [calcHistory, setCalcHistory] = useState<string[]>()

  useEffect(() => {
    const targetPrice = program.Prices.find((item) => item.Rate == rate)
    setLLPA(targetPrice?.LLPA)
    setCalcHistory(targetPrice?.CalcHistory)
    setBaseRate(targetPrice?.BaseRate)
  }, [program])

  useEffect(() => {
    if (!llpa) return
    let sum = Number(price.LockTermLLPA)

    Object.keys(llpa).forEach((key) => {
      sum += Number(llpa[key].value)
    })

    setLLPASum(sum)
  }, [llpa, price])

  return (
    <div>
      <Modal
        title="Loan Level Price/Rate Adjustor(s) Evidence"
        isOpen={isOpen}
        onClose={onClose}
        titleCancel={''}
        titleOkay={''}
      >
        <div>
          <table className="table w-full text-sm text-center">
            <tbody>
              <tr>
                <td className="border p-2">Rate: {rate}</td>
                <td className="border p-2">Price/Pts: {price.Price}</td>
              </tr>
              <tr>
                <td className="border p-2">Original Rate: {baseRate}</td>
                <td className="border p-2">Original: {price.BasePrice}</td>
              </tr>
            </tbody>
          </table>

          {!!llpa && (
            <>
              <div className="mt-3 mb-1 font-variation-settings-600">Adjustment</div>
              <table className="table text-sm text-center w-full">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="border p-2">Name</th>
                    <th className="border p-2">Description</th>
                    <th className="border p-2">Price</th>
                  </tr>
                </thead>

                <tbody>
                  {Object.keys(llpa).map((key, index) => {
                    const { name, label, from, to, value, isEnabled } = llpa[key]

                    if (isEnabled) {
                      return (
                        <tr key={index}>
                          <td className="border p-2">
                            {name} {label ? ': ' + label : ''} (LLPA)
                          </td>
                          <td className="border p-2">
                            {from
                              ? `${name}: ${key === 'LoanAmount' ? '$' : ''}${getPrice3decimal(from)} - ${
                                  key === 'LoanAmount' ? '$' : ''
                                }${to ? getPrice3decimal(to) : ''},`
                              : ''}{' '}
                            LTV: {LTV.from} - {LTV.to}
                          </td>
                          <td className="border p-2">{value}</td>
                        </tr>
                      )
                    }
                  })}

                  {price.LockTermLLPA !== 0 && (
                    <tr>
                      <td className="border p-2">Lock Term - {price.LockDay}(LLPA)</td>
                      <td className="border p-2">
                        LTV: {LTV.from} - {LTV.to}
                      </td>
                      <td className="border p-2">{price.LockTermLLPA}</td>
                    </tr>
                  )}

                  <tr className="bg-gray-100">
                    <th colSpan={2} scope="colgroup" className="border p-2">
                      Total
                    </th>
                    <th className="border p-2">{llpaSum}</th>
                  </tr>
                </tbody>
              </table>
            </>
          )}

          {!!calcHistory && (
            <>
              <div className="mt-3 mb-1 font-variation-settings-600">Calculation History</div>

              <table className="table w-full text-sm">
                <tbody>
                  {calcHistory.map((item, index) => (
                    <tr key={index}>
                      <td className="border p-2">{item}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </Modal>
    </div>
  )
}
