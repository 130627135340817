import cloneDeep from 'clone-deep'
import {
  type ILeverageAdjustments,
  type IProgram,
  bankruptcyOptions,
  citizenshipOptions,
  commercialOccupancyTypeOptions,
  forbearanceOptions,
  foreclosuresOptions,
  mortgageLatesOptions,
  propertyTypeLabels,
  yesNoOptions,
} from 'config'
import { useEffect, useMemo, useState } from 'react'
import { getNonDscrLeverageData } from 'services'
import { ButtonGroup } from 'stories/components'

import { RehabBudgetTypes } from '../constants'
import { FicoLeverageAdjustment } from './FicoLeverageAdjustment'
import { RangeLeverageAdjustment } from './RangeLeverageAdjustment'
import { ValueLeverageAdjustment } from './ValueLeverageAdjustment'

const initialMenus = {
  occupancy: 'Occupancy',
  loanAmount: 'Loan Amount',
  experience: 'Experience',
  propertyType: 'Property Type',
  fico: 'FICO',
  bankruptcy: 'Bankruptcy',
  fcSsDil: 'FC/SS/DIL',
  mortgageLates: 'Mortgage Lates',
  forbearance: 'Forbearance / Loan Modification',
  citizenship: 'Citizenship',
  monthsReserve: 'Months Reserve',
  rehabBudgetType: 'Rehab Budget Type',
  underConstruction: 'Property Under Construction? (2)',
  isPropertyInLeasableState: 'Can property be rented as is? (2)',
  decliningMarketProperty: 'Property in a declining market? (2)',
  newConstructionProperty: 'Is the property a new construction? (2)',
  ruralProperty: 'Rural Property? (2)',
  firstTimeHomeBuyer: 'First Time Home Buyer? (2)',
  firstTimeInvestor: 'First Time Home Investor? (2)',
  exitFee: 'Exit Fee',
}

interface IProps {
  program: IProgram
  loanId: number
  title: string
  isTemplate: boolean
  loading: string
  onChangeNonDscrLeverageData: (data: any[], dataKey: string, type: string) => void
}

export const LeverageAdjustments = (props: IProps) => {
  const { title, isTemplate, loading, loanId, program, onChangeNonDscrLeverageData } = props

  const [menus, setMenus] = useState(initialMenus)
  const [selectedMenu, setSelectedMenu] = useState(menus.occupancy)
  const [selectedMenuKey, setSelectedMenuKey] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [leverageData, setLeverageData] = useState<ILeverageAdjustments>()

  useEffect(() => {
    if (isTemplate) setLeverageData(program.OtherTypeProgramData.leverageAdjustments)
    else {
      ;(async () => {
        setLoading(true)

        try {
          const res = await getNonDscrLeverageData(loanId, program.ID, 'leverageAdjustments')

          setLeverageData(res)
        } catch (err) {
          console.log(err)
        }

        setLoading(false)
      })()
    }
  }, [program])

  useEffect(() => {
    if (!leverageData) {
      setMenus(initialMenus)
      return
    }

    const {
      occupancyLeverageAdjustment,
      loanAmountLeverageAdjustment,
      experienceLeverageAdjustment,
      ficoLeverageAdjustment,
      monthsReserveLeverageAdjustment,
      citizenshipLeverageAdjustment,
      rehabBudgetTypeLeverageAdjustment,
      propertyTypeLeverageAdjustment,
      mortgageLatesLeverageAdjustment,
      forbearanceLeverageAdjustment,
      fcSsDilLeverageAdjustment,
      bankruptcyLeverageAdjustment,
      exitFeeLeverageAdjustment,
    } = leverageData as ILeverageAdjustments

    const newMenus = cloneDeep(initialMenus)

    Object.keys(initialMenus).forEach((key) => {
      switch (key) {
        case 'occupancy':
          if (!!occupancyLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${occupancyLeverageAdjustment.length})`
          }
          break
        case 'rehabBudgetType':
          if (!!rehabBudgetTypeLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${rehabBudgetTypeLeverageAdjustment.length})`
          }
          break
        case 'citizenship':
          if (!!citizenshipLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${citizenshipLeverageAdjustment.length})`
          }
          break
        case 'monthsReserve':
          if (!!monthsReserveLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${monthsReserveLeverageAdjustment.length})`
          }
          break
        case 'fico':
          if (!!ficoLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${ficoLeverageAdjustment.length})`
          }
          break
        case 'loanAmount':
          if (!!loanAmountLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${loanAmountLeverageAdjustment.length})`
          }
          break
        case 'experience':
          if (!!experienceLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${experienceLeverageAdjustment.length})`
          }
          break
        case 'propertyType':
          if (!!propertyTypeLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${propertyTypeLeverageAdjustment.length})`
          }
          break
        case 'mortgageLates':
          if (!!mortgageLatesLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${mortgageLatesLeverageAdjustment.length})`
          }
          break
        case 'forbearance':
          if (!!forbearanceLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${forbearanceLeverageAdjustment.length})`
          }
          break
        case 'fcSsDil':
          if (!!fcSsDilLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${fcSsDilLeverageAdjustment.length})`
          }
          break
        case 'bankruptcy':
          if (!!bankruptcyLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${bankruptcyLeverageAdjustment.length})`
          }
          break
        case 'exitFee':
          if (!!exitFeeLeverageAdjustment.length) {
            newMenus[key] = initialMenus[key] + ` (${exitFeeLeverageAdjustment.length})`
          }
          break
      }
    })

    setMenus(newMenus)

    if (selectedMenuKey) setSelectedMenu((newMenus as any)[selectedMenuKey])
    else setSelectedMenu(newMenus.occupancy)
  }, [leverageData, program])

  const renderContent = useMemo(() => {
    switch (selectedMenu) {
      case menus.occupancy:
        return (
          <ValueLeverageAdjustment
            title="Occupancy"
            options={commercialOccupancyTypeOptions}
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.occupancyLeverageAdjustment || []}
            program={program}
            onSave={(data) => onChangeNonDscrLeverageData(data, 'occupancyLeverageAdjustment', 'leverageAdjustments')}
          />
        )
      case menus.propertyType:
        return (
          <ValueLeverageAdjustment
            title="Property Type"
            options={propertyTypeLabels}
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.propertyTypeLeverageAdjustment || []}
            program={program}
            onSave={(data) =>
              onChangeNonDscrLeverageData(data, 'propertyTypeLeverageAdjustment', 'leverageAdjustments')
            }
          />
        )
      case menus.mortgageLates:
        return (
          <ValueLeverageAdjustment
            title="Mortgage Lates"
            options={mortgageLatesOptions}
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.mortgageLatesLeverageAdjustment || []}
            program={program}
            onSave={(data) =>
              onChangeNonDscrLeverageData(data, 'mortgageLatesLeverageAdjustment', 'leverageAdjustments')
            }
          />
        )
      case menus.fcSsDil:
        return (
          <ValueLeverageAdjustment
            title="FC/SS/DIL"
            options={foreclosuresOptions}
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.fcSsDilLeverageAdjustment || []}
            program={program}
            onSave={(data) => onChangeNonDscrLeverageData(data, 'fcSsDilLeverageAdjustment', 'leverageAdjustments')}
          />
        )
      case menus.bankruptcy:
        return (
          <ValueLeverageAdjustment
            title="Bankruptcy"
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.bankruptcyLeverageAdjustment || []}
            program={program}
            options={bankruptcyOptions}
            onSave={(data) => onChangeNonDscrLeverageData(data, 'bankruptcyLeverageAdjustment', 'leverageAdjustments')}
          />
        )
      case menus.forbearance:
        return (
          <ValueLeverageAdjustment
            title="Forbearance / Loan Modification"
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.forbearanceLeverageAdjustment || []}
            program={program}
            options={forbearanceOptions}
            onSave={(data) => onChangeNonDscrLeverageData(data, 'forbearanceLeverageAdjustment', 'leverageAdjustments')}
          />
        )
      case menus.citizenship:
        return (
          <ValueLeverageAdjustment
            title="Citizenship"
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.citizenshipLeverageAdjustment || []}
            program={program}
            options={citizenshipOptions}
            onSave={(data) => onChangeNonDscrLeverageData(data, 'citizenshipLeverageAdjustment', 'leverageAdjustments')}
          />
        )
      case menus.rehabBudgetType:
        return (
          <ValueLeverageAdjustment
            title="Rehab Budget Type"
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.rehabBudgetTypeLeverageAdjustment || []}
            program={program}
            options={RehabBudgetTypes}
            onSave={(data) =>
              onChangeNonDscrLeverageData(data, 'rehabBudgetTypeLeverageAdjustment', 'leverageAdjustments')
            }
          />
        )
      case menus.underConstruction:
        return (
          <ValueLeverageAdjustment
            onlyBridge={true}
            readOnlyValue={true}
            title="Property Under Construction?"
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.underConstructionLeverageAdjustment || []}
            program={program}
            options={yesNoOptions}
            onSave={(data) =>
              onChangeNonDscrLeverageData(data, 'underConstructionLeverageAdjustment', 'leverageAdjustments')
            }
          />
        )
      case menus.isPropertyInLeasableState:
        return (
          <ValueLeverageAdjustment
            onlyBridge={true}
            readOnlyValue={true}
            title="Can property be rented as is?"
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.isPropertyInLeasableStateLeverageAdjustment || []}
            program={program}
            options={yesNoOptions}
            onSave={(data) =>
              onChangeNonDscrLeverageData(data, 'isPropertyInLeasableStateLeverageAdjustment', 'leverageAdjustments')
            }
          />
        )
      case menus.decliningMarketProperty:
        return (
          <ValueLeverageAdjustment
            readOnlyValue={true}
            title="Property in a declining market?"
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.decliningMarketPropertyLeverageAdjustment || []}
            program={program}
            options={yesNoOptions}
            onSave={(data) =>
              onChangeNonDscrLeverageData(data, 'decliningMarketPropertyLeverageAdjustment', 'leverageAdjustments')
            }
          />
        )
      case menus.newConstructionProperty:
        return (
          <ValueLeverageAdjustment
            readOnlyValue={true}
            onlyBridge={true}
            title="Is the property a new construction?"
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.newConstructionPropertyLeverageAdjustment || []}
            program={program}
            options={yesNoOptions}
            onSave={(data) =>
              onChangeNonDscrLeverageData(data, 'newConstructionPropertyLeverageAdjustment', 'leverageAdjustments')
            }
          />
        )
      case menus.ruralProperty:
        return (
          <ValueLeverageAdjustment
            readOnlyValue={true}
            title="Rural Property?"
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.ruralPropertyLeverageAdjustment || []}
            program={program}
            options={yesNoOptions}
            onSave={(data) =>
              onChangeNonDscrLeverageData(data, 'ruralPropertyLeverageAdjustment', 'leverageAdjustments')
            }
          />
        )
      case menus.firstTimeHomeBuyer:
        return (
          <ValueLeverageAdjustment
            readOnlyValue={true}
            title="First Time Home Buyer?"
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.firstTimeHomeBuyerLeverageAdjustment || []}
            program={program}
            options={yesNoOptions}
            onSave={(data) =>
              onChangeNonDscrLeverageData(data, 'firstTimeHomeBuyerLeverageAdjustment', 'leverageAdjustments')
            }
          />
        )
      case menus.firstTimeInvestor:
        return (
          <ValueLeverageAdjustment
            readOnlyValue={true}
            title="First Time Home Investor?"
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.firstTimeInvestorLeverageAdjustment || []}
            program={program}
            options={yesNoOptions}
            onSave={(data) =>
              onChangeNonDscrLeverageData(data, 'firstTimeInvestorLeverageAdjustment', 'leverageAdjustments')
            }
          />
        )
      case menus.exitFee:
        return (
          <RangeLeverageAdjustment
            title="Exit Fee"
            step={0.01}
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.exitFeeLeverageAdjustment || []}
            program={program}
            onSave={(data) => onChangeNonDscrLeverageData(data, 'exitFeeLeverageAdjustment', 'leverageAdjustments')}
          />
        )
      case menus.experience:
        return (
          <RangeLeverageAdjustment
            title="Experience"
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.experienceLeverageAdjustment || []}
            program={program}
            onSave={(data) => onChangeNonDscrLeverageData(data, 'experienceLeverageAdjustment', 'leverageAdjustments')}
          />
        )
      case menus.fico:
        return (
          <FicoLeverageAdjustment
            title="FICO"
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.ficoLeverageAdjustment || []}
            program={program}
            onSave={(data) => onChangeNonDscrLeverageData(data, 'ficoLeverageAdjustment', 'leverageAdjustments')}
          />
        )
      case menus.loanAmount:
        return (
          <RangeLeverageAdjustment
            title="Loan Amount"
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.loanAmountLeverageAdjustment || []}
            program={program}
            onSave={(data) => onChangeNonDscrLeverageData(data, 'loanAmountLeverageAdjustment', 'leverageAdjustments')}
          />
        )
      case menus.monthsReserve:
        return (
          <RangeLeverageAdjustment
            title="Months Reserve"
            isTemplate={isTemplate}
            loading={loading}
            isLoading={isLoading}
            leverageData={leverageData?.monthsReserveLeverageAdjustment || []}
            program={program}
            onSave={(data) =>
              onChangeNonDscrLeverageData(data, 'monthsReserveLeverageAdjustment', 'leverageAdjustments')
            }
          />
        )
      default:
        return <></>
    }
  }, [selectedMenu, loading, program, isLoading, leverageData])

  const onChangeMenuItem = (value: string) => {
    const selectedKey = Object.keys(menus).find((key) => (menus as any)[key] === value)

    if (selectedKey) setSelectedMenuKey(selectedKey)

    setSelectedMenu(value)
  }

  const renderMenus = useMemo(() => {
    return <ButtonGroup title={Object.values(menus)} value={selectedMenu} onChange={onChangeMenuItem} />
  }, [menus, selectedMenu])

  return (
    <div>
      <div className="text-lg font-variation-settings-600 mb-3">{title}</div>

      {renderMenus}

      <div className="py-2">{renderContent}</div>
    </div>
  )
}
