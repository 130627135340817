import { ArrowLongRightIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import type { ICompany } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getTemplates } from 'services/apis/ratesheet'
import { Checkbox, Modal, Select2 } from 'stories/components'

import { LoadingStatus } from '..'

interface ITemplateData {
  company: ICompany
  programs: {
    ID: number
    Name: string
  }[]
}

export const SyncProgramModal = (props: any) => {
  const { program, isOpen, loading, onClose, onSubmit } = props

  const [companies, setCompanies] = useState<ICompany[]>([])
  const [templates, setTemplates] = useState<ITemplateData[]>([])
  const [programs, setPrograms] = useState<string[]>([])
  const [company, setCompany] = useState('')
  const [template, setTemplate] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [isAdd, setIsAdd] = useState(false)
  const [checkboxDisabled, setDisabled] = useState(true)

  useEffect(() => {
    ;(async () => {
      setLoading(true)

      const res = await getTemplates()

      const targetCompany = res.companies.find((item: ICompany) =>
        item.name.toLowerCase().includes('commercial.nextres.com'),
      )
      setCompanies(res.companies)
      setTemplates(res.templates)

      if (targetCompany) setCompany(targetCompany.name)

      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    if (program.Name !== template) {
      setDisabled(false)
    } else {
      setIsAdd(false)
      setDisabled(true)
    }
  }, [template, program])

  useEffect(() => {
    if (!company) {
      setPrograms([])
      return
    }

    const ratesheet = templates.find((item) => item.company.name === company)

    if (!ratesheet) {
      setPrograms([])
      return
    }

    const newPrograms = ratesheet.programs.map((program) => program.Name)
    setPrograms(newPrograms)

    const targetProgram = newPrograms.find((item) => item === program.Name)

    if (targetProgram) setTemplate(targetProgram)
    else setTemplate('')
  }, [company, templates])

  const onOk = async () => {
    const selectedCompany = companies.find((item) => item.name === company)
    const selectedTemplate = templates.find((item) => item.company.name === company)

    if (!selectedCompany) {
      toast('Select Company', {
        type: 'error',
      })
      return
    }

    if (!isAdd && !selectedTemplate) {
      toast('Select Program', {
        type: 'error',
      })

      return
    }

    const targetProgram = selectedTemplate?.programs.find((item) => item.Name === template)

    const data = {
      generalProgamId: program.ID,
      companyID: selectedCompany.id,
      programID: targetProgram?.ID,
      isAdd: isAdd,
    }

    await onSubmit(data)
  }

  return (
    <div>
      <Modal
        title="Sync Program with Company"
        titleOkay={isAdd ? 'Add' : 'Sync'}
        isOpen={isOpen}
        loading={loading === LoadingStatus.SYNC_PROGRAM_WITH_GENERAL}
        onClose={onClose}
        disabled={isAdd ? false : template !== program.Name ? true : false}
        onOk={onOk}
      >
        <div className="relative min-w-[400px]">
          <LayoutLoading show={isLoading} />

          <div className="mb-3 font-variation-settings-600">
            Sync <span className="text-shade-blue">{program.Name}</span> to
          </div>

          <div className="flex mb-3 font-variation-settings-600">
            {company && (
              <span className="flex items-center">
                {company}
                <ArrowLongRightIcon className="w-5 mx-2" />
              </span>
            )}
            {template && <span className="text-shade-blue">{isAdd ? program.Name : template}</span>}
          </div>

          <Select2
            title="Company"
            id="company"
            onChange={(value) => setCompany(value)}
            value={company}
            hasDefaultOption={true}
            options={companies.map((company) => company.name)}
            className="mb-4"
          />
          <Select2
            title="Program"
            id="program"
            onChange={(value) => setTemplate(value)}
            value={template}
            options={programs}
            hasDefaultOption={true}
            disabled={isAdd}
            error={`${isAdd ? '' : template && program.Name !== template ? 'Program Names are different' : ''}`}
          />
          <Checkbox
            id="add"
            title="Add to Template as a New Program"
            value={isAdd}
            className="mt-4"
            disabled={checkboxDisabled}
            onChange={() => setIsAdd(!isAdd)}
          />
        </div>
      </Modal>
    </div>
  )
}
