import { ProgramContext } from 'pages/RateSheetOverview'
import { useContext } from 'react'

import { plOnlyWvoeOnlyConditions } from '../../../constants'

export function PLOnlyWvoeOnlyConditions() {
  const { selectedProgram } = useContext(ProgramContext)

  return (
    <div>
      <table className="w-full text-gray-700 dark:text-gray-400 pl-6">
        {Object.keys(plOnlyWvoeOnlyConditions[selectedProgram.Type]).map((key, index) => {
          const values = plOnlyWvoeOnlyConditions[selectedProgram.Type][key]

          return (
            <tbody key={index} className="border">
              <tr className="w-full">
                <th className="bg-gray-300 px-4 py-2 text-center">{key}</th>
              </tr>
              <tr className="w-full">
                <td>
                  <ul className="mb-0 list-disc pl-7">
                    {values.map((value, id) => (
                      <li key={id} className="my-2 text-sm">
                        {value}
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            </tbody>
          )
        })}
      </table>
    </div>
  )
}
