import { API_GET_LOAN_DETAILS, API_LOAN_DETAIL, API_SAVE_LOAN_DETAIL } from 'config'
import Api from 'services/api'

export const updateLoanDetail = (data: Record<string, any>, id: any) => {
  if (id === 'new') return Api.post(API_SAVE_LOAN_DETAIL, data)
  else return Api.post(API_SAVE_LOAN_DETAIL, data, { id })
}

export const getLoanDetails = (data: any) => {
  return Api.post(API_GET_LOAN_DETAILS, data)
}

export const removeLoanDetail = (id: number) => {
  return Api.delete(API_LOAN_DETAIL, {}, { id })
}

export const getLoanDetail = (id: number) => {
  return Api.get(API_LOAN_DETAIL, {}, { id })
}
