import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { ICompany } from 'config'
import { statesConstant } from 'config/states.constants'
import { Checkbox } from 'stories/components'

interface IProps {
  company: ICompany
  loading: string
  onUpdateStates: (states: string[]) => {}
}

export function LicensedStates({ company, loading, onUpdateStates }: IProps) {
  const onChangeState = async (state: string, value: boolean) => {
    const newStates = cloneDeep(company.states)

    if (value) {
      newStates.push(state)

      newStates.sort()
    } else {
      const removeIndex = newStates.findIndex((item: string) => item === state)

      newStates.splice(removeIndex, 1)
    }

    await onUpdateStates(newStates)
  }

  const onChangeAllStates = async (value: boolean) => {
    if (value) await onUpdateStates(statesConstant)
    else await onUpdateStates([])
  }

  return (
    <div className="relative p-4 rounded shadow1 mb-5">
      <LayoutLoading show={!!loading} />

      <div className="border-b">
        <Checkbox
          id="select-all"
          title="Licensed States"
          value={statesConstant.length === company.states.length}
          fontClass="text-lg font-variation-settings-600"
          color="gray"
          onChange={onChangeAllStates}
        />
      </div>

      <div className="flex flex-wrap">
        {statesConstant.map((item, index) => {
          return (
            <Checkbox
              key={index}
              id={index.toString()}
              title={item}
              value={company.states.includes(item)}
              className="my-2 mx-4 w-[40px]"
              color="gray"
              onChange={async (value) => await onChangeState(item, value)}
            />
          )
        })}
      </div>
    </div>
  )
}
