import { ArchiveBoxXMarkIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { PlainInput } from 'components/PlainInput'
import { IDscrLoanAmountLeverageLimit } from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import { convertNullToBlank } from 'pages/RateSheetOverview/ExcelSheets/CommercialOnlyProgrammed/logic'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from 'stories/components'
import { confirm, getPrice3decimal, removeComma, solveDecimalJavascriptSum } from 'utils'

interface IProps {
  leverageData: IDscrLoanAmountLeverageLimit[]
  isLoading: boolean
  isTemplate: boolean
  loading: string
  onSave: (data: IDscrLoanAmountLeverageLimit[]) => void
}

export const DscrLoanAmountLeverageLimit = (props: IProps) => {
  const { loading, isTemplate, leverageData, isLoading, onSave } = props

  const [data, setData] = useState<IDscrLoanAmountLeverageLimit[]>([])
  const [edit, setEdit] = useState(false)

  const step = 1

  useEffect(() => {
    if (!leverageData) return

    setData(leverageData)

    setEdit(false)
  }, [leverageData])

  const addLimit = () => {
    const newData = cloneDeep(data)

    newData.push({
      loanAmount: {
        from: !data.length ? 0 : data[data.length - 1].loanAmount.to + step,
        to: !data.length ? 0 : data[data.length - 1].loanAmount.to + step,
      },
      LTV: {
        purchase: NaN,
        nocashout: NaN,
        cashout: NaN,
      },
      CLTV: {
        purchase: NaN,
        nocashout: NaN,
        cashout: NaN,
      },
    })

    setData(newData)
    setEdit(true)
  }

  const onChangeLtvLimit = (
    index: number,
    key: 'LTV' | 'CLTV',
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    value: any,
  ) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if (newData[index][key][loanPurpose] == value) return

    newData[index][key][loanPurpose] = value

    setData(newData)
    setEdit(true)
  }

  const removeLtvLimit = async (index: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this row?
        <br />
        <span className="text-gray-600 text-base">
          Loan Amount: {getPrice3decimal(data[index].loanAmount.from)} - {getPrice3decimal(data[index].loanAmount.to)}
        </span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const newData = cloneDeep(data)

    newData.splice(index, 1)

    for (let i = 1; i < newData.length; i++) {
      if (newData[i].loanAmount.from - newData[i - 1].loanAmount.to !== step)
        newData[i].loanAmount.from = newData[i - 1].loanAmount.to + step

      if (newData[i].loanAmount.from > newData[i].loanAmount.to) newData[i].loanAmount.to = newData[i].loanAmount.from
    }

    setData(newData)
    setEdit(true)
  }

  const onChangeLoanAmount = (index: number, value: any, startValue = false) => {
    const newData = cloneDeep(data)

    value = removeComma(value)

    if (startValue) {
      newData[0].loanAmount.from = value

      if (newData[0].loanAmount.from > newData[0].loanAmount.to) newData[0].loanAmount.to = newData[0].loanAmount.from
    } else {
      if (newData[index].loanAmount.from > value) return

      newData[index].loanAmount.to = value
    }

    if (index < newData.length - 1)
      for (let i = index + 1; i < newData.length; i++) {
        if (newData[i].loanAmount.from - newData[i - 1].loanAmount.to !== step)
          newData[i].loanAmount.from = solveDecimalJavascriptSum([newData[i - 1].loanAmount.to, step])

        if (newData[i].loanAmount.from > newData[i].loanAmount.to) newData[i].loanAmount.to = newData[i].loanAmount.from
      }

    setData(newData)
    setEdit(true)
  }

  const renderLtvLimits = useMemo(() => {
    if (data.length === 0)
      return (
        <div className="w-full flex justify-center items-center mt-10">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limits</span>
          </div>
        </div>
      )

    return (
      <table className="table w-full text-center text-sm">
        <thead className="bg-gray-100">
          <tr>
            <th colSpan={2} className="border p-1 whitespace-nowrap">
              Loan Amount
            </th>
            <th rowSpan={2} className="border p-1">
              Loan Purpose
            </th>
            <th rowSpan={2} className="border p-1 w-[140px]">
              Max LTV
            </th>
            <th rowSpan={2} className="border p-1 w-[140px]">
              Max CLTV
            </th>
            {isTemplate && (
              <th rowSpan={2} className="border p-1 w-[150px]">
                Action
              </th>
            )}
          </tr>
          <tr>
            <th className="border p-1 w-[180px]">From</th>
            <th className="border p-1 w-[180px]">To</th>
          </tr>
        </thead>

        <tbody>
          {data.map((item, index) => {
            return (
              <Fragment key={index}>
                <tr>
                  <td rowSpan={3} className="border p-1">
                    <div className="flex justify-center">
                      <PlainInput
                        value={item.loanAmount.from}
                        origin={item.loanAmount.from}
                        content={getPrice3decimal(item.loanAmount.from)}
                        onChange={(value: any) => onChangeLoanAmount(index, value, index === 0)}
                        disabled={!isTemplate || index !== 0}
                        className="w-full"
                      />
                    </div>
                  </td>
                  <td rowSpan={3} className="border p-1">
                    <div className="flex justify-center">
                      <PlainInput
                        value={item.loanAmount.to}
                        origin={item.loanAmount.to}
                        content={getPrice3decimal(item.loanAmount.to)}
                        onChange={(value: any) => onChangeLoanAmount(index, value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                  <td className={`border whitespace-nowrap p-1`}>Purchase</td>

                  <td className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.LTV.purchase)}
                        origin={convertNullToBlank(item.LTV.purchase)}
                        content={convertNullToBlank(item.LTV.purchase)}
                        onChange={(value: any) => onChangeLtvLimit(index, 'LTV', 'purchase', value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                  <td className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.CLTV.purchase)}
                        origin={convertNullToBlank(item.CLTV.purchase)}
                        content={convertNullToBlank(item.CLTV.purchase)}
                        onChange={(value: any) => onChangeLtvLimit(index, 'CLTV', 'purchase', value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>

                  {isTemplate && (
                    <td rowSpan={3} className="border p-1">
                      <div className="flex justify-center">
                        <div
                          className="w-fit p-1 transition-all duration-200 hover:cursor-pointer hover-shadow1 rounded"
                          onClick={() => removeLtvLimit(index)}
                        >
                          <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                        </div>
                      </div>
                    </td>
                  )}
                </tr>

                <tr>
                  <td className={`border whitespace-nowrap p-1`}>No-Cashout</td>

                  <td className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.LTV.nocashout)}
                        origin={convertNullToBlank(item.LTV.nocashout)}
                        content={convertNullToBlank(item.LTV.nocashout)}
                        onChange={(value: any) => onChangeLtvLimit(index, 'LTV', 'nocashout', value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                  <td className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.CLTV.nocashout)}
                        origin={convertNullToBlank(item.CLTV.nocashout)}
                        content={convertNullToBlank(item.CLTV.nocashout)}
                        onChange={(value: any) => onChangeLtvLimit(index, 'CLTV', 'nocashout', value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className={`border whitespace-nowrap p-1`}>Cashout</td>

                  <td className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.LTV.cashout)}
                        origin={convertNullToBlank(item.LTV.cashout)}
                        content={convertNullToBlank(item.LTV.cashout)}
                        onChange={(value: any) => onChangeLtvLimit(index, 'LTV', 'cashout', value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                  <td className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.CLTV.cashout)}
                        origin={convertNullToBlank(item.CLTV.cashout)}
                        content={convertNullToBlank(item.CLTV.cashout)}
                        onChange={(value: any) => onChangeLtvLimit(index, 'CLTV', 'cashout', value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                </tr>
              </Fragment>
            )
          })}
        </tbody>
      </table>
    )
  }, [data])

  return (
    <div className=" relative">
      <LayoutLoading show={LoadingStatus.LEVERAGE_LIMIT === loading || isLoading} />

      <div className="px-2 mt-2">
        {isTemplate && (
          <div className={`flex items-center mb-2 ${edit ? 'justify-between' : 'justify-end'}`}>
            {edit && (
              <Button
                loading={LoadingStatus.LEVERAGE_LIMIT === loading}
                onClick={() => onSave(data)}
                className="pt-1.5 pb-1.5"
              >
                Save
              </Button>
            )}

            <div
              className="flex items-center gap-2 cursor-pointer w-fit text-shade-blue transition-all duration-200 hover:border-b hover:border-b-shade-blue pr-1 h-6"
              onClick={addLimit}
            >
              <PlusIcon className="w-4 h-4" />
              <span>Add Limit</span>
            </div>
          </div>
        )}

        {renderLtvLimits}
      </div>
    </div>
  )
}
