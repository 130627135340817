import type { InputType } from 'config'

const mortgageTypeOptions = [
  'VA',
  'FHA',
  'Conventional',
  'RHS',
  'Other',
  'HELOC',
  'State Agency',
  'Local Agency',
  'Non-QM',
]

const amortizationTypeOptions = ['Fixed', 'ARM']

const loanTermOptions = [
  '6',
  '12',
  '18',
  '24',
  '120',
  '132',
  '144',
  '156',
  '168',
  '180',
  '192',
  '204',
  '216',
  '228',
  '240',
  '252',
  '264',
  '276',
  '288',
  '300',
  '312',
  '324',
  '336',
  '348',
  '360',
  '372',
  '384',
  '396',
  '408',
  '420',
  '432',
  '444',
  '456',
  '468',
  '480',
]

const jumboOptions = ['Yes', 'No']

const ARMRoudingOptions = {
  0: 'No Rounding',
  1: 'To the nearest 1/8 %',
  2: 'Up to the nearest 1/8 %',
  3: 'Down to the nearest 1/8 %',
  4: 'To the nearest 1/4 %',
  5: 'Up to the nearest 1/4 %',
  6: 'Down to the nearest 1/4 %',
}

const ARMIndexTypeOptions = {
  1: 'Eleventh District Cost Of Funds',
  2: 'One Year Treasury',
  3: 'Three Year Treasury',
  4: 'Six Month Treasury',
  5: 'Daily Certificate Of Deposit Rate',
  6: 'Fannie Mae 60 Day Required Net Yield',
  7: 'Fannie Mae LIBOR',
  8: 'Federal Cost Of Funds',
  9: 'Freddie Mac 60 Day Required Net Yield',
  10: 'Freddie Mac LIBOR',
  11: 'LIBOR',
  12: 'Monthly Average Constant Maturing Treasury',
  13: 'National Average Contract Rate (FHLBB)',
  14: 'National Monthly Median Cost Of Funds',
  15: 'Treasury Bill Daily Average',
  16: 'Wall Street Journal LIBOR',
  17: 'Weekly Average Certificate Of Deposit Rate',
  18: 'Weekly Average CMT',
  19: 'Weekly Average Prime Rate',
  20: 'Weekly Average Secondary Market Treasury Bill Investment Yield',
  21: 'Weekly Average Treasury Auction Average Bond Discount Yield',
  22: 'Weekly Average Treasury Auction Average Investment Yield',
  23: 'Other',
  24: 'One Month LIBOR',
  25: 'Three Month LIBOR',
  26: 'Six Month LIBOR',
  27: 'One Year LIBOR',
  28: 'Five Year Treasury',
  29: '30 Day Average SOFR',
  30: '90 Day Average SOFR',
  31: '180 Day Average SOFR',
  32: 'Weekly Average CMT - One Year',
  33: 'Weekly Average CMT - Three Year',
  34: 'Wekkly Average CMT - Five Year',
}

export const ARMDetailFields = [
  'dividerArmDetails',
  'ARMIndexValue',
  'ARMIntRateFixedFor',
  'ARMIntRateAdjustAt',
  'ARMIndexValuesLocked',
  'ARMLifeCap',
  'ARMFloor',
  'ARMNoAdjCap',
  'ARMAdjustCapFirst',
  'ARMAdjustCapSubsequent',
  'ARMRounding',
  'ARMMaxRate',
  'ARMCommonRenewal',
  'ARMHasNegAmFeature',
  'ARMIndexType',
  'ARMIndexValueForWeekEnding',
  'ARMIndexNameOV',
  'ARMIndexPublished',
  'ARMLookbackDays',
  'ARMInterestRateChangesDescOV',
  'ARMPaymentChangesDescOV',
  'ARMNegAmDesc',
  'ARMAddFeatures',
  'DemandFeature',
  'ARMCarryover',
  'ARMConversationOption',
]

export const defaultInputs = (): Record<string, InputType> => {
  const rlt: Record<string, InputType> = {
    programCode: {
      inputType: 'text',
      type: 'text',
      title: 'Program Code',
      value: '',
      error: '',
      required: true,
    },
    productName: {
      inputType: 'select',
      options: [],
      title: 'Product Name',
      hasDefaultOption: true,
      value: '',
      error: '',
      required: true,
    },
    programID: {
      inputType: 'select',
      options: {},
      title: 'Program Name',
      hasDefaultOption: true,
      value: '',
      error: '',
      required: true,
    },
    mortgageType: {
      inputType: 'select',
      options: mortgageTypeOptions,
      title: 'Mortgage Type',
      hasDefaultOption: true,
      value: '',
      error: '',
      required: true,
    },
    loanTerm: {
      inputType: 'select',
      options: loanTermOptions,
      title: 'Loan Term (months)',
      hasDefaultOption: true,
      value: '',
      error: '',
      readOnly: true,
      required: true,
    },
    jumbo: {
      inputType: 'select',
      options: jumboOptions,
      title: 'Jumbo',
      hasDefaultOption: true,
      value: '',
      error: '',
      required: true,
    },
    interestOnly: {
      title: 'Interest Only?',
      inputType: 'check',
      error: '',
      disabled: true,
      required: true,
    },
    ioMonths: {
      title: 'Interest Only Months',
      inputType: 'text',
      type: 'number',
      error: '',
      required: true,
      visible: false,
    },
    amortizationType: {
      inputType: 'select',
      options: amortizationTypeOptions,
      title: 'Amortization Type',
      hasDefaultOption: true,
      value: '',
      readOnly: true,
      error: '',
      required: true,
      span: 3,
    },
    dividerArmDetails: {
      inputType: 'section',
      title: 'ARM Details',
      span: 6,
    },
    ARMIndexValue: {
      inputType: 'text',
      type: 'number',
      title: 'Index Value',
      value: '',
      error: '',
      required: true,
    },
    ARMIntRateFixedFor: {
      inputType: 'text',
      type: 'number',
      title: 'First Rate Change (months)',
      value: '',
      error: '',
      required: true,
    },
    ARMIntRateAdjustAt: {
      inputType: 'text',
      type: 'number',
      title: 'Next Rate Change (months)',
      value: '',
      error: '',
      required: true,
    },
    ARMIndexValuesLocked: {
      inputType: 'checkbox',
      title: 'Locked',
      value: false,
    },
    ARMLifeCap: {
      inputType: 'text',
      type: 'number',
      title: 'Life Cap',
      prefix: '%',
      value: '',
      error: '',
      required: true,
    },
    ARMFloor: {
      inputType: 'text',
      type: 'number',
      title: 'Floor',
      prefix: '%',
      value: '',
      error: '',
      required: true,
    },
    ARMNoAdjCap: {
      inputType: 'checkbox',
      title: 'No Caps',
      value: false,
    },
    ARMAdjustCapFirst: {
      inputType: 'text',
      type: 'number',
      title: 'First Adj Cap',
      prefix: '%',
      value: '',
      error: '',
      required: true,
    },
    ARMAdjustCapSubsequent: {
      inputType: 'text',
      type: 'number',
      title: 'Next Adj Cap',
      prefix: '%',
      value: '',
      error: '',
      required: true,
    },
    ARMRounding: {
      inputType: 'select',
      options: ARMRoudingOptions,
      title: 'Rounding Method',
      value: '',
      hasDefaultOption: true,
      error: '',
      required: true,
    },
    ARMMaxRate: {
      inputType: 'text',
      type: 'number',
      title: 'Max Rate',
      prefix: '%',
      value: '',
      error: '',
      required: true,
    },
    ARMCommonRenewal: {
      inputType: 'text',
      type: 'number',
      title: 'FHA Common Renewal',
      value: '',
      error: '',
      required: true,
    },
    ARMMargin: {
      inputType: 'text',
      type: 'number',
      title: 'Margin',
      prefix: '%',
      value: '',
      error: '',
      required: true,
    },
    ARMHasNegAmFeature: {
      inputType: 'checkbox',
      title: 'Loan has Potential Negative Amortization Feature',
      value: false,
      span: 6,
    },
    ARMIndexType: {
      inputType: 'select',
      options: ARMIndexTypeOptions,
      title: 'Index Type',
      value: '',
      hasDefaultOption: true,
      error: '',
      required: true,
      span: 3,
    },
    ARMIndexValueForWeekEnding: {
      inputType: 'text',
      type: 'date',
      title: 'Index Value for the Week Ending',
      value: '',
      error: '',
      required: true,
      span: 3,
    },
    ARMIndexNameOV: {
      inputType: 'text',
      type: 'text',
      title: 'Index Name',
      value: '',
      error: '',
      required: true,
    },
    ARMIndexPublished: {
      inputType: 'text',
      type: 'text',
      title: 'Index Published In',
      value: '',
      error: '',
      required: true,
    },
    ARMLookbackDays: {
      inputType: 'text',
      type: 'number',
      title: 'Lookback Days',
      value: '',
      error: '',
      required: true,
    },
    ARMInterestRateChangesDescOV: {
      inputType: 'text',
      type: 'text',
      title: 'Interest Changes',
      value: '',
      error: '',
      span: 3,
    },
    ARMPaymentChangesDescOV: {
      inputType: 'text',
      type: 'text',
      title: 'Payment Changes',
      value: '',
      error: '',
      span: 3,
    },
    ARMNegAmDesc: {
      inputType: 'text',
      type: 'text',
      title: 'Neg Am Description',
      value: '',
      error: '',
      span: 3,
    },
    ARMAddFeatures: {
      inputType: 'text',
      type: 'text',
      title: 'Additional Features',
      value: '',
      error: '',
      span: 3,
    },
    DemandFeature: {
      inputType: 'checkbox',
      title: 'Demand Feature',
      value: false,
    },
    ARMCarryover: {
      inputType: 'checkbox',
      title: 'Interest Rate Carryover',
      value: false,
    },
    ARMConversationOption: {
      inputType: 'checkbox',
      title: 'Conversion Option',
      value: false,
    },
  }
  ARMDetailFields.map((key) => {
    rlt[key].visible = false
  })
  return rlt
}
