import { ArchiveBoxXMarkIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { PlainInput } from 'components/PlainInput'
import type { ILoanAmountBonusMargin, IProgram } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { Button } from 'stories/components'
import { confirm, getPrice3decimal, removeComma } from 'utils'

import { LoadingStatus } from '..'

interface IProps {
  program: IProgram
  loading: string
  onSave: (data: ILoanAmountBonusMargin[], key: string) => void
}

export const LoanAmountBonusMargin = (props: IProps) => {
  const { program, loading, onSave } = props

  const [data, setData] = useState<ILoanAmountBonusMargin[]>([])
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    setEdit(false)

    if (!program?.BonusMargin) {
      setData([])
      return
    }

    if (!program.BonusMargin?.loanAmount) {
      setData([])
      return
    }

    setData(program.BonusMargin.loanAmount)
  }, [program])

  useEffect(() => {
    if (!loading) setEdit(false)
  }, [loading])

  const addExperienceRange = () => {
    const newData = cloneDeep(data)

    newData.push({
      from: 0,
      to: 0,
      margin: 0,
    })

    setData(newData)
    setEdit(true)
  }

  const onChange = (index: number, key: 'from' | 'to' | 'margin', value: string) => {
    const newValue = removeComma(value)

    const newData = cloneDeep(data)

    if (newData[index][key] === newValue) return

    if (['from', 'to'].includes(key) && newValue < 0) return

    newData[index][key] = newValue

    setData(newData)
    setEdit(true)
  }

  const onRemove = async (index: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this row?
        <br />
        <span className="text-gray-600 text-base">
          Loan Amount: {getPrice3decimal(data[index].from)} - {getPrice3decimal(data[index].to)}
        </span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const newData = cloneDeep(data)

    newData.splice(index, 1)

    setData(newData)
    setEdit(true)
  }

  const renderTable = useMemo(() => {
    if (data.length === 0)
      return (
        <div className="w-full flex justify-center items-center mt-10">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Margin</span>
          </div>
        </div>
      )

    return (
      <table className="table w-full text-center text-sm mt-2">
        <thead className="bg-gray-100">
          <tr>
            <th className="border px-3 py-2 w-1/4 whitespace-nowrap">From</th>
            <th className="border px-3 py-2 w-1/4">To</th>
            <th className="border px-3 py-2 w-1/4">Bonus Margin</th>
            <th className="border px-3 py-2 w-1/4">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td className="p-1 border">
                <div className="flex justify-center">
                  <PlainInput
                    value={item.from}
                    origin={item.from}
                    content={getPrice3decimal(item.from)}
                    onChange={(value: any) => onChange(index, 'from', value)}
                    className="w-full"
                  />
                </div>
              </td>
              <td className="p-1 border">
                <div className="flex justify-center">
                  <PlainInput
                    value={item.to}
                    origin={item.to}
                    content={getPrice3decimal(item.to)}
                    onChange={(value: any) => onChange(index, 'to', value)}
                    className="w-full"
                  />
                </div>
              </td>
              <td className="p-1 border">
                <div className="flex justify-center">
                  <PlainInput
                    value={getPrice3decimal(item.margin)}
                    origin={getPrice3decimal(item.margin)}
                    content={getPrice3decimal(item.margin)}
                    onChange={(value: any) => onChange(index, 'margin', value)}
                    className="w-full"
                  />
                </div>
              </td>
              <td className="p-1 border">
                <div className="flex justify-center">
                  <div
                    className="w-fit p-1 transition-all duration-200 hover:cursor-pointer hover-shadow1 rounded"
                    onClick={() => onRemove(index)}
                  >
                    <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }, [data])

  return (
    <div className="relative">
      <LayoutLoading show={LoadingStatus.BONUS_MARGIN === loading} />

      <div className="px-2 mt-2">
        <div className={`flex items-center ${edit ? 'justify-between' : 'justify-end'}`}>
          {edit && (
            <Button
              loading={LoadingStatus.BONUS_MARGIN === loading}
              onClick={() => onSave(data, 'loanAmount')}
              className="pt-1.5 pb-1.5"
            >
              Save
            </Button>
          )}

          <div
            className="flex items-center gap-2 cursor-pointer w-fit h-6 text-shade-blue transition-all duration-200 hover:border-b hover:border-b-shade-blue pr-1"
            onClick={addExperienceRange}
          >
            <PlusIcon className="w-4 h-4" />
            <span>Add Bonus Margin</span>
          </div>
        </div>

        {renderTable}
      </div>
    </div>
  )
}
