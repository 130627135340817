import {
  API_CREATE_COMPANY,
  API_DELETE_COMPANY,
  API_GENERATE_SECRET_KEY,
  API_GET_COMPANIES,
  API_GET_COMPANIES_INFO,
  API_GET_COMPANY_HISTORY,
  API_GET_COMPANY_RATESHEETS,
  API_UPDATE_COMPANY,
} from 'config'
import Api from 'services/api'

export const getCompanies = () => {
  return Api.get(API_GET_COMPANIES, {})
}

export const getCompaniesInfo = () => {
  return Api.get(API_GET_COMPANIES_INFO)
}

export const getCompanyRatesheets = () => {
  return Api.get(API_GET_COMPANY_RATESHEETS, {})
}

export const createCompany = (name: string) => {
  return Api.post(API_CREATE_COMPANY, { name: name })
}

export const deleteCompany = (id: number) => {
  return Api.delete(API_DELETE_COMPANY, {}, { id })
}

export const updateCompany = (data: Record<string, any>, id: number) => {
  return Api.put(API_UPDATE_COMPANY, JSON.stringify(data), { id })
}

export const getCompanyHistory = (id: number) => {
  return Api.get(API_GET_COMPANY_HISTORY, {}, { id })
}

export const generateSecretKey = (id: number) => {
  return Api.post(API_GENERATE_SECRET_KEY, {}, { id })
}
