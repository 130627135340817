import { PlusIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { ICompany, IRehabBudgetType, RehabBudgetTypeOption } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import {
  getRehabBudgetTypeDefinition,
  updateRehabBudgetTypeDefinition,
  updateRehabBudgetTypeGeneralDefinition,
} from 'services'
import { Button } from 'stories/components'
import { confirm, removeComma } from 'utils'

import { RehabBudgetTypeDefinition } from './RehabBudgetTypeDefinition'

export const RehabBudgetTypeItem = ({ company }: { company: ICompany }) => {
  const [generalDef, setGeneralDef] = useState<IRehabBudgetType>()
  const [data, setData] = useState<IRehabBudgetType[]>([])
  const [programNames, setProgramNames] = useState<Record<number, string>>({})
  const [errors, setErrors] = useState<string[]>([])
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (company) {
        setLoading(true)
        const { general, definitions, programs } = await getRehabBudgetTypeDefinition(company.id)

        const errs: string[] = []

        Object.keys(programs).forEach(() => errs.push(''))
        setGeneralDef(general)
        setErrors(errs)
        setData(definitions)
        setProgramNames(programs)
        setLoading(false)
      }
    })()

    setEdit(false)
  }, [company])

  const addMoreDefinition = () => {
    if (!generalDef) return

    const newData = cloneDeep(data)
    const newErrors = cloneDeep(errors)

    if (newData.length === Object.keys(programNames).length) {
      toast(`The addition of further definition is impossible.`, { type: 'warning' })
      return
    }

    newData.unshift(generalDef)
    newErrors.unshift('')

    setErrors(newErrors)
    setData(newData)
    setEdit(true)
  }

  const onSubmit = async () => {
    const newErrors = cloneDeep(errors)

    let hasError = false

    data.forEach((item, index) => {
      if (!item.programID) {
        hasError = true
        newErrors[index] = 'Required field'
      }
    })

    if (hasError) {
      setErrors(newErrors)
      toast(`Required fields exist!`, { type: 'error' })
      return
    }

    try {
      setLoading(true)
      await updateRehabBudgetTypeDefinition(company.id, data)
    } catch (error) {
      console.log(error)
    }

    setLoading(false)
    setEdit(false)
  }

  const onChangeProgram = (newProgramID: number, index: number) => {
    const newData = cloneDeep(data)
    const newErrors = cloneDeep(errors)

    const sameNameIndex = newData.findIndex((item) => item.programID === newProgramID)

    if (sameNameIndex !== -1 && sameNameIndex !== index) {
      toast(`Please choose a different program!`, { type: 'error' })
      return
    }

    newData[index].programID = newProgramID
    newErrors[index] = ''

    setErrors(newErrors)
    setData(newData)
    setEdit(true)
  }

  const onChangeValues = (
    key:
      | 'lightRate'
      | 'lightTo'
      | 'standardRate'
      | 'standardFrom'
      | 'standardTo'
      | 'heavyRate'
      | 'heavyFrom'
      | 'heavyRateFrom'
      | 'heavyRateTo'
      | 'heavyArvFrom',
    option: RehabBudgetTypeOption,
    value: string,
    index: number,
  ) => {
    const newData = cloneDeep(data)

    if ((newData[index].definition[option] as any)[key] == removeComma(value)) return
    ;(newData[index].definition[option] as any)[key] = removeComma(value)

    setData(newData)
    setEdit(true)
  }

  const onChangeOption = (newOption: RehabBudgetTypeOption, index: number) => {
    const newData = cloneDeep(data)

    newData[index].option = newOption

    setData(newData)
    setEdit(true)
  }

  const onChangeGeneralOption = (newOption: RehabBudgetTypeOption) => {
    if (!generalDef) return

    const newData = cloneDeep(generalDef)

    newData.option = newOption

    setGeneralDef(newData)
  }

  const onChangeGeneralValues = (
    key:
      | 'lightRate'
      | 'lightTo'
      | 'standardRate'
      | 'standardFrom'
      | 'standardTo'
      | 'heavyRate'
      | 'heavyFrom'
      | 'heavyRateFrom'
      | 'heavyRateTo'
      | 'heavyArvFrom',
    option: RehabBudgetTypeOption,
    value: string,
  ) => {
    if (!generalDef) return

    const newData = cloneDeep(generalDef)

    if ((newData.definition[option] as any)[key] == removeComma(value)) return
    ;(newData.definition[option] as any)[key] = removeComma(value)

    setGeneralDef(newData)
  }

  const onRemoveDefinition = async (index: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to remove this definition?
        <br />
        <span className="text-gray-600 text-base">
          Program Name: {data[index].programID !== undefined ? programNames[Number(data[index].programID)] : 'N/A'}
        </span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const newData = cloneDeep(data)
    const newErrors = cloneDeep(errors)

    newData.splice(index, 1)
    newErrors.splice(index, 1)

    setEdit(true)
    setData(newData)
    setErrors(newErrors)
  }

  const onSubmitGeneral = async () => {
    if (!generalDef) return

    try {
      setLoading(true)
      const reqBody = {
        companyID: company.id,
        option: generalDef.option,
        definition: generalDef.definition,
      }
      await updateRehabBudgetTypeGeneralDefinition(reqBody)
    } catch (error) {
      console.log(error)
    }

    setLoading(false)
  }

  const renderDefinitions = useMemo(() => {
    if (!data.length) return <></>

    return (
      <div className="mt-4">
        {data.map((item, index) => (
          <RehabBudgetTypeDefinition
            key={index}
            data={item}
            programID={item.programID}
            error={errors[index]}
            programNames={programNames}
            isGeneral={false}
            onChangeProgram={(newProgram) => onChangeProgram(newProgram, index)}
            onChangeValues={(key, option, value) => onChangeValues(key, option, value, index)}
            onChangeOption={(newOption) => onChangeOption(newOption, index)}
            onRemove={() => onRemoveDefinition(index)}
          />
        ))}
      </div>
    )
  }, [data, errors])

  return (
    <div className="relative">
      <LayoutLoading show={loading} />

      {generalDef && (
        <RehabBudgetTypeDefinition
          data={generalDef}
          loading={loading}
          isGeneral={true}
          onSubmitGeneral={onSubmitGeneral}
          onChangeGeneralValues={onChangeGeneralValues}
          onChangeGeneralOption={onChangeGeneralOption}
        />
      )}

      <div className={`flex items-end mt-4 h-8 ${edit ? 'justify-between' : 'justify-end'}`}>
        {edit && (
          <Button onClick={onSubmit} loading={loading} className="pt-1.5 pb-1.5 mb-[-2px] transition-all duration-200">
            Save
          </Button>
        )}

        {generalDef && (
          <div
            className="flex items-center h-8 gap-2 cursor-pointer w-fit text-shade-blue transition-all duration-200 hover:border-b hover:border-b-shade-blue pr-1"
            onClick={addMoreDefinition}
          >
            <PlusIcon className="w-4 h-4" />
            <span>Add Definition</span>
          </div>
        )}
      </div>

      {renderDefinitions}
    </div>
  )
}
