export const changeLogs: Record<string, string[]> = {
  '10/18/2022': [
    "States with 'PPP Calculation' = 'No restrictions' may not price with 0 year PPP",
    "IL – Borrower must be ['Corp', 'S Corp'] if interest rate is over 8%",
    "NJ – Borrower must be ['Corp', 'S Corp'] if interest rate is over 8%",
    'PA – Loan amount to $278,204.00 from 256,023.00',
  ],
  '10/19/2022': [
    "IL – Borrower must be ['LLC', 'LLP', 'Corp', 'S Corp', 'Partnership'] if interest rate is over 8%",
    "NJ – Borrower must be ['LLC', 'LLP', 'Corp', 'S Corp', 'Partnership'] if interest rate is over 8%",
  ],
  '10/20/2022': ['PA – 0 year will be Eligible'],
  '02/28/2023': ['KS – 0 year will be Eligible'],
}
