import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { ILoan, itemCountPerPage } from 'config'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getLoanDetails, removeLoanDetail } from 'services/apis/loan'
import { svgLoading, svgSearch } from 'stories/assets'
import { Input2, Pagination } from 'stories/components'
import { confirm, formatDate } from 'utils'
import { useTitle } from 'utils/pageTitle'
import { renderHeader } from 'utils/table'

import { checkLastOneItem } from './logic'

const headerList = [
  {
    title: 'No',
    key: '',
    sortable: false,
  },
  {
    title: 'Loan #',
    key: 'no',
    sortable: true,
  },
  {
    title: 'Loan Name',
    key: 'loanName',
    sortable: true,
  },
  {
    title: 'Rate Sheet',
    key: 'ratesheet',
    sortable: true,
  },
  {
    title: 'Created By',
    key: 'user',
    sortable: true,
  },
  {
    title: 'Created At',
    key: 'createdAt',
    sortable: true,
  },
  {
    title: 'Action',
    key: '',
    sortable: false,
  },
]

export function Loans() {
  useTitle('Loans - RTLTrader')

  const [isLoading, setLoading] = useState(false)
  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    orderBy: '',
    orderDir: -1,
    pageNum: 0,
  })
  const [total, setTotal] = useState<number>(0)
  const [loanDetails, setLoanDetails] = useState<ILoan[]>([])
  const [filterQuery, setFilterQuery] = useState(filters.query)

  useEffect(() => {
    filterData()
  }, [])

  useEffect(() => {
    const timeOutId = setTimeout(() => !isLoading && onChangeFilter('pageNum', 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const filterData = async (filtersData: Record<string, any> = filters, _pageNum: number = -1) => {
    if (filtersData?.query) filtersData.query = filtersData.query.trim()
    if (_pageNum === -1) _pageNum = filtersData.pageNum

    setLoading(true)

    const res = await getLoanDetails({
      ...filtersData,
      skip: _pageNum * itemCountPerPage,
      count: itemCountPerPage,
    })
    setLoanDetails(res.loanDetails)
    setTotal(res.total)

    setLoading(false)
  }

  const onPageNavigate = (num: number) => {
    onChangeFilter('pageNum', num)
  }

  const onChangeFilter = (key: 'query' | 'orderBy' | 'orderDir' | 'pageNum', value: any, refetch = true) => {
    if (isLoading) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else if (refetch) filterData(newFilters)
  }

  const onTrashLoanDetail = async (no: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Are you sure want to delete?
        <br />
        <span className="text-gray-600 text-base">Loan ID: {no}</span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const res = await removeLoanDetail(no)

    if (res) {
      const isLastItemOfLastPage = checkLastOneItem(loanDetails, no)
      let pageNum = filters.pageNum

      if (isLastItemOfLastPage && filters.pageNum !== 0) {
        pageNum -= 1
      }
      onChangeFilter('pageNum', pageNum)

      toast('Loan removed!', { type: 'info' })
    }
  }

  return (
    <div className="py-6 px-2">
      <div className="relative shadow1 max-w-screen-2xl m-auto bg-white rounded p-3 md:p-7 pb-3 md:pb-3 sm:text-center lg:text-left w-full">
        <h1 className="text-2xl font-variation-settings-600 flex items-center mb-3">
          <span>Loans</span>

          {isLoading ? (
            <span className="ml-3">
              <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
            </span>
          ) : (
            <span className="text-base font-semibold ml-3">({total})</span>
          )}
        </h1>

        <div className="w-full md:w-1/3 my-4">
          <Input2
            type="search"
            title="Search"
            hasIcon
            icon={svgSearch}
            value={filters.query}
            onChange={(value) => onChangeFilter('query', value)}
          />
        </div>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                {headerList.map((item, index) => {
                  return renderHeader({
                    title: item.title,
                    key: item.key,
                    index: index,
                    onSort: (key: string, order: number) => {
                      const newFilters = Object.assign({}, filters)
                      newFilters['orderBy'] = key
                      newFilters['orderDir'] = order
                      setFilters(newFilters)
                      filterData(newFilters)
                    },
                    sortable: item.sortable,
                    sortOrder: filters.orderBy == item.key ? filters.orderDir : 0,
                  })
                })}
              </tr>
            </thead>

            <tbody>
              {loanDetails ? (
                loanDetails.map((item, index) => (
                  <tr className={`border-b ${index % 2 ? 'bg-slate-50' : 'bg-white'}`} key={`${index}`}>
                    <td className="p-2">{filters.pageNum * 10 + index + 1}</td>
                    <td className="p-2">{item.no}</td>
                    <td className="p-2">
                      <Link
                        to={`/pricing/${item.no}`}
                        className="font-variation-settings-600 text-shade-blue hover:cursor-pointer hover:underline"
                      >
                        {item.loanName}
                      </Link>
                    </td>
                    <td className="p-2">{item.ratesheet.loanId}</td>
                    <td className="p-2">{item.user.name}</td>
                    <td className="p-2">{formatDate(item.createdAt)}</td>
                    <td>
                      <span className="flex p-2">
                        <Link
                          to={`/pricing/${item.no}`}
                          className="text-shade-blue p-1 hover-shadow1 cursor-pointer rounded mr-1"
                        >
                          <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                        </Link>
                        <span
                          className="text-red-600 p-1 hover-shadow1 cursor-pointer rounded"
                          onClick={() => onTrashLoanDetail(item.no)}
                        >
                          <TrashIcon className="w-4 h-4"></TrashIcon>
                        </span>
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <span>
                  <img src={svgLoading} className="inline w-8 h-8 text-white animate-spin" />
                </span>
              )}
            </tbody>
          </table>

          <div className="flex justify-end">
            <Pagination
              totalCount={total}
              itemCountPerPage={itemCountPerPage}
              onNavigate={onPageNavigate}
              pageNum={filters.pageNum}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
