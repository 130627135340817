import type { IProgram } from 'config'
import { useEffect, useState } from 'react'
import excelService from 'services/excelService'
import Sheet, { Style } from 'sheet-happens'
import { Button } from 'stories/components'
import { getPrice3decimal, isEmpty } from 'utils'

import { LoadingStatus } from '..'
import { fastMoneyInformations } from '../constants'

interface Props {
  program: IProgram
  loading: string
  onSave: (data: Record<string, any>) => {}
}

interface IData {
  sheet: any[]
}

export function FastMoneyExcelSheet({ program, loading, onSave }: Props) {
  const [data, setData] = useState<any[][]>([[], []])
  const [edit, setEdit] = useState<Record<string, number>>({})
  const [cellWidth, setCellWidth] = useState<number[]>([125, 100, 100, 100, 75, 75, 175])
  const [cellHeight, setCellHeight] = useState<number[]>([])
  const [editable, setEditable] = useState<string[]>([])
  const [editObject, setEditObject] = useState<Record<string, any>>({})

  useEffect(() => {
    ;(async function initializeData() {
      let { sheet } = (await excelService.parseFastMoneyProgram(program.OtherTypeProgramData)) as IData
      setData(sheet)
      setEdit({})

      let _editable = ['2-0', '2-1', '2-2', '2-3', '0-11', '9-0', '9-1', '9-2', '9-3', '7-8']

      const _rows = [2, 3, 4, 5]
      const _columns = [4, 5, 7, 8, 9, 10, 11]
      _rows.map((y) => {
        _columns.map((x) => {
          _editable.push(`${y}-${x}`)
        })
      })

      _editable = [..._editable, '9-4', '9-5', '10-4', '10-5', '9-7', '9-8', '10-7', '10-8']
      setEditable(_editable)
      setEditObject(program.OtherTypeProgramData)
    })()
  }, [program])

  const onSelectionChanged = (x1: number, y1: number, x2: number, y2: number) => {
    console.log('track change', x1, y1, x2, y2)
    return {}
  }

  const columnHeaders = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]

  const cellStyle = (x: number, y: number) => {
    let rlt: Record<string, any> = {
      textAlign: 'left',
    }
    if ([0, 7].indexOf(y) !== -1) {
      if ([0, 1, 2, 3, 4, 5].indexOf(x) !== -1) {
        rlt.fillColor = '#6DA2FB22'
        rlt.weight = 'bold'
      }
    }
    if (y === 1) {
      if (x <= 11) {
        rlt.fillColor = '#6DA2FB22'
      }
    }
    if (y === 8) {
      if (x <= 8) {
        rlt.fillColor = '#6DA2FB22'
      }
    }
    if (editable.indexOf(`${y}-${x}`) !== -1) {
      rlt.fillColor = '#f7f9fa'
    }
    return rlt
  }

  const editData = (x: number, y: number) => {
    return data?.[y]?.[x]
  }

  const displayData = (x: number, y: number) => {
    let rlt = data?.[y]?.[x]
    if (['0-11', '7-8'].indexOf(`${y}-${x}`) !== -1) {
      rlt = !isEmpty(rlt) ? 'Price at ' + rlt : 'Price at N/A'
    }
    if (['2-0', '2-1', '9-0', '9-1'].indexOf(`${y}-${x}`) !== -1) {
      rlt = '$' + getPrice3decimal(rlt)
    }
    if ([2, 3, 4, 5].indexOf(y) !== -1 && [7, 8, 9, 10, 11].indexOf(x) !== -1) {
      if (rlt === '') rlt = 'N/A'
      else if (rlt !== 'N/A') rlt += '%'
    }
    if ([9, 10].indexOf(y) !== -1 && [7, 8].indexOf(x) !== -1) {
      if (rlt === '') rlt = 'N/A'
      else if (rlt !== 'N/A') rlt += '%'
    }
    return rlt
  }

  const sourceData = (x: number, y: number) => {
    return data?.[y]?.[x]
  }

  const isReadOnly = (x: number, y: number) => {
    if (editable.indexOf(`${y}-${x}`) === -1) {
      return true
    }
    return false
  }

  const onCellWidthChange = (columnIdx: any, newWidth: number) => {
    const cw = [...cellWidth]
    if (columnIdx > cw.length) {
      for (let i = cw.length; i <= columnIdx; i++) {
        cw.push(100)
      }
    }
    cw[columnIdx] = newWidth
    setCellWidth(cw)
  }

  const onCellHeightChange = (rowIdx: any, newHeight: number) => {
    const ch = [...cellHeight]
    if (rowIdx > ch.length) {
      for (let i = ch.length; i <= rowIdx; i++) {
        ch.push(22)
      }
    }
    ch[rowIdx] = newHeight
    setCellHeight(ch)
  }

  const mapInvestor = ['purchaseFixFlip', 'purchaseAcquisition', 'refinanceFixFlip', 'refinance']
  const mapPrimarySecond = ['bridge1', 'bridge2']

  const onChange = async (changes: any[]) => {
    let newData = [...data]
    let newEdit: Record<string, number> = { ...edit }

    for (const change of changes) {
      let { x, y, value } = change

      if (newData[y][x] === value) continue

      if (value === '') value = ''
      else value = isNaN(Number(value)) ? '' : Number(Number(value).toFixed(3))

      if (editable.indexOf(`${y}-${x}`) !== -1) {
        const pos = `${y}-${x}`
        switch (pos) {
          case '0-11':
            editObject.Investor.pricing = value
            break
          case '2-0':
            editObject.Investor.loanAmount.from = value
            break
          case '2-1':
            editObject.Investor.loanAmount.to = value
            break
          case '2-2':
            editObject.Investor.monthsReserve = value
            break
          case '2-3':
            editObject.Investor.minFico = value
            break
          case '7-8':
            editObject.PrimarySecond.pricing = value
            break
          case '9-0':
            editObject.PrimarySecond.loanAmount.from = value
            break
          case '9-1':
            editObject.PrimarySecond.loanAmount.to = value
            break
          case '9-2':
            editObject.PrimarySecond.monthsReserve = value
            break
          case '9-3':
            editObject.PrimarySecond.minFico = value
            break
          default:
        }
        if ([2, 3, 4, 5].indexOf(y) !== -1) {
          const key1 = mapInvestor[y - 2]
          if (x === 4) editObject.Investor.rates[key1].fico.from = value
          if (x === 5) editObject.Investor.rates[key1].fico.to = value
          if (x === 7) editObject.Investor.rates[key1].aiv_ltv = value
          if (x === 8) editObject.Investor.rates[key1].arv_ltv = value
          if (x === 9) editObject.Investor.rates[key1].ltc = value
          if (x === 10) editObject.Investor.rates[key1].ltp = value
          if (x === 11) editObject.Investor.rates[key1].rate = value
        }
        if ([9, 10].indexOf(y) !== -1) {
          const key1 = mapPrimarySecond[y - 9]
          if (x === 4) editObject.PrimarySecond.rates[key1].fico.from = value
          if (x === 5) editObject.PrimarySecond.rates[key1].fico.to = value
          if (x === 7) editObject.PrimarySecond.rates[key1].aiv_ltv = value
          if (x === 8) editObject.PrimarySecond.rates[key1].rate = value
        }
        newData[y][x] = value
        newEdit[`${y}-${x}`] = value
      }
    }
    setEditObject(editObject)
    setEdit(newEdit)
    setData(newData)
  }

  return (
    <div className="py-6">
      <div className="relative max-w-screen-2xl m-auto w-full">
        <div className="flex flex-col justify-center items-center mb-5">
          <div className="w-full lg:w-2/3">
            <p className="font-variation-settings-600 mb-2">{program.Type} General Information</p>

            <table className="w-full">
              <tbody>
                {Object.keys(fastMoneyInformations).map((key: string, index: number) => {
                  const logic = fastMoneyInformations[key]
                  const rlt = []
                  rlt.push(
                    <tr key={index}>
                      <th className="border bg-gray-100 py-2">{key}</th>
                    </tr>,
                  )

                  rlt.push(
                    <tr key={`${key}-${index}`}>
                      <td className="border py-2 px-3 text-sm">
                        <ul className="list-disc pl-5">
                          {logic.map((item: string, id: number) => (
                            <li key={`${index}-${id}`} className="my-2">
                              {item}
                            </li>
                          ))}
                        </ul>
                      </td>
                    </tr>,
                  )

                  return rlt
                })}
              </tbody>
            </table>
          </div>
        </div>

        {program && Object.keys(edit).length > 0 && (
          <div className="flex justify-end">
            <Button
              loading={loading === LoadingStatus.SAVE_PROGRAM_CHANGES}
              onClick={async () => {
                await onSave(editObject)
                setEdit({})
              }}
            >
              Save
            </Button>
          </div>
        )}
        <Sheet
          onSelectionChanged={onSelectionChanged}
          onRightClick={() => {}}
          columnHeaders={columnHeaders}
          cellStyle={cellStyle as Style}
          editData={editData}
          displayData={displayData}
          sourceData={sourceData}
          cellWidth={cellWidth}
          cellHeight={cellHeight}
          onChange={onChange}
          readOnly={isReadOnly}
          onCellWidthChange={onCellWidthChange}
          onCellHeightChange={onCellHeightChange}
        />
      </div>
    </div>
  )
}
