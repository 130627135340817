import { ClockIcon } from '@heroicons/react/24/outline'
import type { Color } from 'stories/types'

import { Tooltip } from '../Tooltip/Tooltip'

interface InputFileProps {
  /**
   * What background color to use
   */
  color?: Color
  /**
   * Is Full
   */
  full?: boolean
  /**
   * Is disabled
   */
  disabled?: boolean
  /**
   * Tooltip of Input
   */
  tooltip?: string
  /**
   * Title of Input
   */
  title?: string
  /**
   * Name of Input
   */
  name?: string
  /**
   * Error of Input
   */
  error?: string
  /**
   * Custom class name
   */
  className?: string
  /**
   * Is has icon
   */
  hasIcon?: boolean
  /**
   * Required
   */
  required?: boolean
  /**
   * Icon component
   */
  icon?: string | JSX.Element | null

  multiple?: boolean

  acceptFileTypes?: string

  history?: boolean

  ref?: React.RefObject<HTMLInputElement> | null
  /**
   * Icon component
   */
  onChange: (e: FileList | File | null) => void // string | React.ChangeEvent<HTMLInputElement>) => void
  showHistory?: () => void
}

/**
 * Primary UI component for user interaction
 */
export const InputFile = ({
  color = 'sky',
  disabled = false,
  tooltip = '',
  title = '',
  name = '',
  error = '',
  className = '',
  hasIcon = false,
  icon = null,
  required = false,
  multiple = false,
  acceptFileTypes = 'application/pdf',
  history = false,
  ref = null,
  onChange = () => {},
  showHistory = () => {},
  ...props
}: InputFileProps) => {
  const classNames = [
    'w-fit',
    'block',
    'rounded-lg',
    'py-1.5',
    'px-2',
    'w-full',
    'text-sm',
    'text-gray-900',
    'bg-transparent',
    'appearance-none',
    'dark:text-white',
    'dark:border-gray-600',
    `dark:focus:border-${color}-500`,
    'focus:outline-none',
    'focus:ring-0',
    `focus:border-${color}-600`,
    'peer',
    hasIcon && icon ? 'pl-10' : '',
    disabled && 'cursor-not-allowed',
  ]
  return (
    <div className="input-container mt-4">
      <div className={`group relative z-0 w-full ${className}`}>
        {hasIcon && icon && typeof icon === 'string' && (
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <img src={icon} className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
        )}
        {hasIcon && icon && typeof icon !== 'string' && (
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">{icon}</div>
        )}
        <input
          type="file"
          name={name}
          className={classNames.join(' ')}
          disabled={disabled}
          required={required}
          onChange={(e) =>
            onChange(e.target.files?.length ? (multiple ? e.target.files : e.target.files?.item(0)) : null)
          }
          accept={acceptFileTypes}
          ref={ref}
          multiple={multiple}
          {...props}
        />
        <label
          htmlFor={name}
          className={`absolute flex items-center text-[14px] text-gray-900 dark:text-gray-400 duration-300 transform -translate-y-6 top-3 origin-[0] ml-2 px-1 rounded ${
            disabled ? '' : 'bg-white'
          } peer-placeholder-shown:-z-10 peer-focus:z-10 ${hasIcon && icon ? 'pl-8' : ''} peer-focus:pl-1 left-0 flex`}
        >
          {title}
          {required && '*'}
          {tooltip.length > 0 ? <Tooltip message={tooltip}></Tooltip> : null}
          {history && (
            <span className="ml-1 hidden group-hover:inline" onClick={() => showHistory()}>
              <ClockIcon className="h-[14px] w-[14px] text-gray-500 cursor-pointer" aria-hidden="true" />
            </span>
          )}
        </label>
      </div>
      {error && <p className="peer-invalid:visible text-rose-700 text-xs pl-1">{error}</p>}
    </div>
  )
}
