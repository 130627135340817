import Api from 'services/api'

const API_COMPANY_PRODUCTS_PROGRAMS = '/admin/loan/productsPrograms/:companyID'
const API_LOAN_PROGRAMS = '/admin/loan/loanPrograms/:companyID'
const API_LOAN_PROGRAMS_BY_PROGRAM_ID = '/admin/loan/loanPrograms/:companyID/:programID'
const API_LOAN_PROGRAMS_BY_ID = `${API_LOAN_PROGRAMS}/:id`

export const getCompanyProductsPrograms = (companyID: number) => {
  return Api.get(API_COMPANY_PRODUCTS_PROGRAMS, {}, { companyID })
}

export const getLoanPrograms = (companyID: number, data: Record<string, any>) => {
  return Api.post(API_LOAN_PROGRAMS, data, { companyID })
}

export const getLoanProgramsByProgramID = (companyID: number, programID: number, data: Record<string, any>) => {
  return Api.post(API_LOAN_PROGRAMS_BY_PROGRAM_ID, data, { companyID, programID })
}

export const submitLoanProgram = (companyID: number, id: number, data: Record<string, string>) => {
  if (!id) return Api.put(API_LOAN_PROGRAMS, data, { companyID })

  return Api.put(API_LOAN_PROGRAMS_BY_ID, data, { companyID, id })
}

export const deleteLoanProgram = (companyID: number, id: number) => {
  return Api.delete(API_LOAN_PROGRAMS_BY_ID, {}, { companyID, id })
}
