import { ILoan, itemCountPerPage } from 'config'

export const checkLastOneItem = (loanDetails: ILoan[], no: number) => {
  const isLastItem = loanDetails[loanDetails.length - 1].no === no

  if (isLastItem) {
    const itemsNumOfLastPage = loanDetails.length % itemCountPerPage

    if (itemsNumOfLastPage === 1) return true
    else return false
  } else return false
}
