import type { InputType, ModalProps } from 'config'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { createAccount, updateAccount } from 'services'
import { Modal } from 'stories/components'
import { AccountType } from 'stories/layouts'
import { InputValidate } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { type IUser, AccountTypeOptions, InvestorAccountTypeOptions } from '.'

export const NewDialog = (props: ModalProps & { item: IUser | null; companies: Record<number, string> }) => {
  const auth = useSelector((state: any) => state.auth)
  const isInvestor = [AccountType.INVESTOR, AccountType.ASSOCIATE].includes(auth.profile.userType)

  const [isLoading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<Record<string, InputType>>({
    name: {
      title: 'Full Name',
      inputType: 'text',
      error: '',
      required: true,
    },
    email: {
      title: 'Email',
      inputType: 'text',
      type: 'email',
      error: '',
      required: true,
    },
    userType: {
      title: 'User Type',
      inputType: 'select',
      required: true,
      options: isInvestor ? InvestorAccountTypeOptions : AccountTypeOptions,
      hasDefaultOption: true,
      value: '',
    },
    companyID: {
      title: 'Company',
      inputType: 'select',
      options: props.companies,
      hasDefaultOption: true,
      disabled: isInvestor,
      value: isInvestor ? auth.profile.companyID : '',
    },
  })

  useEffect(() => {
    if (props.item) {
      const newInputs = cloneDeep(inputs)
      for (let key in newInputs) newInputs[key].value = (props.item as any)[key]
      setInputs(newInputs)
    }
  }, [])

  const onChangeInputs = (key: string, value: string) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = value
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onOk = async () => {
    let hasError = false
    const newStats = cloneDeep(inputs)
    const inputData: Record<string, any> = {}
    for (const key in newStats) {
      const { value } = newStats[key]
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true

      if (value !== undefined) inputData[key] = value
    }
    if (hasError) {
      setInputs(newStats)
      return
    }

    setLoading(true)
    try {
      let newValues
      if (!props.item) newValues = await createAccount(inputData)
      else newValues = await updateAccount(props.item.id, inputData)

      props.onSubmit && props.onSubmit(newValues)
    } catch (e) {}
    setLoading(false)
  }

  return (
    <Modal
      isOpen
      title={!props.item ? 'Add new Account' : 'Update Account'}
      titleOkay="Ok"
      onOk={onOk}
      onClose={() => props.onClose()}
      loading={isLoading}
    >
      <div className={`text-gray-600 text-md w-144`}>
        {Object.keys(inputs).map((key, index) => {
          const input = inputs[key]
          return (
            <div key={index} className="input mb-3">
              <RenderInput input={input} Key={key} onChange={onChangeInputs} />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
