import {
  ArrowSmallRightIcon,
  BuildingOfficeIcon,
  ClipboardDocumentCheckIcon,
  Cog8ToothIcon,
  MagnifyingGlassIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { ICompany, InputType, IRateSheet, itemCountPerPage } from 'config'
import { Tooltip } from 'flowbite-react'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { createCompany, deleteCompany, generateSecretKey, getCompanies, updateCompany } from 'services'
import {
  addRatesheet,
  deleteRatesheet,
  getLoanIDs,
  getRatesheets,
  removeOldRatesheets,
  updateRatesheet,
  uploadExcelToS3Bucket,
} from 'services/apis/ratesheet'
import { Button, Input, Input2, Pagination } from 'stories/components'
import { AccountType } from 'stories/layouts'
import { confirm, InputConvert, isEmpty, openAuditLog } from 'utils'
import { useTitle } from 'utils/pageTitle'
import { RenderInput } from 'utils/RenderInput'
import { renderHeader } from 'utils/table'

import { defaultInputs } from './constants'
import { LicensedStates } from './LicensedStates/LicensedStates'
import { checkLastOneItem } from './logic'
import { NewCompanyModal } from './NewCompanyModal'
import { UpdateRatesheetDateModal } from './UpdateRatesheetDateModal'

export function RateSheet() {
  useTitle('Rate Sheets - RTLTrader')

  const auth = useSelector((state: any) => state.auth)
  const isInvestor = [AccountType.INVESTOR, AccountType.ASSOCIATE].includes(auth.profile.userType)

  const [filters, setFilters] = useState<Record<string, any>>({
    query: '',
    orderBy: 'loanId',
    orderDir: -1,
    pageNum: 0,
  })
  const [filterQuery, setFilterQuery] = useState(filters.query)
  const [companies, setCompanies] = useState<ICompany[]>([])
  const [company, setCompany] = useState<ICompany>()
  const [onNewCompany, setNewCompany] = useState<boolean>(false)
  const [onNewDate, setNewDate] = useState<boolean>(false)
  const [newDateLoanId, setNewDateLoanId] = useState<number>()
  const [newDateRatesheetDate, setNewDateRatesheetDate] = useState<string>('')
  const [loading, setLoading] = useState<string>('')
  const [editName, setEditName] = useState<boolean>(false)
  const [companyName, setCompanyName] = useState<string>('')
  const [inputValueChanged, setInputValueChanged] = useState<boolean>(false)
  const [totalRatesheets, setTotalRatesheets] = useState<number>(0)
  const [ratesheets, setRatesheets] = useState<IRateSheet[]>([])
  const [templateLoanID, setTemplateLoanID] = useState<number>()
  const [clearDate, setClearDate] = useState<string>()
  const [inputs, setInputs] = useState<Record<string, InputType>>(defaultInputs())

  const [searchParams, setQueryParams] = useSearchParams()
  const companyID = searchParams.get('company') || ''

  useEffect(() => {
    ;(async function getData() {
      setLoading('companies')

      const res = await getCompanies()

      if (res.length === 0) {
        setLoading('')
        return
      }
      setCompanies(res)

      let selected = res.find((item: ICompany) => item.name.toLowerCase().includes('commercial.nextres.com'))
      if (companyID) selected = res.find((item: ICompany) => item.id == Number(companyID))
      if (!selected) selected = res[0]

      setCompany(selected)
      setQueryParams({ company: String(selected.id) })
      setComapnyData(selected)

      await filterData(filters, selected)

      setLoading('')
    })()
  }, [])

  useEffect(() => {
    const timeOutId = setTimeout(() => isEmpty(loading) && onChangeFilter('pageNum', 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const setComapnyData = async (companyData: ICompany, newRatesheetId: number = NaN) => {
    let nInputs = cloneDeep(inputs)

    const res = await getLoanIDs(companyData.id)

    setTemplateLoanID(res.templateLoanId)
    nInputs.ratesheetId.options = res.loanIDs

    if (newRatesheetId) {
      nInputs.ratesheetId.value = newRatesheetId

      setCompany({ ...companyData, ratesheetId: newRatesheetId })
    } else nInputs.ratesheetId.value = companyData.ratesheetId

    setQueryParams({ company: String(companyData.id) })

    Object.keys(nInputs).forEach((key) => {
      if (key === 'ratesheetId') return
      nInputs[key].value = (companyData as any)[key]
    })
    setInputs(nInputs)
  }

  const filterData = async (
    filters: Record<string, any>,
    selectedCompany: ICompany | undefined = company,
    _pageNum: number = -1,
  ) => {
    if (!selectedCompany) return
    if (filters?.query) filters.query = filters.query.trim()
    if (_pageNum === -1) _pageNum = filters.pageNum

    setLoading('loadingRatesheets')

    const res = await getRatesheets(selectedCompany.id, {
      ...filters,
      skip: _pageNum * itemCountPerPage,
      count: itemCountPerPage,
    })
    setRatesheets(res.ratesheetData)
    setTotalRatesheets(res.totalNum)

    setLoading('')
  }

  const createNewCompany = async (name: string) => {
    if (!name) {
      toast('Company Name is required!', { type: 'error' })
      return
    }

    setLoading('addCompany')
    try {
      const newCompany = await createCompany(name)

      if (!!companies.length) setCompanies([...companies, newCompany])
      else setCompanies([newCompany])

      setCompany(newCompany)
      setRatesheets([])
      setTotalRatesheets(0)

      await setComapnyData(newCompany)

      toast('Successfully created!', { type: 'success' })
      setNewCompany(false)
    } catch (error) {
      console.log(error)
    }
    setLoading('')
  }

  const onDelete = async () => {
    if (!company) return

    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this company?
        <br />
        <span className="text-gray-600 text-base">Company name: {company?.name}</span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    setLoading('companies')

    try {
      await deleteCompany(company.id)

      const nCompanies = companies?.filter((item) => item.id !== company.id)

      if (!!nCompanies.length) {
        await filterData(filters, nCompanies[nCompanies.length - 1])
        setComapnyData(nCompanies[nCompanies.length - 1])
      }

      setCompanies(nCompanies)
      setCompany(!!nCompanies.length ? nCompanies[nCompanies.length - 1] : undefined)

      toast('Successfully deleted!', { type: 'info' })
    } catch (error) {
      console.log(error)
    }

    setLoading('')
  }

  const onUpdateStates = async (states: string[]) => {
    if (!companies || !company) return

    setLoading('editCompany')

    const data = {
      key: 'states',
      value: states,
    }

    await updateCompany(data, company.id)

    const nCompanies = companies.map((item) => {
      if (item.id === company.id) {
        return { ...item, states: states }
      } else return item
    })

    setCompany({ ...company, states: states })
    setCompanies(nCompanies)

    toast('States updated!', { type: 'info' })
    setLoading('')
  }

  const onUpdateCompany = async (key: string, newInputs: Record<string, any> = inputs) => {
    if (!company || !companies) return

    try {
      const reqBody = {
        key: key,
        value:
          key === 'name' ? companyName : key === 'ratesheetId' ? Number(newInputs[key].value) : newInputs[key].value,
      }

      await updateCompany(reqBody, company.id)

      let nCompanies: ICompany[]

      if (key === 'name') {
        nCompanies = companies.map((item) => {
          if (item.id === company.id) {
            return { ...item, name: companyName }
          } else return item
        })

        setCompany({ ...company, name: companyName })
        setEditName(false)
      } else {
        nCompanies = companies.map((item) => {
          if (item.id === company.id) {
            return { ...item, [key]: newInputs[key].value }
          } else return item
        })

        setCompany({ ...company, [key]: newInputs[key].value })
      }

      setCompanies(nCompanies)
      toast(`Company ${key} changed!`, { type: 'info' })
    } catch (error) {
      console.log(error)
    }
    setLoading('')
  }

  const onChangeInputs = (key: string, value: any) => {
    let newInputs = cloneDeep(inputs)

    newInputs[key].value = InputConvert(newInputs[key], value)
    newInputs[key].error = ''

    setInputs(newInputs)
    setInputValueChanged(true)
  }

  const showHistory = (key: string) => {
    if (!company) return

    const options = {
      table: 'Company',
      key: key,
      id: company.id,
    }
    openAuditLog(options)
  }

  const onBlur = async (key: string) => {
    if (!inputValueChanged) return

    setInputValueChanged(false)

    await onUpdateCompany(key)
  }

  const onSelectCompany = async (selectedCompany: ICompany) => {
    setCompany(selectedCompany)
    setComapnyData(selectedCompany)
    await filterData(filters, selectedCompany)
    setEditName(false)
  }

  const onGenerate = async () => {
    if (!company) return

    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        <p>
          Are you sure want to regenerate the Secret Key? <br />
          You need to inform to <span className="text-blue-800 text-xl font-semibold">{company?.name}</span> after
          regenerate!
        </p>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    setLoading('editCompany')

    const newSecretKey = await generateSecretKey(company.id)
    let nInputs = cloneDeep(inputs)
    nInputs.secretKey.value = newSecretKey
    setInputs(nInputs)
    setLoading('')
  }

  const onCopyToClipboard = () => {
    try {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(inputs.secretKey.value)
      } else {
        // text area method
        let textArea = document.createElement('textarea')
        textArea.value = inputs.secretKey.value
        // make the textarea out of viewport
        textArea.style.position = 'fixed'
        textArea.style.left = '-999999px'
        textArea.style.top = '-999999px'
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        document.execCommand('copy')
        textArea.remove()
      }

      toast('SecretKey Copied!', { type: 'info' })
    } catch (error) {
      console.log('copy error', error)
    }
  }

  const onAddRatesheet = async () => {
    if (!company) return

    const confirmMsg = (
      <div className="mb-4 text-[18px]">
        <p>Are you want to add a new rate sheet?</p>
      </div>
    )

    const rlt = await confirm(confirmMsg)

    if (!rlt) return

    setLoading('addRatesheet')

    try {
      const newRatesheet = await addRatesheet(company.id)

      await filterData(filters, company)

      const content = (
        <div className="text-gray-400 mb-4 text-[18px]">
          <p>You have just added a new Rate Sheet.</p>
          <p>
            Are you okay to change the Pricing Rate Sheet ID from{' '}
            <span className="text-blue-800 text-xl font-semibold">{company.ratesheetId}</span> to{' '}
            <span className="text-blue-800 text-xl font-semibold">{newRatesheet.loanId}</span> ?
          </p>
        </div>
      )

      const result = await confirm(content)

      if (!result) {
        setLoading('')
        return
      }

      let nInputs = cloneDeep(inputs)

      const loanIDs = nInputs['ratesheetId'].options
      loanIDs?.unshift(newRatesheet.loanId)

      nInputs['ratesheetId'].value = newRatesheet.loanId
      nInputs['ratesheetId'].options = loanIDs

      setInputs(nInputs)

      await onUpdateCompany('ratesheetId', nInputs)

      if (!isEmpty(company.apiUrl)) {
        const res = await uploadExcelToS3Bucket(Number(newRatesheet.loanId))
        if (res) toast(`Successfully added!`, { type: 'info' })
      }
    } catch (error) {
      console.log(error)
    }

    setLoading('')
  }

  const onTrashRatesheet = async (loanId: number) => {
    if (!company) return

    try {
      const content = (
        <div className="text-gray-400 mb-4 text-[18px]">
          Are you sure want to delete?
          <br />
          <span className="text-gray-600 text-base">Rate Sheet ID: {loanId}</span>
        </div>
      )

      const result = await confirm(content)

      if (!result) return

      const newRatesheetId = await deleteRatesheet(loanId)

      if (Number(company.ratesheetId) !== Number(newRatesheetId)) await setComapnyData(company, newRatesheetId)

      const newFilters = cloneDeep(filters)
      const isLastItemOfLastPage = checkLastOneItem(ratesheets, loanId)

      if (isLastItemOfLastPage && newFilters.pageNum !== 0) {
        newFilters.pageNum -= 1
        setFilters(newFilters)
      }

      await filterData(newFilters, company)

      toast('Successfully deleted!', { type: 'info' })
    } catch (error) {
      console.log(error)
    }
  }

  const onPageNavigate = (num: number) => {
    onChangeFilter('pageNum', num)
  }

  const onChangeFilter = (
    key: 'query' | 'envelopeStatus' | 'status' | 'orderBy' | 'orderDir' | 'pageNum' | 'paidOff',
    value: any,
    refetch = true,
  ) => {
    if (!isEmpty(loading)) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else if (refetch) filterData(newFilters)
  }

  const updateRatesheetDate = async (date: string) => {
    if (!newDateLoanId) return

    try {
      setLoading('editRatesheetDate')

      const newDate = {
        ratesheetDate: date,
      }

      await updateRatesheet(newDateLoanId, newDate)

      let nRatesheets = ratesheets.map((item) => {
        if (item.loanId === newDateLoanId) {
          return { ...item, ratesheetDate: newDate.ratesheetDate }
        } else return item
      })

      setRatesheets(nRatesheets)
      setNewDate(false)

      toast(`${newDateLoanId} - Rate Sheet Date has been updated!`, { type: 'info' })
    } catch (error) {
      console.log(error)
    }
    setLoading('')
  }

  const onRemoveOldRatesheets = async () => {
    if (!company) return

    if (isEmpty(clearDate)) {
      toast(`Remove Date is required!`, { type: 'error' })

      return
    }

    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to remove the old ratesheets?
        <br />
        <span className="text-gray-600 text-base">Remove Date: {clearDate}</span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    try {
      const data = {
        companyId: company.id,
        date: clearDate,
      }

      setLoading('loadingRatesheets')

      const res = await removeOldRatesheets(data)

      toast(`${res} old ratesheets has removed!`, { type: 'info' })

      const newFilters = cloneDeep(filters)
      newFilters.pageNum = 0
      setFilters(newFilters)

      await filterData(newFilters)
    } catch (error) {
      setLoading('')
    }
  }

  const renderCompanyInfo = useMemo(() => {
    const newInputs = cloneDeep(inputs)
    newInputs.apiUrl.disabled = isInvestor

    return (
      <div className="grid gap-4 md:grid-cols-2 grid-cols-1">
        {Object.keys(newInputs).map((key) => {
          let input = newInputs[key]
          let additionalElements = null

          if (key === 'secretKey') {
            additionalElements = (
              <span className="contents">
                {!isInvestor && (
                  <Tooltip content="Generate">
                    <Cog8ToothIcon className="w-4 h-4 cursor-pointer" onClick={onGenerate} />
                  </Tooltip>
                )}
                <Tooltip content="Copy">
                  <ClipboardDocumentCheckIcon className="w-4 h-4 cursor-pointer" onClick={onCopyToClipboard} />
                </Tooltip>
              </span>
            )
          }
          return (
            <div className={`input md:col-span-${input.span || 1}`} key={key}>
              <RenderInput
                input={{ ...input, additionalElements }}
                Key={key}
                onChange={onChangeInputs}
                onBlur={onBlur}
                showHistory={showHistory}
              />
            </div>
          )
        })}
      </div>
    )
  }, [inputs, isInvestor])

  const _renderHeader = (title: string, sortable: boolean = false, key: string) => {
    return renderHeader({
      title,
      index: 0,
      key,
      sortable,
      onSort: (key: string, sortOrder: number) => {
        const newFilters = Object.assign({}, filters)
        newFilters['orderBy'] = key
        newFilters['orderDir'] = sortOrder
        setFilters(newFilters)
        filterData(newFilters)
      },
      sortOrder: filters.orderBy == key ? filters.orderDir : 0,
    })
  }

  return (
    <div className="py-6 px-2">
      <div className="max-w-screen-2xl m-auto grid grid-cols-12 gap-6 px-2">
        <div className="relative col-span-12 md:col-span-3 shrink-0 p-4 bg-white shadow1 rounded">
          <LayoutLoading show={!!loading} />
          <ul className="flex flex-col">
            {!!companies.length ? (
              companies.map((item, index) => {
                return (
                  <li key={index} onClick={async () => await onSelectCompany(item)} className="border-b py-2">
                    <p
                      className={`hover:underline cursor-pointer ${
                        company?.name === item.name ? 'border px-4 py-1 bg-zinc-100' : 'py-1'
                      }`}
                    >
                      {index + 1 + '. ' + item.name}
                    </p>
                  </li>
                )
              })
            ) : (
              <div className="py-6 flex justify-center items-center gap-4">
                <BuildingOfficeIcon className="w-7 h-7" />
                <span>No Company</span>
              </div>
            )}
          </ul>

          {!isInvestor && (
            <Button className="w-full mt-4 flex justify-center" color="gray" onClick={() => setNewCompany(true)}>
              <div className="flex gap-1 items-center">
                <PlusIcon className="w-5 h-5" />
                Add Company
              </div>
            </Button>
          )}
        </div>
        <div className="col-span-12 md:col-span-9">
          {company && (
            <div className="relative bg-white p-4 rounded shadow1 mb-5">
              <LayoutLoading show={['editCompany', 'loadingRatesheets'].indexOf(loading) !== -1} />
              <div>
                <div className="flex justify-between items-center mb-4">
                  {editName ? (
                    <div className="flex items-center">
                      <div className="mr-2 mt-4 w-[300px]">
                        <Input title="Company Name" value={companyName} onChange={(value) => setCompanyName(value)} />
                      </div>
                      <div className="mb-2 mt-4">
                        <Button
                          loading={loading === 'changeCompanyName'}
                          className="mr-2 text-[14px] pt-1 pb-1"
                          onClick={() => onUpdateCompany('name')}
                        >
                          Save
                        </Button>
                        <Button className="text-[14px] pt-1 pb-1" color="white" onClick={() => setEditName(false)}>
                          Cancel
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <h1 className="text-2xl font-semibold mr-2">{company.name}</h1>
                      <div
                        className="p-1 hover-shadow1 cursor-pointer rounded"
                        onClick={() => {
                          setEditName(true)
                          setCompanyName(company?.name || '')
                        }}
                      >
                        <PencilSquareIcon className="w-5 h-5" />
                      </div>
                    </div>
                  )}
                  {!isInvestor && (
                    <div className="p-1 hover-shadow1 cursor-pointer rounded" onClick={onDelete}>
                      <TrashIcon className="w-5 h-5 text-red-600" />
                    </div>
                  )}
                </div>

                {renderCompanyInfo}

                {auth.profile.email === 'charlesadmin@gmail.com' && (
                  <div className="mt-6">
                    <div className="text-lg font-bold">Clear Old Rate Sheets</div>
                    <hr className="mb-4" />

                    <div className="flex gap-4 items-end">
                      <div className="mb-[-16px]">
                        <Input
                          type="date"
                          title="Remove Date"
                          key="clearDate"
                          value={clearDate}
                          onChange={(value) => setClearDate(value)}
                        />
                      </div>

                      <Button className="mb-0 pt-[5px] pb-[5px]" color="gray" onClick={onRemoveOldRatesheets}>
                        <span className="flex gap-2 items-center ">
                          <TrashIcon className="w-5 h-5" />
                          Remove
                        </span>
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {company && <LicensedStates company={company} loading={loading} onUpdateStates={onUpdateStates} />}

          {company && (
            <div className="relative bg-white p-4 rounded shadow1">
              <LayoutLoading show={loading === 'loadingRatesheets'} />
              <div className="flex items-center gap-4 justify-between mb-4 mt-2">
                <div className="w-[300px]">
                  <Input2
                    type="search"
                    title="Search"
                    hasIcon
                    icon={<MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />}
                    value={filters.query}
                    onChange={(value) => onChangeFilter('query', value)}
                  />
                </div>
                <div className="flex justify-center">
                  <Link to={`/ratesheet/${templateLoanID}?company=${companyID}`}>
                    <Button>
                      <div className="flex gap-1 items-center">
                        <ArrowSmallRightIcon className="w-4 h-4" />
                        Go to Template
                      </div>
                    </Button>
                  </Link>
                  <Button loading={loading === 'addRatesheet'} onClick={onAddRatesheet}>
                    <div className="flex gap-1 items-center">
                      <PlusIcon className="w-4 h-4"></PlusIcon>
                      Add Rate Sheet
                    </div>
                  </Button>
                </div>
              </div>

              <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      {_renderHeader('Rate Sheet ID', true, 'loanId')}
                      {_renderHeader('Rate Sheet Date', true, 'ratesheetDate')}
                      {_renderHeader('Created By', true, 'createdBy')}
                      {_renderHeader('Action', false, 'action')}
                    </tr>
                  </thead>
                  <tbody>
                    {ratesheets &&
                      ratesheets.map((item, index: number) => {
                        return (
                          <tr className={`border-b ${index % 2 ? 'bg-slate-50' : 'bg-white'}`} key={`${index}`}>
                            <td
                              scope="row"
                              className="px-3 py-3 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                            >
                              <Link
                                to={`/ratesheet/${item.loanId}?company=${company.id}`}
                                className="font-variation-settings-600 text-shade-blue hover:cursor-pointer hover:underline"
                              >
                                {item.loanId}
                              </Link>
                            </td>
                            <td className="px-3">
                              <div className="h-full flex items-center">
                                <span className="mr-2">{item.ratesheetDate.slice(0, 10)}</span>
                                <PencilSquareIcon
                                  className="text-shade-blue w-6 h-6 hover-shadow1 p-1 rounded hover:cursor-pointer"
                                  onClick={() => {
                                    setNewDate(true)
                                    setNewDateLoanId(item.loanId)
                                    setNewDateRatesheetDate(item.ratesheetDate)
                                  }}
                                />
                              </div>
                            </td>
                            <td className="px-3">{item.createdBy}</td>
                            <td className="">
                              <span className="flex px-3">
                                <Link
                                  to={`/ratesheet/${item.loanId}?company=${company.id}`}
                                  className="text-shade-blue p-1 hover-shadow1 cursor-pointer rounded mr-1"
                                >
                                  <PencilSquareIcon className="w-4 h-4" />
                                </Link>
                                <span
                                  className="text-red-600 p-1 hover-shadow1 cursor-pointer rounded"
                                  onClick={() => onTrashRatesheet(item.loanId)}
                                >
                                  <TrashIcon className="w-4 h-4" />
                                </span>
                              </span>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>

                <div className="flex justify-end">
                  <Pagination
                    totalCount={totalRatesheets}
                    itemCountPerPage={itemCountPerPage}
                    onNavigate={onPageNavigate}
                    pageNum={filters.pageNum}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {onNewCompany && (
        <NewCompanyModal
          isOpen={onNewCompany}
          loading={loading}
          onClose={() => setNewCompany(false)}
          onSubmit={createNewCompany}
        />
      )}
      {onNewDate && (
        <UpdateRatesheetDateModal
          isOpen={onNewDate}
          loanId={newDateLoanId}
          loading={loading}
          ratesheetDate={newDateRatesheetDate}
          onClose={() => setNewDate(false)}
          onSubmit={updateRatesheetDate}
        />
      )}
    </div>
  )
}
