import { TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { PlainInput } from 'components/PlainInput'
import type { IFicoRemove, ILoanAmountRange, ILtvLimitsData, ILTVs, IRange, ITableValue } from 'config'
import { fromToNumberOptions } from 'pages/PricingPlayground/logic'
import { LoadingContext, LoadingStatus, ModalSetting, ProgramContext, ProgramTypes } from 'pages/RateSheetOverview'
import { convertNullValue } from 'pages/RateSheetOverview/ExcelSheets/CommercialOnlyProgrammed/logic'
import { useContext, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Select } from 'stories/components'
import { confirm, getPrice3decimal, isEmpty } from 'utils'

import { AddNewFico } from './Modals/AddNewFico'
import { UpdateLoanAmountModal } from './Modals/UpdateLoanAmountModal'

interface Props {
  restrictionKey: string
  onSave: (restriction: Record<string, ILTVs[] | undefined>) => {}
  onCancel: () => {}
}

export function RestrictionTable({ restrictionKey, onSave, onCancel }: Props) {
  const { selectedProgram, setSelectedProgram } = useContext(ProgramContext)
  const loading = useContext(LoadingContext)

  const [isEditingRestriction, setEditingRestriction] = useState(false)
  const [modal, setModal] = useState('')
  const [loanAmountRange, setLoanAmountRange] = useState<ILoanAmountRange>()
  const [data, setData] = useState<ILtvLimitsData>()
  const [ficoIndex, setFicoIndex] = useState<number>()
  const [dscrKey, setDscrKey] = useState('ltvs')

  const ltvLimitOptions: string[] = fromToNumberOptions(0, 100).concat(['N/A'])

  useEffect(() => {
    setData(selectedProgram.Restriction.LtvLimits[restrictionKey])
  }, [selectedProgram])

  const onUpdateLoanAmountRange = (rangeProps: ILoanAmountRange) => {
    setLoanAmountRange(rangeProps)
    setModal(ModalSetting.UPDATE_LOAN_AMOUNT_RANGE)
  }

  const onNewLoanAmountModal = (key: 'ltvs' | 'bigDscrLtvs') => {
    if (!data) return

    const rangeProps: ILoanAmountRange = {
      from: !data[key]![0].data.length ? 0 : data[key]![0].data[data[key]![0].data.length - 1].loanAmount.to + 1,
      to: NaN,
      index: -1,
      status: 'add',
      key: key,
    }

    onUpdateLoanAmountRange(rangeProps)
  }

  const updateLoanAmountRange = async (newLoanAmount: ILoanAmountRange) => {
    if (!data) return

    const { index, from, to, key = 'ltvs' } = newLoanAmount

    if (from > to) {
      toast("'To' should be greater than 'From'.", { type: 'error' })
      return
    }

    const restriction = (data as any)[key]

    restriction.forEach((item: ILTVs) => {
      if (index === -1) {
        item.data.push({
          loanAmount: {
            from,
            to,
          },
          monthsReserve: 0,
          ficos: [0],
          purchaseRT: [NaN],
          nocashout: [NaN],
          cashout: [NaN],
        })
      } else {
        item.data[index].loanAmount.from = from
        item.data[index].loanAmount.to = to

        if (index < item.data.length - 1) item.data[index + 1].loanAmount.from = to + 1
      }
    })

    const updatedProgram = cloneDeep(selectedProgram)

    if (!updatedProgram) return

    updatedProgram.Restriction.LtvLimits[restrictionKey][key] = restriction

    setData(updatedProgram.Restriction.LtvLimits[restrictionKey])
    setSelectedProgram(updatedProgram)
    setModal('')
  }

  const onChangeTableValue = async (newData: ITableValue) => {
    if (!data) return

    let { index, index1, index2, key, value, subkey = 'ltvs' } = newData

    value = Number(value)

    const restriction = (data as any)[subkey]
    const arrValues = (data as any)[subkey][index2].data[index]

    if (key === 'monthsReserve') {
      arrValues[key] = value
    } else if (key === 'ficos') {
      const upValue = index1 == 0 ? -1 : arrValues[key][index1 - 1]
      const downValue = index1 == arrValues[key].length - 1 ? -1 : arrValues[key][index1 + 1]

      let result = value

      if (upValue != -1 && upValue <= value) result = upValue - 1
      else if (upValue != -1 && value <= downValue) result = downValue + 1

      arrValues[key][index1] = result
    } else if (['purchaseRT', 'nocashout', 'cashout'].includes(key)) (arrValues[key] as any)[index1] = value

    restriction[index2].data[index] = arrValues

    const updatedProgram = cloneDeep(selectedProgram)

    if (!updatedProgram) return

    updatedProgram.Restriction.LtvLimits[restrictionKey][subkey] = restriction

    setData(updatedProgram.Restriction.LtvLimits[restrictionKey])
    setSelectedProgram(updatedProgram)
    setModal('')
  }

  const onRemoveFico = async (ficoIndexes: IFicoRemove) => {
    if (!data) return
    const { index, index1, value, key = 'ltvs' } = ficoIndexes

    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this FICO?
        <br />
        <span className="text-gray-600 text-base">FICO: {value}</span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const restriction = cloneDeep((data as any)[key])

    restriction.forEach((item: ILTVs) => {
      item.data[index].ficos.splice(index1, 1)
      item.data[index].purchaseRT.splice(index1, 1)
      item.data[index].nocashout.splice(index1, 1)
      item.data[index].cashout.splice(index1, 1)
    })

    const updatedProgram = cloneDeep(selectedProgram)

    if (!updatedProgram) return

    updatedProgram.Restriction.LtvLimits[restrictionKey][key] = restriction

    setData(updatedProgram.Restriction.LtvLimits[restrictionKey])
    setSelectedProgram(updatedProgram)
  }

  const onRemoveLoanAmountRange = async (index: number, loanAmount: IRange, key: 'ltvs' | 'bigDscrLtvs') => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this Loan Amount range?
        <br />
        <p className="text-gray-600 text-base">
          {getPrice3decimal(loanAmount.from)} - {getPrice3decimal(loanAmount.to)}
        </p>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const restriction = cloneDeep((data as any)[key])

    restriction?.forEach((item: ILTVs) => item.data.splice(index, 1))

    const updatedProgram = cloneDeep(selectedProgram)

    if (!updatedProgram) return

    updatedProgram.Restriction.LtvLimits[restrictionKey][key] = restriction

    setData(updatedProgram.Restriction.LtvLimits[restrictionKey])
    setSelectedProgram(updatedProgram)
  }

  const addNewFico = async (value: number, index: number, key: 'ltvs' | 'bigDscrLtvs') => {
    const restriction = cloneDeep((data as any)[key])

    if (!restriction) return

    const ficos = restriction[0].data[index].ficos as number[]
    let targetIndex: number
    ficos.forEach((item, idx) => {
      if (idx === 0 && value > item) {
        targetIndex = -1
        return
      } else if (value < item && value > ficos[idx + 1]) {
        targetIndex = idx + 1
        return
      }
    })

    if (isEmpty(value)) {
      toast('FICO value is required!', { type: 'error' })
      return
    } else if (ficos.includes(value)) {
      toast(`The same value already exists!`, { type: 'error' })
      return
    }

    restriction.forEach((item: ILTVs) => {
      if (targetIndex === -1) {
        item.data[index].ficos.unshift(value)
        item.data[index].cashout.unshift(NaN)
        item.data[index].nocashout.unshift(NaN)
        item.data[index].purchaseRT.unshift(NaN)
      } else if (!isEmpty(targetIndex) && targetIndex !== -1) {
        item.data[index].ficos.splice(targetIndex, 0, value)
        item.data[index].cashout.splice(targetIndex, 0, NaN)
        item.data[index].nocashout.splice(targetIndex, 0, NaN)
        item.data[index].purchaseRT.splice(targetIndex, 0, NaN)
      } else {
        item.data[index].ficos.push(value)
        item.data[index].cashout.push(NaN)
        item.data[index].nocashout.push(NaN)
        item.data[index].purchaseRT.push(NaN)
      }
    })

    const updatedProgram = cloneDeep(selectedProgram)

    if (!updatedProgram) return

    updatedProgram.Restriction.LtvLimits[restrictionKey][key] = restriction

    setData(updatedProgram.Restriction.LtvLimits[restrictionKey])
    setSelectedProgram(updatedProgram)
    setModal('')
  }

  const renderTable = useMemo(() => {
    if (!data) return <></>

    const isBPI = selectedProgram.Type === ProgramTypes.BEYOND_PRIME_INVESTOR
    const isBpiDscr = isBPI && restrictionKey === 'DSCR'
    const eligibleOccupancy = isBPI ? 'Non Owner Occupied' : ''

    return (
      <div>
        {isBpiDscr && data?.bigDscrLtvs && !!data.bigDscrLtvs.length && (
          <table className="mb-4 w-full text-gray-700">
            <thead className="bg-gray-50">
              <tr>
                <th colSpan={2 + data.bigDscrLtvs.length * 4} className="py-2 border">{`DSCR >= 1`}</th>
              </tr>
              <tr>
                <th colSpan={2} className="py-2 border">
                  {eligibleOccupancy}
                </th>
                {data.bigDscrLtvs.map((item, index: number) => (
                  <th key={index} colSpan={4} className="border">
                    {item.title}
                  </th>
                ))}
              </tr>

              <tr className="text-sm">
                {data.bigDscrLtvs.map((item, index: number) => {
                  const fileds = ['Loan Amount', 'FICO', 'Reserves', 'Purchase', 'No-Cashout', 'Cashout']

                  return fileds.map((field, id: number) => {
                    if (id == 0 || id == 1) {
                      if (index === 0) {
                        return (
                          <th key={`${index}-${id}`} className="py-2 border">
                            {field}
                          </th>
                        )
                      }
                    } else {
                      return (
                        <th key={`${index}-${id}`} className="py-2 border">
                          {field}
                        </th>
                      )
                    }
                  })
                })}
              </tr>
            </thead>

            <tbody className="text-sm text-center">
              {data.bigDscrLtvs[0].data.map((item, index: number) => {
                if (!data?.bigDscrLtvs) return <></>

                const dataLen = data.bigDscrLtvs[0].data.length
                const ficosLength = item.ficos.length + (isEditingRestriction ? 1 : 0)
                const rlt: JSX.Element[] = []
                item.ficos.map((fico, index1: number) => {
                  if (!data?.bigDscrLtvs) return <></>

                  rlt.push(
                    <tr key={`${index}-${index1}`} className={`${index % 2 === 1 ? 'bg-gray-50' : ''}`}>
                      {data.bigDscrLtvs.map((val, index2: number) => {
                        if (!data?.bigDscrLtvs) return <></>

                        const rlt1 = []
                        if (index2 === 0) {
                          if (index1 === 0) {
                            rlt1.push(
                              <td key={`${index}-${index1}-${index2}-1`} rowSpan={ficosLength} className="border">
                                {getPrice3decimal(item.loanAmount.from)}-{getPrice3decimal(item.loanAmount.to)}
                                {isEditingRestriction && (
                                  <>
                                    <br />
                                    <br />
                                    <a
                                      className="underline hover:cursor-pointer"
                                      onClick={() =>
                                        onUpdateLoanAmountRange({
                                          index,
                                          from: item.loanAmount.from,
                                          to: item.loanAmount.to,
                                          key: 'bigDscrLtvs',
                                        })
                                      }
                                    >
                                      Update Range
                                    </a>
                                    <br />
                                    {dataLen - 1 === index && (
                                      <a
                                        className="underline text-red-500 hover:cursor-pointer"
                                        onClick={() => onRemoveLoanAmountRange(index, item.loanAmount, 'bigDscrLtvs')}
                                      >
                                        Remove
                                      </a>
                                    )}
                                  </>
                                )}
                              </td>,
                            )
                          }
                          rlt1.push(
                            <td key={`${index}-${index1}-${index2}-2`} className="border w-[120px]">
                              {isEditingRestriction ? (
                                <div className="flex justify-center items-center">
                                  <PlainInput
                                    type="number"
                                    value={fico}
                                    content={fico}
                                    className="w-full"
                                    onChange={(value: string) =>
                                      onChangeTableValue({
                                        subkey: 'bigDscrLtvs',
                                        index,
                                        index1,
                                        index2,
                                        key: 'ficos',
                                        value: value,
                                      })
                                    }
                                  />
                                  {ficosLength > 2 && (
                                    <div className="p-1 hover-shadow1 hover:cursor-pointer rounded mx-2">
                                      <TrashIcon
                                        className="text-red-600 w-3.5 h-3.5"
                                        onClick={() => onRemoveFico({ index, index1, value: fico, key: 'bigDscrLtvs' })}
                                      />
                                    </div>
                                  )}
                                </div>
                              ) : (
                                fico
                              )}
                            </td>,
                          )
                        }

                        if (index1 === 0) {
                          const value = data.bigDscrLtvs[index2].data[index].monthsReserve
                          rlt1.push(
                            <td
                              key={`${index}-${index1}-${index2}-3`}
                              rowSpan={ficosLength}
                              className="border w-[80px]"
                            >
                              {isEditingRestriction ? (
                                <div className="flex justify-center">
                                  <PlainInput
                                    type="number"
                                    value={value}
                                    content={value}
                                    className="w-full"
                                    onChange={(value: string) =>
                                      onChangeTableValue({
                                        subkey: 'bigDscrLtvs',
                                        index,
                                        index1,
                                        index2,
                                        key: 'monthsReserve',
                                        value: value,
                                      })
                                    }
                                  />
                                </div>
                              ) : (
                                `${value}Mo`
                              )}
                            </td>,
                          )
                        }

                        const purchaseRTValue = convertNullValue(
                          data.bigDscrLtvs[index2].data[index].purchaseRT[index1],
                        )

                        rlt1.push(
                          <td key={`${index}-${index1}-${index2}-4`} className="border">
                            {isEditingRestriction ? (
                              <Select
                                id="purchaseRT"
                                value={purchaseRTValue.toString()}
                                options={ltvLimitOptions}
                                onChange={(value: string) =>
                                  onChangeTableValue({
                                    subkey: 'bigDscrLtvs',
                                    index,
                                    index1,
                                    index2,
                                    key: 'purchaseRT',
                                    value: value,
                                  })
                                }
                                className=" mb-[-20px]"
                              />
                            ) : (
                              purchaseRTValue
                            )}
                          </td>,
                        )

                        const noCashOutValue = convertNullValue(data.bigDscrLtvs[index2].data[index].nocashout[index1])

                        rlt1.push(
                          <td key={`${index}-${index1}-${index2}-5`} className="border">
                            {isEditingRestriction ? (
                              <Select
                                id="nocashout"
                                value={noCashOutValue.toString()}
                                options={ltvLimitOptions}
                                onChange={(value: string) =>
                                  onChangeTableValue({
                                    subkey: 'bigDscrLtvs',
                                    index,
                                    index1,
                                    index2,
                                    key: 'nocashout',
                                    value: value,
                                  })
                                }
                                className=" mb-[-20px]"
                              />
                            ) : (
                              noCashOutValue
                            )}
                          </td>,
                        )

                        const cashoutValue = convertNullValue(data.bigDscrLtvs[index2].data[index].cashout[index1])

                        rlt1.push(
                          <td key={`${index}-${index1}-${index2}-6`} className="border">
                            {isEditingRestriction ? (
                              <Select
                                id="cashout"
                                value={cashoutValue.toString()}
                                options={ltvLimitOptions}
                                onChange={(value: string) =>
                                  onChangeTableValue({
                                    subkey: 'bigDscrLtvs',
                                    index,
                                    index1,
                                    index2,
                                    key: 'cashout',
                                    value: value,
                                  })
                                }
                                className=" mb-[-20px]"
                              />
                            ) : (
                              cashoutValue
                            )}
                          </td>,
                        )

                        return rlt1
                      })}
                    </tr>,
                  )
                })

                isEditingRestriction &&
                  rlt.push(
                    <tr key={`${index}-new`} className={`${index % 2 === 1 ? 'bg-gray-50' : ''}`}>
                      <td className="border">
                        <a
                          className="underline hover:cursor-pointer"
                          onClick={() => {
                            setFicoIndex(index)
                            setDscrKey('bigDscrLtvs')
                            setModal(ModalSetting.ADD_NEW_FICO)
                          }}
                        >
                          + New FICO
                        </a>
                      </td>
                      <td className="border" colSpan={data.bigDscrLtvs.length * 4 - 1}></td>
                    </tr>,
                  )
                return rlt
              })}

              {isEditingRestriction && (
                <tr>
                  <td className="border">
                    <a className="underline hover:cursor-pointer" onClick={() => onNewLoanAmountModal('bigDscrLtvs')}>
                      + New Range
                    </a>
                  </td>
                  <td className="border" colSpan={9}></td>
                </tr>
              )}
            </tbody>
          </table>
        )}

        <table className="mb-4 w-full text-gray-700">
          <thead className="bg-gray-50">
            {isBpiDscr && (
              <tr>
                <th colSpan={2 + data.ltvs.length * 4} className="py-2 border">{`DSCR < 1`}</th>
              </tr>
            )}
            <tr>
              <th colSpan={2} className="py-2 border">
                {isBpiDscr ? eligibleOccupancy : data.occupancy}
              </th>
              {data.ltvs.map((item, index: number) => (
                <th key={index} colSpan={4} className="border">
                  {item.title}
                </th>
              ))}
            </tr>

            <tr className="text-sm">
              {data.ltvs.map((item, index: number) => {
                const fileds = ['Loan Amount', 'FICO', 'Reserves', 'Purchase', 'No-Cashout', 'Cashout']

                return fileds.map((field, id: number) => {
                  if (id == 0 || id == 1) {
                    if (index === 0) {
                      return (
                        <th key={`${index}-${id}`} className="py-2 border">
                          {field}
                        </th>
                      )
                    }
                  } else {
                    return (
                      <th key={`${index}-${id}`} className="py-2 border">
                        {field}
                      </th>
                    )
                  }
                })
              })}
            </tr>
          </thead>

          <tbody className="text-sm text-center">
            {data.ltvs[0].data.map((item, index: number) => {
              const dataLen = data.ltvs[0].data.length
              const ficosLength = item.ficos.length + (isEditingRestriction ? 1 : 0)
              const rlt: JSX.Element[] = []
              item.ficos.map((fico, index1: number) => {
                rlt.push(
                  <tr key={`${index}-${index1}`} className={`${index % 2 === 1 ? 'bg-gray-50' : ''}`}>
                    {data.ltvs.map((val, index2: number) => {
                      const rlt1 = []
                      if (index2 === 0) {
                        if (index1 === 0) {
                          rlt1.push(
                            <td key={`${index}-${index1}-${index2}-1`} rowSpan={ficosLength} className="border">
                              {getPrice3decimal(item.loanAmount.from)}-{getPrice3decimal(item.loanAmount.to)}
                              {isEditingRestriction && (
                                <>
                                  <br />
                                  <br />
                                  <a
                                    className="underline hover:cursor-pointer"
                                    onClick={() =>
                                      onUpdateLoanAmountRange({
                                        index,
                                        from: item.loanAmount.from,
                                        to: item.loanAmount.to,
                                      })
                                    }
                                  >
                                    Update Range
                                  </a>
                                  <br />
                                  {dataLen - 1 === index && (
                                    <a
                                      className="underline text-red-500 hover:cursor-pointer"
                                      onClick={() => onRemoveLoanAmountRange(index, item.loanAmount, 'ltvs')}
                                    >
                                      Remove
                                    </a>
                                  )}
                                </>
                              )}
                            </td>,
                          )
                        }
                        rlt1.push(
                          <td key={`${index}-${index1}-${index2}-2`} className="border w-[120px]">
                            {isEditingRestriction ? (
                              <div className="flex justify-center items-center">
                                <PlainInput
                                  type="number"
                                  value={fico}
                                  content={fico}
                                  className="w-full"
                                  onChange={(value: string) =>
                                    onChangeTableValue({
                                      index,
                                      index1,
                                      index2,
                                      key: 'ficos',
                                      value: value,
                                    })
                                  }
                                />
                                {ficosLength > 2 && (
                                  <div className="p-1 hover-shadow1 hover:cursor-pointer rounded mx-2">
                                    <TrashIcon
                                      className="text-red-600 w-3.5 h-3.5"
                                      onClick={() => onRemoveFico({ index, index1, value: fico })}
                                    />
                                  </div>
                                )}
                              </div>
                            ) : (
                              fico
                            )}
                          </td>,
                        )
                      }

                      if (index1 === 0) {
                        const value = data.ltvs[index2].data[index].monthsReserve
                        rlt1.push(
                          <td key={`${index}-${index1}-${index2}-3`} rowSpan={ficosLength} className="border w-[80px]">
                            {isEditingRestriction ? (
                              <div className="flex justify-center">
                                <PlainInput
                                  type="number"
                                  value={value}
                                  content={value}
                                  className="w-full"
                                  onChange={(value: string) =>
                                    onChangeTableValue({
                                      index,
                                      index1,
                                      index2,
                                      key: 'monthsReserve',
                                      value: value,
                                    })
                                  }
                                />
                              </div>
                            ) : (
                              `${value}Mo`
                            )}
                          </td>,
                        )
                      }

                      const purchaseRTValue = convertNullValue(data.ltvs[index2].data[index].purchaseRT[index1])

                      rlt1.push(
                        <td key={`${index}-${index1}-${index2}-4`} className="border">
                          {isEditingRestriction ? (
                            <Select
                              id="purchaseRT"
                              value={purchaseRTValue.toString()}
                              options={ltvLimitOptions}
                              onChange={(value: string) =>
                                onChangeTableValue({
                                  index,
                                  index1,
                                  index2,
                                  key: 'purchaseRT',
                                  value: value,
                                })
                              }
                              className=" mb-[-20px]"
                            />
                          ) : (
                            purchaseRTValue
                          )}
                        </td>,
                      )

                      const noCashOutValue = convertNullValue(data.ltvs[index2].data[index].nocashout[index1])

                      rlt1.push(
                        <td key={`${index}-${index1}-${index2}-5`} className="border">
                          {isEditingRestriction ? (
                            <Select
                              id="nocashout"
                              value={noCashOutValue.toString()}
                              options={ltvLimitOptions}
                              onChange={(value: string) =>
                                onChangeTableValue({
                                  index,
                                  index1,
                                  index2,
                                  key: 'nocashout',
                                  value: value,
                                })
                              }
                              className=" mb-[-20px]"
                            />
                          ) : (
                            noCashOutValue
                          )}
                        </td>,
                      )

                      const cashoutValue = convertNullValue(data.ltvs[index2].data[index].cashout[index1])

                      rlt1.push(
                        <td key={`${index}-${index1}-${index2}-6`} className="border">
                          {isEditingRestriction ? (
                            <Select
                              id="cashout"
                              value={cashoutValue.toString()}
                              options={ltvLimitOptions}
                              onChange={(value: string) =>
                                onChangeTableValue({
                                  index,
                                  index1,
                                  index2,
                                  key: 'cashout',
                                  value: value,
                                })
                              }
                              className=" mb-[-20px]"
                            />
                          ) : (
                            cashoutValue
                          )}
                        </td>,
                      )

                      return rlt1
                    })}
                  </tr>,
                )
              })

              isEditingRestriction &&
                rlt.push(
                  <tr key={`${index}-new`} className={`${index % 2 === 1 ? 'bg-gray-50' : ''}`}>
                    <td className="border">
                      <a
                        className="underline hover:cursor-pointer"
                        onClick={() => {
                          setFicoIndex(index)
                          setDscrKey('ltvs')
                          setModal(ModalSetting.ADD_NEW_FICO)
                        }}
                      >
                        + New FICO
                      </a>
                    </td>
                    <td className="border" colSpan={data.ltvs.length * 4 - 1}></td>
                  </tr>,
                )
              return rlt
            })}

            {isEditingRestriction && (
              <tr>
                <td className="border">
                  <a className="underline hover:cursor-pointer" onClick={() => onNewLoanAmountModal('ltvs')}>
                    + New Range
                  </a>
                </td>
                <td className="border" colSpan={9}></td>
              </tr>
            )}
          </tbody>
        </table>

        {isEditingRestriction ? (
          <div className="flex justify-center">
            <Button
              loading={loading === LoadingStatus.SAVE_RESTRICTION}
              onClick={async () => {
                await onSave({ ltvs: data.ltvs, bigDscrLtvs: data?.bigDscrLtvs })
                setEditingRestriction(!isEditingRestriction)
              }}
            >
              Save
            </Button>
            <Button
              color="white"
              onClick={() => {
                onCancel()
                setEditingRestriction(!isEditingRestriction)
              }}
            >
              Cancel
            </Button>
          </div>
        ) : (
          <div className="flex justify-center">
            <Button onClick={() => setEditingRestriction(!isEditingRestriction)}>Edit Restrictions</Button>
          </div>
        )}
      </div>
    )
  }, [data, isEditingRestriction])

  return (
    <div className="relative">
      <LayoutLoading show={loading === LoadingStatus.SAVE_RESTRICTION} />
      {renderTable}

      {modal === ModalSetting.UPDATE_LOAN_AMOUNT_RANGE && loanAmountRange && (
        <UpdateLoanAmountModal
          data={loanAmountRange}
          loading={loading}
          isOpen={modal === ModalSetting.UPDATE_LOAN_AMOUNT_RANGE}
          onClose={() => setModal('')}
          onSubmit={updateLoanAmountRange}
        />
      )}
      {modal === ModalSetting.ADD_NEW_FICO && (
        <AddNewFico
          isOpen={modal === ModalSetting.ADD_NEW_FICO}
          index={ficoIndex}
          dscrKey={dscrKey}
          onClose={() => setModal('')}
          onSubmit={addNewFico}
        />
      )}
    </div>
  )
}
