import { PlusIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { DscrLeverageLimitTable } from 'components/LeverageDataTable'
import type { IDscrValueLeverageLimit } from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from 'stories/components'
import { confirm, isEmpty, removeComma } from 'utils'

interface IProps {
  leverageData: IDscrValueLeverageLimit[]
  isLoading: boolean
  isTemplate: boolean
  title: string
  options: any
  loading: string
  onSave: (data: IDscrValueLeverageLimit[]) => void
}

export const DscrValueLeverageLimit = (props: IProps) => {
  const { title, options, loading, isTemplate, leverageData, isLoading, onSave } = props

  const [data, setData] = useState<IDscrValueLeverageLimit[]>([])
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    if (!leverageData) return

    setData(leverageData)

    setEdit(false)
  }, [leverageData])

  const addLtvLimit = () => {
    const newData = cloneDeep(data)

    if (newData.length === Object.keys(options).length) {
      toast(`All options are already included.`, { type: 'info' })
      return
    }

    newData.unshift({
      value: '',
      loanAmount: NaN,
      LTV: NaN,
      CLTV: NaN,
      minFICO: 0,
      limits: [
        {
          FICO: { from: 0, to: 1000 },
          loanAmount: {
            purchase: {
              min: NaN,
              max: NaN,
            },
            nocashout: {
              min: NaN,
              max: NaN,
            },
            cashout: {
              min: NaN,
              max: NaN,
            },
          },
          LTV: {
            purchase: { ltv: { min: NaN, max: NaN }, cltv: NaN },
            nocashout: { ltv: { min: NaN, max: NaN }, cltv: NaN },
            cashout: { ltv: { min: NaN, max: NaN }, cltv: NaN },
          },
          minDSCR: {
            purchase: 0,
            nocashout: 0,
            cashout: 0,
          },
          minMonthsReserve: {
            purchase: 0,
            nocashout: 0,
            cashout: 0,
          },
        },
      ],
    })

    setData(newData)
    setEdit(true)
  }

  const addSubLimit = (index: number) => {
    const newData = cloneDeep(data)

    newData[index].limits.push({
      FICO: {
        from: newData[index].limits[newData[index].limits.length - 1].FICO.from,
        to: newData[index].limits[newData[index].limits.length - 1].FICO.to,
      },
      loanAmount: {
        purchase: {
          min: NaN,
          max: NaN,
        },
        nocashout: {
          min: NaN,
          max: NaN,
        },
        cashout: {
          min: NaN,
          max: NaN,
        },
      },
      LTV: {
        purchase: { ltv: { min: NaN, max: NaN }, cltv: NaN },
        nocashout: { ltv: { min: NaN, max: NaN }, cltv: NaN },
        cashout: { ltv: { min: NaN, max: NaN }, cltv: NaN },
      },
      minDSCR: {
        purchase: 0,
        nocashout: 0,
        cashout: 0,
      },
      minMonthsReserve: {
        purchase: 0,
        nocashout: 0,
        cashout: 0,
      },
    })

    setData(newData)
    setEdit(true)
  }

  const onChangeValue = (index: number, value: string) => {
    const newData = cloneDeep(data)

    if (newData.find((item) => item.value === value)) {
      toast(`Please choose another option.`, {
        type: 'info',
      })
      return
    }

    newData[index].value = value

    setData(newData)
    setEdit(true)
  }

  const onChangeLtvLimit = (
    index: number,
    idx: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: 'min' | 'max',
    value: any,
  ) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if (newData[index].limits[idx].LTV[loanPurpose].ltv[key] == value) return

    newData[index].limits[idx].LTV[loanPurpose].ltv[key] = value

    setData(newData)
    setEdit(true)
  }

  const onChangeCLTVLimit = (
    index: number,
    idx: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    value: any,
  ) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if (newData[index].limits[idx].LTV[loanPurpose].cltv == value) return

    newData[index].limits[idx].LTV[loanPurpose].cltv = value

    setData(newData)
    setEdit(true)
  }

  const onChangeMinDscrMonthsReserve = (
    index: number,
    idx: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: string,
    value: any,
  ) => {
    value = removeComma(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if ((newData[index].limits[idx] as any)[key][loanPurpose] == value) return
    ;(newData[index].limits[idx] as any)[key][loanPurpose] = value

    setData(newData)
    setEdit(true)
  }

  const removeLtvLimit = async (index: number, idx: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this row?
        <br />
        <span className="text-gray-600 text-base">
          {title}: {options[data[index].value] || data[index].value || 'N/A'}
        </span>
        <br />
        <span className="text-gray-600 text-base">
          FICO: {data[index].limits[idx].FICO.from} - {data[index].limits[idx].FICO.to}
        </span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const newData = cloneDeep(data)

    newData[index].limits.splice(idx, 1)

    if (newData[index].limits.length === 0) newData.splice(index, 1)

    setData(newData)
    setEdit(true)
  }

  const submit = () => {
    let hasError = false

    data.forEach((item) => {
      if (isEmpty(item.value)) {
        hasError = true
      }
    })

    if (hasError) {
      toast(`Required fields exist!`, { type: 'error' })
      return
    }

    onSave(data)
  }

  const onChangeLoanAmountOverly = (
    index: number,
    idx: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: string,
    value: any,
  ) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if ((newData[index].limits[idx].loanAmount[loanPurpose] as any)[key] == value) return
    ;(newData[index].limits[idx].loanAmount[loanPurpose] as any)[key] = value

    setData(newData)
    setEdit(true)
  }

  const onChangeFICO = (index: number, idx: number, key: string, value: any) => {
    value = removeComma(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if ((newData[index].limits[idx].FICO as any)[key] === value) return
    ;(newData[index].limits[idx].FICO as any)[key] = value

    setData(newData)
    setEdit(true)
  }

  const onChangeMaxLimits = (index: number, key: 'LTV' | 'CLTV' | 'loanAmount', value: any) => {
    const newData = cloneDeep(data)

    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (newData[index][key] == value) return

    newData[index][key] = value

    setData(newData)
    setEdit(true)
  }

  const onChangeMinFICO = (index: number, value: any) => {
    const newData = cloneDeep(data)

    value = removeComma(value)

    if (newData[index].minFICO == value) return

    newData[index].minFICO = value

    setData(newData)
    setEdit(true)
  }

  const renderLtvLimits = useMemo(() => {
    return (
      <DscrLeverageLimitTable
        title={title}
        data={data}
        isTemplate={isTemplate}
        options={options}
        onChangeValue={onChangeValue}
        onChangeLoanAmountOverly={onChangeLoanAmountOverly}
        onChangeLtvLimit={onChangeLtvLimit}
        onChangeCLTVLimit={onChangeCLTVLimit}
        onChangeMinDscrMonthsReserve={onChangeMinDscrMonthsReserve}
        removeLtvLimit={removeLtvLimit}
        addSubLimit={addSubLimit}
        onChangeFICO={onChangeFICO}
        onChangeMinFICO={onChangeMinFICO}
        onChangeMaxLimits={onChangeMaxLimits}
      />
    )
  }, [data])

  return (
    <div className="relative">
      <LayoutLoading show={LoadingStatus.LEVERAGE_LIMIT === loading || isLoading} />

      <div className="px-2 mt-2">
        {isTemplate && (
          <div className={`flex items-center mb-2 ${edit ? 'justify-between' : 'justify-end'}`}>
            {edit && (
              <Button loading={LoadingStatus.LEVERAGE_LIMIT === loading} onClick={submit} className="pt-1.5 pb-1.5">
                Save
              </Button>
            )}

            <div
              className="flex items-center gap-2 cursor-pointer w-fit text-shade-blue transition-all duration-200 hover:border-b hover:border-b-shade-blue pr-1 h-6"
              onClick={addLtvLimit}
            >
              <PlusIcon className="w-4 h-4" />
              <span>Add Limit</span>
            </div>
          </div>
        )}

        {renderLtvLimits}
      </div>
    </div>
  )
}
