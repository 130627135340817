import { useState } from 'react'
import { Input2, Modal, Select2 } from 'stories/components'

import { LoadingStatus } from '..'

export const programTypes = [
  'Other',
  'Beyond Prime',
  'Beyond Prime Investor',
  'Commercial Only Programmed',
  'MultiFlow',
  'FastMoney',
  'HardMoney',
  'SoftMoney',
  'CommercialDSCR',
]

export const UpdateProgramNameModal = (props: any) => {
  const [name, setName] = useState(props.program.Name)

  return (
    <div>
      <Modal
        title="Update Program Name"
        titleOkay="Update"
        isOpen={props.isOpen}
        loading={props.loading === LoadingStatus.UPDATE_PROGRAM_INFO}
        onClose={props.onClose}
        onOk={() => props.onSubmit(name)}
      >
        <div className="w-[400px]">
          <Input2 title="Program Name" onChange={(value) => setName(value)} value={name} className="mb-4" />
          <Select2 title="Type" id="type" value={props.program.Type} options={programTypes} disabled={true} />
        </div>
      </Modal>
    </div>
  )
}
