import { ListBulletIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { Fragment, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { addCounty, getCounties, removeCounty, updateCounty } from 'services/apis/admin'
import { isEmpty } from 'utils'

import { AddItems } from '../AddItems'

interface IProps {
  title: string
}

export const StateCounties = (props: IProps) => {
  const { title } = props

  const [stateCounties, setStateCounties] = useState<Record<string, string[]>>({})
  const [loading, setLoading] = useState('')
  const [activeState, setActiveState] = useState('')
  const [totalCounties, setTotalCounties] = useState<number>(0)

  useEffect(() => {
    ;(async () => {
      setLoading('getStates')

      const res = await getCounties()

      setStateCounties(res)

      setLoading('')
    })()
  }, [])

  useEffect(() => {
    let count = 0

    Object.values(stateCounties).forEach((item) => {
      count += item.length
    })

    setTotalCounties(count)
  }, [stateCounties])

  const onAddCounty = async (state: string, countyName: string) => {
    setLoading('addItem')

    const data = {
      state: state,
      countyName: countyName.trim(),
    }

    try {
      await addCounty(data)

      const newStates = cloneDeep(stateCounties)
      newStates[state].push(countyName)
      newStates[state].sort((x, y) => {
        const a = x.toLowerCase()
        const b = y.toLowerCase()

        if (a < b) return -1
        if (a > b) return 1
        return 0
      })

      toast(`${countyName} has added`, { type: 'info' })

      setStateCounties(newStates)
    } catch (error) {
      console.log(error)
    }

    setLoading('')
  }

  const onRemoveCounty = async (state: string, countyName: string) => {
    setLoading('removeItem')

    const data = {
      state: state,
      countyName: countyName,
    }

    try {
      await removeCounty(data)

      const newStates = cloneDeep(stateCounties)
      const removeIndex = newStates[state].findIndex((item) => item === countyName)

      newStates[state].splice(removeIndex, 1)

      toast(`${countyName} has removed`, { type: 'info' })

      setStateCounties(newStates)
    } catch (error) {
      console.log(error)
    }

    setLoading('')
  }

  const onUpdateCounty = async (state: string, originName: string, newName: string) => {
    setLoading('updateItem')

    const data = {
      state: state,
      originName: originName.trim(),
      newName: newName.trim(),
    }

    try {
      await updateCounty(data)

      const newStates = cloneDeep(stateCounties)
      const updatedIndex = newStates[state].findIndex((item) => item === originName)

      newStates[state][updatedIndex] = newName
      newStates[state].sort((x, y) => {
        const a = x.toLowerCase()
        const b = y.toLowerCase()

        if (a < b) return -1
        if (a > b) return 1
        return 0
      })

      toast(`County Name has updated`, { type: 'info' })

      setStateCounties(newStates)
    } catch (error) {
      console.log(error)
    }

    setLoading('')
  }

  return (
    <div className="relative">
      <LayoutLoading show={['getStates', 'addItem', 'removeItem', 'updateItem'].includes(loading)} />

      <div className="text-2xl font-variation-settings-600 mb-3">
        <span>{title}</span>
        <span className="ml-2 text-base">({totalCounties})</span>
      </div>

      <table className="w-full text-sm text-left text-gray-900 pl-6">
        <thead className="text-xs text-gray-700 text-center uppercase bg-gray-50">
          <tr>
            <th scope="col" className="px-3 py-3">
              No
            </th>
            <th scope="col" className="px-3 py-3">
              State
            </th>
            <th scope="col" className="px-3 py-3">
              Counties
            </th>
            <th scope="col" className="px-3 py-3">
              Action
            </th>
          </tr>
        </thead>

        <tbody>
          {Object.keys(stateCounties).map((key, index: number) => {
            const counties = stateCounties[key]
            const isCollapse = activeState === key

            return (
              <Fragment key={index}>
                <tr className={`border-b text-center ${index % 2 ? 'bg-slate-50' : 'bg-white'}`}>
                  <td scope="row" className="px-3 py-3 text-gray-900 w-[40px]">
                    {index + 1}
                  </td>
                  <td className="px-3 w-[100px]">{key}</td>
                  <td className="px-3">{counties.length}</td>
                  <td className="px-3 w-[100px]">
                    <div className="flex justify-center">
                      <div
                        className="text-shade-blue p-1 hover-shadow1 cursor-pointer rounded mr-1"
                        onClick={() => {
                          if (isEmpty(activeState) || activeState !== key) setActiveState(key)
                          else setActiveState('')
                        }}
                      >
                        <ListBulletIcon className="w-4 h-4" />
                      </div>
                    </div>
                  </td>
                </tr>

                {isCollapse && (
                  <tr>
                    <td />
                    <td colSpan={4}>
                      <AddItems
                        data={counties}
                        title="County Names"
                        onAdd={(countyName) => onAddCounty(key, countyName)}
                        onRemove={(countyName) => onRemoveCounty(key, countyName)}
                        onUpdate={(originName, newName) => onUpdateCounty(key, originName, newName)}
                        onClose={() => setActiveState('')}
                      />
                    </td>
                  </tr>
                )}
              </Fragment>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
