import { AdminTools } from 'pages/AdminTools'
import { Loans } from 'pages/Loans'
import { ManageAccounts } from 'pages/ManageAccounts'
import { PrepayPenalty } from 'pages/PrepayPenalty'
import { PricingPlayground } from 'pages/PricingPlayground'
import { RateSheet } from 'pages/RateSheet'
import { RateSheetOverview } from 'pages/RateSheetOverview'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AccountType } from 'stories/layouts'
import { PermissionGate } from 'utils/PermissionGate'

export function MainRoute() {
  const auth = useSelector((state: any) => state.auth)
  if (!auth.isAuthenticated) return null

  return (
    <Routes>
      <Route path="/loans" element={<Loans />} />
      <Route path="/pricing/:id" element={<PricingPlayground />} />
      <Route path="/ratesheet" element={<RateSheet />} />
      <Route path="/ratesheet/:id" element={<RateSheetOverview />} />
      <Route
        path="/programs"
        element={
          <PermissionGate permissions={[AccountType.ADMIN, AccountType.PROGRAMMER]}>
            <RateSheetOverview />
          </PermissionGate>
        }
      />
      <Route
        path="/manageAccounts"
        element={
          <PermissionGate permissions={[AccountType.ADMIN, AccountType.PROGRAMMER, AccountType.INVESTOR]}>
            <ManageAccounts />
          </PermissionGate>
        }
      />
      <Route path="/prepayPenalty" element={<PrepayPenalty />} />
      <Route path="/adminTools" element={<AdminTools />} />
      <Route path="*" element={<Navigate to="/ratesheet" replace />} />
    </Routes>
  )
}
