import type { IProgram } from 'config'
import { useEffect, useState } from 'react'
import { Input2, Modal, Select2 } from 'stories/components'

import { LoadingStatus } from '..'

interface IProps {
  programs: IProgram[]
  isOpen: boolean
  loading: string
  onClose: () => void
  onSubmit: (newName: string, originalName: string) => void
}

export const CloneProgramModal = ({ programs, isOpen, loading, onClose, onSubmit }: IProps) => {
  const [name, setName] = useState('')
  const [selectedProgram, setProgram] = useState('')
  const [programsList, setProgramsList] = useState<string[]>([])

  useEffect(() => {
    const programNames = programs.map((item) => item.Name)

    setProgramsList(programNames)
  }, [programs])

  return (
    <div>
      <Modal
        title="Clone Program"
        titleOkay="Clone"
        isOpen={isOpen}
        loading={loading === LoadingStatus.CLONE_PROGRAM}
        onClose={onClose}
        onOk={() => onSubmit(name, selectedProgram)}
      >
        <div className="w-[400px]">
          <Input2 title="Program Name" onChange={(value) => setName(value)} value={name} className="mb-4" />
          <Select2
            title="Programs"
            id="programs"
            onChange={(value) => setProgram(value)}
            value={selectedProgram}
            options={programsList}
            hasDefaultOption={true}
          />
        </div>
      </Modal>
    </div>
  )
}
