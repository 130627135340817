import { ArchiveBoxXMarkIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { PlainInput } from 'components/PlainInput'
import type { IDscrRangeLeverageAdjustment } from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import { convertNullValue } from 'pages/RateSheetOverview/ExcelSheets/CommercialOnlyProgrammed/logic'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { Button } from 'stories/components'
import { confirm, getPrice3decimal, removeComma, solveDecimalJavascriptSum } from 'utils'

interface IProps {
  title: string
  leverageData: IDscrRangeLeverageAdjustment[]
  isLoading: boolean
  isTemplate: boolean
  loading: string
  readOnlyValue?: boolean
  onSave: (data: IDscrRangeLeverageAdjustment[]) => void
}

export const DscrRangeLeverageAdjustment = (props: IProps) => {
  const { title, loading, isTemplate, leverageData, isLoading, readOnlyValue = false, onSave } = props

  const [data, setData] = useState<IDscrRangeLeverageAdjustment[]>([])
  const [edit, setEdit] = useState(false)

  const step = 1

  useEffect(() => {
    if (!leverageData) return

    setData(leverageData)

    setEdit(false)
  }, [leverageData])

  const addLtvAdjustment = () => {
    const newData = cloneDeep(data)

    newData.push({
      range: {
        from: !data.length ? 0 : data[data.length - 1].range.to + step,
        to: !data.length ? 0 : data[data.length - 1].range.to + step,
      },
      limit: {
        purchase: NaN,
        nocashout: NaN,
        cashout: NaN,
      },
    })

    setData(newData)
    setEdit(true)
  }

  const onChangeLoanAmount = (index: number, value: any, startValue = false) => {
    const newData = cloneDeep(data)

    value = removeComma(value)

    if (startValue) {
      newData[0].range.from = value

      if (newData[0].range.from > newData[0].range.to) newData[0].range.to = newData[0].range.from
    } else {
      if (newData[index].range.from > value) return

      newData[index].range.to = value
    }

    if (index < newData.length - 1)
      for (let i = index + 1; i < newData.length; i++) {
        if (newData[i].range.from - newData[i - 1].range.to !== step)
          newData[i].range.from = solveDecimalJavascriptSum([newData[i - 1].range.to, step])

        if (newData[i].range.from > newData[i].range.to) newData[i].range.to = newData[i].range.from
      }

    setData(newData)
    setEdit(true)
  }

  const onChangeLtvAdjustment = (index: number, loanPurpose: string, value: any) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    const newData = cloneDeep(data)

    if ((newData[index].limit as any)[loanPurpose] == value) return
    ;(newData[index].limit as any)[loanPurpose] = value

    setData(newData)
    setEdit(true)
  }

  const removeLtvAdjustment = async (index: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this row?
        <br />
        <span className="text-gray-600 text-base">
          {title}: {getPrice3decimal(data[index].range.from)} - {getPrice3decimal(data[index].range.to)}
        </span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const newData = cloneDeep(data)

    newData.splice(index, 1)

    for (let i = 1; i < newData.length; i++) {
      if (newData[i].range.from - newData[i - 1].range.to !== step)
        newData[i].range.from = newData[i - 1].range.to + step

      if (newData[i].range.from > newData[i].range.to) newData[i].range.to = newData[i].range.from
    }

    setData(newData)
    setEdit(true)
  }

  const renderAdjustments = useMemo(() => {
    if (data.length === 0)
      return (
        <div className="w-full flex justify-center items-center mt-10">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Data</span>
          </div>
        </div>
      )

    return (
      <table className="table w-full text-center text-sm">
        <thead className="bg-gray-100">
          <tr>
            <th colSpan={2} className="border px-2 py-1 whitespace-nowrap">
              {title}
            </th>
            <th colSpan={3} className="border px-2 py-1 whitespace-nowrap">
              LTV Adjustments
            </th>

            {isTemplate && !readOnlyValue && (
              <th rowSpan={2} className="border px-2 py-1">
                Action
              </th>
            )}
          </tr>
          <tr>
            <th className="border p-1 w-1/6">From</th>
            <th className="border p-1 w-1/6">To</th>
            <th className="border px-2 py-1 w-1/6">Purchase</th>
            <th className="border px-2 py-1 w-1/6">No-Cashout</th>
            <th className="border px-2 py-1 w-1/6">Cashout</th>
          </tr>
        </thead>

        <tbody>
          {data.map((item, index) => {
            return (
              <Fragment key={index}>
                <tr>
                  <td className="border p-1">
                    <div className="flex justify-center">
                      <PlainInput
                        value={item.range.from}
                        origin={item.range.from}
                        content={getPrice3decimal(item.range.from)}
                        onChange={(value: any) => onChangeLoanAmount(index, value, index === 0)}
                        disabled={!isTemplate || index !== 0}
                        className="w-full"
                      />
                    </div>
                  </td>
                  <td className="border p-1">
                    <div className="flex justify-center">
                      <PlainInput
                        value={item.range.to}
                        origin={item.range.to}
                        content={getPrice3decimal(item.range.to)}
                        onChange={(value: any) => onChangeLoanAmount(index, value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>

                  {['purchase', 'nocashout', 'cashout'].map((key, idx) => (
                    <td key={`${index}-${idx}`} className={`border whitespace-nowrap p-1`}>
                      <div className="flex justify-center">
                        <PlainInput
                          value={convertNullValue((item.limit as any)[key])}
                          origin={convertNullValue((item.limit as any)[key])}
                          content={convertNullValue((item.limit as any)[key])}
                          onChange={(value: any) => onChangeLtvAdjustment(index, key, value)}
                          disabled={!isTemplate}
                          className="w-full"
                        />
                      </div>
                    </td>
                  ))}

                  {isTemplate && !readOnlyValue && (
                    <td className="border p-1">
                      <div className="flex justify-center">
                        <div
                          className="w-fit p-1 transition-all duration-200 hover:cursor-pointer hover-shadow1 rounded"
                          onClick={() => removeLtvAdjustment(index)}
                        >
                          <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              </Fragment>
            )
          })}
        </tbody>
      </table>
    )
  }, [data])

  return (
    <div className=" relative">
      <LayoutLoading show={LoadingStatus.LEVERAGE_LIMIT === loading || isLoading} />

      <div className="px-2 mt-2">
        {isTemplate && (
          <div className={`flex items-center mb-2 ${edit && !readOnlyValue ? 'justify-between' : 'justify-end'}`}>
            {edit && (
              <Button
                loading={LoadingStatus.LEVERAGE_LIMIT === loading}
                onClick={() => onSave(data)}
                className="pt-1.5 pb-1.5"
              >
                Save
              </Button>
            )}

            {!readOnlyValue && (
              <div
                className="flex items-center gap-2 cursor-pointer w-fit text-shade-blue transition-all duration-200 hover:border-b hover:border-b-shade-blue pr-1 h-6"
                onClick={addLtvAdjustment}
              >
                <PlusIcon className="w-4 h-4" />
                <span>Add Adjustment</span>
              </div>
            )}
          </div>
        )}

        {renderAdjustments}
      </div>
    </div>
  )
}
