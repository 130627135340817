import cloneDeep from 'clone-deep'
import type { IProgram } from 'config'
import { useEffect, useMemo, useState } from 'react'
import { ButtonGroup } from 'stories/components'

import type { IDscrOverlay } from './interface'
import { LoanAmountDscrOverlay } from './LoanAmountDscrOverlay'
import { LoanPurposeDscrOverlay } from './LoanPurposeDscrOverlay'

const initialMenus = {
  loanAmount: 'Loan Amount',
  loanPurpose: 'Loan Purpose',
}

interface IProps {
  title: string
  program: IProgram
  loading: string
  onSave: (data: any[], key: string, type: string) => void
}

export const DscrOverlay = (props: IProps) => {
  const { title, program, loading, onSave } = props

  const [menus, setMenus] = useState(initialMenus)
  const [selectedMenu, setSelectedMenu] = useState(menus.loanAmount)
  const [selectedMenuKey, setSelectedMenuKey] = useState('')
  const [dscrOverlay, setDscrOverlay] = useState<IDscrOverlay>()

  useEffect(() => {
    setDscrOverlay(program.Restriction?.DscrOverlay)
  }, [program])

  useEffect(() => {
    if (!dscrOverlay) {
      setMenus(initialMenus)
      return
    }

    const { loanAmountDscrOverlay, loanPurposeDscrOverlay } = dscrOverlay as IDscrOverlay

    const newMenus = cloneDeep(initialMenus)

    Object.keys(initialMenus).forEach((key) => {
      switch (key) {
        case 'loanAmount':
          if (loanAmountDscrOverlay && !!loanAmountDscrOverlay.length) {
            newMenus[key] = initialMenus[key] + ` (${loanAmountDscrOverlay.length})`
          }
          break
        case 'loanPurpose':
          if (loanPurposeDscrOverlay && !!loanPurposeDscrOverlay.length) {
            newMenus[key] = initialMenus[key] + ` (${loanPurposeDscrOverlay.length})`
          }
          break
      }
    })

    setMenus(newMenus)

    if (selectedMenuKey) setSelectedMenu((newMenus as any)[selectedMenuKey])
    else setSelectedMenu(newMenus.loanAmount)
  }, [dscrOverlay, program])

  const renderContent = useMemo(() => {
    switch (selectedMenu) {
      case menus.loanAmount:
        return (
          <LoanAmountDscrOverlay
            loading={loading}
            dscrOverlay={dscrOverlay?.loanAmountDscrOverlay || []}
            onSave={(data, key) => onSave(data, key, 'DscrOverlay')}
          />
        )
      case menus.loanPurpose:
        return (
          <LoanPurposeDscrOverlay
            loading={loading}
            dscrOverlay={dscrOverlay?.loanPurposeDscrOverlay || []}
            onSave={(data, key) => onSave(data, key, 'DscrOverlay')}
          />
        )
      default:
        return <></>
    }
  }, [selectedMenu, loading, program, dscrOverlay])

  const onChangeMenuItem = (value: string) => {
    const selectedKey = Object.keys(menus).find((key) => (menus as any)[key] === value)

    if (selectedKey) setSelectedMenuKey(selectedKey)

    setSelectedMenu(value)
  }

  const renderMenus = useMemo(() => {
    return <ButtonGroup title={Object.values(menus)} value={selectedMenu} onChange={onChangeMenuItem} />
  }, [menus, selectedMenu])

  return (
    <div>
      <div className="text-lg font-variation-settings-600 mb-3">{title}</div>

      {renderMenus}

      <div className="py-2">{renderContent}</div>
    </div>
  )
}
