import { ArchiveBoxXMarkIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import FirstTimeInvestorLeverageLimitTable from 'components/LeverageDataTable/FirstTimeInvestorLeverageLimitTable'
import { PlainInput } from 'components/PlainInput'
import {
  bankruptcyOptions,
  foreclosuresOptions,
  ICommercialOnlyProgrammed,
  IFirstTimeInvestorLeverageData,
  IFirstTimeInvestorValueLeverageLimit,
  IProgram,
  mortgageLatesOptions,
} from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Input3, ToggleButton } from 'stories/components'
import { confirm, isEmpty, removeComma } from 'utils'

import { convertNullToBlank, convertNullToBlankValueToDecimal } from '../logic'

interface IProps {
  isTemplate: boolean
  leverageData: IFirstTimeInvestorLeverageData[]
  program: IProgram
  isLoading: boolean
  loading: string
  title: string
  onSave: (data: IFirstTimeInvestorLeverageData[], limitKey: string) => void
}

const initialMaxLimits = {
  purchase: {
    aivLtv: NaN,
    arvLtv: NaN,
    ltc: NaN,
    ltp: NaN,
  },
  nocashout: {
    aivLtv: NaN,
    arvLtv: NaN,
    ltc: NaN,
    ltp: NaN,
  },
  cashout: {
    aivLtv: NaN,
    arvLtv: NaN,
    ltc: NaN,
    ltp: NaN,
  },
}

export const FirstTimeInvestorLeverageMaxLimits = (props: IProps) => {
  const { program, leverageData, isLoading, isTemplate, loading, onSave } = props

  const [data, setData] = useState<IFirstTimeInvestorLeverageData[]>([])
  const [mortgageLates, setMortgageLates] = useState<IFirstTimeInvestorValueLeverageLimit[]>([])
  const [fcSsDil, setFcSsDil] = useState<IFirstTimeInvestorValueLeverageLimit[]>([])
  const [bankruptcy, setBankruptcy] = useState<IFirstTimeInvestorValueLeverageLimit[]>([])
  const [edit, setEdit] = useState(false)
  const [update, setUpdate] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const [programData, setProgramData] = useState<ICommercialOnlyProgrammed>()

  useEffect(() => {
    const newMortgageLates: IFirstTimeInvestorValueLeverageLimit[] = []
    const newFcSsDil: IFirstTimeInvestorValueLeverageLimit[] = []
    const newBankruptcy: IFirstTimeInvestorValueLeverageLimit[] = []

    Object.keys(mortgageLatesOptions).forEach((key) =>
      newMortgageLates.push({
        value: key,
        limit: initialMaxLimits,
        loanAmount: {
          purchase: { min: NaN, max: NaN },
          nocashout: { min: NaN, max: NaN },
          cashout: { min: NaN, max: NaN },
        },
        minMonthsReserve: {
          purchase: 0,
          nocashout: 0,
          cashout: 0,
        },
      }),
    )

    Object.keys(foreclosuresOptions).forEach((key) =>
      newFcSsDil.push({
        value: key,
        limit: initialMaxLimits,
        loanAmount: {
          purchase: { min: NaN, max: NaN },
          nocashout: { min: NaN, max: NaN },
          cashout: { min: NaN, max: NaN },
        },
        minMonthsReserve: {
          purchase: 0,
          nocashout: 0,
          cashout: 0,
        },
      }),
    )

    Object.keys(bankruptcyOptions).forEach((key) =>
      newBankruptcy.push({
        value: key,
        limit: initialMaxLimits,
        loanAmount: {
          purchase: { min: NaN, max: NaN },
          nocashout: { min: NaN, max: NaN },
          cashout: { min: NaN, max: NaN },
        },
        minMonthsReserve: {
          purchase: 0,
          nocashout: 0,
          cashout: 0,
        },
      }),
    )

    setMortgageLates(newMortgageLates)
    setFcSsDil(newFcSsDil)
    setBankruptcy(newBankruptcy)
  }, [])

  useEffect(() => {
    if (!program) return
    if (!leverageData) return

    setData(leverageData)

    const errs: string[] = []
    leverageData.forEach(() => errs.push(''))
    setErrors(errs)

    setProgramData(program.OtherTypeProgramData as ICommercialOnlyProgrammed)
    setEdit(false)
  }, [program, leverageData])

  useEffect(() => {
    if (!loading) setEdit(false)
  }, [loading])

  const addLtvLimit = () => {
    const newData = cloneDeep(data)

    if (newData.length === 0) {
      newData.push({
        label: 'Yes',
        limits: [
          {
            ficoRange: { from: 0, to: 0 },
            limit: initialMaxLimits,
            loanAmount: {
              purchase: { min: NaN, max: NaN },
              nocashout: { min: NaN, max: NaN },
              cashout: { min: NaN, max: NaN },
            },
            minMonthsReserve: {
              purchase: 0,
              nocashout: 0,
              cashout: 0,
            },
            mortgageLates,
            fcSsDil,
            bankruptcy,
          },
        ],
      })
    } else if (newData[0].limits.length === 0)
      newData[0].limits.push({
        ficoRange: {
          from: 0,
          to: 0,
        },
        limit: initialMaxLimits,
        loanAmount: {
          purchase: { min: NaN, max: NaN },
          nocashout: { min: NaN, max: NaN },
          cashout: { min: NaN, max: NaN },
        },
        minMonthsReserve: {
          purchase: 0,
          nocashout: 0,
          cashout: 0,
        },
        mortgageLates,
        fcSsDil,
        bankruptcy,
      })
    else
      newData[0].limits.push({
        ficoRange: {
          from: data[0].limits[data[0].limits.length - 1].ficoRange.to + 1,
          to: data[0].limits[data[0].limits.length - 1].ficoRange.to + 1,
        },
        limit: initialMaxLimits,
        loanAmount: {
          purchase: { min: NaN, max: NaN },
          nocashout: { min: NaN, max: NaN },
          cashout: { min: NaN, max: NaN },
        },
        minMonthsReserve: {
          purchase: 0,
          nocashout: 0,
          cashout: 0,
        },
        mortgageLates,
        fcSsDil,
        bankruptcy,
      })

    setData(newData)
    setEdit(true)
  }

  const removeLtvLimit = async (index: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this row?
        <br />
        <span className="text-gray-600 text-base">
          FICO: {data[0].limits[index].ficoRange.from} - {data[0].limits[index].ficoRange.to}
        </span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const newData = cloneDeep(data)

    newData[0].limits.splice(index, 1)

    for (let i = 1; i < newData[0].limits.length; i++) {
      if (newData[0].limits[i].ficoRange.from - newData[0].limits[i - 1].ficoRange.to !== 1)
        newData[0].limits[i].ficoRange.from = newData[0].limits[i - 1].ficoRange.to + 1

      if (newData[0].limits[i].ficoRange.from > newData[0].limits[i].ficoRange.to)
        newData[0].limits[i].ficoRange.to = newData[0].limits[i].ficoRange.from
    }

    setData(newData)
    setEdit(true)
  }

  const onChangeLtvLimit = (
    index: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: string,
    value: any,
  ) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if ((newData[0].limits[index].limit[loanPurpose] as any)[key] === value) return
    ;(newData[0].limits[index].limit[loanPurpose] as any)[key] = value

    setData(newData)
    setEdit(true)
  }

  const onChangeFicoRange = (index: number, value: any, startValue = false) => {
    const newData = cloneDeep(data)

    value = Number(value)

    if (startValue) {
      newData[0].limits[0].ficoRange.from = value

      if (newData[0].limits[0].ficoRange.from > newData[0].limits[0].ficoRange.to)
        newData[0].limits[0].ficoRange.to = newData[0].limits[0].ficoRange.from
    } else {
      if (newData[0].limits[index].ficoRange.from > value) {
        setUpdate(!update)
        return
      }

      newData[0].limits[index].ficoRange.to = value
    }

    if (index < newData[0].limits.length - 1)
      for (let i = index + 1; i < newData[0].limits.length; i++) {
        if (newData[0].limits[i].ficoRange.from - newData[0].limits[i - 1].ficoRange.to !== 1)
          newData[0].limits[i].ficoRange.from = newData[0].limits[i - 1].ficoRange.to + 1

        if (newData[0].limits[i].ficoRange.from > newData[0].limits[i].ficoRange.to)
          newData[0].limits[i].ficoRange.to = newData[0].limits[i].ficoRange.from
      }

    setData(newData)
    setEdit(true)
  }

  const onChangeSubLTVLimit = (
    index: number,
    idx: number,
    type: 'mortgageLates' | 'fcSsDil' | 'bankruptcy',
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: string,
    value: any,
  ) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if ((newData[0].limits[index][type][idx].limit[loanPurpose] as any)[key] === value) return
    ;(newData[0].limits[index][type][idx].limit[loanPurpose] as any)[key] = value

    setData(newData)
    setEdit(true)
  }

  const submit = () => {
    onSave(data, 'firstTimeInvestorLeverageMaxLimit')
  }

  const onChangeMinMonthsReserve = (index: number, loanPurpose: 'purchase' | 'nocashout' | 'cashout', value: any) => {
    value = removeComma(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if (newData[0].limits[index].minMonthsReserve[loanPurpose] == value) return
    newData[0].limits[index].minMonthsReserve[loanPurpose] = value

    setData(newData)
    setEdit(true)
  }

  const onChangeSubMinMonthsReserve = (
    index: number,
    idx: number,
    type: 'mortgageLates' | 'fcSsDil' | 'bankruptcy',
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    value: any,
  ) => {
    value = removeComma(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if (newData[0].limits[index][type][idx].minMonthsReserve[loanPurpose] == value) return
    newData[0].limits[index][type][idx].minMonthsReserve[loanPurpose] = value

    setData(newData)
    setEdit(true)
  }

  const onChangeLoanAmountOverly = (
    index: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: string,
    value: any,
  ) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if ((newData[0].limits[index].loanAmount[loanPurpose] as any)[key] == value) return
    ;(newData[0].limits[index].loanAmount[loanPurpose] as any)[key] = value

    setData(newData)
    setEdit(true)
  }

  const onChangeSubLoanAmountOverly = (
    index: number,
    idx: number,
    type: 'mortgageLates' | 'fcSsDil' | 'bankruptcy',
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: string,
    value: any,
  ) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if ((newData[0].limits[index][type][idx].loanAmount[loanPurpose] as any)[key] == value) return
    ;(newData[0].limits[index][type][idx].loanAmount[loanPurpose] as any)[key] = value

    setData(newData)
    setEdit(true)
  }

  const renderLtvMaxLimits = useMemo(() => {
    if (!programData || data.length === 0 || data[0].limits.length === 0)
      return (
        <div className="w-full flex justify-center items-center mt-10">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limits</span>
          </div>
        </div>
      )

    return data[0].limits.map((item, index) => (
      <table className={`table w-full text-center text-sm border rounded shadow ${index === 0 ? '' : 'mt-4'}`}>
        <thead className="bg-gray-100">
          <tr>
            <th rowSpan={2} className="border p-1 whitespace-nowrap">
              FICO
            </th>
            <th rowSpan={2} className="border p-1 whitespace-nowrap">
              Loan Purpose
            </th>
            <th colSpan={2} className="border p-1">
              Loan Amount
            </th>
            <th rowSpan={2} className="border p-1 w-[100px]">
              Min Months Reserve
            </th>
            <th colSpan={4} className="border p-1">
              Max LTVs
            </th>
            {isTemplate && (
              <th rowSpan={2} className="border p-1 whitespace-nowrap">
                Action
              </th>
            )}
          </tr>
          <tr>
            <th className="border p-1 w-[150px]">From</th>
            <th className="border p-1 w-[150px]">To</th>
            <th className="border p-1 w-[100px]">AIV-LTV</th>
            <th className="border p-1 w-[100px]">ARV-LTV</th>
            <th className="border p-1 w-[100px]">LTC</th>
            <th className="border p-1 w-[100px]">LTP</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td rowSpan={3} className={`border p-1`}>
              {isTemplate ? (
                <div className="flex items-center justify-center gap-4">
                  {index === 0 ? (
                    <Input3
                      type="number"
                      title="From"
                      value={item.ficoRange.from.toString()}
                      className="w-[80px] mb-[-16px]"
                      onChange={(value) => onChangeFicoRange(index, value, true)}
                    />
                  ) : (
                    <Input3
                      type="number"
                      title="From"
                      value={item.ficoRange.from.toString()}
                      className="w-[80px] mb-[-16px]"
                      disabled={true}
                      onChange={() => {}}
                    />
                  )}

                  <Input3
                    type="number"
                    title="To"
                    value={item.ficoRange.to.toString()}
                    className="w-[80px] mb-[-16px]"
                    onChange={(value) => onChangeFicoRange(index, value)}
                  />
                </div>
              ) : (
                <div className="flex justify-center">
                  {item.ficoRange.from} - {item.ficoRange.to}
                </div>
              )}
            </td>
            <td className={`border whitespace-nowrap p-1`}>Purchase</td>
            {['min', 'max'].map((key, index1) => (
              <td
                key={`${index}-${index1}`}
                className={`border whitespace-nowrap p-1 ${!isTemplate ? '' : 'bg-gray-50'}`}
              >
                <div className="flex justify-center">
                  <PlainInput
                    value={convertNullToBlank((item.loanAmount.purchase as any)[key])}
                    origin={convertNullToBlank((item.loanAmount.purchase as any)[key])}
                    content={convertNullToBlankValueToDecimal((item.loanAmount.purchase as any)[key])}
                    onChange={(value: any) => onChangeLoanAmountOverly(index, 'purchase', key, value)}
                    disabled={!isTemplate}
                    className="w-full"
                  />
                </div>
              </td>
            ))}
            <td className={`p-1 border ${!isTemplate ? '' : 'bg-gray-50'}`}>
              <div className="flex justify-center">
                <PlainInput
                  value={convertNullToBlank(item.minMonthsReserve.purchase)}
                  origin={convertNullToBlank(item.minMonthsReserve.purchase)}
                  content={convertNullToBlank(item.minMonthsReserve.purchase)}
                  disabled={!isTemplate}
                  onChange={(value: any) => onChangeMinMonthsReserve(index, 'purchase', value)}
                  className="w-full"
                />
              </div>
            </td>
            {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, idx) => (
              <td
                key={`${index}-${idx}`}
                className={`p-1 border ${
                  isEmpty(program.OtherTypeProgramData.ltvLimits.purchase[key]) || !isTemplate ? '' : 'bg-gray-50'
                }`}
              >
                <div className="flex justify-center">
                  <PlainInput
                    value={convertNullToBlank((item.limit.purchase as any)[key])}
                    origin={convertNullToBlank((item.limit.purchase as any)[key])}
                    content={convertNullToBlank((item.limit.purchase as any)[key])}
                    disabled={isEmpty(program.OtherTypeProgramData.ltvLimits.purchase[key]) || !isTemplate}
                    onChange={(value: any) => onChangeLtvLimit(index, 'purchase', key, value)}
                    className="w-full"
                  />
                </div>
              </td>
            ))}

            {isTemplate && (
              <td rowSpan={3} className="border p-1">
                <div className="flex justify-center">
                  <div
                    className="w-fit p-1 transition-all duration-200 hover:cursor-pointer hover-shadow1 rounded"
                    onClick={() => removeLtvLimit(index)}
                  >
                    <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                  </div>
                </div>
              </td>
            )}
          </tr>

          <tr>
            <td className={`border whitespace-nowrap p-1`}>No-Cashout</td>
            {['min', 'max'].map((key, index1) => (
              <td
                key={`${index}-${index1}`}
                className={`border whitespace-nowrap p-1 ${!isTemplate ? '' : 'bg-gray-50'}`}
              >
                <div className="flex justify-center">
                  <PlainInput
                    value={convertNullToBlank((item.loanAmount.nocashout as any)[key])}
                    origin={convertNullToBlank((item.loanAmount.nocashout as any)[key])}
                    content={convertNullToBlankValueToDecimal((item.loanAmount.nocashout as any)[key])}
                    onChange={(value: any) => onChangeLoanAmountOverly(index, 'nocashout', key, value)}
                    disabled={!isTemplate}
                    className="w-full"
                  />
                </div>
              </td>
            ))}
            <td className={`p-1 border ${!isTemplate ? '' : 'bg-gray-50'}`}>
              <div className="flex justify-center">
                <PlainInput
                  value={convertNullToBlank(item.minMonthsReserve.nocashout)}
                  origin={convertNullToBlank(item.minMonthsReserve.nocashout)}
                  content={convertNullToBlank(item.minMonthsReserve.nocashout)}
                  disabled={!isTemplate}
                  onChange={(value: any) => onChangeMinMonthsReserve(index, 'nocashout', value)}
                  className="w-full"
                />
              </div>
            </td>
            {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, idx) => (
              <td
                key={`${index}-${idx}`}
                className={`p-1 border ${
                  isEmpty(program.OtherTypeProgramData.ltvLimits.nocashout[key]) || !isTemplate ? '' : 'bg-gray-50'
                }`}
              >
                <div className="flex justify-center">
                  <PlainInput
                    value={convertNullToBlank((item.limit.nocashout as any)[key])}
                    origin={convertNullToBlank((item.limit.nocashout as any)[key])}
                    content={convertNullToBlank((item.limit.nocashout as any)[key])}
                    disabled={isEmpty(program.OtherTypeProgramData.ltvLimits.nocashout[key]) || !isTemplate}
                    onChange={(value: any) => onChangeLtvLimit(index, 'nocashout', key, value)}
                    className="w-full"
                  />
                </div>
              </td>
            ))}
          </tr>

          <tr>
            <td className={`border whitespace-nowrap p-1`}>Cashout</td>
            {['min', 'max'].map((key, index1) => (
              <td
                key={`${index}-${index1}`}
                className={`border whitespace-nowrap p-1 ${!isTemplate ? '' : 'bg-gray-50'}`}
              >
                <div className="flex justify-center">
                  <PlainInput
                    value={convertNullToBlank((item.loanAmount.cashout as any)[key])}
                    origin={convertNullToBlank((item.loanAmount.cashout as any)[key])}
                    content={convertNullToBlankValueToDecimal((item.loanAmount.cashout as any)[key])}
                    onChange={(value: any) => onChangeLoanAmountOverly(index, 'cashout', key, value)}
                    disabled={!isTemplate}
                    className="w-full"
                  />
                </div>
              </td>
            ))}
            <td className={`p-1 border ${!isTemplate ? '' : 'bg-gray-50'}`}>
              <div className="flex justify-center">
                <PlainInput
                  value={convertNullToBlank(item.minMonthsReserve.cashout)}
                  origin={convertNullToBlank(item.minMonthsReserve.cashout)}
                  content={convertNullToBlank(item.minMonthsReserve.cashout)}
                  disabled={!isTemplate}
                  onChange={(value: any) => onChangeMinMonthsReserve(index, 'cashout', value)}
                  className="w-full"
                />
              </div>
            </td>
            {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, idx) => (
              <td
                key={`${index}-${idx}`}
                className={`p-1 border ${
                  isEmpty(program.OtherTypeProgramData.ltvLimits.cashout[key]) || !isTemplate ? '' : 'bg-gray-50'
                }`}
              >
                <div className="flex justify-center">
                  <PlainInput
                    value={convertNullToBlank((item.limit.cashout as any)[key])}
                    origin={convertNullToBlank((item.limit.cashout as any)[key])}
                    content={convertNullToBlank((item.limit.cashout as any)[key])}
                    disabled={isEmpty(program.OtherTypeProgramData.ltvLimits.cashout[key]) || !isTemplate}
                    onChange={(value: any) => onChangeLtvLimit(index, 'cashout', key, value)}
                    className="w-full"
                  />
                </div>
              </td>
            ))}
          </tr>

          <tr>
            <td colSpan={isTemplate ? 10 : 9} className="border p-3">
              <FirstTimeInvestorLeverageLimitTable
                programData={programData}
                data={item.bankruptcy}
                isTemplate={isTemplate}
                title="Bankruptcy"
                options={bankruptcyOptions}
                readOnlyValue
                onChangeLoanAmountOverly={(idx, loanPurpose, key, value) =>
                  onChangeSubLoanAmountOverly(index, idx, 'bankruptcy', loanPurpose, key, value)
                }
                onChangeLtvLimit={(idx, loanPurpose, key, value: any) =>
                  onChangeSubLTVLimit(index, idx, 'bankruptcy', loanPurpose, key, value)
                }
                onChangeMinFicoMonthsReserve={(idx, loanPurpose, key, value) =>
                  onChangeSubMinMonthsReserve(index, idx, 'bankruptcy', loanPurpose, value)
                }
              />

              <FirstTimeInvestorLeverageLimitTable
                programData={programData}
                data={item.fcSsDil}
                isTemplate={isTemplate}
                title="FC/SS/DIL"
                options={foreclosuresOptions}
                readOnlyValue
                onChangeLoanAmountOverly={(idx, loanPurpose, key, value) =>
                  onChangeSubLoanAmountOverly(index, idx, 'fcSsDil', loanPurpose, key, value)
                }
                onChangeLtvLimit={(idx, loanPurpose, key, value: any) =>
                  onChangeSubLTVLimit(index, idx, 'fcSsDil', loanPurpose, key, value)
                }
                onChangeMinFicoMonthsReserve={(idx, loanPurpose, key, value) =>
                  onChangeSubMinMonthsReserve(index, idx, 'fcSsDil', loanPurpose, value)
                }
              />

              <FirstTimeInvestorLeverageLimitTable
                programData={programData}
                data={item.mortgageLates}
                isTemplate={isTemplate}
                title="Mortgage Lates"
                readOnlyValue
                options={mortgageLatesOptions}
                onChangeLoanAmountOverly={(idx, loanPurpose, key, value) =>
                  onChangeSubLoanAmountOverly(index, idx, 'mortgageLates', loanPurpose, key, value)
                }
                onChangeLtvLimit={(idx, loanPurpose, key, value: any) =>
                  onChangeSubLTVLimit(index, idx, 'mortgageLates', loanPurpose, key, value)
                }
                onChangeMinFicoMonthsReserve={(idx, loanPurpose, key, value) =>
                  onChangeSubMinMonthsReserve(index, idx, 'mortgageLates', loanPurpose, value)
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
    ))
  }, [data, errors, update])

  return (
    <div className="relative">
      <LayoutLoading show={LoadingStatus.LEVERAGE_LIMIT === loading || isLoading} />

      <div className="px-2 mt-2">
        <ToggleButton
          id="firstTimeInvestor"
          title="First Time Home Investor?"
          disabled={true}
          value={true}
          className="mb-2"
          label={['Yes', 'No']}
        />

        {isTemplate && (
          <div className={`flex items-center ${edit ? 'justify-between' : 'justify-end'} mb-2`}>
            {edit && (
              <Button loading={LoadingStatus.LEVERAGE_LIMIT === loading} onClick={submit} className="pt-1.5 pb-1.5">
                Save
              </Button>
            )}

            <div
              className="flex items-center gap-2 cursor-pointer w-fit text-shade-blue transition-all duration-200 hover:border-b hover:border-b-shade-blue pr-1 h-6"
              onClick={addLtvLimit}
            >
              <PlusIcon className="w-4 h-4" />
              <span>Add LTV Limit</span>
            </div>
          </div>
        )}

        {renderLtvMaxLimits}
      </div>
    </div>
  )
}
