import type { ILoanAmountRange } from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import { useState } from 'react'
import { Input2, Modal } from 'stories/components'
import { removeComma } from 'utils'

interface Props {
  data: ILoanAmountRange
  isOpen: boolean
  loading: string
  onClose: () => void
  onSubmit: ({ index, from, to, key }: { index: number; from: number; to: number; key?: 'ltvs' | 'bigDscrLtvs' }) => {}
}

export function UpdateLoanAmountModal(props: Props) {
  const { data, isOpen, loading, onClose, onSubmit } = props

  const [from, setFrom] = useState<number>(Number(data.from))
  const [to, setTo] = useState<number>(Number(data.to))

  return (
    <div>
      <Modal
        title={`${data.status === 'add' ? 'Add New' : 'Update'} Loan Amount Range`}
        titleOkay={`${data.status === 'add' ? 'Add' : 'Update'}`}
        isOpen={isOpen}
        loading={loading === LoadingStatus.UPDATE_LOAN_AMOUNT_RANGE}
        onClose={onClose}
        onOk={() =>
          onSubmit({
            index: data.index,
            from: from,
            to: to,
            key: data?.key,
          })
        }
      >
        <div className="w-[400px]">
          <Input2
            type="thousandSep"
            title="Range From"
            onChange={(value) => setFrom(removeComma(value))}
            value={from.toString()}
            className="mb-4"
            // disabled={data.index !== 0}
          />
          <Input2
            type="thousandSep"
            title="Range To"
            onChange={(value) => setTo(removeComma(value))}
            value={to.toString()}
          />
        </div>
      </Modal>
    </div>
  )
}
