import { ArchiveBoxXMarkIcon, TrashIcon } from '@heroicons/react/24/outline'
import { PlainInput } from 'components/PlainInput'
import type { ICommercialOnlyProgrammed, IValueLeverageData } from 'config'
import { convertNullValue } from 'pages/RateSheetOverview/ExcelSheets/CommercialOnlyProgrammed/logic'
import { Fragment } from 'react'
import { Select } from 'stories/components'
import { isEmpty } from 'utils'

interface IProps {
  title: string
  programData: ICommercialOnlyProgrammed | undefined
  data: IValueLeverageData[]
  isTemplate: boolean
  readOnlyValue?: boolean
  options?: any
  onChangeLtvAdjustment: (
    index: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: string,
    value: any,
  ) => void
  removeAdjustment?: (index: number) => void
  onChange?: (index: number, value: any) => void
}

export const LeverageAdjustmentTable = (props: IProps) => {
  const {
    title,
    programData,
    data,
    isTemplate,
    readOnlyValue = false,
    options = [],
    onChangeLtvAdjustment,
    removeAdjustment = () => {},
    onChange = () => {},
  } = props

  if (!programData || data.length === 0)
    return (
      <div className="w-full flex justify-center items-center mt-10">
        <div className="flex flex-col items-center gap-1">
          <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
          <span className="text-gray-400">No Data</span>
        </div>
      </div>
    )

  return (
    <table className="table w-full text-center text-sm">
      <thead className="bg-gray-100">
        <tr>
          <th rowSpan={2} className="border px-2 py-1 whitespace-nowrap">
            {title}
          </th>
          <th rowSpan={2} className="border px-2 py-1 whitespace-nowrap">
            Loan Purpose
          </th>
          <th colSpan={4} className="border px-2 py-1 whitespace-nowrap">
            LTV Adjustments
          </th>

          {isTemplate && !readOnlyValue && (
            <th rowSpan={2} className="border px-2 py-1">
              Action
            </th>
          )}
        </tr>
        <tr>
          <th className="border px-2 py-1 w-[140px]">AIV-LTV</th>
          <th className="border px-2 py-1 w-[140px]">ARV-LTV</th>
          <th className="border px-2 py-1 w-[140px]">LTC</th>
          <th className="border px-2 py-1 w-[140px]">LTP</th>
        </tr>
      </thead>

      <tbody>
        {data.map((item, index) => {
          return (
            <Fragment key={index}>
              <tr>
                <td rowSpan={3} className="border p-1">
                  {isTemplate && !readOnlyValue ? (
                    <Select
                      id="value"
                      options={options}
                      hasDefaultOption={true}
                      value={item.value}
                      className="mb-[-16px]"
                      readOnly={!isTemplate}
                      onChange={(v) => onChange(index, v)}
                    />
                  ) : (
                    <span>{options[item.value] || item.value}</span>
                  )}
                </td>

                <td className="border p-1">Purchase</td>

                {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, idx) => (
                  <td
                    key={`${index}-${idx}`}
                    className={`p-1 border ${
                      isEmpty((programData.ltvLimits.purchase as any)[key]) || !isTemplate ? '' : 'bg-gray-50'
                    }`}
                  >
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullValue((item.limit.purchase as any)[key])}
                        origin={convertNullValue((item.limit.purchase as any)[key])}
                        content={convertNullValue((item.limit.purchase as any)[key])}
                        disabled={isEmpty((programData.ltvLimits.purchase as any)[key]) || !isTemplate}
                        onChange={(value: any) => onChangeLtvAdjustment(index, 'purchase', key, value)}
                        className="w-full"
                      />
                    </div>
                  </td>
                ))}

                {isTemplate && !readOnlyValue && (
                  <td rowSpan={3} className="border p-1">
                    <div className="flex justify-center">
                      <div
                        className="w-fit p-1 transition-all duration-200 hover:cursor-pointer hover-shadow1 rounded"
                        onClick={() => removeAdjustment(index)}
                      >
                        <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                      </div>
                    </div>
                  </td>
                )}
              </tr>

              <tr>
                <td className="border p-1">No-Cashout</td>

                {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, idx) => (
                  <td
                    key={`${index}-${idx}`}
                    className={`p-1 border ${
                      isEmpty((programData.ltvLimits.nocashout as any)[key]) || !isTemplate ? '' : 'bg-gray-50'
                    }`}
                  >
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullValue((item.limit.nocashout as any)[key])}
                        origin={convertNullValue((item.limit.nocashout as any)[key])}
                        content={convertNullValue((item.limit.nocashout as any)[key])}
                        disabled={isEmpty((programData.ltvLimits.nocashout as any)[key]) || !isTemplate}
                        onChange={(value: any) => onChangeLtvAdjustment(index, 'nocashout', key, value)}
                        className="w-full"
                      />
                    </div>
                  </td>
                ))}
              </tr>

              <tr>
                <td className="border p-1">Cashout</td>

                {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, idx) => (
                  <td
                    key={`${index}-${idx}`}
                    className={`p-1 border ${
                      isEmpty((programData.ltvLimits.cashout as any)[key]) || !isTemplate ? '' : 'bg-gray-50'
                    }`}
                  >
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullValue((item.limit.cashout as any)[key])}
                        origin={convertNullValue((item.limit.cashout as any)[key])}
                        content={convertNullValue((item.limit.cashout as any)[key])}
                        disabled={isEmpty((programData.ltvLimits.cashout as any)[key]) || !isTemplate}
                        onChange={(value: any) => onChangeLtvAdjustment(index, 'cashout', key, value)}
                        className="w-full"
                      />
                    </div>
                  </td>
                ))}
              </tr>
            </Fragment>
          )
        })}
      </tbody>
    </table>
  )
}
