import type { IMinMaxLimit, IProgram } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Input2 } from 'stories/components'
import { removeComma } from 'utils'

import { LoadingStatus } from '..'

interface IProps {
  program: IProgram
  loading: string
  type: 'MinMaxRate'
  onSave: (data: IMinMaxLimit, key: 'Margin' | 'MinMaxRate') => void
}

const labels: Record<string, any> = {
  MinMaxPrice: { min: 'Min Price', max: 'Max Price' },
  MinMaxRate: { min: 'Min Rate', max: 'Max Rate' },
}

const titles: Record<string, any> = {
  MinMaxPrice: 'Min / Max Price',
  MinMaxRate: 'Min / Max Rate',
}

export const MinMaxPriceRate = (props: IProps) => {
  const { program, loading, type, onSave } = props

  const [data, setData] = useState<{ min: string; max: string }>({ min: '', max: '' })
  const [origin, setOrigin] = useState<{ min: string; max: string }>({ min: '', max: '' })
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    if (program[type]) {
      setData({
        min: program[type]!.min.toString(),
        max: program[type]!.max.toString(),
      })
      setOrigin({
        min: program[type]!.min.toString(),
        max: program[type]!.max.toString(),
      })
    } else {
      setData({ min: '', max: '' })
      setOrigin({ min: '', max: '' })
    }

    setEdit(false)
  }, [program])

  useEffect(() => {
    if (!loading) setEdit(false)
  }, [loading])

  const onBlur = (key: string) => {
    const originValue = removeComma((origin as any)[key])
    const newValue = removeComma((data as any)[key])

    if (originValue === newValue) return

    setOrigin({ ...data, [key]: (data as any)[key] })
    setEdit(true)
  }

  const onSubmit = () => {
    const newData: IMinMaxLimit = {
      min: removeComma(data.min),
      max: removeComma(data.max),
    }

    if (newData.max < newData.min) {
      toast(`${newData.min} is greater than ${newData.max}`, { type: 'error' })
      return
    }

    onSave(newData, type)
  }

  return (
    <div>
      <p className="text-lg font-variation-settings-600 mt-4 mb-1">{titles[type]}</p>

      <div className="flex items-center gap-4 mb-4">
        {Object.keys(data).map((key, index) => {
          const value = String((data as any)[key])

          return (
            <Input2
              key={index}
              title={labels[type][key]}
              type="number"
              className="w-[250px]"
              value={value}
              onChange={(v) => setData({ ...data, [key]: v })}
              onBlur={() => onBlur(key)}
            />
          )
        })}

        {edit && (
          <Button
            loading={loading === LoadingStatus.UPDATE_PROGRAM_INFO}
            className="pt-1.5 pb-1.5 mb-[-2px]"
            onClick={onSubmit}
          >
            Save
          </Button>
        )}
      </div>
    </div>
  )
}
