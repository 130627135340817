import {
  CheckCircleIcon,
  MagnifyingGlassIcon,
  PencilSquareIcon,
  TrashIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { CommercialProdutTypes, ICompany, itemCountPerPage } from 'config'
import { ProgramTypes } from 'pages/RateSheetOverview'
import { useEffect, useMemo, useState } from 'react'
import { deleteLoanProgram, getCompanyProductsPrograms, getLoanPrograms } from 'services'
import { Button, Input2, Pagination, Tooltip } from 'stories/components'
import { confirm, formatDateYMD } from 'utils'
import { renderHeader } from 'utils/table'

import type { ILoanProgram, ILoanProgramValidateData } from './interface'
import LoanProgramForm from './LoanProgramForm'

const defaultSortOrder = {
  no: 1,
  programCode: 1,
  productName: 1,
  programID: -1,
  mortgageType: 1,
  amortizationType: 1,
  createdAt: 1,
}

const headerList = [
  {
    title: 'No',
    key: 'no',
  },
  {
    title: 'Program Code',
    key: 'programCode',
  },
  {
    title: 'Product Type',
    key: 'productName',
  },
  {
    title: 'Program Name',
    key: 'programID',
  },
  {
    title: 'Mortgage Type',
    key: 'mortgageType',
  },
  {
    title: 'Amortization Type',
    key: 'amortizationType',
  },
  {
    title: 'Created At',
    key: 'createdAt',
  },
]

export function LoanProgramTable({ company }: { company: ICompany }) {
  const [query, setQuery] = useState('')
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [sortOrder, setSortOrder] = useState<Record<string, number>>(defaultSortOrder)
  const [order, setOrder] = useState<Record<string, string>>({})
  const [statistics, setStatistics] = useState<Record<string, string>>({})
  const [loanPrograms, setLoanPrograms] = useState<ILoanProgram[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now())
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [programs, setPrograms] = useState<ILoanProgramValidateData[]>([])
  const [validatedData, setValidatedData] = useState<ILoanProgramValidateData[]>([])
  const [productOptions, setProductOptions] = useState<string[]>([])
  const [programOptions, setProgramOptions] = useState<Record<string, string>>({})

  useEffect(() => {
    getCompanyProductsPrograms(company.id)
      .then(({ products, programs, programsData }) => {
        setProductOptions(products)
        setProgramOptions(programs)
        setPrograms(programsData)
      })
      .catch((err) => console.log(err))

    refresh()
  }, [company])

  useEffect(() => {
    const newData = cloneDeep(programs)
    newData.forEach((item) => {
      const generalProgramID = Object.keys(programOptions).find((key) => programOptions[key] === item.Name) || ''

      let requiredItems = item.Type === ProgramTypes.COMMERCIAL_ONLY_PROGRAMMED ? 4 : 21
      requiredItems -= Number(statistics[generalProgramID] || 0)

      item.Error = requiredItems > 0 ? `Please add ${requiredItems} more items to the list.` : ''

      item.Tooltip = item.Error
        ? `<div><div>- Product Type = ${CommercialProdutTypes[item.ProductType] || 'N/A'}</div><div class="mt-2">- ${
            item.Error
          }</div></div>`
        : `Product Type = ${CommercialProdutTypes[item.ProductType]}`
    })
    setValidatedData(newData)
  }, [statistics, programs])

  const refresh = (pageNum: number = page, orderData: Record<string, string> = order, queryData: string = query) => {
    setIsLoading(true)

    const data = {
      limit: itemCountPerPage,
      page: pageNum + 1,
      order: orderData,
      query: queryData,
    }

    getLoanPrograms(company.id, data)
      .then(({ programCount, loanPrograms, totalNum }) => {
        setTotal(totalNum)
        setLoanPrograms(loanPrograms)
        setStatistics(programCount)
      })
      .finally(() => setIsLoading(false))
  }

  const onSort = async (key: string, orderValue: number) => {
    setSortOrder({ ...defaultSortOrder, [key]: orderValue })
    setOrder({ [key]: orderValue === 1 ? 'ASC' : 'DESC' })

    refresh(page, { [key]: orderValue === 1 ? 'ASC' : 'DESC' })
  }

  const onPageNavigate = async (pageNum: number) => {
    setPage(pageNum)
    await refresh(pageNum)
  }

  const onAdd = () => {
    setLastUpdatedAt(Date.now())
    setSelectedIndex(-1)
    setIsEditing(true)
  }

  const onEdit = (index: number) => {
    setLastUpdatedAt(Date.now())
    setSelectedIndex(index)
    setIsEditing(true)
  }

  const onTrash = async (index: number) => {
    const { id, no } = loanPrograms[index]
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to remove this Loan Program?
        <br />
        <span className="text-gray-600 text-base">Loan Program No: {no}</span>
      </div>
    )
    const result = await confirm(content)
    if (!result) return

    setIsLoading(true)

    deleteLoanProgram(company.id, id)
      .then((res) => {
        if (res.success) refresh()
      })
      .finally(() => setIsLoading(false))
  }

  const renderProgramsValidate = useMemo(() => {
    if (!validatedData.length) return <></>

    return (
      <div className="mt-4 py-2 px-3 shadow1 rounded">
        <div className="text-lg font-semibold">Programs Validation</div>

        <div className="flex gap-1 flex-wrap">
          {validatedData.map((item, index) => (
            <Tooltip key={index} message={item.Tooltip}>
              <Button
                className="mb-0 my-2 py-1 px-2 text-sm transition-all duration-200"
                color={item.Error ? 'gray' : 'green'}
              >
                <div className="flex items-center gap-2">
                  {item.Error ? <XCircleIcon className="w-4 h-4" /> : <CheckCircleIcon className="w-4 h-4" />}
                  <span>{item.Name}</span>
                </div>
              </Button>
            </Tooltip>
          ))}
        </div>
      </div>
    )
  }, [validatedData])

  if (isEditing)
    return (
      <LoanProgramForm
        companyID={company.id}
        productOptions={productOptions}
        programOptions={programOptions}
        lastUpdatedAt={lastUpdatedAt}
        defaults={selectedIndex == -1 ? null : loanPrograms[selectedIndex]}
        onBack={() => setIsEditing(false)}
        onComplete={refresh}
      />
    )

  return (
    <div className="LoanPrograms-container relative p-2">
      <LayoutLoading show={isLoading} />

      <div className="flex flex-wrap justify-between mb-3">
        <div className="md:w-96 w-72">
          <Input2
            type="search"
            title="Search"
            hasIcon
            icon={<MagnifyingGlassIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />}
            value={query}
            onChange={(value) => {
              setQuery(value)
              setPage(0)
              refresh(0, order, value)
            }}
          />
        </div>

        <div className="w-32">
          <Button full onClick={onAdd}>
            Add
          </Button>
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full min-w-max text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {headerList.map((item: Record<string, string>, index: number) => {
                return renderHeader({
                  title: item.title,
                  key: item.key,
                  index: index,
                  onSort: (key: string, order: number) => onSort(key, order),
                  sortable: true,
                  sortOrder: sortOrder[item.key],
                })
              })}
              <th scope="col" className="px-1">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="">
            {loanPrograms.map((program, index) => {
              const { no, programCode, productName, programID, mortgageType, amortizationType, createdAt } = program

              return (
                <tr className={`border-b ${index % 2 ? 'bg-slate-50' : 'bg-white'}`} key={`${index}`}>
                  <td scope="row" className="px-2 py-2 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {no}
                  </td>
                  <td className="px-2">{programCode}</td>
                  <td className="px-2">{productName}</td>
                  <td className="px-2">{programOptions[programID]}</td>
                  <td className="px-2">{mortgageType}</td>
                  <td className="px-2">{amortizationType}</td>
                  <td className="px-2">{formatDateYMD(createdAt)}</td>
                  <td className="">
                    <span className="flex px-1">
                      <span
                        className="text-shade-blue p-1 hover-shadow1 cursor-pointer rounded-sm transition-all duration-200"
                        onClick={() => onEdit(index)}
                      >
                        <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
                      </span>
                      <span
                        className="text-red-800 p-1 hover-shadow1 cursor-pointer rounded-sm transition-all duration-200"
                        onClick={() => onTrash(index)}
                      >
                        <TrashIcon className="w-4 h-4"></TrashIcon>
                      </span>
                    </span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>

        <div className="flex justify-end">
          <Pagination
            totalCount={total}
            itemCountPerPage={itemCountPerPage}
            onNavigate={onPageNavigate}
            pageNum={page}
          />
        </div>
      </div>

      {renderProgramsValidate}
    </div>
  )
}
