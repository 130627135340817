import { ArchiveBoxXMarkIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { PlainInput } from 'components/PlainInput'
import { type IDscrRangeLeverageLimit } from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import {
  convertNullToBlank,
  convertNullToBlankValueToDecimal,
} from 'pages/RateSheetOverview/ExcelSheets/CommercialOnlyProgrammed/logic'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from 'stories/components'
import { confirm, removeComma, solveDecimalJavascriptSum } from 'utils'

interface IProps {
  leverageData: IDscrRangeLeverageLimit[]
  isLoading: boolean
  isTemplate: boolean
  loading: string
  onSave: (data: IDscrRangeLeverageLimit[], key: string) => void
}

export const DscrRangeLeverageLimit = (props: IProps) => {
  const { loading, isTemplate, leverageData, isLoading, onSave } = props

  const [data, setData] = useState<IDscrRangeLeverageLimit[]>([])
  const [edit, setEdit] = useState(false)

  const step = 0.01

  useEffect(() => {
    if (!leverageData) return

    setData(leverageData)

    setEdit(false)
  }, [leverageData])

  const addLimit = () => {
    const newData = cloneDeep(data)

    newData.push({
      dscr: {
        from: !data.length ? 0 : data[data.length - 1].dscr.to + step,
        to: !data.length ? 0 : data[data.length - 1].dscr.to + step,
      },
      loanAmount: {
        purchase: {
          min: NaN,
          max: NaN,
        },
        nocashout: {
          min: NaN,
          max: NaN,
        },
        cashout: {
          min: NaN,
          max: NaN,
        },
      },
      minFICO: {
        purchase: 0,
        nocashout: 0,
        cashout: 0,
      },
      limit: {
        purchase: { ltv: { min: NaN, max: NaN }, cltv: NaN },
        nocashout: { ltv: { min: NaN, max: NaN }, cltv: NaN },
        cashout: { ltv: { min: NaN, max: NaN }, cltv: NaN },
      },
    })

    setData(newData)
    setEdit(true)
  }

  const onChangeLtvLimit = (
    index: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: 'min' | 'max',
    value: any,
  ) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if (newData[index].limit[loanPurpose].ltv[key] == value) return

    newData[index].limit[loanPurpose].ltv[key] = value

    setData(newData)
    setEdit(true)
  }

  const onChangeCLtvLimit = (index: number, loanPurpose: 'purchase' | 'nocashout' | 'cashout', value: any) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if (newData[index].limit[loanPurpose].cltv == value) return

    newData[index].limit[loanPurpose].cltv = value

    setData(newData)
    setEdit(true)
  }

  const onChangeMinFICO = (index: number, loanPurpose: 'purchase' | 'nocashout' | 'cashout', value: any) => {
    value = removeComma(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if (newData[index].minFICO[loanPurpose] == value) return
    newData[index].minFICO[loanPurpose] = value

    setData(newData)
    setEdit(true)
  }

  const removeLtvLimit = async (index: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this row?
        <br />
        <span className="text-gray-600 text-base">
          DSCR: {data[index].dscr.from} - {data[index].dscr.to}
        </span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const newData = cloneDeep(data)

    newData.splice(index, 1)

    for (let i = 1; i < newData.length; i++) {
      if (newData[i].dscr.from - newData[i - 1].dscr.to !== step) newData[i].dscr.from = newData[i - 1].dscr.to + step

      if (newData[i].dscr.from > newData[i].dscr.to) newData[i].dscr.to = newData[i].dscr.from
    }

    setData(newData)
    setEdit(true)
  }

  const onChangeLoanAmountOverly = (
    index: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: string,
    value: any,
  ) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if ((newData[index].loanAmount[loanPurpose] as any)[key] == value) return
    ;(newData[index].loanAmount[loanPurpose] as any)[key] = value

    setData(newData)
    setEdit(true)
  }

  const onChangeDSCR = (index: number, value: any, startValue = false) => {
    const newData = cloneDeep(data)

    value = removeComma(value)

    if (startValue) {
      newData[0].dscr.from = value

      if (newData[0].dscr.from > newData[0].dscr.to) newData[0].dscr.to = newData[0].dscr.from
    } else {
      if (newData[index].dscr.from > value) return

      newData[index].dscr.to = value
    }

    if (index < newData.length - 1)
      for (let i = index + 1; i < newData.length; i++) {
        if (newData[i].dscr.from - newData[i - 1].dscr.to !== step)
          newData[i].dscr.from = solveDecimalJavascriptSum([newData[i - 1].dscr.to, step])

        if (newData[i].dscr.from > newData[i].dscr.to) newData[i].dscr.to = newData[i].dscr.from
      }

    setData(newData)
    setEdit(true)
  }

  const renderLtvLimits = useMemo(() => {
    if (data.length === 0)
      return (
        <div className="w-full flex justify-center items-center mt-10">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limits</span>
          </div>
        </div>
      )

    return (
      <table className="table w-full text-center text-sm">
        <thead className="bg-gray-100">
          <tr>
            <th colSpan={2} className="border p-1 whitespace-nowrap">
              DSCR
            </th>
            <th rowSpan={2} className="border p-1">
              Loan Purpose
            </th>
            <th colSpan={2} className="border p-1">
              Loan Amount
            </th>
            <th rowSpan={2} className="border p-1 w-[100px]">
              Min FICO
            </th>
            <th colSpan={2} className="border p-1">
              LTV
            </th>
            <th rowSpan={2} className="border p-1 w-[100px]">
              Max CLTV
            </th>
            {isTemplate && (
              <th rowSpan={2} className="border p-1 w-[100px]">
                Action
              </th>
            )}
          </tr>
          <tr>
            <th className="border p-1 w-[100px]">From</th>
            <th className="border p-1 w-[100px]">To</th>
            <th className="border p-1 w-[150px]">From</th>
            <th className="border p-1 w-[150px]">To</th>
            <th className="border p-1 w-[100px]">Min</th>
            <th className="border p-1 w-[100px]">Max</th>
          </tr>
        </thead>

        <tbody>
          {data.map((item, index) => {
            return (
              <Fragment key={index}>
                <tr>
                  <td rowSpan={3} className="border p-1">
                    <div className="flex justify-center">
                      <PlainInput
                        value={item.dscr.from}
                        origin={item.dscr.from}
                        content={item.dscr.from}
                        onChange={(value: any) => onChangeDSCR(index, value, index === 0)}
                        disabled={!isTemplate || index !== 0}
                        className="w-full"
                      />
                    </div>
                  </td>
                  <td rowSpan={3} className="border p-1">
                    <div className="flex justify-center">
                      <PlainInput
                        value={item.dscr.to}
                        origin={item.dscr.to}
                        content={item.dscr.to}
                        onChange={(value: any) => onChangeDSCR(index, value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                  <td className={`border whitespace-nowrap p-1`}>Purchase</td>

                  {['min', 'max'].map((key, index1) => (
                    <td key={`${index}-${index1}`} className={`border whitespace-nowrap p-1`}>
                      <div className="flex justify-center">
                        <PlainInput
                          value={convertNullToBlank((item.loanAmount.purchase as any)[key])}
                          origin={convertNullToBlank((item.loanAmount.purchase as any)[key])}
                          content={convertNullToBlankValueToDecimal((item.loanAmount.purchase as any)[key])}
                          onChange={(value: any) => onChangeLoanAmountOverly(index, 'purchase', key, value)}
                          disabled={!isTemplate}
                          className="w-full"
                        />
                      </div>
                    </td>
                  ))}

                  <td className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.minFICO.purchase)}
                        origin={convertNullToBlank(item.minFICO.purchase)}
                        content={convertNullToBlank(item.minFICO.purchase)}
                        onChange={(value: any) => onChangeMinFICO(index, 'purchase', value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>

                  {['min', 'max'].map((key, index1) => (
                    <td key={`${index}-${index1}`} className={`border whitespace-nowrap p-1`}>
                      <div className="flex justify-center">
                        <PlainInput
                          value={convertNullToBlank(item.limit.purchase.ltv[key as 'min' | 'max'])}
                          origin={convertNullToBlank(item.limit.purchase.ltv[key as 'min' | 'max'])}
                          content={convertNullToBlank(item.limit.purchase.ltv[key as 'min' | 'max'])}
                          onChange={(value: any) => onChangeLtvLimit(index, 'purchase', key as 'min' | 'max', value)}
                          disabled={!isTemplate}
                          className="w-full"
                        />
                      </div>
                    </td>
                  ))}

                  <td className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.limit.purchase.cltv)}
                        origin={convertNullToBlank(item.limit.purchase.cltv)}
                        content={convertNullToBlank(item.limit.purchase.cltv)}
                        onChange={(value: any) => onChangeCLtvLimit(index, 'purchase', value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>

                  {isTemplate && (
                    <td rowSpan={3} className="border p-1">
                      <div className="flex justify-center">
                        <div
                          className="w-fit p-1 transition-all duration-200 hover:cursor-pointer hover-shadow1 rounded"
                          onClick={() => removeLtvLimit(index)}
                        >
                          <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                        </div>
                      </div>
                    </td>
                  )}
                </tr>

                <tr>
                  <td className={`border whitespace-nowrap p-1`}>No-Cashout</td>

                  {['min', 'max'].map((key, index1) => (
                    <td key={`${index}-${index1}`} className={`border whitespace-nowrap p-1`}>
                      <div className="flex justify-center">
                        <PlainInput
                          value={convertNullToBlank((item.loanAmount.nocashout as any)[key])}
                          origin={convertNullToBlank((item.loanAmount.nocashout as any)[key])}
                          content={convertNullToBlankValueToDecimal((item.loanAmount.nocashout as any)[key])}
                          onChange={(value: any) => onChangeLoanAmountOverly(index, 'nocashout', key, value)}
                          disabled={!isTemplate}
                          className="w-full"
                        />
                      </div>
                    </td>
                  ))}

                  <td className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.minFICO.nocashout)}
                        origin={convertNullToBlank(item.minFICO.nocashout)}
                        content={convertNullToBlank(item.minFICO.nocashout)}
                        onChange={(value: any) => onChangeMinFICO(index, 'nocashout', value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>

                  {['min', 'max'].map((key, index1) => (
                    <td key={`${index}-${index1}`} className={`border whitespace-nowrap p-1`}>
                      <div className="flex justify-center">
                        <PlainInput
                          value={convertNullToBlank(item.limit.nocashout.ltv[key as 'min' | 'max'])}
                          origin={convertNullToBlank(item.limit.nocashout.ltv[key as 'min' | 'max'])}
                          content={convertNullToBlank(item.limit.nocashout.ltv[key as 'min' | 'max'])}
                          onChange={(value: any) => onChangeLtvLimit(index, 'nocashout', key as 'min' | 'max', value)}
                          disabled={!isTemplate}
                          className="w-full"
                        />
                      </div>
                    </td>
                  ))}

                  <td className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.limit.nocashout.cltv)}
                        origin={convertNullToBlank(item.limit.nocashout.cltv)}
                        content={convertNullToBlank(item.limit.nocashout.cltv)}
                        onChange={(value: any) => onChangeCLtvLimit(index, 'nocashout', value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className={`border whitespace-nowrap p-1`}>Cashout</td>

                  {['min', 'max'].map((key, index1) => (
                    <td key={`${index}-${index1}`} className={`border whitespace-nowrap p-1`}>
                      <div className="flex justify-center">
                        <PlainInput
                          value={convertNullToBlank((item.loanAmount.cashout as any)[key])}
                          origin={convertNullToBlank((item.loanAmount.cashout as any)[key])}
                          content={convertNullToBlankValueToDecimal((item.loanAmount.cashout as any)[key])}
                          onChange={(value: any) => onChangeLoanAmountOverly(index, 'cashout', key, value)}
                          disabled={!isTemplate}
                          className="w-full"
                        />
                      </div>
                    </td>
                  ))}

                  <td className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.minFICO.cashout)}
                        origin={convertNullToBlank(item.minFICO.cashout)}
                        content={convertNullToBlank(item.minFICO.cashout)}
                        onChange={(value: any) => onChangeMinFICO(index, 'cashout', value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>

                  {['min', 'max'].map((key, index1) => (
                    <td key={`${index}-${index1}`} className={`border whitespace-nowrap p-1`}>
                      <div className="flex justify-center">
                        <PlainInput
                          value={convertNullToBlank(item.limit.cashout.ltv[key as 'min' | 'max'])}
                          origin={convertNullToBlank(item.limit.cashout.ltv[key as 'min' | 'max'])}
                          content={convertNullToBlank(item.limit.cashout.ltv[key as 'min' | 'max'])}
                          onChange={(value: any) => onChangeLtvLimit(index, 'cashout', key as 'min' | 'max', value)}
                          disabled={!isTemplate}
                          className="w-full"
                        />
                      </div>
                    </td>
                  ))}

                  <td className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.limit.cashout.cltv)}
                        origin={convertNullToBlank(item.limit.cashout.cltv)}
                        content={convertNullToBlank(item.limit.cashout.cltv)}
                        onChange={(value: any) => onChangeCLtvLimit(index, 'cashout', value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                </tr>
              </Fragment>
            )
          })}
        </tbody>
      </table>
    )
  }, [data])

  return (
    <div className=" relative">
      <LayoutLoading show={LoadingStatus.LEVERAGE_LIMIT === loading || isLoading} />

      <div className="px-2 mt-2">
        {isTemplate && (
          <div className={`flex items-center mb-2 ${edit ? 'justify-between' : 'justify-end'}`}>
            {edit && (
              <Button
                loading={LoadingStatus.LEVERAGE_LIMIT === loading}
                onClick={() => onSave(data, 'DscrLeverageLimit')}
                className="pt-1.5 pb-1.5"
              >
                Save
              </Button>
            )}

            <div
              className="flex items-center gap-2 cursor-pointer w-fit text-shade-blue transition-all duration-200 hover:border-b hover:border-b-shade-blue pr-1 h-6"
              onClick={addLimit}
            >
              <PlusIcon className="w-4 h-4" />
              <span>Add Limit</span>
            </div>
          </div>
        )}

        {renderLtvLimits}
      </div>
    </div>
  )
}
