import {
  API_STATE,
  API_STATE_CITIES,
  API_STATE_CITY,
  API_STATE_COUNTIES,
  API_STATE_COUNTY,
  API_STATE_MSA,
  API_STATE_MSA_DATA,
  API_STATE_MSAS,
  API_STATE_UPDATE_CITY,
  API_STATE_UPDATE_COUNTY,
} from 'config'
import Api from 'services/api'

export const getCities = () => {
  return Api.get(API_STATE_CITIES, {})
}

export const getCounties = () => {
  return Api.get(API_STATE_COUNTIES, {})
}

export const getMsa = (data: Record<string, any>) => {
  return Api.post(API_STATE_MSAS, data, {})
}

export const addMsa = (data: Record<string, any>) => {
  return Api.post(API_STATE_MSA, data, {})
}

export const updateMsa = (data: Record<string, any>) => {
  return Api.put(API_STATE_MSA, data, {})
}

export const removeMsa = (rank: number) => {
  return Api.delete(API_STATE_MSA_DATA, {}, { rank })
}

export const addCity = (data: Record<string, string>) => {
  return Api.post(API_STATE_CITY, data, {})
}

export const removeCity = (data: Record<string, string>) => {
  return Api.put(API_STATE_CITY, data, {})
}

export const updateCity = (data: Record<string, string>) => {
  return Api.put(API_STATE_UPDATE_CITY, data, {})
}

export const addCounty = (data: Record<string, string>) => {
  return Api.post(API_STATE_COUNTY, data, {})
}

export const removeCounty = (data: Record<string, string>) => {
  return Api.put(API_STATE_COUNTY, data, {})
}

export const updateCounty = (data: Record<string, string>) => {
  return Api.put(API_STATE_UPDATE_COUNTY, data, {})
}

export const getStateData = (data: Record<string, string>) => {
  return Api.post(API_STATE, data, {})
}
