import { ArchiveBoxXMarkIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { PlainInput } from 'components/PlainInput'
import type { IExperienceLtvMaxLimits, IProgram } from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Input, Select } from 'stories/components'
import { confirm, isEmpty, removeComma } from 'utils'

import { convertNullToBlank, convertNullToBlankValueToDecimal } from '../logic'

interface IProps {
  isTemplate: boolean
  program: IProgram
  leverageData: IExperienceLtvMaxLimits[]
  isLoading: boolean
  loading: string
  title: string
  onSave: (data: IExperienceLtvMaxLimits[], limitKey: string) => void
}

export const ExperienceLtvMaxLimits = (props: IProps) => {
  const { isTemplate, program, leverageData, loading, isLoading, onSave } = props

  const [data, setData] = useState<IExperienceLtvMaxLimits[]>([])
  const [expOptions, setExpOptions] = useState<string[]>([])
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    const experienceOptions: string[] = []

    for (let i = 0; i <= 100; i++) experienceOptions.push(`${i}`)

    setExpOptions(experienceOptions)
  }, [])

  useEffect(() => {
    if (!program) return
    if (!leverageData) return

    setData(leverageData)

    setEdit(false)
  }, [program, leverageData])

  useEffect(() => {
    if (!loading) setEdit(false)
  }, [loading])

  const addLimit = () => {
    const newData = cloneDeep(data)

    if (!!data.length && data[data.length - 1].to === 100) {
      toast(`All options are already included.`, { type: 'info' })
      return
    }

    newData.push({
      from: data.length === 0 ? 0 : data[data.length - 1].to + 1,
      to: data.length === 0 ? 0 : data[data.length - 1].to + 1,
      limits: [
        {
          FICO: { from: 0, to: 1000 },
          LTV: {
            purchase: {
              aivLtv: NaN,
              arvLtv: NaN,
              ltc: NaN,
              ltp: NaN,
            },
            nocashout: {
              aivLtv: NaN,
              arvLtv: NaN,
              ltc: NaN,
              ltp: NaN,
            },
            cashout: {
              aivLtv: NaN,
              arvLtv: NaN,
              ltc: NaN,
              ltp: NaN,
            },
          },
          loanAmount: {
            purchase: { min: NaN, max: NaN },
            nocashout: { min: NaN, max: NaN },
            cashout: { min: NaN, max: NaN },
          },
          minMonthsReserve: {
            purchase: 0,
            nocashout: 0,
            cashout: 0,
          },
        },
      ],
    })

    setData(newData)
    setEdit(true)
  }

  const addSubLimit = (index: number) => {
    const newData = cloneDeep(data)

    newData[index].limits.push({
      FICO: {
        from: newData[index].limits[newData[index].limits.length - 1].FICO.from,
        to: newData[index].limits[newData[index].limits.length - 1].FICO.to,
      },
      LTV: {
        purchase: {
          aivLtv: NaN,
          arvLtv: NaN,
          ltc: NaN,
          ltp: NaN,
        },
        nocashout: {
          aivLtv: NaN,
          arvLtv: NaN,
          ltc: NaN,
          ltp: NaN,
        },
        cashout: {
          aivLtv: NaN,
          arvLtv: NaN,
          ltc: NaN,
          ltp: NaN,
        },
      },
      loanAmount: {
        purchase: { min: NaN, max: NaN },
        nocashout: { min: NaN, max: NaN },
        cashout: { min: NaN, max: NaN },
      },
      minMonthsReserve: {
        purchase: 0,
        nocashout: 0,
        cashout: 0,
      },
    })

    setData(newData)
    setEdit(true)
  }

  const onChangeFICO = (index: number, idx: number, key: string, value: any) => {
    value = removeComma(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if ((newData[index].limits[idx].FICO as any)[key] === value) return
    ;(newData[index].limits[idx].FICO as any)[key] = value

    setData(newData)
    setEdit(true)
  }

  const removeLtvLimit = async (index: number, idx: number) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this row?
        <br />
        <span className="text-gray-600 text-base">
          Experience: {data[index].from} - {data[index].to}
        </span>
        <br />
        <span className="text-gray-600 text-base">
          FICO: {data[index].limits[idx].FICO.from} - {data[index].limits[idx].FICO.to}
        </span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    const newData = cloneDeep(data)

    newData[index].limits.splice(idx, 1)

    if (!newData[index].limits.length) {
      newData.splice(index, 1)
      for (let i = 1; i < newData.length; i++) {
        if (newData[i].from - newData[i - 1].to !== 1) newData[i].from = newData[i - 1].to + 1
        if (newData[i].from > newData[i].to) newData[i].to = newData[i].from
      }
    }

    setData(newData)
    setEdit(true)
  }

  const onChangeExperienceRange = (index: number, value: number, startValue = false) => {
    const newData = cloneDeep(data)

    if (value === 100 && index !== data.length - 1) return

    if (startValue) {
      newData[0].from = value

      if (newData[0].from > newData[0].to) newData[0].to = newData[0].from
    } else {
      if (newData[index].from > value) return

      newData[index].to = value
    }

    if (index < newData.length - 1)
      for (let i = index + 1; i < newData.length; i++) {
        if (newData[i].from - newData[i - 1].to !== 1) newData[i].from = newData[i - 1].to + 1

        if (newData[i].from > newData[i].to) newData[i].to = newData[i].from
      }

    if (!!newData.length && newData[newData.length - 1].to > 100) return

    setData(newData)
    setEdit(true)
  }

  const onChangeLtvLimit = (
    index: number,
    idx: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: string,
    value: any,
  ) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if ((newData[index].limits[idx].LTV[loanPurpose] as any)[key] === value) return
    ;(newData[index].limits[idx].LTV[loanPurpose] as any)[key] = value

    setData(newData)
    setEdit(true)
  }

  const onChangeMinMonthsReserve = (
    index: number,
    idx: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    value: any,
  ) => {
    value = removeComma(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if (newData[index].limits[idx].minMonthsReserve[loanPurpose] === value) return
    newData[index].limits[idx].minMonthsReserve[loanPurpose] = value

    setData(newData)
    setEdit(true)
  }

  const onChangeLoanAmountOverly = (
    index: number,
    idx: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: string,
    value: any,
  ) => {
    if (value == '') value = NaN
    else value = isNaN(Number(value)) ? NaN : Number(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    const newData = cloneDeep(data)

    if ((newData[index].limits[idx].loanAmount[loanPurpose] as any)[key] == value) return
    ;(newData[index].limits[idx].loanAmount[loanPurpose] as any)[key] = value

    setData(newData)
    setEdit(true)
  }

  const renderLtvMaxLimits = useMemo(() => {
    if (data.length === 0)
      return (
        <div className="w-full flex justify-center items-center mt-10">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limits</span>
          </div>
        </div>
      )

    return (
      <table className="table w-full text-center text-sm">
        <thead className="bg-gray-100">
          <tr>
            <th rowSpan={2} className="border p-1 whitespace-nowrap">
              Experience
            </th>
            <th colSpan={2} className="border p-1 whitespace-nowrap">
              FICO
            </th>
            <th rowSpan={2} className="border p-1 whitespace-nowrap">
              Loan Purpose
            </th>
            <th colSpan={2} className="border p-1 whitespace-nowrap">
              Loan Amount
            </th>
            <th rowSpan={2} className="border p-1 w-[100px]">
              Min Months Reserve
            </th>
            <th colSpan={4} className="border p-1 whitespace-nowrap">
              Max LTVs
            </th>

            {isTemplate && (
              <th rowSpan={2} className="border p-1">
                Action
              </th>
            )}
          </tr>
          <tr>
            <th className="border p-1 w-[90px]">From</th>
            <th className="border p-1 w-[90px]">To</th>
            <th className="border p-1 w-[120px]">From</th>
            <th className="border p-1 w-[120px]">To</th>
            <th className="border p-1 w-[90px]">AIV-LTV</th>
            <th className="border p-1 w-[90px]">ARV-LTV</th>
            <th className="border p-1 w-[90px]">LTC</th>
            <th className="border p-1 w-[90px]">LTP</th>
          </tr>
        </thead>

        <tbody>
          {data.map((item, index) => {
            return (
              <Fragment key={index}>
                <tr>
                  <td rowSpan={3 * item.limits.length + 1} className="border px-1 pb-1 pt-4">
                    {isTemplate ? (
                      <div className="flex justify-center items-center gap-2">
                        {index === 0 ? (
                          <Select
                            id="from"
                            options={expOptions}
                            title="From"
                            value={item.from.toString()}
                            className="w-[70px] mb-[-16px]"
                            onChange={(v) => onChangeExperienceRange(index, Number(v), true)}
                          />
                        ) : (
                          <Input
                            title="From"
                            value={item.from.toString()}
                            className="w-[70px] mb-[-16px]"
                            disabled={true}
                            onChange={() => {}}
                          />
                        )}
                        <Select
                          id="to"
                          options={expOptions}
                          title="To"
                          value={item.to.toString()}
                          className="w-[70px] mb-[-16px]"
                          onChange={(v) => onChangeExperienceRange(index, Number(v))}
                        />
                      </div>
                    ) : (
                      <div className="flex justify-center">
                        {item.from} - {item.to}
                      </div>
                    )}

                    {isTemplate && (
                      <div className="mt-1 flex justify-center w-full">
                        <span
                          className="cursor-pointer text-shade-blue hover:scale-105 transform duration-100 hover:underline"
                          onClick={() => addSubLimit(index)}
                        >
                          Add Limit
                        </span>
                      </div>
                    )}
                  </td>
                </tr>

                {item.limits.map((limit, idx) => (
                  <Fragment key={`${index}-${idx}`}>
                    <tr>
                      {['from', 'to'].map((key, index1) => (
                        <td rowSpan={3} key={`${index}-${idx}-${index1}`} className={`border whitespace-nowrap p-1`}>
                          <div className="flex justify-center">
                            <PlainInput
                              value={(limit.FICO as any)[key]}
                              origin={(limit.FICO as any)[key]}
                              content={(limit.FICO as any)[key]}
                              onChange={(value: any) => onChangeFICO(index, idx, key, value)}
                              className="w-full"
                              disabled={!isTemplate}
                            />
                          </div>
                        </td>
                      ))}

                      <td className="border p-1">Purchase</td>

                      {['min', 'max'].map((key, index1) => (
                        <td
                          key={`${index}-${idx}-${index1}`}
                          className={`border whitespace-nowrap p-1 ${isTemplate ? 'bg-gray-50' : ''}`}
                        >
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank((limit.loanAmount.purchase as any)[key])}
                              origin={convertNullToBlank((limit.loanAmount.purchase as any)[key])}
                              content={convertNullToBlankValueToDecimal((limit.loanAmount.purchase as any)[key])}
                              onChange={(value: any) => onChangeLoanAmountOverly(index, idx, 'purchase', key, value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}

                      <td className={`p-1 border ${!isTemplate ? '' : 'bg-gray-50'}`}>
                        <div className="flex justify-center">
                          <PlainInput
                            value={convertNullToBlank(limit.minMonthsReserve.purchase)}
                            origin={convertNullToBlank(limit.minMonthsReserve.purchase)}
                            content={convertNullToBlank(limit.minMonthsReserve.purchase)}
                            disabled={!isTemplate}
                            onChange={(value: any) => onChangeMinMonthsReserve(index, idx, 'purchase', value)}
                            className="w-full"
                          />
                        </div>
                      </td>

                      {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, index1) => (
                        <td
                          key={`${index}-${idx}-${index1}`}
                          className={`p-1 border ${
                            isEmpty(program.OtherTypeProgramData.ltvLimits.purchase[key]) || !isTemplate
                              ? ''
                              : 'bg-gray-50'
                          }`}
                        >
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank((limit.LTV.purchase as any)[key])}
                              origin={convertNullToBlank((limit.LTV.purchase as any)[key])}
                              content={convertNullToBlank((limit.LTV.purchase as any)[key])}
                              disabled={isEmpty(program.OtherTypeProgramData.ltvLimits.purchase[key]) || !isTemplate}
                              onChange={(value: any) => onChangeLtvLimit(index, idx, 'purchase', key, value)}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}

                      {isTemplate && (
                        <td rowSpan={3} className="border p-1">
                          <div className="flex justify-center">
                            <div
                              className="w-fit p-1 transition-all duration-200 hover:cursor-pointer hover-shadow1 rounded"
                              onClick={() => removeLtvLimit(index, idx)}
                            >
                              <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                            </div>
                          </div>
                        </td>
                      )}
                    </tr>

                    <tr>
                      <td className="border p-1">No-Cashout</td>

                      {['min', 'max'].map((key, index1) => (
                        <td
                          key={`${index}-${idx}-${index1}`}
                          className={`border whitespace-nowrap p-1 ${isTemplate ? 'bg-gray-50' : ''}`}
                        >
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank((limit.loanAmount.nocashout as any)[key])}
                              origin={convertNullToBlank((limit.loanAmount.nocashout as any)[key])}
                              content={convertNullToBlankValueToDecimal((limit.loanAmount.nocashout as any)[key])}
                              onChange={(value: any) => onChangeLoanAmountOverly(index, idx, 'nocashout', key, value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}

                      <td className={`p-1 border ${!isTemplate ? '' : 'bg-gray-50'}`}>
                        <div className="flex justify-center">
                          <PlainInput
                            value={convertNullToBlank(limit.minMonthsReserve.nocashout)}
                            origin={convertNullToBlank(limit.minMonthsReserve.nocashout)}
                            content={convertNullToBlank(limit.minMonthsReserve.nocashout)}
                            disabled={!isTemplate}
                            onChange={(value: any) => onChangeMinMonthsReserve(index, idx, 'nocashout', value)}
                            className="w-full"
                          />
                        </div>
                      </td>

                      {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, index1) => (
                        <td
                          key={`${index}-${idx}-${index1}`}
                          className={`p-1 border ${
                            isEmpty(program.OtherTypeProgramData.ltvLimits.nocashout[key]) || !isTemplate
                              ? ''
                              : 'bg-gray-50'
                          }`}
                        >
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank((limit.LTV.nocashout as any)[key])}
                              origin={convertNullToBlank((limit.LTV.nocashout as any)[key])}
                              content={convertNullToBlank((limit.LTV.nocashout as any)[key])}
                              disabled={isEmpty(program.OtherTypeProgramData.ltvLimits.nocashout[key]) || !isTemplate}
                              onChange={(value: any) => onChangeLtvLimit(index, idx, 'nocashout', key, value)}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}
                    </tr>

                    <tr>
                      <td className="border p-1">Cashout</td>

                      {['min', 'max'].map((key, index1) => (
                        <td
                          key={`${index}-${idx}-${index1}`}
                          className={`border whitespace-nowrap p-1 ${isTemplate ? 'bg-gray-50' : ''}`}
                        >
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank((limit.loanAmount.cashout as any)[key])}
                              origin={convertNullToBlank((limit.loanAmount.cashout as any)[key])}
                              content={convertNullToBlankValueToDecimal((limit.loanAmount.cashout as any)[key])}
                              onChange={(value: any) => onChangeLoanAmountOverly(index, idx, 'cashout', key, value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}

                      <td className={`p-1 border ${!isTemplate ? '' : 'bg-gray-50'}`}>
                        <div className="flex justify-center">
                          <PlainInput
                            value={convertNullToBlank(limit.minMonthsReserve.cashout)}
                            origin={convertNullToBlank(limit.minMonthsReserve.cashout)}
                            content={convertNullToBlank(limit.minMonthsReserve.cashout)}
                            disabled={!isTemplate}
                            onChange={(value: any) => onChangeMinMonthsReserve(index, idx, 'cashout', value)}
                            className="w-full"
                          />
                        </div>
                      </td>

                      {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, index1) => (
                        <td
                          key={`${index}-${idx}-${index1}`}
                          className={`p-1 border ${
                            isEmpty(program.OtherTypeProgramData.ltvLimits.cashout[key]) || !isTemplate
                              ? ''
                              : 'bg-gray-50'
                          }`}
                        >
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank((limit.LTV.cashout as any)[key])}
                              origin={convertNullToBlank((limit.LTV.cashout as any)[key])}
                              content={convertNullToBlank((limit.LTV.cashout as any)[key])}
                              disabled={isEmpty(program.OtherTypeProgramData.ltvLimits.cashout[key]) || !isTemplate}
                              onChange={(value: any) => onChangeLtvLimit(index, idx, 'cashout', key, value)}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}
                    </tr>
                  </Fragment>
                ))}
              </Fragment>
            )
          })}
        </tbody>
      </table>
    )
  }, [data])

  return (
    <div className="relative">
      <LayoutLoading show={LoadingStatus.LEVERAGE_LIMIT === loading || isLoading} />

      <div className="px-2 mt-2">
        {isTemplate && (
          <div className={`flex items-center ${edit ? 'justify-between' : 'justify-end'} mb-2`}>
            {edit && (
              <Button
                loading={LoadingStatus.LEVERAGE_LIMIT === loading}
                onClick={() => onSave(data, 'experienceLtvLimits')}
                className="pt-1.5 pb-1.5"
              >
                Save
              </Button>
            )}

            <div
              className="flex items-center gap-2 cursor-pointer w-fit text-shade-blue transition-all duration-200 hover:border-b hover:border-b-shade-blue pr-1 h-6"
              onClick={addLimit}
            >
              <PlusIcon className="w-4 h-4" />
              <span>Add Limit</span>
            </div>
          </div>
        )}

        {renderLtvMaxLimits}
      </div>
    </div>
  )
}
