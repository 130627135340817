import cloneDeep from 'clone-deep'
import type { InputType } from 'config'

import { ARMDetailFields } from './constants'

export const inputLogic = (data: Record<string, InputType>) => {
  const newData = cloneDeep(data)

  ARMDetailFields.map((key) => {
    newData[key].visible = newData['amortizationType'].value === 'ARM'
  })

  if (newData.ARMNoAdjCap.value) {
    newData.ARMAdjustCapFirst.required = false
    newData.ARMAdjustCapFirst.value = ''
    newData.ARMAdjustCapFirst.disabled = true
    newData.ARMAdjustCapSubsequent.required = false
    newData.ARMAdjustCapSubsequent.value = ''
    newData.ARMAdjustCapSubsequent.disabled = true
  } else {
    newData.ARMAdjustCapFirst.required = true
    newData.ARMAdjustCapFirst.disabled = false
    newData.ARMAdjustCapSubsequent.required = true
    newData.ARMAdjustCapSubsequent.disabled = false
  }

  newData.ioMonths.visible = newData.interestOnly.value === true

  return newData
}

export const parseProductType = (data: Record<string, InputType>) => {
  let newData = cloneDeep(data)

  const productType: string = newData['productName'].value

  const isARM = productType.includes('ARM')
  newData['amortizationType'].value = isARM ? 'ARM' : 'Fixed'

  newData['interestOnly'].value = productType.includes('I/O') || productType.includes('IO') ? true : false

  let months = 0
  const monthsIndex = productType.indexOf('months')
  const yearIndex = productType.indexOf('yr')
  if (monthsIndex !== -1) months = Number(productType.slice(0, monthsIndex - 1))
  if (yearIndex !== -1) months = Number(productType.slice(yearIndex - 2, yearIndex)) * 12
  newData['loanTerm'].value = String(months)

  newData = inputLogic(newData)

  return newData
}
