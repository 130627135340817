import type { ILTVs } from 'config'

import { RestrictionTable } from './components/RestrictionTable'

interface Props {
  title: string
  loading: string
  onSave: (restriction: Record<string, ILTVs[] | undefined>) => {}
  onChangeLtvReduction: (value: number) => Promise<boolean>
  onCancel: () => {}
}

export function DSCR({ title, onSave, onCancel }: Props) {
  return (
    <div>
      <p className="text-lg font-variation-settings-600 mb-3">{title}</p>
      <RestrictionTable onSave={onSave} onCancel={onCancel} restrictionKey="DSCR" />
      {/* <DSCRConditions loading={loading} onChangeLtvReduction={onChangeLtvReduction} /> */}
    </div>
  )
}
