import { PencilSquareIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { IStateMSA, itemCountPerPage } from 'config'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { addMsa, getMsa, removeMsa, updateMsa } from 'services/apis/admin'
import { svgSearch } from 'stories/assets'
import { Input2 } from 'stories/components'
import { confirm } from 'utils'
import { renderHeader } from 'utils/table'

import { MsaEditModal } from './MsaEditModal'

interface IProps {
  title: string
}

const headerList = [
  // {
  //   title: 'Rank',
  //   key: 'sortRank',
  // },
  {
    title: 'MSA Name',
    key: 'sortMsa',
  },
]

const defaultSortOrder = {
  // sortRank: 1,
  sortMsa: 1,
}

export const StateMsa = (props: IProps) => {
  const { title } = props

  const [loading, setLoading] = useState('')
  const [msaData, setMsaData] = useState<IStateMSA[]>([])
  const [selectedMsa, setSelectedMsa] = useState<IStateMSA | undefined>()
  const [sortOrder, setSortOrder] = useState<Record<string, number>>(defaultSortOrder)
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState<number>(0)
  const [orderKey, setOrderKey] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  useEffect(() => {
    getMsaData()
  }, [])

  const getMsaData = async (pageNum: number = page) => {
    setLoading('getMsa')

    try {
      const data = {
        limit: itemCountPerPage,
        page: pageNum + 1,
        msaName: searchValue.trim(),
        [orderKey]: sortOrder[orderKey],
      }

      const res = await getMsa(data)

      setMsaData(res.msa)
      setTotal(res.total)
    } catch (error) {
      console.log(error)
    }

    setLoading('')
  }

  const onSort = async (key: string, orderValue: number) => {
    setSortOrder({ ...defaultSortOrder, [key]: orderValue })
    setOrderKey(key)

    setLoading('getMsa')

    try {
      const data = {
        limit: itemCountPerPage,
        page: page + 1,
        msaName: searchValue.trim(),
        [key]: orderValue,
      }

      const res = await getMsa(data)

      setMsaData(res.msa)
      setTotal(res.total)
    } catch (error) {
      console.log(error)
    }

    setLoading('')
  }

  // const onPageNavigate = (pageNum: number) => {
  //   setPage(pageNum)
  //   getMsaData(pageNum)
  // }

  const onSearchByName = async (str: string) => {
    setSearchValue(str)
    setPage(0)

    setLoading('getMsa')

    try {
      const data = {
        limit: itemCountPerPage,
        page: 1,
        msaName: str.trim(),
        [orderKey]: sortOrder[orderKey],
      }

      const res = await getMsa(data)

      setMsaData(res.msa)
      setTotal(res.total)
    } catch (error) {
      console.log(error)
    }

    setLoading('')
  }

  const onAddNewMsa = async (newMsa: IStateMSA) => {
    setLoading('addMsa')

    try {
      const data = {
        newMsa,
      }

      await addMsa(data)

      await getMsaData()

      toast(`New MSA has been added`, { type: 'info' })
      setModal(false)
    } catch (error) {
      console.log(error)
    }

    setLoading('')
  }

  const onUpdateMsa = async (rank: number, newMsa: IStateMSA) => {
    setLoading('updateMsa')

    try {
      const data = {
        rank: rank,
        newMsa: newMsa,
      }

      await updateMsa(data)

      await getMsaData()

      toast(`MSA has been updated`, { type: 'info' })
      setModal(false)
    } catch (error) {
      console.log(error)
    }

    setLoading('')
  }

  const onRemoveMsa = async (rank: number, name: string) => {
    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this MSA?
        <br />
        <span className="text-gray-600 text-base">MSA Name: {name}</span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    setLoading('removeMsa')

    try {
      await removeMsa(Number(rank))
      getMsaData()
    } catch (error) {
      console.log(error)
    }

    setLoading('')
  }

  return (
    <div className="relative">
      <LayoutLoading show={['getMsa', 'removeMsa'].includes(loading)} />

      <div className="text-2xl font-variation-settings-600 mb-3">
        <span>{title}</span>
        <span className="ml-2 text-base">({total})</span>
      </div>

      <div className="flex justify-between items-end pr-2 mb-3">
        <Input2
          type="search"
          title="Search By MSA Name"
          hasIcon
          icon={svgSearch}
          value={searchValue}
          className="w-[400px]"
          onChange={(value) => onSearchByName(value)}
        />

        <div
          className="flex items-center pr-1 cursor-pointer hover:border-b hover:text-shade-blue hover:border-b-shade-blue h-6"
          onClick={() => {
            setModal(true)
            setIsEdit(false)
          }}
        >
          <PlusIcon className="w-4 h-4 mr-1" />
          <span className="text-sm">New MSA</span>
        </div>
      </div>

      <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-3 py-3">
              No
            </th>
            {headerList.map((item: Record<string, string>, index: number) => {
              return renderHeader({
                title: item.title,
                key: item.key,
                index: index,
                onSort: (key: string, order: number) => onSort(key, order),
                sortable: true,
                sortOrder: sortOrder[item.key],
              })
            })}
            <th scope="col" className="px-3 py-3">
              States MSA
            </th>
            <th scope="col" className="px-3 py-3">
              Action
            </th>
          </tr>
        </thead>

        <tbody>
          {msaData &&
            msaData.map((item, index) => {
              const statesMsa = item.states.join('-') + ' MSA'
              return (
                <tr className={`border-b ${index % 2 ? 'bg-slate-50' : 'bg-white'}`} key={index}>
                  <td scope="row" className="px-3 py-3">
                    {index + 1}
                  </td>
                  {/* <td scope="row" className="px-3 py-3">
                    {item.msa.ranking}
                  </td> */}
                  <td scope="row" className="px-3 py-3">
                    {item.msa.name}
                  </td>
                  <td scope="row" className="px-3 py-3">
                    {statesMsa}
                  </td>
                  <td className="px-3">
                    <div className="flex justify-start">
                      <div
                        className="text-shade-blue p-1 hover-shadow1 cursor-pointer rounded mr-1"
                        onClick={() => {
                          setSelectedMsa(item)
                          setModal(true)
                          setIsEdit(true)
                        }}
                      >
                        <PencilSquareIcon className="w-4 h-4" />
                      </div>
                      <div
                        className="text-red-600 p-1 hover-shadow1 cursor-pointer rounded mr-1"
                        onClick={() => onRemoveMsa(item.msa.ranking, item.msa.name)}
                      >
                        <TrashIcon className="w-4 h-4" />
                      </div>
                    </div>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>

      {/* <div className="flex justify-end">
        <Pagination totalCount={total} itemCountPerPage={itemCountPerPage} onNavigate={onPageNavigate} pageNum={page} />
      </div> */}

      {modal && (
        <MsaEditModal
          isEdit={isEdit}
          data={selectedMsa}
          isOpen={modal}
          loading={loading}
          onClose={() => {
            setModal(false)
            setSelectedMsa(undefined)
          }}
          onAdd={onAddNewMsa}
          onUpdate={onUpdateMsa}
        />
      )}
    </div>
  )
}
