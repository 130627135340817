import cloneDeep from 'clone-deep'
import type { ICityCountyTier, IExperienceCityCountyTier } from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import { useEffect, useState } from 'react'
import { getStateData } from 'services/apis/admin'
import { svgSearch } from 'stories/assets'
import { Checkbox, Input2, Modal } from 'stories/components'

import { checkAndGetValue, checkDisableValue, getAllAvailableItems, parseValues } from '../logic'

interface IProps {
  isTemplate: boolean
  state: string
  title: string
  tierData: ICityCountyTier | IExperienceCityCountyTier
  allTiers: ICityCountyTier[] | IExperienceCityCountyTier[]
  isOpen: boolean
  loading: string
  onClose: () => void
  onSubmit: (values: string[]) => void
}

export const StateDataModal = (props: IProps) => {
  const { isTemplate, state, title, tierData, allTiers, isOpen, loading, onClose, onSubmit } = props

  const [stateData, setStateData] = useState<string[]>([])
  const [values, setValues] = useState<string[]>([])
  const [selectedItems, setSelectedItems] = useState(0)
  const [isAll, setIsAll] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [searchedItems, setSearchedItems] = useState<string[]>([])

  useEffect(() => {
    ;(async () => {
      const data = {
        state: state,
        key: title,
      }

      setIsLoading(true)

      const res = await getStateData(data)

      setStateData(res)
      setSearchedItems(res)
      setIsLoading(false)
    })()
  }, [])

  useEffect(() => {
    if (Object.keys(tierData.values).includes(state)) setValues(tierData.values[state])
  }, [tierData])

  useEffect(() => {
    const parseRlt = parseValues(allTiers, values, state, tierData.title, stateData)

    setIsAll(parseRlt.isAll)
    setSelectedItems(parseRlt.selectedItems)
  }, [values, stateData])

  const onSelectItem = (value: string) => {
    if (!isTemplate) return

    const newValues = cloneDeep(values)

    if (newValues.includes(value)) {
      const removeIndex = newValues.findIndex((item) => item === value)
      newValues.splice(removeIndex, 1)
    } else {
      newValues.push(value)
    }

    setValues(newValues)
  }

  const onSelectAll = () => {
    if (isAll) {
      setValues([])
    } else {
      const allAvailableValues = getAllAvailableItems(allTiers, stateData, state, tierData.title)

      setValues(allAvailableValues)
    }
  }

  const onSearch = (value: string) => {
    setSearchValue(value)

    const newStateData = stateData.filter((item) => item.toLowerCase().includes(value.trim().toLowerCase()))

    setSearchedItems(newStateData)
  }

  return (
    <div>
      <Modal
        title={`${state} ${title === 'city' ? 'Cities' : title === 'county' ? 'Counties' : ''} (${selectedItems}/${
          stateData.length
        })`}
        titleOkay={`${isTemplate ? 'Save' : ''}`}
        isOpen={isOpen}
        loading={isLoading || loading === LoadingStatus.TIER_VALUES}
        onClose={onClose}
        onOk={() => onSubmit(values)}
      >
        <div className="min-w-[300px]">
          <div className="flex items-end gap-3 mb-2">
            {isTemplate && (
              <Checkbox
                id="select-all"
                title="Select All"
                value={isAll}
                fontClass="font-variation-settings-600 text-base"
                color="gray"
                onClick={onSelectAll}
              />
            )}

            <Input2
              title="Search By Name"
              type="search"
              value={searchValue}
              hasIcon
              icon={svgSearch}
              onChange={(value) => onSearch(value)}
            />
          </div>

          <div className="flex flex-wrap gap-4 border rounded p-2">
            {searchedItems && searchedItems?.length > 0 ? (
              searchedItems.map((item, index) => (
                <Checkbox
                  key={index}
                  id={index.toString()}
                  title={item}
                  value={checkAndGetValue(allTiers, values, item, state, tierData.title)}
                  className="w-fit"
                  color="gray"
                  disabled={checkDisableValue(allTiers, item, state, tierData.title)}
                  onClick={() => onSelectItem(item)}
                />
              ))
            ) : (
              <span>No {title === 'city' ? 'Cities' : title === 'county' ? 'Counties' : ''}</span>
            )}
          </div>
        </div>
      </Modal>
    </div>
  )
}
