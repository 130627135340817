import { ArchiveBoxXMarkIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { PlainInput } from 'components/PlainInput'
import { type IDscrInterestOnlyLeverageLimit, type IInterestOnlyLimit, LoanPurposeMapping } from 'config'
import { LoadingStatus } from 'pages/RateSheetOverview'
import {
  convertNullToBlank,
  convertNullToPlus,
} from 'pages/RateSheetOverview/ExcelSheets/CommercialOnlyProgrammed/logic'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from 'stories/components'
import { confirm, getPrice3decimal, removeComma } from 'utils'

interface IProps {
  leverageData: IDscrInterestOnlyLeverageLimit
  isLoading: boolean
  isTemplate: boolean
  title: string
  loading: string
  onSave: (data: IDscrInterestOnlyLeverageLimit | undefined, key: string) => void
}

export const DscrInterestOnlyLeverageMaxLimit = (props: IProps) => {
  const { loading, isTemplate, leverageData, isLoading, onSave } = props

  const [data, setData] = useState<IDscrInterestOnlyLeverageLimit>()
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    if (!leverageData) return

    setData(leverageData)

    setEdit(false)
  }, [leverageData])

  const addLtvLimit = (key: string) => {
    const newData = cloneDeep(data)
    const limit: IInterestOnlyLimit[] = (newData as any)[key]

    limit.push({
      fico: {
        from: !limit.length ? 0 : limit[limit.length - 1].fico.from,
        to: !limit.length ? 1000 : limit[limit.length - 1].fico.to,
      },
      minDscr: 0,
      minMonthsReserve: 0,
      minLoanAmount: 0,
      maxLoanAmount: 1000000000,
      minLTV: NaN,
      maxLTV: NaN,
      maxCLTV: NaN,
    })
    ;(newData as any)[key] = limit

    setData(newData)
    setEdit(true)
  }

  const removeLimit = async (index: number, key: string) => {
    const newData = cloneDeep(data)
    const limit: IInterestOnlyLimit[] = (newData as any)[key]

    const content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to delete this row?
        <br />
        <span className="text-gray-600 text-base">
          FICO: {limit[index].fico.from} - {limit[index].fico.to}
        </span>
      </div>
    )

    const result = await confirm(content)

    if (!result) return

    limit.splice(index, 1)
    ;(newData as any)[key] = limit

    setData(newData)
    setEdit(true)
  }

  const changeFICO = (loanPurpose: string, index: number, key: 'from' | 'to', value: any) => {
    const newData = cloneDeep(data)
    const limit: IInterestOnlyLimit[] = (newData as any)[loanPurpose]

    value = removeComma(value)

    if (value < 0) {
      toast(`Positive value required.`, {
        type: 'info',
      })
      return
    }

    if (limit[index].fico[key] === value) return
    limit[index].fico[key] = value
    ;(newData as any)[loanPurpose] = limit

    setData(newData)
    setEdit(true)
  }

  const changeLimit = (
    loanPurpose: string,
    index: number,
    key: 'minDscr' | 'minLoanAmount' | 'maxLoanAmount' | 'maxCLTV' | 'maxLTV' | 'minLTV' | 'minMonthsReserve',
    value: any,
  ) => {
    const newData = cloneDeep(data)
    const limit: IInterestOnlyLimit[] = (newData as any)[loanPurpose]

    if (['maxLTV', 'minLTV', 'maxCLTV'].includes(key)) {
      if (value == '') value = NaN
      else value = isNaN(Number(value)) ? NaN : Number(value)
    } else value = removeComma(value)

    limit[index][key] = value
    ;(newData as any)[loanPurpose] = limit

    setData(newData)
    setEdit(true)
  }

  const renderLtvLimits = useMemo(() => {
    if (!data)
      return (
        <div className="w-full flex justify-center items-center mt-10">
          <div className="flex flex-col items-center gap-1">
            <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
            <span className="text-gray-400">No Limits</span>
          </div>
        </div>
      )

    return (
      <div>
        {Object.keys(data)
          .sort((a, b) => {
            if (a < b) return 1
            if (a > b) return -1
            else return 0
          })
          .map((key, index) => {
            const limit: IInterestOnlyLimit[] = (data as any)[key]

            return (
              <div key={index} className={`${index !== 0 ? 'mt-3' : ''}`}>
                <table className="table w-full text-center text-sm">
                  <thead className="bg-gray-100">
                    <tr>
                      <th colSpan={10} className="border px-2 py-1">
                        {LoanPurposeMapping[key]}
                      </th>
                    </tr>
                    <tr>
                      <th colSpan={2} className="border px-2 py-1 whitespace-nowrap">
                        FICO
                      </th>
                      <th colSpan={2} className="border px-2 py-1 whitespace-nowrap">
                        Loan Amount
                      </th>
                      <th rowSpan={2} className="border px-2 py-1 w-[100px]">
                        Min DSCR
                      </th>
                      <th rowSpan={2} className="border px-2 py-1 w-[110px]">
                        Min Months Reserve
                      </th>
                      <th colSpan={2} className="border px-2 py-1">
                        LTV
                      </th>
                      <th rowSpan={2} className="border px-2 py-1 w-[100px]">
                        Max CLTV
                      </th>
                      {isTemplate && (
                        <th rowSpan={2} className="border px-2 py-1 w-[90px]">
                          Action
                        </th>
                      )}
                    </tr>
                    <tr>
                      <th className="border px-2 py-1 w-[120px]">From</th>
                      <th className="border px-2 py-1 w-[120px]">To</th>
                      <th className="border px-2 py-1 w-[140px]">From</th>
                      <th className="border px-2 py-1 w-[140px]">To</th>
                      <th className="border px-2 py-1 w-[80px]">Min</th>
                      <th className="border px-2 py-1 w-[80px]">Max</th>
                    </tr>
                  </thead>
                  <tbody>
                    {limit.map((item, idx) => (
                      <tr key={`${index}-${idx}`}>
                        <td className="border p-1">
                          <div className="flex justify-center">
                            <PlainInput
                              value={item.fico.from}
                              origin={item.fico.from}
                              content={item.fico.from}
                              onChange={(value: any) => changeFICO(key, idx, 'from', value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                        <td className="border p-1">
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToPlus(item.fico.to)}
                              origin={convertNullToPlus(item.fico.to)}
                              content={convertNullToPlus(item.fico.to)}
                              onChange={(value: any) => changeFICO(key, idx, 'to', value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                        <td className="border p-1">
                          <div className="flex justify-center">
                            <PlainInput
                              value={item.minLoanAmount}
                              origin={item.minLoanAmount}
                              content={getPrice3decimal(item.minLoanAmount)}
                              onChange={(value: any) => changeLimit(key, idx, 'minLoanAmount', value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                        <td className="border p-1">
                          <div className="flex justify-center">
                            <PlainInput
                              value={item.maxLoanAmount}
                              origin={item.maxLoanAmount}
                              content={getPrice3decimal(item.maxLoanAmount)}
                              onChange={(value: any) => changeLimit(key, idx, 'maxLoanAmount', value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                        <td className="border p-1">
                          <div className="flex justify-center">
                            <PlainInput
                              value={item.minDscr}
                              origin={item.minDscr}
                              content={item.minDscr}
                              onChange={(value: any) => changeLimit(key, idx, 'minDscr', value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                        <td className="border p-1">
                          <div className="flex justify-center">
                            <PlainInput
                              value={item.minMonthsReserve}
                              origin={item.minMonthsReserve}
                              content={item.minMonthsReserve}
                              onChange={(value: any) => changeLimit(key, idx, 'minMonthsReserve', value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                        <td className="border p-1">
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank(item.minLTV)}
                              origin={convertNullToBlank(item.minLTV)}
                              content={convertNullToBlank(item.minLTV)}
                              onChange={(value: any) => changeLimit(key, idx, 'minLTV', value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                        <td className="border p-1">
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank(item.maxLTV)}
                              origin={convertNullToBlank(item.maxLTV)}
                              content={convertNullToBlank(item.maxLTV)}
                              onChange={(value: any) => changeLimit(key, idx, 'maxLTV', value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                        <td className="border p-1">
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank(item.maxCLTV)}
                              origin={convertNullToBlank(item.maxCLTV)}
                              content={convertNullToBlank(item.maxCLTV)}
                              onChange={(value: any) => changeLimit(key, idx, 'maxCLTV', value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>

                        {isTemplate && (
                          <td className="border p-1">
                            <div className="flex justify-center">
                              <div
                                className="w-fit p-1 transition-all duration-200 hover:cursor-pointer hover-shadow1 rounded"
                                onClick={() => removeLimit(idx, key)}
                              >
                                <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                              </div>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>

                {isTemplate && (
                  <div
                    className="flex items-center gap-2 cursor-pointer w-fit text-shade-blue transition-all duration-200 hover:border-b hover:border-b-shade-blue pr-1 h-6 mt-2"
                    onClick={() => addLtvLimit(key)}
                  >
                    <PlusIcon className="w-4 h-4" />
                    <span>Add LTV Limit</span>
                  </div>
                )}
              </div>
            )
          })}
      </div>
    )
  }, [data])

  return (
    <div className=" relative">
      <LayoutLoading show={LoadingStatus.LEVERAGE_LIMIT === loading || isLoading} />

      <div className="px-2 mt-2">
        {isTemplate && edit && (
          <Button
            loading={LoadingStatus.LEVERAGE_LIMIT === loading}
            onClick={() => onSave(data, 'InterestOnlyLeverageLimit')}
            className="pt-1.5 pb-1.5"
          >
            Save
          </Button>
        )}

        {renderLtvLimits}
      </div>
    </div>
  )
}
