import type { IPrepayPenalty } from 'config'
import { useEffect, useState } from 'react'
import { getPrepayPenalty } from 'services/apis/ratesheet'
import { svgLoading } from 'stories/assets'
import { useTitle } from 'utils/pageTitle'

import { changeLogs } from './constants'

export function PrepayPenalty() {
  useTitle('Prepay Penalty - RTLTrader')

  const [data, setData] = useState<Record<string, IPrepayPenalty>>({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const res = await getPrepayPenalty()

      setData(res)
      setLoading(false)
    })()
  }, [])

  const renderPermitted = (value: string) => {
    return value === 'NA' ? 'No' : 'Yes'
  }

  const renderCalculation = (value: string) => {
    switch (value) {
      case 'NoRestriction':
        return 'No restrictions'
      case 'NA':
        return 'N/A'
      default:
        return value
    }
  }

  const renderMaxTerm = (value: any) => {
    switch (value) {
      case 'NA':
        return 'N/A'
      case 'None':
        return 'None'
      case 'Blank':
        return ''
    }

    switch (typeof value) {
      case 'number':
        return `${value} Months`
      case 'string':
        return value
      case 'object':
        return `Purchase: ${value.Purchase} Months\nRefinance: ${value.Refinance} Months`
    }
  }

  const renderApplicability = (value: string) => {
    switch (value) {
      case 'NA':
        return 'N/A'
      case 'Refinance':
        return 'Refinance only'
      case 'Purchase':
        return 'Purchase only'
      case 'All':
        return 'Refinance or sale'
      case 'Blank':
        return ''
    }
  }

  return (
    <div className="relative max-w-screen-xl m-auto shadow1 rounded px-6 py-8 mb-5">
      <div className="flex items-center mb-4">
        <span className="text-2xl font-variation-settings-600">Prepayment Penalty Restrictions</span>
        {loading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </div>

      <div className="mb-4">
        <div className="font-variation-settings-600 mb-2">Applied Programs:</div>
        <div className="pl-4">All 'Long Term Rental(DSCR)' Programs</div>
      </div>

      <div className="mb-6">
        <div className="font-variation-settings-600 mb-2">Change Logs:</div>

        {Object.keys(changeLogs).map((key: string, index: number) => {
          const data: string[] = changeLogs[key]

          return (
            <div className="my-2 pl-4">
              <div className="font-variation-settings-600">- {key}</div>
              <ul className="list-disc pl-7">
                {data.map((item: string, id: number) => (
                  <li key={`${index}-${id}`} className="my-1">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          )
        })}
      </div>

      <table className="w-full text-sm text-center text-gray-900 pl-6">
        <thead className="text-gray-700 bg-gray-50">
          <tr>
            <th scope="col" className="px-3 py-3 border">
              No
            </th>
            <th scope="col" className="px-3 py-3 border">
              State
            </th>
            <th scope="col" className="px-3 py-3 border whitespace-nowrap">
              PPP Permitted
            </th>
            <th scope="col" className="px-3 py-3 border whitespace-nowrap">
              PPP Calculation
            </th>
            <th scope="col" className="px-3 py-3 border whitespace-nowrap">
              PPP Max Term
            </th>
            <th scope="col" className="px-3 py-3 border whitespace-nowrap">
              PPP Applicability
            </th>
            <th scope="col" className="px-3 py-3 border whitespace-nowrap">
              Other Restrictions
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(data).map((state, index: number) => (
            <tr key={index} className={`${index % 2 ? 'bg-slate-50' : 'bg-white'}`}>
              <td className="px-3 py-2 border">{index + 1}</td>
              <td className="px-3 py-2 border">{state}</td>
              <td className="px-3 py-2 border">{renderPermitted(data[state].calculation)}</td>
              <td className="px-3 py-2 border whitespace-nowrap">{renderCalculation(data[state].calculation)}</td>
              <td className="px-3 py-2 border">{renderMaxTerm(data[state].maxTerm)}</td>
              <td className="px-3 py-2 border whitespace-nowrap">{renderApplicability(data[state].applicability)}</td>
              <td className="px-3 py-2 border whitespace-pre-wrap text-left">{data[state].other}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
