import type { IinEligibleProduct, ILtvValues } from 'config'
import { useEffect, useState } from 'react'
import { ButtonGroup, Modal } from 'stories/components'

import { LtvValuesAndLimits } from '../LtvValuesAndLimits/LtvValuesAndLimits'

interface Props {
  inEligibleProducts: IinEligibleProduct
  isOpen: boolean
  programs?: Record<string, any>[]
  onClose: () => void
}

export const InEligibleModal = (props: Props) => {
  const { inEligibleProducts, programs = [], isOpen, onClose } = props

  const [program, setProgram] = useState('')
  const [ltvValues, setLtvValues] = useState<ILtvValues | undefined>(undefined)
  const [ltvLimits, setLtvLimits] = useState<ILtvValues | undefined>(undefined)

  useEffect(() => {
    setProgram(Object.keys(inEligibleProducts)[0])
  }, [inEligibleProducts])

  useEffect(() => {
    if (programs.length === 0) return

    const targetProgram = programs.find((item) => item.Name === program)

    if (targetProgram?.LtvLimit) {
      const ltvValueData: ILtvValues = {
        aivLtv: targetProgram.LtvLimit.aiv_ltv,
        arvLtv: targetProgram.LtvLimit.arv_ltv,
        ltc: targetProgram.LtvLimit.ltc,
        ltp: targetProgram.LtvLimit.ltp,
      }

      const ltvLimitData: ILtvValues = {
        aivLtv: targetProgram.LtvLimit.max_aiv_ltv,
        arvLtv: targetProgram.LtvLimit.max_arv_ltv,
        ltc: targetProgram.LtvLimit.max_ltc,
        ltp: targetProgram.LtvLimit.max_ltp,
      }

      setLtvValues(ltvValueData)
      setLtvLimits(ltvLimitData)
    }
  }, [program])

  return (
    <div>
      <Modal title="Ineligible Programs" isOpen={isOpen} onClose={onClose} titleCancel={''} titleOkay={''}>
        <div>
          <ButtonGroup
            title={Object.keys(inEligibleProducts)}
            value={program}
            onChange={(value) => setProgram(value)}
          />

          {ltvValues && ltvLimits && (
            <div className="mt-2 mb-4 max-w-[600px]">
              <LtvValuesAndLimits values={ltvValues} limits={ltvLimits} />
            </div>
          )}

          <table className="table w-full mt-2 text-sm text-center">
            <thead className="bg-gray-100">
              <tr>
                <th className="border p-2">Field</th>
                <th className="border p-2">Value</th>
                <th className="border p-2">Description</th>
              </tr>
            </thead>

            <tbody>
              {inEligibleProducts[program] &&
                inEligibleProducts[program].map((item, index: number) => (
                  <tr key={index}>
                    <td className="border p-2">{item.name}</td>
                    <td className="border p-2">{item.value}</td>
                    <td className="border p-2">
                      {item.description}{' '}
                      {item.ltvMaxCalcHistory && (
                        <ul className="text-left list-disc pl-4">
                          {item.ltvMaxCalcHistory.map((data, id: number) => (
                            <li key={index + id}>{data}</li>
                          ))}
                        </ul>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  )
}
