import { useEffect, useState } from 'react'
import { Input2, Modal } from 'stories/components'
import { formatDateYMD } from 'utils'

export const UpdateRatesheetDateModal = (props: any) => {
  const [date, setDate] = useState('')

  useEffect(() => {
    setDate(formatDateYMD(props.ratesheetDate))
  }, [props.ratesheetDate])

  return (
    <div>
      <Modal
        title={`Edit Rate Sheet Date - ${props.loanId}`}
        titleOkay="Confirm"
        loading={props.loading === 'editRatesheetDate'}
        isOpen={props.isOpen}
        onClose={props.onClose}
        onOk={() => props.onSubmit(date)}
      >
        <div className="w-80">
          <Input2 type="date" title="Rate Sheet Date" onChange={(value) => setDate(value)} value={date} />
        </div>
      </Modal>
    </div>
  )
}
