import 'sheet-happens/dist/index.css'

import { PencilSquareIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import type { INormalLLPA, IProgram, IRangeLLPA } from 'config'
import { useEffect, useMemo, useState } from 'react'
import excelService, { IStyle } from 'services/excelService'
import Sheet, { Style } from 'sheet-happens'
import { Button, Select } from 'stories/components'
import { solveDecimalJavascriptSum } from 'utils'

import { LoadingStatus, ProgramTypes } from '../..'
import { RangeEditModal } from './RangeEditModal'
import { StateLLPAEditModal } from './StateLLPAEditModal'

interface Props {
  program: IProgram
  loading: string
  onSave: (data: Record<string, number>) => {}
  onSaveRange: (data: IRangeLLPA[] | INormalLLPA[], type: string, key: string, name: string) => void
}

interface IData {
  sheet: any[]
  style: IStyle
}

const rangeNames = {
  fico: 'FICO (DSCR)',
  loanAmount: 'Loan Amount (LLPA)',
  dscr: 'DSCR (LLPA)',
  state: 'State (LLPA)',
}

export function Dscr({ program, loading, onSave, onSaveRange }: Props) {
  const [data, setData] = useState<any[]>([])
  const [sheetStyle, setStyle] = useState<IStyle>({
    inputTypeRows: [],
    LTV: 0,
    Rates: 0,
  })
  const [cellWidth, setCellWidth] = useState<number[]>([])
  const [cellHeight, setCellHeight] = useState<number[]>([])
  const [selectedProgramkey, setSelectedProgramKey] = useState(Math.random() * 100000)
  const [edit, setEdit] = useState<Record<string, number>>({})
  const [modal, setModal] = useState('')
  const [priceDisplay, setPriceDisplay] = useState('Price')

  const columnHeaders = ['A', 'B', 'C']

  const isPoints = useMemo(() => {
    return priceDisplay === 'Points'
  }, [priceDisplay])

  useEffect(() => {
    ;(async function initializeProgram() {
      const nProgram = cloneDeep(program)
      if (isPoints) {
        nProgram.ArmBasePrice = nProgram.ArmBasePrice.map((item) => solveDecimalJavascriptSum([100, -1 * Number(item)]))
        nProgram.FixedBasePrice = nProgram.FixedBasePrice.map((item) =>
          solveDecimalJavascriptSum([100, -1 * Number(item)]),
        )
      }

      const { sheet, style } = (await excelService.parseNormalProgram(nProgram, isPoints)) as IData

      const widths = [200, 200]

      program.LTV.forEach(() => widths.push(85))
      const newWidths = widths.concat([40, 80, 80, 80])

      setCellWidth(newWidths)
      setData(sheet)
      setEdit({})
      setStyle(style)
      setSelectedProgramKey(Math.random() * 100000)
    })()
  }, [program, isPoints])

  useEffect(() => {
    if (!loading) setModal('')
  }, [loading])

  const cellStyle = (x: number, y: number) => {
    if (y === 0 && x <= sheetStyle.LTV + 3) {
      return {
        fillColor: '#6DA2FB22',
        textAlign: 'center',
        marginRight: 10,
        weight: 'bold',
        marginTop: '120px',
        marginBottom: '120px',
        height: '200px',
      }
    }
    if (x > sheetStyle.LTV && x <= sheetStyle.LTV + 1) {
      if (y >= 1 && y <= sheetStyle.Rates) {
        return {
          fillColor: '#f7f9fa',
          textAlign: 'center',
        }
      }
    }
    if (y === data.length && x < sheetStyle.LTV) {
      return {
        fillColor: '#6DA2FB22',
      }
    }
    if (x === sheetStyle.LTV && y <= data.length) {
      return {
        fillColor: '#6DA2FB22',
      }
    }
    if (sheetStyle.inputTypeRows.indexOf(y) !== -1 && x < sheetStyle.LTV) {
      return {
        fillColor: '#f7f9fa',
      }
    }
    if (x >= 2) {
      return {
        textAlign: 'center',
      }
    }
    return {
      textAlign: 'left',
    }
  }

  const editData = (x: number, y: number) => {
    return data?.[y]?.[x]
  }

  const displayData = (x: number, y: number) => {
    return data?.[y]?.[x]
  }

  const sourceData = (x: number, y: number) => {
    return data?.[y]?.[x]
  }

  const isReadOnly = (x: number, y: number) => {
    if (x === 0 || x === 1 || y === 0) {
      return true
    }
    return false
  }

  const onCellWidthChange = (columnIdx: any, newWidth: any) => {
    const cw: number[] = [...cellWidth]
    if (columnIdx > cw.length) {
      for (let i = cw.length; i <= columnIdx; i++) {
        cw.push(100)
      }
    }
    cw[columnIdx] = newWidth
    setCellWidth(cw)
  }
  const onCellHeightChange = (rowIdx: any, newHeight: any) => {
    const ch = [...cellHeight]
    if (rowIdx > ch.length) {
      for (let i = ch.length; i <= rowIdx; i++) {
        ch.push(22)
      }
    }
    ch[rowIdx] = newHeight
    setCellHeight(ch)
  }

  const onSelectionChanged = (x1: number, y1: number, x2: number, y2: number) => {
    console.log('track change', x1, y1, x2, y2)
    return {}
  }

  const onChange = async (changes: any[]) => {
    if (!data) return
    if (!sheetStyle) return

    const newData = [...data]
    let newEdit: Record<string, number> = { ...edit }
    for (const change of changes) {
      let { x, y, value } = change

      if (newData[y][x] === value) continue

      let flag = 0
      let type = ''
      let posy = 0
      let posx = 0
      let doc = 0
      if (x >= sheetStyle.LTV + 1 && x <= sheetStyle.LTV + 3) {
        if (y >= 1) {
          flag = 1
        }
        if (x === sheetStyle.LTV + 1) type = 'InterestRate'
        if (x === sheetStyle.LTV + 2) type = 'ArmBasePrice'
        if (x === sheetStyle.LTV + 3) type = 'FixedBasePrice'
        posy = y - 1
      }

      if (x >= 2 && x < sheetStyle.LTV && sheetStyle.inputTypeRows.indexOf(y) === -1 && 1 <= y && y <= data.length) {
        flag = 1
        posx = x - 2
        for (let i = y; i > 0; i -= 1) {
          if (sheetStyle.inputTypeRows.indexOf(i) !== -1) {
            posy = i
            break
          }
        }
        type = data[posy][0]
        posy = y - posy - 1
        if (type.indexOf(' — ') !== -1) {
          const params = type.split(' — ')
          type = params[0]
          doc = Number(params[1])
        } else {
          doc = 0
        }
      }
      if (flag) {
        if (!newData[y]) {
          newData[y] = []
        }
        try {
          if (value === '') value = ''
          else value = isNaN(Number(value)) ? '' : Number(Number(value).toFixed(3))

          newData[y][x] = value
          newEdit[`${type}-${posx}-${posy}-${doc}`] =
            ['ArmBasePrice', 'FixedBasePrice'].includes(type) && isPoints
              ? solveDecimalJavascriptSum([100, -1 * Number(value)])
              : value
        } catch (error) {
          console.log('change excel cell', error)
        }
      }
    }

    setEdit(newEdit)
    setData(newData)
  }

  const renderModal = useMemo(() => {
    if (!modal) return

    const { IncomeDocs, LLPAs, LTV } = program

    const dscrIncomeDocLLPA = IncomeDocs.find((item) => item.IncomeDocItems.includes('DebtServiceCoverageRatio'))?.LLPA
    const dscrLLPA = LLPAs.find((item) => item.ID === 'DSCR')?.Values
    const stateLLPA = LLPAs.find((item) => item.ID === 'State')?.Values
    const loanAmountLLPA = LLPAs.find((item) => item.ID === 'LoanAmount')?.Values

    switch (modal) {
      case rangeNames.fico:
        return (
          <RangeEditModal
            name={modal}
            range={dscrIncomeDocLLPA || []}
            LTVs={LTV}
            loading={loading}
            onClose={() => setModal('')}
            onSave={(llpa) => onSaveRange(llpa, 'incomeDoc', 'DebtServiceCoverageRatio', rangeNames.fico)}
          />
        )
      case rangeNames.loanAmount:
        return (
          <RangeEditModal
            name={modal}
            range={loanAmountLLPA || []}
            LTVs={LTV}
            loading={loading}
            onClose={() => setModal('')}
            onSave={(llpa) => onSaveRange(llpa, 'llpa', 'LoanAmount', rangeNames.loanAmount)}
          />
        )
      case rangeNames.dscr:
        return (
          <RangeEditModal
            name={modal}
            range={dscrLLPA || []}
            LTVs={LTV}
            rangeStep={0.01}
            loading={loading}
            onClose={() => setModal('')}
            onSave={(llpa) => onSaveRange(llpa, 'llpa', 'DSCR', rangeNames.dscr)}
          />
        )
      case rangeNames.state:
        return (
          <StateLLPAEditModal
            stateLLPA={stateLLPA || []}
            LTVs={LTV}
            loading={loading}
            onClose={() => setModal('')}
            onSave={(llpa) => onSaveRange(llpa, 'llpa', 'State', rangeNames.state)}
          />
        )
    }
  }, [modal, loading, program])

  return (
    <div className="pt-3 pb-6">
      <div className="relative max-w-screen-2xl m-auto w-full">
        <div className="flex items-end justify-between mb-4">
          <div className="flex items-center gap-x-6 h-6">
            {[ProgramTypes.BEYOND_PRIME_INVESTOR, ProgramTypes.MULTIFLOW].includes(program.Type as ProgramTypes) && (
              <div
                className="flex items-center text-sm hover:cursor-pointer text-shade-blue hover:border-b hover:border-b-shade-blue transition-all duration-200 pb-1"
                onClick={() => setModal(rangeNames.fico)}
              >
                <PencilSquareIcon className="w-4 h-4 mr-1" />
                <span>FICO (DSCR)</span>
              </div>
            )}

            <div
              className="flex items-center text-sm hover:cursor-pointer text-shade-blue hover:border-b hover:border-b-shade-blue transition-all duration-200 pb-1"
              onClick={() => setModal(rangeNames.loanAmount)}
            >
              <PencilSquareIcon className="w-4 h-4 mr-1" />
              <span>Loan Amount (LLPA)</span>
            </div>

            <div
              className="flex items-center text-sm hover:cursor-pointer text-shade-blue hover:border-b hover:border-b-shade-blue transition-all duration-200 pb-1"
              onClick={() => setModal(rangeNames.state)}
            >
              <PencilSquareIcon className="w-4 h-4 mr-1" />
              <span>State (LLPA)</span>
            </div>

            {[ProgramTypes.BEYOND_PRIME_INVESTOR, ProgramTypes.MULTIFLOW].includes(program.Type as ProgramTypes) && (
              <div
                className="flex items-center text-sm hover:cursor-pointer text-shade-blue hover:border-b hover:border-b-shade-blue transition-all duration-200 pb-1"
                onClick={() => setModal(rangeNames.dscr)}
              >
                <PencilSquareIcon className="w-4 h-4 mr-1" />
                <span>DSCR Range (LLPA)</span>
              </div>
            )}

            <Select
              id="priceDisplay"
              title="Price Display"
              className="w-24 -mb-4"
              options={['Price', 'Points']}
              value={priceDisplay}
              onChange={(value: any) => setPriceDisplay(value)}
            />
          </div>

          {program && Object.keys(edit).length > 0 && (
            <div className="flex justify-end">
              <Button
                loading={loading === LoadingStatus.SAVE_PROGRAM_CHANGES}
                onClick={async () => {
                  await onSave(edit)
                  setEdit({})
                }}
                size="sm"
                className="-mb-2"
              >
                Save
              </Button>
            </div>
          )}
        </div>
        <Sheet
          onSelectionChanged={onSelectionChanged}
          onRightClick={() => {}}
          columnHeaders={columnHeaders}
          cellStyle={cellStyle as Style}
          editData={editData}
          displayData={displayData}
          sourceData={sourceData}
          cellWidth={cellWidth}
          cellHeight={cellHeight}
          onChange={onChange}
          readOnly={isReadOnly}
          onCellWidthChange={onCellWidthChange}
          onCellHeightChange={onCellHeightChange}
          sheetStyle={{
            freezeColumns: 2,
            freezeRows: 1,
          }}
          key={selectedProgramkey}
        />
      </div>

      {renderModal}
    </div>
  )
}
