import { InputType } from 'config'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    websiteUrl: {
      inputType: 'text',
      type: 'text',
      title: 'Website',
      value: '',
      error: '',
      history: true,
    },
    apiUrl: {
      inputType: 'text',
      type: 'text',
      title: 'API URL',
      value: '',
      error: '',
      history: true,
      disabled: true,
    },
    ratesheetId: {
      inputType: 'select',
      options: [],
      title: 'Pricing Rate Sheet ID',
      value: '',
      error: '',
      history: true,
      hasDefaultOption: true,
    },
    secretKey: {
      inputType: 'text',
      type: 'text',
      title: 'Secret Key',
      value: '',
      error: '',
      history: true,
      disabled: true,
    },
  }
}
