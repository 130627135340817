import { ArchiveBoxXMarkIcon, TrashIcon } from '@heroicons/react/24/outline'
import { PlainInput } from 'components/PlainInput'
import type { IDscrFirstTimeInvestorValueLeverageLimit } from 'config'
import {
  convertNullToBlank,
  convertNullToBlankValueToDecimal,
} from 'pages/RateSheetOverview/ExcelSheets/CommercialOnlyProgrammed/logic'
import { Fragment } from 'react'
import { Select } from 'stories/components'
import { isEmpty } from 'utils'

interface IProps {
  data: IDscrFirstTimeInvestorValueLeverageLimit[]
  title: string
  isTemplate: boolean
  readOnlyValue?: boolean
  options?: Record<string, string>
  onChangeValue?: (index: number, value: string) => void
  onChangeLoanAmountOverly: (
    index: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: string,
    value: any,
  ) => void
  onChangeLtvLimit: (
    index: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: 'min' | 'max',
    value: any,
  ) => void
  onChangeCLTVLimit: (index: number, loanPurpose: 'purchase' | 'nocashout' | 'cashout', value: any) => void
  onChangeMinDscrFicoMonthsReserve: (
    index: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: string,
    value: any,
  ) => void
  removeLtvLimit?: (index: number) => void
}

export const DscrFirstTimeInvestorLeverageLimitTable = (props: IProps) => {
  const {
    data,
    title,
    isTemplate,
    readOnlyValue = false,
    options = {},
    onChangeValue = () => {},
    onChangeLoanAmountOverly,
    onChangeLtvLimit,
    onChangeCLTVLimit,
    onChangeMinDscrFicoMonthsReserve,
    removeLtvLimit = () => {},
  } = props

  const minDscrMonthsReserveKeys = ['minDSCR', 'minMonthsReserve']

  if (data.length === 0)
    return (
      <div className="w-full flex justify-center items-center mt-10">
        <div className="flex flex-col items-center gap-1">
          <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
          <span className="text-gray-400">No Limits</span>
        </div>
      </div>
    )

  return (
    <table className="table w-full text-center text-sm">
      <thead className="bg-gray-100">
        <tr>
          <th rowSpan={2} className="border p-1 whitespace-nowrap">
            {title}
          </th>
          <th rowSpan={2} className="border p-1">
            Loan Purpose
          </th>
          <th colSpan={2} className="border p-1">
            Loan Amount
          </th>
          <th rowSpan={2} className="border p-1 w-[80px]">
            Min DSCR
          </th>
          <th rowSpan={2} className="border p-1 w-[100px]">
            Min Months Reserve
          </th>
          <th colSpan={2} className="border p-1">
            LTV
          </th>
          <th rowSpan={2} className="border p-1 w-[85px]">
            Max CLTV
          </th>
          {isTemplate && !readOnlyValue && (
            <th rowSpan={2} className="border p-1">
              Action
            </th>
          )}
        </tr>
        <tr>
          <th className="border p-1 w-[125px]">From</th>
          <th className="border p-1 w-[125px]">To</th>
          <th className="border p-1 w-[60px]">Min</th>
          <th className="border p-1 w-[60px]">Max</th>
        </tr>
      </thead>

      <tbody>
        {data.map((item, index) => {
          return (
            <Fragment key={index}>
              <tr>
                <td rowSpan={3} className={`border whitespace-nowrap p-1`}>
                  {isEmpty(options) ? (
                    (item as any).label
                  ) : isTemplate && !readOnlyValue ? (
                    <Select
                      id="value"
                      options={options}
                      hasDefaultOption={true}
                      value={(item as any).value}
                      className="w-full mb-[-16px]"
                      onChange={(v) => onChangeValue(index, v)}
                    />
                  ) : (
                    options[(item as any).value]
                  )}
                </td>
                <td className={`border whitespace-nowrap p-1`}>Purchase</td>

                {['min', 'max'].map((key, index1) => (
                  <td key={`${index}-${index1}`} className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank((item.loanAmount.purchase as any)[key])}
                        origin={convertNullToBlank((item.loanAmount.purchase as any)[key])}
                        content={convertNullToBlankValueToDecimal((item.loanAmount.purchase as any)[key])}
                        onChange={(value: any) => onChangeLoanAmountOverly(index, 'purchase', key, value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                ))}
                {minDscrMonthsReserveKeys.map((key, idx) => (
                  <td key={`${index}-${idx}`} className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank((item as any)[key].purchase)}
                        origin={convertNullToBlank((item as any)[key].purchase)}
                        content={convertNullToBlank((item as any)[key].purchase)}
                        onChange={(value: any) => onChangeMinDscrFicoMonthsReserve(index, 'purchase', key, value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                ))}
                {['min', 'max'].map((key, index1) => (
                  <td key={`${index}-${index1}`} className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.limit.purchase.ltv[key as 'min' | 'max'])}
                        origin={convertNullToBlank(item.limit.purchase.ltv[key as 'min' | 'max'])}
                        content={convertNullToBlank(item.limit.purchase.ltv[key as 'min' | 'max'])}
                        onChange={(value: any) => onChangeLtvLimit(index, 'purchase', key as 'min' | 'max', value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                ))}
                <td className={`border whitespace-nowrap p-1`}>
                  <div className="flex justify-center">
                    <PlainInput
                      value={convertNullToBlank(item.limit.purchase.cltv)}
                      origin={convertNullToBlank(item.limit.purchase.cltv)}
                      content={convertNullToBlank(item.limit.purchase.cltv)}
                      onChange={(value: any) => onChangeCLTVLimit(index, 'purchase', value)}
                      disabled={!isTemplate}
                      className="w-full"
                    />
                  </div>
                </td>

                {isTemplate && !readOnlyValue && (
                  <td rowSpan={3} className="border p-1">
                    <div className="flex justify-center">
                      <div
                        className="w-fit p-1 transition-all duration-200 hover:cursor-pointer hover-shadow1 rounded"
                        onClick={() => removeLtvLimit(index)}
                      >
                        <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                      </div>
                    </div>
                  </td>
                )}
              </tr>

              <tr>
                <td className={`border whitespace-nowrap p-1`}>No-Cashout</td>

                {['min', 'max'].map((key, index1) => (
                  <td key={`${index}-${index1}`} className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank((item.loanAmount.nocashout as any)[key])}
                        origin={convertNullToBlank((item.loanAmount.nocashout as any)[key])}
                        content={convertNullToBlankValueToDecimal((item.loanAmount.nocashout as any)[key])}
                        onChange={(value: any) => onChangeLoanAmountOverly(index, 'nocashout', key, value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                ))}
                {minDscrMonthsReserveKeys.map((key, idx) => (
                  <td key={`${index}-${idx}`} className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank((item as any)[key].nocashout)}
                        origin={convertNullToBlank((item as any)[key].nocashout)}
                        content={convertNullToBlank((item as any)[key].nocashout)}
                        onChange={(value: any) => onChangeMinDscrFicoMonthsReserve(index, 'nocashout', key, value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                ))}
                {['min', 'max'].map((key, index1) => (
                  <td key={`${index}-${index1}`} className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.limit.nocashout.ltv[key as 'min' | 'max'])}
                        origin={convertNullToBlank(item.limit.nocashout.ltv[key as 'min' | 'max'])}
                        content={convertNullToBlank(item.limit.nocashout.ltv[key as 'min' | 'max'])}
                        onChange={(value: any) => onChangeLtvLimit(index, 'nocashout', key as 'min' | 'max', value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                ))}
                <td className={`border whitespace-nowrap p-1`}>
                  <div className="flex justify-center">
                    <PlainInput
                      value={convertNullToBlank(item.limit.nocashout.cltv)}
                      origin={convertNullToBlank(item.limit.nocashout.cltv)}
                      content={convertNullToBlank(item.limit.nocashout.cltv)}
                      onChange={(value: any) => onChangeCLTVLimit(index, 'nocashout', value)}
                      disabled={!isTemplate}
                      className="w-full"
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <td className={`border whitespace-nowrap p-1`}>Cashout</td>

                {['min', 'max'].map((key, index1) => (
                  <td key={`${index}-${index1}`} className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank((item.loanAmount.cashout as any)[key])}
                        origin={convertNullToBlank((item.loanAmount.cashout as any)[key])}
                        content={convertNullToBlankValueToDecimal((item.loanAmount.cashout as any)[key])}
                        onChange={(value: any) => onChangeLoanAmountOverly(index, 'cashout', key, value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                ))}
                {minDscrMonthsReserveKeys.map((key, idx) => (
                  <td key={`${index}-${idx}`} className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank((item as any)[key].cashout)}
                        origin={convertNullToBlank((item as any)[key].cashout)}
                        content={convertNullToBlank((item as any)[key].cashout)}
                        onChange={(value: any) => onChangeMinDscrFicoMonthsReserve(index, 'cashout', key, value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                ))}
                {['min', 'max'].map((key, index1) => (
                  <td key={`${index}-${index1}`} className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.limit.cashout.ltv[key as 'min' | 'max'])}
                        origin={convertNullToBlank(item.limit.cashout.ltv[key as 'min' | 'max'])}
                        content={convertNullToBlank(item.limit.cashout.ltv[key as 'min' | 'max'])}
                        onChange={(value: any) => onChangeLtvLimit(index, 'cashout', key as 'min' | 'max', value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                ))}
                <td className={`border whitespace-nowrap p-1`}>
                  <div className="flex justify-center">
                    <PlainInput
                      value={convertNullToBlank(item.limit.cashout.cltv)}
                      origin={convertNullToBlank(item.limit.cashout.cltv)}
                      content={convertNullToBlank(item.limit.cashout.cltv)}
                      onChange={(value: any) => onChangeCLTVLimit(index, 'cashout', value)}
                      disabled={!isTemplate}
                      className="w-full"
                    />
                  </div>
                </td>
              </tr>
            </Fragment>
          )
        })}
      </tbody>
    </table>
  )
}
