import { LoadingStatus } from 'pages/RateSheetOverview'
import { useState } from 'react'
import { Input2, Modal } from 'stories/components'

interface IProps {
  value: number
  isOpen: boolean
  loading: string
  onClose: () => void
  onSubmit: (value: number) => void
}

export function UpdateOccupancyLtvReduction(props: IProps) {
  const { value, isOpen, loading, onClose, onSubmit } = props
  const [ltvReduction, setLtvReduction] = useState<number>(value)

  return (
    <Modal
      title="Update LTV Reduction"
      titleOkay="Save"
      isOpen={isOpen}
      loading={loading === LoadingStatus.UPDATE_DSCR_OCCUPANCY_LTV_REDUCTION}
      onClose={onClose}
      onOk={() => onSubmit(ltvReduction)}
    >
      <div>
        <Input2
          type="number"
          title="LTV Reduction"
          onChange={(value) => setLtvReduction(Number(value))}
          value={ltvReduction?.toString()}
        />
      </div>
    </Modal>
  )
}
