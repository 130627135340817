import { useEffect, useMemo, useState } from 'react'
import { Input2, Modal, Select2 } from 'stories/components'
import { removeComma } from 'utils'

import type { ISelectedRangeData } from './MsaTiers'

interface Props {
  data: ISelectedRangeData
  isOpen: boolean
  onClose: () => void
  onSave: (index: number, key: 'aivValue' | 'arvValue' | 'unitsRange', from: number, to: number) => void
}

export function UpdateAivArvValueRange(props: Props) {
  const { data, isOpen, onClose, onSave } = props

  const [from, setFrom] = useState(data.from)
  const [to, setTo] = useState(data.to)
  const [error, setError] = useState('')

  const [unitOptions, setUnitOptions] = useState<string[]>([])

  useEffect(() => {
    const options: string[] = []

    for (let i = 1; i <= 100; i++) {
      options.push(i.toString())
    }

    setUnitOptions(options)
  }, [])

  const onChange = (key: 'from' | 'to', value: string) => {
    const newValue = removeComma(value)

    if (key === 'from') {
      setFrom(newValue)
    } else if (key === 'to') {
      setTo(newValue)
    } else return
  }

  const onOk = () => {
    if (from > to) {
      setError('From must be lower than To')
      return
    }

    onSave(data.index, data.key, from, to)
  }

  const renderRange = useMemo(() => {
    switch (data.key) {
      case 'aivValue':
      case 'arvValue':
        return (
          <>
            <Input2
              type="thousandSep"
              title="From"
              onChange={(value) => onChange('from', value)}
              value={String(from)}
              className="mb-4"
            />
            <Input2 type="thousandSep" title="To" onChange={(value) => onChange('to', value)} value={String(to)} />
          </>
        )
      case 'unitsRange':
        return (
          <>
            <Select2
              id="from"
              title="From"
              options={unitOptions}
              value={String(from)}
              hasDefaultOption={true}
              className="mb-4"
              onChange={(value) => onChange('from', value)}
            />
            <Select2
              id="to"
              title="To"
              options={unitOptions}
              value={String(to)}
              hasDefaultOption={true}
              onChange={(value) => onChange('to', value)}
            />
          </>
        )
      default:
        return <></>
    }
  }, [data, unitOptions, from, to])

  return (
    <Modal
      title={`Update ${
        data.key === 'aivValue'
          ? 'As Is Appraised Value'
          : data.key === 'arvValue'
          ? 'After Repair Value'
          : 'Number of Units'
      } Range`}
      titleOkay="Save"
      isOpen={isOpen}
      onClose={onClose}
      onOk={onOk}
    >
      <div className="w-[400px]">
        {error && <div className="mb-3 text-sm text-red-600">{error}</div>}
        {renderRange}
      </div>
    </Modal>
  )
}
