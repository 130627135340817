import { ArchiveBoxXMarkIcon, TrashIcon } from '@heroicons/react/24/outline'
import { PlainInput } from 'components/PlainInput'
import type { ICommercialOnlyProgrammed, IValueLeverageLimit } from 'config'
import {
  convertNullToBlank,
  convertNullToBlankValueToDecimal,
} from 'pages/RateSheetOverview/ExcelSheets/CommercialOnlyProgrammed/logic'
import { Fragment } from 'react'
import { Select } from 'stories/components'
import { getPrice3decimal, isEmpty } from 'utils'

interface IProps {
  programData: ICommercialOnlyProgrammed | undefined
  data: IValueLeverageLimit[]
  title: string
  isTemplate: boolean
  readOnlyValue?: boolean
  options?: Record<string, string>
  addSubLimit: (index: number) => void
  onChangeValue: (index: number, value: string) => void
  onChangeFICO: (index: number, idx: number, key: string, value: any) => void
  onChangeLoanAmountOverly: (
    index: number,
    idx: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: string,
    value: any,
  ) => void
  onChangeLtvLimit: (
    index: number,
    idx: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    key: string,
    value: any,
  ) => void
  onChangeMinMonthsReserve: (
    index: number,
    idx: number,
    loanPurpose: 'purchase' | 'nocashout' | 'cashout',
    value: any,
  ) => void
  removeLimit: (index: number, idx: number) => void
  onChangeMaxLoanAmount: (index: number, value: any) => void
  onChangeMinFICO: (index: number, value: any) => void
}

export default function LeverageLimitTable(props: IProps) {
  const {
    programData,
    data,
    title,
    isTemplate,
    readOnlyValue = false,
    options = {},
    addSubLimit,
    onChangeValue,
    onChangeFICO,
    onChangeLoanAmountOverly,
    onChangeLtvLimit,
    onChangeMinMonthsReserve,
    removeLimit,
    onChangeMaxLoanAmount,
    onChangeMinFICO,
  } = props

  if (!programData || data.length === 0)
    return (
      <div className="w-full flex justify-center items-center mt-10">
        <div className="flex flex-col items-center gap-1">
          <ArchiveBoxXMarkIcon className="w-12 h-12 text-gray-400" />
          <span className="text-gray-400">No Limits</span>
        </div>
      </div>
    )

  return (
    <div className="overflow-x-auto">
      <table className="table min-w-max w-full text-center text-sm">
        <thead className="bg-gray-100">
          <tr>
            <th rowSpan={2} className="border p-1 whitespace-nowrap">
              {title}
            </th>
            <th rowSpan={2} className="border p-1 whitespace-nowrap w-[150px]">
              Max Loan Amount
            </th>
            <th rowSpan={2} className="border p-1 whitespace-nowrap w-[90px]">
              Min FICO
            </th>
            <th colSpan={2} className="border p-1 whitespace-nowrap">
              FICO
            </th>
            <th rowSpan={2} className="border p-1 whitespace-nowrap">
              Loan Purpose
            </th>
            <th colSpan={2} className="border p-1 whitespace-nowrap">
              Loan Amount
            </th>
            <th rowSpan={2} className="border p-1 w-[100px]">
              Min Months Reserve
            </th>
            <th colSpan={4} className="border p-1 whitespace-nowrap">
              Max LTVs
            </th>

            {isTemplate && !readOnlyValue && (
              <th rowSpan={2} className="border p-1">
                Action
              </th>
            )}
          </tr>
          <tr>
            <th className="border p-1 w-[70px]">From</th>
            <th className="border p-1 w-[70px]">To</th>
            <th className="border p-1 w-[100px]">From</th>
            <th className="border p-1 w-[100px]">To</th>
            <th className="border p-1 w-[80px]">AIV-LTV</th>
            <th className="border p-1 w-[80px]">ARV-LTV</th>
            <th className="border p-1 w-[80px]">LTC</th>
            <th className="border p-1 w-[80px]">LTP</th>
          </tr>
        </thead>

        <tbody>
          {data.map((item, index) => {
            return (
              <Fragment key={index}>
                <tr>
                  <td rowSpan={3 * item.limits.length + 1} className="border p-1">
                    {isTemplate && !readOnlyValue ? (
                      <Select
                        id="value"
                        options={options}
                        hasDefaultOption={true}
                        value={item.value}
                        className="mb-[-16px]"
                        onChange={(v) => onChangeValue(index, v)}
                      />
                    ) : (
                      options[item.value] || item.value
                    )}

                    {isTemplate && (
                      <div className="mt-5 flex justify-center w-full">
                        <span
                          className="cursor-pointer text-shade-blue hover:scale-105 transform duration-100 hover:underline"
                          onClick={() => addSubLimit(index)}
                        >
                          Add Limit
                        </span>
                      </div>
                    )}
                  </td>
                  <td rowSpan={3 * item.limits.length + 1} className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={convertNullToBlank(item.loanAmount)}
                        origin={convertNullToBlank(item.loanAmount)}
                        content={getPrice3decimal(item.loanAmount)}
                        onChange={(value: any) => onChangeMaxLoanAmount(index, value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                  <td rowSpan={3 * item.limits.length + 1} className={`border whitespace-nowrap p-1`}>
                    <div className="flex justify-center">
                      <PlainInput
                        value={item.minFICO}
                        origin={item.minFICO}
                        content={item.minFICO}
                        onChange={(value: any) => onChangeMinFICO(index, value)}
                        disabled={!isTemplate}
                        className="w-full"
                      />
                    </div>
                  </td>
                </tr>

                {item.limits.map((limit, idx) => (
                  <Fragment key={`${index}-${idx}`}>
                    <tr>
                      {['from', 'to'].map((key, index1) => (
                        <td rowSpan={3} key={`${index}-${idx}-${index1}`} className={`border whitespace-nowrap p-1`}>
                          <div className="flex justify-center">
                            <PlainInput
                              value={(limit.FICO as any)[key]}
                              origin={(limit.FICO as any)[key]}
                              content={(limit.FICO as any)[key]}
                              onChange={(value: any) => onChangeFICO(index, idx, key, value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}

                      <td className="border p-1">Purchase</td>

                      {['min', 'max'].map((key, index1) => (
                        <td
                          key={`${index}-${idx}-${index1}`}
                          className={`border whitespace-nowrap p-1 ${isTemplate ? 'bg-gray-50' : ''}`}
                        >
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank((limit.loanAmount.purchase as any)[key])}
                              origin={convertNullToBlank((limit.loanAmount.purchase as any)[key])}
                              content={convertNullToBlankValueToDecimal((limit.loanAmount.purchase as any)[key])}
                              onChange={(value: any) => onChangeLoanAmountOverly(index, idx, 'purchase', key, value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}

                      <td className={`p-1 border ${!isTemplate ? '' : 'bg-gray-50'}`}>
                        <div className="flex justify-center">
                          <PlainInput
                            value={convertNullToBlank(limit.minMonthsReserve.purchase)}
                            origin={convertNullToBlank(limit.minMonthsReserve.purchase)}
                            content={convertNullToBlank(limit.minMonthsReserve.purchase)}
                            disabled={!isTemplate}
                            onChange={(value: any) => onChangeMinMonthsReserve(index, idx, 'purchase', value)}
                            className="w-full"
                          />
                        </div>
                      </td>

                      {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, index1) => (
                        <td
                          key={`${index}-${idx}-${index1}`}
                          className={`p-1 border ${
                            isEmpty((programData.ltvLimits.purchase as any)[key]) || !isTemplate ? '' : 'bg-gray-50'
                          }`}
                        >
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank((limit.LTV.purchase as any)[key])}
                              origin={convertNullToBlank((limit.LTV.purchase as any)[key])}
                              content={convertNullToBlank((limit.LTV.purchase as any)[key])}
                              disabled={isEmpty((programData.ltvLimits.purchase as any)[key]) || !isTemplate}
                              onChange={(value: any) => onChangeLtvLimit(index, idx, 'purchase', key, value)}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}

                      {isTemplate && !readOnlyValue && (
                        <td rowSpan={3} className="border p-1">
                          <div className="flex justify-center">
                            <div
                              className="w-fit p-1 transition-all duration-200 hover:cursor-pointer hover-shadow1 rounded"
                              onClick={() => removeLimit(index, idx)}
                            >
                              <TrashIcon className="w-4 h-4 hover:cursor-pointer text-red-700" />
                            </div>
                          </div>
                        </td>
                      )}
                    </tr>

                    <tr>
                      <td className="border p-1">No-Cashout</td>

                      {['min', 'max'].map((key, index1) => (
                        <td
                          key={`${index}-${idx}-${index1}`}
                          className={`border whitespace-nowrap p-1 ${isTemplate ? 'bg-gray-50' : ''}`}
                        >
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank((limit.loanAmount.nocashout as any)[key])}
                              origin={convertNullToBlank((limit.loanAmount.nocashout as any)[key])}
                              content={convertNullToBlankValueToDecimal((limit.loanAmount.nocashout as any)[key])}
                              onChange={(value: any) => onChangeLoanAmountOverly(index, idx, 'nocashout', key, value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}

                      <td className={`p-1 border ${!isTemplate ? '' : 'bg-gray-50'}`}>
                        <div className="flex justify-center">
                          <PlainInput
                            value={convertNullToBlank(limit.minMonthsReserve.nocashout)}
                            origin={convertNullToBlank(limit.minMonthsReserve.nocashout)}
                            content={convertNullToBlank(limit.minMonthsReserve.nocashout)}
                            disabled={!isTemplate}
                            onChange={(value: any) => onChangeMinMonthsReserve(index, idx, 'nocashout', value)}
                            className="w-full"
                          />
                        </div>
                      </td>

                      {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, index1) => (
                        <td
                          key={`${index}-${idx}-${index1}`}
                          className={`p-1 border ${
                            isEmpty((programData.ltvLimits.nocashout as any)[key]) || !isTemplate ? '' : 'bg-gray-50'
                          }`}
                        >
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank((limit.LTV.nocashout as any)[key])}
                              origin={convertNullToBlank((limit.LTV.nocashout as any)[key])}
                              content={convertNullToBlank((limit.LTV.nocashout as any)[key])}
                              disabled={isEmpty((programData.ltvLimits.nocashout as any)[key]) || !isTemplate}
                              onChange={(value: any) => onChangeLtvLimit(index, idx, 'nocashout', key, value)}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}
                    </tr>

                    <tr>
                      <td className="border p-1">Cashout</td>

                      {['min', 'max'].map((key, index1) => (
                        <td
                          key={`${index}-${idx}-${index1}`}
                          className={`border whitespace-nowrap p-1 ${isTemplate ? 'bg-gray-50' : ''}`}
                        >
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank((limit.loanAmount.cashout as any)[key])}
                              origin={convertNullToBlank((limit.loanAmount.cashout as any)[key])}
                              content={convertNullToBlankValueToDecimal((limit.loanAmount.cashout as any)[key])}
                              onChange={(value: any) => onChangeLoanAmountOverly(index, idx, 'cashout', key, value)}
                              disabled={!isTemplate}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}

                      <td className={`p-1 border ${!isTemplate ? '' : 'bg-gray-50'}`}>
                        <div className="flex justify-center">
                          <PlainInput
                            value={convertNullToBlank(limit.minMonthsReserve.cashout)}
                            origin={convertNullToBlank(limit.minMonthsReserve.cashout)}
                            content={convertNullToBlank(limit.minMonthsReserve.cashout)}
                            disabled={!isTemplate}
                            onChange={(value: any) => onChangeMinMonthsReserve(index, idx, 'cashout', value)}
                            className="w-full"
                          />
                        </div>
                      </td>

                      {['aivLtv', 'arvLtv', 'ltc', 'ltp'].map((key, index1) => (
                        <td
                          key={`${index}-${idx}-${index1}`}
                          className={`p-1 border ${
                            isEmpty((programData.ltvLimits.cashout as any)[key]) || !isTemplate ? '' : 'bg-gray-50'
                          }`}
                        >
                          <div className="flex justify-center">
                            <PlainInput
                              value={convertNullToBlank((limit.LTV.cashout as any)[key])}
                              origin={convertNullToBlank((limit.LTV.cashout as any)[key])}
                              content={convertNullToBlank((limit.LTV.cashout as any)[key])}
                              disabled={isEmpty((programData.ltvLimits.cashout as any)[key]) || !isTemplate}
                              onChange={(value: any) => onChangeLtvLimit(index, idx, 'cashout', key, value)}
                              className="w-full"
                            />
                          </div>
                        </td>
                      ))}
                    </tr>
                  </Fragment>
                ))}
              </Fragment>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
